import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {InputLabel,FormControl,Select as MaterialSelect  ,Box 
  ,FormControlLabel,LinearProgress,Checkbox,RadioGroup,Radio,FormLabel} from '@material-ui/core';
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";

import Modal from './ModalDummy';
import Rating from '@material-ui/lab/Rating';
const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '20ch',
      }, 
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
      Textnumero: {
        width: 5+"px",
      },
      formControlFull: {
        //margin: theme.spacing(1),
        //minWidth: 120,
        width:5+"rem"
      },
      margin: {
        margin: theme.spacing(1),
      },
    },
  }));

const EditarActividad = ({modalAbierto,setModalAbierto,titulo,auxActividad}) => {
  let history = useHistory();
  const classes = useStyles();
  const [espera,setEspera]=React.useState(false);
  const [mensaje, setMensaje] = React.useState('');
  const [tipoAdvertencia, setTipoAdvertencia] = React.useState('success');
  const [aviso, setAviso] = React.useState(false);  
  const [nvActividad,guardarNvActividad]= React.useState({Orden:0,Tipo:0,Estado:1,Finaliza:0,
                                                          Prioridad:0,Descripcion:'',Solucion:'',ActividadId:0});  
  const {Orden,Tipo,Estado,Finaliza,Prioridad,Descripcion,Solucion,ActividadId}=nvActividad  
  const [listTipo, guardarListTipo] = React.useState([])
  const [listStatusActivid, guardarListStatusActivid] = React.useState([])
  //const [value, setValue] = React.useState(0);
  React.useEffect(()=>{           
    console.log(auxActividad);
    const{ordenId,Id,descr,prioridad,solucion,tipoId,statusId}=auxActividad
    let auxFinaliza=solucion.length!=0?true:false
    guardarNvActividad({
      ...nvActividad,
      Orden:ordenId,ActividadId:Id,Descripcion:descr,Prioridad:prioridad,
      Solucion:solucion,Tipo:tipoId,Estado:statusId.toString(),Finaliza:auxFinaliza
    })              
    const source = axios.CancelToken.source();             
    async function llenarListTipo()  {       
      setEspera(true)   
      let auxU=process.env.REACT_APP_LINK +`/orden-actividad-tipo`;               
      let token =localStorage.getItem('token20') ;             
      let dataInfo = qs.stringify({                                    
          'idDispositivo':'9uccjcsdcsdcsd'
          });
      let config = {
          url:auxU ,
          method: 'POST',
          headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
          data: dataInfo,
          cancelToken: source.token
      };
      let aux ={};           
      await axios(config)
      .then(function (response) {      
        aux=  response.data;                           
        if (aux[0].Id!=-1) 
        {//console.log(aux);
          let auxArre=[];   
          aux.map((tipo) => {
            if (tipo.Id!=0) 
            {
              auxArre.push({ value : tipo.Id, label :tipo.Tipo })  
            }            
          })                    
          guardarListTipo(auxArre);                                      
        }                                            
      })
      .catch(function (error) {                  
        console.log(`Error: ${error}`);      
        if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
        {
          console.log('datos incorrectos' );  
        }else
        {
          if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
              ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
          {
            console.log('loguearse de nuevo');
            localStorage.clear();                      
            history.push("/");                              
          } 
        }
      });  
      setEspera(false)                       
    };
    async function llenarListStatActiv()  {       
      setEspera(true)   
      let auxU=process.env.REACT_APP_LINK +`/orden-actividad-status`;               
      let token =localStorage.getItem('token20') ;             
      let dataInfo = qs.stringify({                                    
          'idDispositivo':'9uccjcsdcsdcsd'
          });
      let config = {
          url:auxU ,
          method: 'POST',
          headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
          data: dataInfo,
          cancelToken: source.token
      };
      let aux ={};           
      await axios(config)
      .then(function (response) {      
        aux=  response.data;                           
        if (aux[0].Id!=-1) 
        { //console.log(aux);
          let auxArre=[];   
          aux.map((tipo) => {
            if (tipo.Id!=0) 
            {
              auxArre.push({ value : tipo.Id, label :tipo.Tipo })  
            }            
          })                    
          guardarListStatusActivid(aux);                                      
        }                                            
      })
      .catch(function (error) {                  
        console.log(`Error: ${error}`);      
        if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
        {
          console.log('datos incorrectos' );  
        }else
        {
          if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
              ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
          {
            console.log('loguearse de nuevo');
            localStorage.clear();                      
            history.push("/");                              
          } 
        }
      });  
      setEspera(false)                       
    };     
  if (listTipo.length==0) 
  {
    llenarListTipo()
  }  
  if (listStatusActivid.length==0) 
  {
    llenarListStatActiv()
  }  
  
  return ()=> {
      source.cancel();
  }     
    
},[]);

  const registrarEditActividad=(auxEstatus)=>{
    const source = axios.CancelToken.source();     
    setEspera(true)     
    async function llenarNvActi()  {                   
      let auxU=process.env.REACT_APP_LINK +`/orden-actividad-edit`;                   
      let token =localStorage.getItem('token20') ;         
      let idusu= localStorage.getItem('UsuId'); 
      let NomUsu= localStorage.getItem('UsuarioNom');  
      let dataInfo = qs.stringify({      
          'ActividadId':ActividadId,                    
          'Orden':Orden,
          'Usuario':idusu,
          'Tipo':Tipo,
          'Status':auxEstatus,
          'Prioridad':Prioridad,
          'Descripcion':Descripcion,
          'Solucion':Solucion,
          'FchSolucion':'',
          'UsrFinaliza':NomUsu,
          'idDispositivo':'8978yuhjnfrsdsd'
          });
      let config = {
          url:auxU ,
          method: 'POST',
          headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
          data: dataInfo,
          cancelToken: source.token
      };
      let aux ={};           
      await axios(config)
      .then(function (response) {                 
        aux=  response.data;       
       // console.log(aux);                               
        if (aux.respuesta==1) 
        { //console.log(aux);
          let mensaje='Se registro correctamente la Actividad'
          setTipoAdvertencia("success")      
          setMensaje(mensaje);
          setAviso(true)                    
        }                                            
      })
      .catch(function (error) {                  
        console.log(`Error: ${error}`);
        
        if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
        {
          console.log('datos incorrectos' );  
        }else
        {
          if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
              ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
          {
            console.log('loguearse de nuevo');
            localStorage.clear();                      
            history.push("/");                              
          } 
        }
      });
      setEspera(false)       
      
    };
    llenarNvActi();    
    return ()=> {
        source.cancel();
    }

  }
  const onChange = e =>
  { let expNopermitida = new RegExp('[#_:$!%-({})/*=?|]');
    let expMenos = new RegExp('-');
    let expMas = new RegExp('[+]');     
    let expCadena = new RegExp('[A-Za-z0-9]');
    if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) && !expMenos.test(e.target.value) &&
        !expMas.test(e.target.value) ) || e.target.value===' ' || e.target.value==='') 
    {
    
      guardarNvActividad({
        ...nvActividad,
        [e.target.name] : e.target.value  
      })
      if (aviso) 
      {
        setMensaje('');
        setAviso(false);
      }
    }
  };
  /*const onChange2 = e =>
  { let expNopermitida = new RegExp('[#_:$!¡%-({})/*=?|]');
    let expMenos = new RegExp('-');
    let expMas = new RegExp('[+]');     
    let expCadena = new RegExp('[A-Za-z0-9]');
    if (expCadena.test(e.target.value)&&!expNopermitida.test(e.target.value)&&!expMenos.test(e.target.value)&&
        !expMas.test(e.target.value)||e.target.value===' '||e.target.value==='') 
    {
      guardarNvActividad({
        ...nvActividad,
        [e.target.name] : e.target.value  
      })
      if (aviso) 
      {
        setMensaje('');
        setAviso(false);
      }
    }
  };*/
  
  
  const guardTerminal = e =>
  {
    guardarNvActividad({
      ...nvActividad,
      [e.target.name] : e.target.value  
    })
  };
  
  const guardarDatos = () => 
  {
    if (Tipo!= '' &&Tipo!= '0' && Tipo!= null) 
    { if ( Prioridad != ''&&Prioridad != '0') 
      { if ( Descripcion != ''&&Descripcion != '0'&&Descripcion.length>=5) 
        { if ( Estado != '' && Estado === "0" ||Estado!="0") 
          { if ( Estado == "3" && Finaliza ||Estado!="3") 
            { 
              if (Finaliza&&Solucion!=''|| !Finaliza) 
              { 
                registrarEditActividad(parseInt(Estado))                             
              } 
              else 
              { setTipoAdvertencia("warning")
                let mensaje="Llenar campo de Solución"            
                setMensaje(mensaje);
                setAviso(true)                                         
              }
            }   
            else
            { setTipoAdvertencia("warning")
              let mensaje="Seleccionar Finaliza"            
              setMensaje(mensaje);
              setAviso(true)   
            }
          }   
          else
          { setTipoAdvertencia("warning")
            let mensaje="Seleccionar Estatus"            
            setMensaje(mensaje);
            setAviso(true)   
          }
        }   
        else
        { setTipoAdvertencia("warning")
          let mensaje="Llenar campo de Descripción (mayor a 5 caracteres)"            
          setMensaje(mensaje);
          setAviso(true)   
        }
      }   
      else
      { setTipoAdvertencia("warning")
        let mensaje="Seleccionar Prioridad"            
        setMensaje(mensaje);
        setAviso(true)   
      }   
    } 
    else 
    { setTipoAdvertencia("warning")
      let mensaje="Seleccionar Tipo"            
      setMensaje(mensaje);
      setAviso(true)   
    }                           
  };
  
  const cambioActualiza = (event) => {
    guardarNvActividad({
      ...nvActividad,
        [event.target.name]: event.target.checked 
      });
  };
  const guardarPrioridad = (event) => {
    //console.log(event);
    if (event) {      
      guardarNvActividad({
        ...nvActividad,
        Prioridad : event  
      })
    }
    else
    {
      guardarNvActividad({
        ...nvActividad,
        Prioridad : 0
      })
      //console.log('sin guardar vacio');
    }
    
  };
  const guardaEstado = e =>
  {  
    guardarNvActividad({
      ...nvActividad,
      Estado : e.target.value  
    })  
    if (aviso) 
    {
      setMensaje('');
      setAviso(false);
    }      
  };
  return ( 
    <Modal
      titulo={titulo}
      modalAbierto={modalAbierto}
      setModalAbierto={setModalAbierto}
      guardarDatos={guardarDatos}
      mensaje={mensaje}
      tipoAdvertencia={tipoAdvertencia}
      aviso={aviso}
      setAviso={setAviso}
      espera={espera}
      setEspera={setEspera}
    >
      <form className={classes.root} noValidate autoComplete="off" fullWidth>                     
      {listStatusActivid.length!=0? 
        <FormControl component="fieldset" style={{width:30+'rem'}} size="small">              
          <FormLabel component="legend" style={{color:'#F46239',fontWeight:500}}>Estatus</FormLabel>      
          <RadioGroup   row   aria-label="position" //name="estatus"row
                defaultValue="top"   name="Estado" value={Estado} onChange={guardaEstado}
          >            
          {listStatusActivid.map((statActiv)=>{
            let auxColor="";
            if (statActiv.Id==0) 
            { auxColor='red'
            } 
            else 
            { if (statActiv.Id==1) 
              { auxColor='#F46239'
              } 
              else 
              {if (statActiv.Id==3) 
                { auxColor='#049b4f'
                }
              } 
            }
            //console.log(statActiv.Id);
           return(
            <span> 
              <FormControlLabel value={statActiv.Id.toString()} style={{color:auxColor}} 
                control={<Radio color="primary" size="small" disabled />} label={statActiv.Tipo}  
              size="small"/>                     
            </span>)
          })}                        
          </RadioGroup>                     
        </FormControl>     
      :null}    
     
       {listTipo.length!=0 ?  
       <Box display="flex" flexDirection="row">
        <Box mr={5}>  
        <FormControl className={classes.formControl} size="small" style={{width:12+'rem'}}>
          <InputLabel htmlFor="age-native-simple">Tipo</InputLabel>
          <MaterialSelect
            native disabled value={Tipo} onChange={guardTerminal}
            inputProps={{
                name: 'Tipo',
                id: 'Tipo',
            }}
            
          >
            <option aria-label="None" value="" />      
            {listTipo.map((row) => (
                <option value={row.value}>{row.label}</option>
            ))}   
          </MaterialSelect>
        </FormControl>     
        </Box>  
        <Box  borderColor="transparent">
        <span style={{color:'#f68160'}}  >Prioridad</span>
        <Rating
          name="simple-controlled" value={Prioridad}
          max={3} disabled
          onChange={(event, newValue) => {
            guardarPrioridad(newValue);
          }}
          style={{color:'#f68160'}}   
        />
      </Box>
      </Box> 
      :<LinearProgress color="primary" />}    
      
      
      <br/>     
      <FormControl component="fieldset" style={{width:30+'rem'}}>    
        <textarea id="Descripcion-TextArea" name="Descripcion" rows="5"
          cols="100"placeholder="Descripción" style={{width: 33.2+"rem",borderRadius:3+'px'}} 
          value={Descripcion} onChange={onChange} disabled
        />
      </FormControl>
      <br/>
        <FormControlLabel
          control={<Checkbox checked={Finaliza} onChange={cambioActualiza} name="Finaliza" />}
          label="Finaliza" disabled
        />
      
      <br/>
      {Finaliza?
      <textarea id="Solucion-TextArea" name="Solucion" rows="5"
          cols="100"placeholder="Solución" style={{width: 33.2+"rem",borderRadius:3+'px'}} 
          value={Solucion} onChange={onChange} disabled
        />:null}
      </form>
  </Modal>
  );
}
 
export default EditarActividad;