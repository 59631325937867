import React from 'react'
import {AuthContext} from '../context/AuthContext'
import {GeneralesContext} from '../context/GeneralesContext'
import { Box ,Select as MaterialSelect  ,LinearProgress,IconButton } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search';
import Reporte from './Reporte'
import ReporteSN from './ReporteSNDatos'
import moment from 'moment';
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import Reloj from './Reloj';
import {findNumber} from "../funciones/FindNumber";
const RepoBitacora = () => {
  let history = useHistory();  
  const [datos,setDatos]=React.useState([])
  const [espera,setEspera]=React.useState(false)
  const {usuario,guardarUsuario}=React.useContext(AuthContext);
  const {guardarGenerales}=React.useContext(GeneralesContext);  
  const [Bucle, setBucle] = React.useState(0);    
  const [buscar, setBuscar] = React.useState(true);
  const [fechas,setFechas]=React.useState({auxFecha:new Date()})
  const {auxFecha}=fechas
    
  React.useEffect(()=>{
    const source = axios.CancelToken.source();  
    async function datosBitacora(auxTer)  {       
      setEspera(true);
      let auxU=process.env.REACT_APP_LINK +`/reporte-directdrive-rango-terminal`;           
      let fInicio=moment(auxFecha).format("YYYY-MM-DD")        
      let token =localStorage.getItem('token20') ;                     
      let dataInfo = qs.stringify({                            
          'fechaIni':fInicio+' 00:00:00' ,
          'fechaFin':fInicio+' 23:59:59',        
          'terminal':auxTer,                 
          'idDispositivo':'ifrekvjkxcdwdcwe'
          });
      let config = {
          url:auxU ,
          method: 'POST',
          headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
          data: dataInfo,
          cancelToken: source.token
      };
      let aux ={};           
      await axios(config)
      .then(function (response) {              
        aux=  response.data;                           
          //console.log(aux)
          console.log(aux[0])
        if (aux[0].length!==0) 
        {                
          setDatos(aux[0]);                                      
        }                                            
      })
      .catch(function (error) {                  
        console.log(`Error: ${error}`);              
        if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
        {
          console.log('datos incorrectos' );  
        }else
        {
          if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
              ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
          {
            console.log('loguearse de nuevo');
            localStorage.clear();                      
            history.push("/");                              
          } 
        }
      });             
      setEspera(false);         
    };
    const veriSesion =  () => 
    {            
      const usu= localStorage.getItem('UsuarioNom');
      const nvl= localStorage.getItem('Tipo');
      const auxTerminal= localStorage.getItem('UsuTerminal')?parseInt(localStorage.getItem('UsuTerminal')) :0;   
      //console.log(typeof(auxTerminal));   
      //console.log(typeof(parseInt(auxTerminal)));
      let auxTer=0;
      if(usu===null)
      {                                
        guardarUsuario([])						
        guardarGenerales([])     
        localStorage.clear();           
        history.push("/")	
      }
      else
      { 
        if (usuario.length === 0) 
        {
          guardarUsuario({Nombre:usu,nivel:nvl});                                      
        }
        if (auxTerminal !== 0) 
        { if(Bucle === 0)
          {            
            auxTer=auxTerminal
            setBucle(auxTerminal)     
          }
          else
          {
            if(auxTerminal !== Bucle)
            {   
              auxTer=Bucle
            }    
            else
            {
              setBucle(auxTerminal)     
              auxTer=auxTerminal
            }
          }
        }
        else
        {
          guardarUsuario([])						
          guardarGenerales([])     
          localStorage.clear();           
          history.push("/")	
        }
        guardarGenerales({Titulo:'Reporte Direct-Drive'})                     
        datosBitacora(auxTer)
      }
    }
    veriSesion()
      //
        
      return ()=> {
        source.cancel();
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps	
  },[buscar])

    const cambiarBucle = (event) => {        
      setBucle(event.target.value);
    };
    const incrementarBuscar = e =>
    { // e.preventDefault();    
        if(Bucle!==0)
        {
          setBuscar(!buscar)        
        }
    };
    function verificaReporte(auxJson){
      let auxValido=false;
      if (auxJson?.lecturas !== undefined) {
        auxValido=true;
        console.log('no esta vacio');
      } 
      else 
      {
        auxValido=false;
        console.log('si esta vacio');
      }
      return auxValido;
    }
    return (
      <div>            
        <Box display="flex" flexDirection="row">
          <Box display="flex" width="15%" flexDirection="column">
            <Reloj fechas={fechas} setFechas ={setFechas } />     
            <Box pl={1}>
            <MaterialSelect
              native value={Bucle} onChange={cambiarBucle}
              inputProps={ { name: 'Bucle', id: 'Bucle', } }
              style={{width:6+'rem'}}
            >
              <option aria-label="None" value="" />
              <option value={1}>Bucle A</option>
              <option value={2}>Bucle B</option>                                                        
            </MaterialSelect>            
            <IconButton color="inherit"  aria-label="add circle" 
              component="span" onClick={incrementarBuscar}
            >
              <SearchIcon color="primary" />
            </IconButton>  
            </Box>          
          </Box>
          <Box display="flex" width="85%" >
            { datos.length !== 0 && !espera ? (
              verificaReporte(datos)?
                <Reporte urlReport={"reports/ReportDirectDrive2812.mrt"} informacion={datos} 
                  nombre={' Reporte Direct-Drive'} mostrarTB={findNumber('|56|') ?  true : false}
                /> :
                <ReporteSN urlReport={"reports/ReportDirectDrive2812.mrt"} informacion={datos} 
                nombre={' Reporte Direct-Drive'} mostrarTB={findNumber('|56|') ?  true : false}
                />
               )
              : 
              <Box style={{width:'100%'}} pt={2}>
                <LinearProgress color="primary" /> 
              </Box>       
            }              
          </Box>
        </Box>                      
      </div>
    )
}

export default RepoBitacora