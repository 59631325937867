import React from 'react'
import {makeStyles,Drawer, Divider,CardMedia ,Box, Typography,Card} from '@material-ui/core'
import Listas from './Listas';

const drawerWidth = 240;
let colorBarra="#FF6439";//claro
let colorBarraHover="#FB8666";// tenue
let colorBackBarra="#F3BAAA";//mas tenue
const estilos = makeStyles(theme=>({
    drawer: {
        width: drawerWidth,
        flexShrink: 0, 
        scrollbarColor: colorBarra+" "+colorBarraHover,//"#F46239 #c4e6f2",
        "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
          backgroundColor: colorBackBarra,
        },
        "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
          borderRadius: 8,
          backgroundColor: colorBarra,
          minHeight: 24,
          border: "3px solid "+colorBarra,
        },
        "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
          backgroundColor: colorBackBarra,
        },
        "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
          backgroundColor: colorBackBarra,
        },
        "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
          backgroundColor: colorBarraHover,
        },
        "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
          backgroundColor: colorBackBarra,
        },
      },
      barra:{
        flexGrow:2
    },
    media: {
        /* height: 152,
        width: 220, */
        borderRadius: '10px',
      },
    eltoolbar:theme.mixins.toolbar,
    drawerPaper: {
    width: drawerWidth,
    background:'#F46239',
    color:'white'
    },
}))

const Cajon = (propiedades) => {
  const misclases =estilos()
  const contacto=(e)=> {
    e.preventDefault()
    window.open("https://daduva.mx");
  }
  return (      
    <Drawer 
      className={misclases.drawer} variant={propiedades.variant} anchor="left"
      open={propiedades.open} onClose={propiedades.onClose ? propiedades.onClose:null }
      classes={{
        paper: misclases.drawerPaper,
      }}        
    >
      <Box className={misclases.eltoolbar} textAlign="center" pt={2}>
        <Typography variant={propiedades.usuario.Nombre.length>15?"body":"h5"} //color="primary"
          color="inherit"  
        >
          {propiedades.usuario.Nombre.toUpperCase()}            
        </Typography>
      </Box>          
      <Divider/>
      <Listas className={misclases.barra}/>
      <Box display="flex" mt={21}  px={1} mb={2} alignItems="flex-end"  alignContent="flex-end">
          <Card  className={misclases.media}>
            <CardMedia                      onClick={contacto}               
              component="img" alt="Logo daduva"
              scaleType="fitCenter" title="Logo daduva"                  
              image="/CIRCULAR_MARGEN_DADUVA20211228.svg"              
            />
          </Card>
      </Box>          
    </Drawer>    
  )
}

export default Cajon
