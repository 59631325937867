import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {Container,InputLabel,MenuItem,FormControl
        ,Select,Grid,LinearProgress, Box, IconButton} from "@material-ui/core";
//import AddCircleIcon from '@material-ui/icons/AddCircle';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RefreshIcon from '@material-ui/icons/Refresh';
import axios from "axios";
import qs from "qs";
import moment from "moment";
import { useHistory } from "react-router-dom";
import {authUser} from "../funciones/AuthUser";
import {AuthContext} from "../context/AuthContext";
import {GeneralesContext} from '../context/GeneralesContext'
import GraficaEnergia from "./GraficaCarroTensor";
import TablaCarroTensor from "./TablaCarroTensor";
import {ErrorGeneral,  llamadaApiToken,llamaApiCargaToken} from "../funciones/ClienteAxios";
import Relojes from './Relojes2'
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  botones: {
   // marginTop:theme.spacing(3),
  },
  formStyle: {
    marginBottom: 30,
    //justifyContent: "left",
    flexDirection:"row",
    display:"flex"
  },
  
  graficainicial:{
    marginLeft: '1em',
  },
  graficasiguiente:{
    marginTop: ".1em",
    marginLeft: '1em',
  },
}));

export default function CarroTensorInicio() {
  const classes = useStyles();
  const historial = useHistory();
  let nvlSNPermiso ='5';
  const source = axios.CancelToken.source();
  const [terminal, setTerminal] = useState(0);
  const [auxTerminal, setAuxTerminal] = useState(0);
  const [listaTerminales, setListaTerminales] = useState([]);
  const [listaCTensor, setListaCTensor] = useState([]);  
  const [espera, setEspera] = useState(false);    
  const {usuario,guardarUsuario}=React.useContext(AuthContext);
  const {guardarGenerales}=React.useContext(GeneralesContext);  
  
  const [fechasSeleccionadas, setFechasSeleccionadas] = useState({inicio: new Date(), final: new Date()});     
  const [dataGrafica, setDataGrafica] = useState({data:[],labels:[],estacion:""}); 
  useEffect(() => {
    let idusu = localStorage.getItem("UsuId");
    const getTerminales = () => {
      let data = qs.stringify({
        usuario: idusu,
        idDispositivo: '987gyubjhkkjh'
      });
      let url = "/energia-terminal";

      function respuesta(aux) {
        if (aux[0].Id>-1) {
          let aux1 = [];
          aux1.push({
            Id: aux[0].Id,
            Descr: "Seleccione",
          });
          for (let i = 1; i < aux.length; i++) {
            aux1.push(aux[i]);
          }
          setListaTerminales(aux1);
          let auxterminal = aux1.filter(function (val) {
            return val.Id !== 0;
          });
          auxterminal.splice(0,0,{
            Id: 0,
            Descr: "Seleccione",
          });              
          
        }
      }

      llamadaApiToken(data, url, respuesta, ErrorGeneral, historial, source)
    };

    const autenticado = authUser();
    if(!autenticado){
      localStorage.clear();
      historial.push('/');
      guardarUsuario([]);
      guardarGenerales([])     
    }
    else
    {
      const usu= localStorage.getItem('UsuarioNom');
      const nvl= localStorage.getItem('Tipo');
      const auxTerminal= localStorage.getItem('UsuTerminal'); 
      guardarUsuario({Nombre: usu, nivel: nvl});
      guardarGenerales({Titulo:'Carro Tensor'}) 
      getTerminales();
      setTerminal(parseInt(auxTerminal))
      generarTabla(parseInt(auxTerminal))
    }

    return () => {
      source.cancel();
    }
  }, []);

  useEffect(()=>{
    if (listaCTensor.length!==0) {
      datosGrafica(listaCTensor)  
    }
    
  },[listaCTensor])

  const handleChange = (event) => {
    setTerminal(event.target.value);    
  };

  function datosGrafica(lista){        
    let listFech = [], listTensor = [];    
    if (lista[0].termNom==="Ecatepec 01") 
    {
      lista.forEach(registro => { 
        listFech.push(moment.utc(registro.fAlta).local(true).format("DD/MM/YYYY"));
        listTensor.push(registro.tensorE1 )             
      })  
    }
    else
    {
      lista.forEach(registro => { 
        listFech.push(moment.utc(registro.fAlta).local(true).format("DD/MM/YYYY"));
        listTensor.push(registro.tensorE7 )             
      }) 
    }    
    
    setDataGrafica({data:listTensor,labels:listFech,estacion:lista[0].termNom})

  } 

  function generarTabla(auxTerminal){
    setDataGrafica({data:[],labels:[],estacion:""})
    const guardaNuevaFalla = () => {
      let fInicio = moment(fechasSeleccionadas.inicio).format("YYYY-MM-DD")
      let fFinal = moment(fechasSeleccionadas.final).format("YYYY-MM-DD")
      let data = qs.stringify({
        fechaIni: fInicio+'T00:00:00',
        fechaFin:fFinal+'T23:59:59',
        idDispositivo: "EBRDVDVDVFFD",
        terminal:terminal!==0?terminal:auxTerminal,
      });
      let url = "/bitacora-ctensor-intervalo";

      function response(aux) {
        //console.log(aux)
        if (aux[0].Id> -1) {
          setListaCTensor(aux);          
          //datosGrafica(aux)
          setAuxTerminal(terminal!==0?terminal:auxTerminal)
        }
      }
      llamaApiCargaToken(data, url,response,ErrorGeneral,setEspera,historial,source);
    }
    guardaNuevaFalla()
  }

  const verGraf = listaCTensor.length > 0 ? <TablaCarroTensor listaCTensor={listaCTensor} listaTerminales={listaCTensor}  
                                              termSelect={auxTerminal} usuarioContext={usuario} setListaCTensor={setListaCTensor}/>
                                          : null;  

  return (  
    <Grid container  style={{paddingLeft:1+'rem'}}>
      <Grid item  sx={{ width: '40%' }}>            
        <div className={classes.formStyle}>
          <FormControl className={classes.formControl} size="small">
            <InputLabel id="inputTerminal">
              Terminal
            </InputLabel>
            <Select
              labelId="inputTerminal"   id="selectTerminal"
              value={terminal} onChange={handleChange}
            >
              {listaTerminales.map((terminal, index) => (
                <MenuItem value={terminal.Id} key={terminal.Id}>
                  <em>{terminal.Descr}</em>
                </MenuItem>
              ))}
            </Select>
          </FormControl>      
          <div style={{marginTop:.5+'rem'}}>
            <Relojes fechasSeleccionadas={fechasSeleccionadas}
              setFechasSeleccionadas={setFechasSeleccionadas}/>
          </div>    
          <FormControl  className={classes.botones} style={{ marginTop: .5+'rem',marginRight: .5+'rem'}}  >
            <IconButton onClick={generarTabla}  >
              <RefreshIcon color="primary" />
            </IconButton>
          </FormControl>
        </div>
          {verGraf}
      </Grid>
     
      <Grid item  sx={{ width: '60%' , marginTop: 20}}>
        {listaCTensor.length>0 && dataGrafica.data.length>0 ? 
          <GraficaEnergia dataGrafica={dataGrafica} 
            arrayDias={"prueba"}            
         />
        : null}
      </Grid>         
    </Grid>   
  );
}