import React, { useEffect,useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import {Box, Table,TableCell, TableContainer,TableHead,
  TableRow,Card,CardContent ,Typography,IconButton,TableBody,
  Backdrop,
  } from '@material-ui/core'; 
import qs from 'qs';
import {llamadaApiToken,ErrorGeneral , llamaApiCargaToken } from '../funciones/ClienteAxios';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import moment from 'moment';
import NewEditDetalle from './NewEditDetalle';
import CreateIcon from '@material-ui/icons/Create'; 
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {findNumber} from "../funciones/FindNumber";
import Viewer from 'react-viewer';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import CircularProgress from '@material-ui/core/CircularProgress';
import EditEvidencia from './ModalEvidencia';
const useStyles = makeStyles((theme)=>({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    root: {
      width: '100%',
    },
    container: {
      //maxHeight: 550,
      maxHeight: window.innerHeight-250,
      // minHeight: 280,
      minWidth:'100%',
    },
    container2: {
      maxHeight: 550, 
    },
    tableRow: {
      "&$selected, &$selected:hover": {
        backgroundColor: process.env.REACT_APP_COLOR
      }
    },
    tableCell: {
      "$selected &": {
        color: "white"
      }
    },
    hover: {},
    selected: {}
}));
const CardMonit = ({orden,listTipo,listUbic}) => {
  const history = useHistory();
  const classes = useStyles();
  const colorTitulo=process.env.REACT_APP_COLOR_SEC
  const source = axios.CancelToken.source();
  const idusu= localStorage.getItem('UsuId');    
  const [datos, setDatos] = useState([])
  const [listImg, setListImg] = useState([])    
  const [detalle, setDetalle] = useState([])
  const [espera, setEspera] = useState(false)
  const [esperaImg, setEsperaImg] = useState(false)
  const [verImg, setVerImg] = useState(false)
  const [modalNvo, setModalNvo] = useState(false);  
  const [modalEvid, setModalEvid] = useState(false);  
  useEffect(()=>{
    if (orden.Id) 
    {
      llenaListTabla()
    }
      
    return ()=> {
      source.cancel();
    } 
  },[orden])
     
  const llenaListImg=(idBit)=>{      
      
    const data = qs.stringify({    
      Usuario:idusu,  
      Bitacora:idBit,        
      idDispositivo:'89y7gttvjhj98h'
    });
    const url = "/cctv-monitoreo-det-evidencia-list";
    function respuestaListTabla(aux) {
      if (aux[0].Id !== -1) 
      {                                                                  
        setListImg(aux)   
        setVerImg(true)
        console.log(aux);
      }
      else
      { setListImg([])        
      }           
    }

    llamaApiCargaToken(data, url,respuestaListTabla, ErrorGeneral, setEsperaImg, history, source);
  } 

  const llenaListTabla=()=>{      
      
    const data = qs.stringify({    
      Usuario:idusu,    
      Llave:orden.Id,
      idDispositivo:'89y7gttvjhj98h'
    });
    const url = "/cctv-monitoreo-det";
    function respuestaListTabla(aux) {
      if (aux[0].Id !== -1) 
      {                                                                  
        setDatos(aux)   
      }
      else
      { setDatos([])        
      }           
    }

    llamaApiCargaToken(data, url,respuestaListTabla,ErrorGeneral,setEspera,history,source);
  }

  const Encabezado=({espacio,estiloCampo,descripcion,campo})=>{
    return(
    <Box {...espacio} >
      <Typography className={classes.title2}  variant="h5" color="secondary" 
        component="h2" align="center"  style={estiloCampo}
      >                                        
        {campo}
      </Typography>                                            
      <Typography  className={classes.subTitle2} color="textSecondary"  align="center" >
        {descripcion }
      </Typography>
    </Box>
    )
  }

  const CampoRenglon=({estiloBox,estiloTitulo,titulo,campo})=>{
    return(
    <Box  {...estiloBox} >
      <span style={estiloTitulo}>{titulo} </span>  
      <Typography variant='body'> {campo}  </Typography>                        
    </Box>
    )
  }

  const seleccionado=(auxOrden)=>{
  
    setDetalle(auxOrden)  
  }

  const celda = (alinear, estilo, contenido) => {
    return (
    <TableCell
      align={alinear} className={classes.tableCell}
      padding={"none"} style={estilo} 
    >
      {contenido}
    </TableCell>
    );
  };

  const abrirModNvo=()=>{
    setDetalle([])  
    setModalNvo(true)
  }

  const abrirImg=( row)=>{   
    llenaListImg(row.Id)    
  }   
  const editarDet=( )=>{    
    setModalNvo(true)
  }

  function Row(props) {
    const { row } = props;
    const fecha = moment.utc(row.FHorario);          
    const modificada= fecha.local(true).format("DD/MM/YYYY HH:mm");  
    const auxColor= detalle?.Id=== row.Id ? "white":colorTitulo
    return (    
    <TableRow onClick={ () => { seleccionado(row); } }
      className={classes.tableRow} selected={ detalle?.Id=== row.Id}
      classes={{ hover: classes.hover, selected: classes.selected }}        
    >           
      {celda("center",{fontWeight:700,width:30+'px'}, 
        findNumber('|71|') ?
          <Box  display="flex" justifyContent={"center"} flexDirection="column" >
            <Encabezado estiloCampo={ {fontWeight: '900',fontSize:1.2+'rem',width:3.2+'rem',color:auxColor==="white"?auxColor:"" }} 
              descripcion={""} campo={row.Id} espacio={{ }}  />     
            <IconButton color={auxColor==="white"?"inherit":"primary"}  aria-label="add circle" size={"small"}
              component="span" onClick={()=>editarDet()} style={{justifyContent:"center"}}
            >
              <CreateIcon/>
            </IconButton>                                                                                                                              
          </Box> :null)}      
        {celda("center",{fontWeight:700,width:30+'px'},              
          <IconButton color={auxColor==="white"?"inherit":"primary"}  aria-label="add circle" size={"small"}
            component="span" onClick={()=>setModalEvid(true)} style={{justifyContent:"center"}}
          >
            <AddPhotoAlternateIcon/> 
          </IconButton>                                                                                                                              
        )}      
      {celda("center",{fontWeight:700,width:30+'px'}, 
        row.Fotos>0 ?          
          <IconButton color={auxColor==="white"?"inherit":"primary"}  aria-label="add circle" size={"small"}
            component="span" onClick={()=>abrirImg(row)} style={{justifyContent:"center"}}
          >
            <PhotoLibraryIcon/>  
          </IconButton>                                                                                                                              
        :null)}      
      {celda("left",{width:200+'px'},
        <Box mx={1} style={{}}>
          <CampoRenglon estiloBox={{display:"flex"}} titulo="Fecha" campo={modificada} 
            estiloTitulo={{color:auxColor,fontWeight:700,marginRight:".2rem"}}
          />        
          <CampoRenglon estiloBox={{display:"flex"}} titulo={row.TipoNom} campo={""} 
            estiloTitulo={{color:auxColor,fontWeight:700}}
          />                               
          <CampoRenglon estiloBox={{display:"flex"}} titulo={""} campo={row.UsrNom} 
            estiloTitulo={{color:auxColor,fontWeight:700}}
          />                                      
        </Box>
      )}     
      {celda("center",{width:80+'px'},
        <CampoRenglon estiloBox={{display:"flex",justifyContent:"center",color:auxColor,}} 
          estiloTitulo={{ fontWeight:700}} titulo={`${row.UbicNom}`} campo={""} 
        />  
      )}                          
      {celda("left",{marginLeft:1+'rem'},
        <Box   >
        <CampoRenglon estiloBox={{display:"flex"}} titulo={row.Titulo} campo={""} 
          estiloTitulo={{color:auxColor,fontWeight:700 }}
        /> 
        <CampoRenglon estiloBox={{}} titulo="" campo={row.Descr} 
          estiloTitulo={{color:auxColor,fontWeight:700}}
        />
      </Box>
      )}     
    </TableRow>          
    );
    }

  function tabla(){
      //console.log("entrando sin preguntar");
    return(
    <TableContainer className={classes.container}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>                                           
            { findNumber('|70|')?
            <TableCell align="center" padding='none'>
              <IconButton size={"small"} color="primary"  
                aria-label="add circle" component="span" 
                onClick={abrirModNvo} style={{marginTop:.6+'rem'}}
              >
                <AddCircleOutlineIcon/>
              </IconButton> 
            </TableCell>:null      }
            <TableCell align="center" padding='none'></TableCell>  
            <TableCell align="center" padding='none'></TableCell>  
            <TableCell align="center" padding='none'>Horario/Actividad</TableCell>            
            <TableCell align="center" padding='none'>Ubicación</TableCell>
            <TableCell align="left" padding='none'>Título/Descripción</TableCell>                    
          </TableRow>
        </TableHead>   
        <TableBody>
          {datos.length!=0 ? datos.map((row) => 
            <Row key={row.Id} row={row} />
          ):null}
        </TableBody>             
      </Table>
    </TableContainer>
    )
  }
  
   
  const modEditEvid= modalEvid ?<EditEvidencia modalAbierto={modalEvid} setModalAbierto={setModalEvid} 
                                  titulo="Agregar Evidencia" orden={detalle} llenaListTabla={llenaListTabla} 
                                />:null
  const modNvoEdit= modalNvo ? <NewEditDetalle modalAbierto={modalNvo} setModalAbierto={setModalNvo} 
                                  auxMonitoreo={detalle} llenaListTabla={llenaListTabla} 
                                  orden={orden} listTipo={listTipo} listUbic={listUbic}
                                />:null
  return (
  <Box pl={1} >
    {tabla()}
    {datos.length!==0 ? 
      (datos.map((orden,index) => 
      {/*  const fecha = moment.utc(orden.FHorario);          
        const modificada= fecha.local(true).format("DD/MM/YYYY HH:mm");    
        return(
        <Card key={index} style={{minHeight:50,padding:0,margin:0}} >
        <CardContent style={{padding:0,margin:0}}>
          <Box display="flex" flexDirection="row" >           
            <Box  display="flex" justifyContent={"center"} flexDirection="column" >
            <Encabezado estiloCampo={ {fontWeight: '900',fontSize:1.2+'rem',width:3.2+'rem' }} 
              descripcion={""} campo={orden.Id} espacio={{ }}  />     
              <IconButton color="primary"  aria-label="add circle" size={"small"}
                component="span" onClick={()=>editarDet(orden)} style={{justifyContent:"center"}}
              >
                <CreateIcon/>
              </IconButton>                                                                                                                              
            </Box> 
            <Box display="flex" flexDirection="row" >
              <Box mx={1} style={{width:14+'rem'}}>
                <CampoRenglon estiloBox={{display:"flex"}} titulo="Fecha" campo={modificada} 
                  estiloTitulo={{color:colorTitulo,fontWeight:700,marginRight:".2rem"}}
                />                                      
                <CampoRenglon estiloBox={{display:"flex"}} titulo={orden.UsrNom} campo={""} 
                  estiloTitulo={{color:colorTitulo,fontWeight:700}}
                />                                      
              </Box>
              {orden.UbicNom&& orden.UbicNom.length!==0 ?
                <CampoRenglon estiloBox={{display:"flex",justifyContent:"center",color:colorTitulo,}} 
                  estiloTitulo={{ fontWeight:700,width:5+'rem',}} titulo={`${orden.UbicNom}`} campo={""} 
                />                                                                                      
              :null}               
              {orden.Descr && orden.Descr.length!==0 ?
                <CampoRenglon estiloBox={{display:"flex", width:"20rem"}} titulo="" campo={orden.Descr} 
                  estiloTitulo={{color:colorTitulo,fontWeight:700,paddingRight:0.2+'rem' }}
                />                                                                                      
              :null}       
            </Box>                                                                                                                                                                                                                                                                                            
          </Box>                                                                                             
        </CardContent>                     
        </Card> 
    )*/
  }))
    : <Card>
        <CardContent>
          <Typography style={{textAlign:"center"}}>Sin detalle</Typography>
        </CardContent>
      </Card>}
    {modNvoEdit}
    {modEditEvid}
    <Backdrop className={classes.backdrop} open={esperaImg} 
      //onClick={()=>setEsperaImg(false)} 
    >
      <CircularProgress color="inherit" />
    </Backdrop>
    <Viewer zIndex={2000}
      visible={verImg} onClose={() => setVerImg(false) }
      images={listImg.map((imagen, index) => {
          return ({src: imagen.Dir+imagen.Archivo, alt: ''})
      })}      
      
    />
  </Box>   
  )
}

export default CardMonit