import React, {useEffect, useState, useContext} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {
    FormControl,
    Grid,
    LinearProgress,
    IconButton,
    Paper,
    Typography, TextField
} from "@material-ui/core";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RefreshIcon from '@material-ui/icons/Refresh';
import axios from "axios";
import qs from "qs";
import {useHistory} from "react-router-dom";
import {authUser} from "../funciones/AuthUser";
import {AuthContext} from "../context/AuthContext";
import {GeneralesContext} from '../context/GeneralesContext'

import TablaCatalogoParoFallas from "./TablaCatalogoParoFallas";
import CrearParoFalla from "./CrearParoFalla";
import {ToastContainer} from "react-toastify";
import {ErrorGeneral, llamadaApiToken} from "../funciones/ClienteAxios";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "block",
        width: '750px',
        textAlign: 'center',
        color: "#F46239"
    },
    botones: {
        margin: theme.spacing(1),
    }, formStyle: {
        marginBottom: 35
    }
}));

export default function CatalogoParoFallas() {

    const classes = useStyles();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const history = useHistory();
    let nvlSNPermiso = '5';
    const {usuario, guardarUsuario} = useContext(AuthContext);
    const {guardarGenerales} = useContext(GeneralesContext);

    const [catalogo, setCatalogo] = useState([]);
    const [modalAgregarAbierto, setModalAgregarAbierto] = useState(false);
    const [modalTitulo, setModalTitulo] = useState("");
    const [actualiza, setActualiza] = useState(false);
    const [ordenBusq, setOrdenBusq] = useState({solucionId: ''});
    const {solucionId} = ordenBusq;

    useEffect(() => {

        const getCatalogo = () => {
            let data = qs.stringify({
                idDispositivo: "987yugbhcdsscs",
            });
            let url = "/parofalla-catalogo-lista";

            function respuestaRefacciones(auxiliar) {
                if (auxiliar[0].Id > -1) {
                    setCatalogo(auxiliar);
                }
            }

            llamadaApiToken(data, url, respuestaRefacciones, ErrorGeneral, history, source)
        };

        const autenticado = authUser();
        if (!autenticado) {
            localStorage.clear();
            history.push('/');
            guardarUsuario([]);
        } else {
            const usu = localStorage.getItem('UsuarioNom');
            const nvl = localStorage.getItem('Tipo');
            guardarUsuario({Nombre: usu, nivel: nvl});
            guardarGenerales({Titulo: 'Catálogo Paro/Fallas - Solución'})
            getCatalogo();
        }
    }, [actualiza]);

    const modalAgregar = modalAgregarAbierto ? (usuario?.nivel !== undefined && usuario.nivel !== nvlSNPermiso ?
        <CrearParoFalla modalAbierto={modalAgregarAbierto}
                        setModalAbierto={setModalAgregarAbierto}
                        titulo={modalTitulo}
                        catalogo={catalogo}
                        actualiza={actualiza}
                        setActualiza={setActualiza}
        /> : null) : null;

    function refreshData() {
        setCatalogo([]);
        setActualiza(!actualiza)
    }

    function abrirModal() {
        setModalTitulo("Nuevo Paro/Falla");
        setModalAgregarAbierto(!modalAgregarAbierto);
    }

    const handleSearch = (e) => {
        let expNopermitida = new RegExp('[#._:$!%-({})/*=?|]');
        let expMenos = new RegExp('-');
        let expMas = new RegExp('[+]');
        let expCadena = new RegExp('[A-Za-z0-9]');
        if ((expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) &&
            !expMenos.test(e.target.value) && !expMas.test(e.target.value)) || e.target.value === ' ' || e.target.value === '') {
            setOrdenBusq({
                ...ordenBusq,
                [e.target.name]: e.target.value.trim()
            })
        }
    }

    const visualizartabla = catalogo.length > 0 ?

        <TablaCatalogoParoFallas catalogo={catalogo}
            usuarioContext={usuario} actualiza={actualiza}
            setActualiza={setActualiza} solucionId={solucionId}
        />
        :
        <Paper className={classes.root}>
            <Typography>
                Cargando...
                <LinearProgress/>
            </Typography>
        </Paper>

    return (<>
        <Grid container>
            <Grid item className={classes.formStyle}>
                <FormControl className={classes.botones} style={{marginLeft: 1 + 'rem'}}>
                    <IconButton onClick={refreshData} size={'small'}>
                        <RefreshIcon color="primary"/>
                    </IconButton>
                </FormControl>
                {usuario.nivel !== undefined && usuario.nivel !== nvlSNPermiso ?
                    <FormControl className={classes.botones} style={{marginLeft: 1 + 'rem'}}>
                        <IconButton onClick={abrirModal} size={'small'}>
                            <AddCircleOutlineIcon color="primary"/>
                        </IconButton>
                    </FormControl> : null}
                <TextField id="outlined-basic" label="Solución" variant="outlined"
                           size="small" style={{marginLeft: 1 + 'rem'}}
                           name="solucionId"
                           onChange={handleSearch}
                />
                {visualizartabla}
            </Grid>
        </Grid>
        {modalAgregar}
        <ToastContainer/>
    </>);
}