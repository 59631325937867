import React,{useState} from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {Box,LinearProgress,MobileStepper ,Paper,Typography,Button}from '@material-ui/core';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import Viewer from 'react-viewer';
import {llamaApiCargaToken,ErrorGeneral } from '../funciones/ClienteAxios';
import ModalEvidencia from './ModalEvidencia'
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
    flexGrow: 1,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  img: {
    height: 400,//'100%',
    display: 'block',
    maxWidth: 600,
    maxHeight: 400,
    overflow: 'hidden',
    width: '100%',
  },
}));

function TablaEvidencia({orden}) {
  const classes = useStyles();
  const theme = useTheme();
  let history = useHistory();
  //const maxSteps = tutorialSteps.length;
  const [activeStep, setActiveStep] = React.useState(0);  
  const [maxSteps, setMaxSteps] = React.useState(0);  
  const [espera, setEspera] = useState(false);
  const [datos, setDatos] = React.useState([])
  const [listIMG, guardarListIMG] = React.useState([])
  const[sinDatos,guardarSinDatos]= React.useState(false)
  const [ visible, setVisible ] = React.useState(false);
  const [ modalEvid, setModalEvid ] = React.useState(false);
  
  React.useEffect(()=>{ 
    const source = axios.CancelToken.source();
    let idusu= localStorage.getItem('UsuId');    
    const llenaListEvidencia = () => {      
      setDatos([])  
      setMaxSteps(0)
      setActiveStep(0)
      guardarListIMG([])
      let data = qs.stringify({
        Usuario:idusu,
        Bitacora:orden.Id,
        idDispositivo:'89y7gttvjhj98h'
      });
      let url = "/cctv-bitacora-det-list";
      function respuestaListAct(aux) {
        if (aux[0].Id!== -1) 
        {
          console.log(aux);
          guardarSinDatos(false)
          setMaxSteps(aux.length)
          setDatos(aux)
          setActiveStep(0)
          let arregloIMG=[]
          aux.forEach((step, index) => (                
            arregloIMG.push(step.Dir+step.Archivo)              
          ))
          //console.log(arregloIMG);
          guardarListIMG(arregloIMG)
        }
        else
        { 
          setDatos([])
        }      
      }

      llamaApiCargaToken(data, url,respuestaListAct,ErrorGeneral,setEspera,history,source);
    };  

    llenaListEvidencia();  

  return ()=> {
    source.cancel();
  }                

},[orden]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };
 
  const btnEvidencia=<Button color="secondary" style={{marginRight:1+'rem'}} onClick={() => { setModalEvid(true) } }>
                    Cargar Evidencias</Button>

  const tablaCompleta=(auxDatos)=>{
    
    return(
    <div>
      <Paper square elevation={0} className={classes.header}>
        {btnEvidencia}
        <Typography>{auxDatos.length!=0 ? auxDatos[activeStep].UsrNom:null}</Typography>
        <Button color="secondary" onClick={() => { setVisible(true); } }>pantalla completa</Button>
        <Viewer      zIndex={2000}
          visible={visible} onClose={() => { setVisible(false); } }
          images={
            auxDatos.map((imagen,index)=>{
              return({src: imagen.Dir+imagen.Archivo, alt: ''})
            })
          }
        />
      </Paper>
            
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep} onChangeIndex={handleStepChange}
        enableMouseEvents autoplay={!visible} interval={5000}
      >
        {auxDatos.length!=0 ?auxDatos.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 2 ? (
              <img className={classes.img} src={step.Dir+step.Archivo} alt={step.Tipo} />
            ) : null}
          </div>
        )):null}
      </AutoPlaySwipeableViews>    
      
      <MobileStepper
        steps={maxSteps} position="static"
        variant="text" activeStep={activeStep}
        nextButton={
          <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
            Siguiente
            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            Atras
          </Button>
        }
      />
      </div>
   )
  }

  const tablaSinDatos=()=>{
   return(
    <Paper square elevation={0} className={classes.header}>
      {btnEvidencia}
      <Typography>Sin fotografias</Typography>
  </Paper>
 ) 
  }
  const contenido = datos.length!==0 ? tablaCompleta(datos) :tablaSinDatos()
  const cargando= !espera ?  contenido : <Box my={2}><LinearProgress /></Box>
  const modEvidencia = modalEvid ? <ModalEvidencia modalAbierto={modalEvid} orden={orden} setMaxSteps={setMaxSteps}
                                datos={datos} maxSteps={maxSteps} arregloIMG={listIMG} setDatos={setDatos} 
                                setListIMG={guardarListIMG}
                                setModalAbierto={setModalEvid} titulo={"Evidencias Orden "+orden.Id} />:null
  return (
    <div className={classes.root} >
      {cargando}
      {modEvidencia}
    </div>
  );
}

export default TablaEvidencia;
