import React,{useState, useContext, useEffect} from 'react'
import { useHistory } from "react-router-dom";
import {AuthContext} from '../context/AuthContext'
import {GeneralesContext} from '../context/GeneralesContext'
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import { Box,Card  ,CardContent ,Typography,IconButton,TextField,    
    FormControl ,Select as MaterialSelect ,
    InputLabel} from '@material-ui/core'
import TablaBitacora from './TablaBitacora';
import RepoTab from './RepoTab';
import moment from 'moment';
import Relojes from './Relojes2'
import NuevaOrden from './ModalNvo'
import axios from 'axios';
import qs from 'qs';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {llamadaApiToken,ErrorGeneral } from '../funciones/ClienteAxios';
import ModalEditar from './ModalEditar2';
import {findNumber} from "../funciones/FindNumber";
const useStyles = makeStyles((theme)=>({
    root: {
      minWidth: 275,
    },    
    title: {
      fontSize: 28,
    },
    title2: {
        fontSize: 18,
      },
    subTitle2: {
        fontSize: 12,
      },
    pos: {
      marginBottom: 12,
    },
    addButton: {
        marginRight: theme.spacing(2),       
      },
    altura2: {
        minHeight: 70,    
        marginTop:0,
        paddingTop:0,    
    },
    altura3: {
        minHeight: 100,
        marginTop:0,
        paddingTop:0,    
    },
    altura1: {
        height: 90,        
    },
  }));

const BitacoraCCTV = () => {
    let history = useHistory();
    const classes = useStyles();
    let nvlSNPermiso ='5'  
    const {usuario,guardarUsuario}= useContext(AuthContext);
    const {guardarGenerales}= useContext(GeneralesContext);          
    const [ordenSeleccionada,setOrdenSeleccionada]= useState([])
    const [ordenBusq, setOrdenBusq] = useState({orden:'',turno:''});
    const [fechasSeleccionadas, setFechasSeleccionadas] = useState({inicio:new Date(),final:new Date()});
    const {orden,turno}=ordenBusq;
    const [estatus, setEstatus] = useState(0);    
    const [buscar, setBuscar] = useState(true);    
    const [modalNvo, setModalNvo] = useState(false);            
    const [modalEdit, setModalEdit] = useState(false);            
    const [listStatus, setListStatus] = useState([]) 
    const [listUbic, setListUbic] = useState([]) 
    const [listTipo, setListTipo] = useState([]) 
    const [datos, setDatos] = useState([])
    const [cargaEdit, setCargaEdit] = useState(false)
    useEffect(()=>{ 
        const source = axios.CancelToken.source();
        let idusu= localStorage.getItem('UsuId');
        const llenarListStatus = () => {      
            let data = qs.stringify({
              Usuario:idusu,
              idDispositivo:'89y7gttvjhj98h'
            });
            let url = "/cctv-status-list";
            function respuestaListTermin(aux) {
              if (aux[0].Id !== -1) 
              { let auxArre=[];                 
                aux.map((tipo) => {
              
                  auxArre.push({ value : tipo.Id, label :tipo.Descr })  
                        
                })                              
                setListStatus(auxArre);                             
              }
            }            
            llamadaApiToken(data, url, respuestaListTermin,ErrorGeneral,history,source)
        };

        const llamarUbicacionOrd = () => {      
            let data = qs.stringify({
                Usuario:idusu,
                idDispositivo:'89y7gttvjhj98h'
            });
            let url = "/cctv-ubicacion-list";
            function respuestaListInterv(auxiliar) {
              if (auxiliar[0].Id!==-1) 
              {                
                let auxArre=[];                 
                auxiliar.map((tipo) => {
              
                  auxArre.push({ value : tipo.Id, label :tipo.Descr })  
                        
                })                              
                setListUbic(auxArre);                                                         
              }                    
            }
      
            llamadaApiToken(data, url,respuestaListInterv,ErrorGeneral,history,source);
        };       

        const llamarTipo = () => {      
            let data = qs.stringify({
                Usuario:idusu,
                idDispositivo:'89y7gttvjhj98h'
            });
            let url = "/cctv-tipo-list";
            function respuestaListInterv(auxiliar) {
              if (auxiliar[0].Id!==-1) 
              {                
                let auxArre=[];                 
                auxiliar.map((tipo) => {
              
                  auxArre.push({ value : tipo.Id, label :tipo.Descr })  
                        
                })                              
                setListTipo(auxArre);                                                         
              }                    
            }
      
            llamadaApiToken(data, url,respuestaListInterv,ErrorGeneral,history,source);
        };     

		const veriSesion =  () => {            
            const usu= localStorage.getItem('UsuarioNom');
            const nvl= localStorage.getItem('Tipo');   
            if(usu === null)
            {                                
                guardarUsuario([])						
                guardarGenerales([])     
                localStorage.clear();           
                history.push("/")	
            }
            else
            {   if (usuario.length === 0) 
                {   guardarUsuario({Nombre:usu,nivel:nvl});                                      
                }
                
                guardarGenerales({Titulo:'Bitácora CCTV'})  
               
                if (listUbic.length === 0) 
                {   llamarUbicacionOrd()    
                }    
                if (listStatus.length === 0) 
                {
                    llenarListStatus();  
                }        
                if (listTipo.length === 0) {
                    llamarTipo()
                }               
            }
        }
	    veriSesion();	
        return ()=> {
            source.cancel();
        } 

    },[]);

    const onChange = e =>
    {   let expNopermitida = new RegExp('[#._:$!%-({})/*=?|]');
        let expMenos = new RegExp('-');
        let expMas = new RegExp('[+]');     
        let expCadena = new RegExp('[A-Za-z0-9]');
        if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) &&
            !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) ||e.target.value===' '||e.target.value==='') 
        {
            setOrdenBusq({
                ...ordenBusq,
                [e.target.name] : e.target.value.trim()  
            })
        }
        
    };

    const incrementarBuscar = e =>
    {   setBuscar(!buscar)    
    }; 

    const onChangeEstatus = (event) => 
    {   setEstatus(event.target.value );       
        console.log(event.target.value,typeof event.target.value);
    }; 

    function tipoEstado(estado)
    {   let auxEtiqueta=''
        let auxColor=''
        if (estado===0) 
        {   auxEtiqueta='Registrado'
            auxColor='#F46239'
        }
        else
        {
        if (estado === 1) 
        {   auxEtiqueta='Solucionado'
            auxColor='#049b4f'
        }
        else
        {   if (estado === -1) 
            {   auxEtiqueta='Cancelado'
                auxColor='red'
            } 
        }
        }
        let renglon={  color:auxColor,etiqueta:auxEtiqueta}
        return renglon
    }

    const Encabezado=({espacio,estiloCampo,descripcion,campo})=>{
        return(
        <Box {...espacio} >
            <Typography className={classes.title2}  variant="h5" color="secondary" 
                component="h2" align="center"  style={estiloCampo}
            >                                        
                {campo}
            </Typography>                                            
            <Typography  className={classes.subTitle2} color="textSecondary"  align="center" >
                {descripcion }
            </Typography>
        </Box>
        )
    }

    const CampoRenglon=({estiloBox,estiloTitulo,titulo,campo})=>{
        return(
        <Box  {...estiloBox} >
            <span style={estiloTitulo}>{titulo} </span>  
            <Typography variant='body'> {campo}  </Typography>                        
        </Box>
        )
    }

const costadoDerecho =(orden)=>{
    let fecha = moment.utc(orden.FEvento);          
    let modificada= fecha.local(true).format("DD/MM/YYYY HH:mm");
    let arrEstado= tipoEstado(orden.Status)
    let colorTitulo='#f68160'
    return(
    <span>
        <Box pl={1} my={1} >
            <Card className={classes.altura3}  >
                <CardContent >
                    <Box display="flex" flexDirection="row" justifyContent="center">
                        <Encabezado espacio={{ }} estiloCampo={ {fontWeight: '900'}} 
                            descripcion={""} campo={orden.Id} />                                                                                                                                             
                        {/* <Encabezado espacio={{ ml:1 }} estiloCampo={ { fontWeight: '900'}} 
                            descripcion={""} campo={orden.TipoNom} />     */}                          
                        <Encabezado espacio={{ ml:1 }} estiloCampo={ { fontWeight: '900'}} 
                            descripcion={"Lugar"} campo={orden.UbicNom} />
                      {/*   <Encabezado espacio={{ ml:1 }} estiloCampo={ {color:orden.StatusColor,fontWeight:700}} 
                            descripcion={"Estatus"} campo={orden.StatusNom} />  */}   
                        <Encabezado espacio={{ ml:1 }} estiloCampo={ {fontWeight: '900'}} 
                            descripcion={"Fecha"} campo={modificada} />                                                                                                                                                                                                                                                                                                                                                                                                                       
                    </Box>     
                    {orden.Titulo.length!==0 ?
                        <CampoRenglon estiloBox={{display:"flex"}} titulo="Titulo " campo={orden.Titulo} 
                            estiloTitulo={{color:colorTitulo,fontWeight:700,paddingRight:0.2+'rem' }}
                        />                                                                                      
                    :null}               
                    {orden.Descr.length!==0 ?
                        <CampoRenglon estiloBox={{display:"flex"}} titulo="Descripción " campo={orden.Descr} 
                            estiloTitulo={{color:colorTitulo,fontWeight:700,paddingRight:0.2+'rem' }}
                        />                                                                                      
                    :null}                                              
                    {orden.Status === -1  ?
                        <CampoRenglon estiloBox={{display:"flex"}} titulo="Causa Cancelación " 
                            campo={moment.utc(orden.Fcancela).local(true).format("DD/MM/YYYY HH:mm")+' '+ orden.causa} 
                            estiloTitulo={{color:'red',fontWeight:700,paddingRight:0.2+'rem' }}
                        />                                                                                      
                    :null}                                                                 
                </CardContent>                     
            </Card>
        </Box>                    
        <RepoTab orden={orden}/>                             
    </span>)
}

const abrirModNvo=()=>{
    setModalNvo(true)
}
const modNvo =  modalNvo ? <NuevaOrden titulo={"Nuevo Registro"} modalAbierto={modalNvo} setModalAbierto={setModalNvo} 
                            listStatus={listStatus} listUbic={listUbic} listTipo={listTipo}  actualizar={incrementarBuscar}/> :null
const modEdit =  modalEdit ? <ModalEditar titulo={"Editar Registro"} modalAbierto={modalEdit} setModalAbierto={setModalEdit} 
                                datos={datos} listStatus={listStatus} listUbic={listUbic} listTipo={listTipo} 
                                seleccionadoEdit={ordenSeleccionada}  setSeleccionado={setOrdenSeleccionada} setDatos={setDatos}
                                setCargaEdit={setCargaEdit}
                            /> :null                                                                
const contenido = ordenSeleccionada.length!=0 ?(costadoDerecho(ordenSeleccionada)):(null)
const principal=()=>{
    //console.log(auxTotal);
    return(
        <Box display="flex" flexDirection="column" >
            <Box style={{}} >
                <Card className={classes.altura2}  >
                    <CardContent style={{padding:0,marginTop:.6+'rem',marginBottom:.5+'rem'}} >
                        <Box display="flex" flexDirection="row" >
                            <Box ml={1} >                                            
                                { usuario?.nivel !=undefined && usuario.nivel!== nvlSNPermiso && findNumber('|34|') ?
                                    <IconButton size={"small"} color="primary"  
                                        aria-label="add circle" component="span" 
                                        onClick={abrirModNvo} style={{marginTop:.6+'rem'}}
                                    >
                                        <AddCircleOutlineIcon/>
                                    </IconButton>        
                                    :   null                                
                                }
                            </Box>                                                                  
                            <Box mr={1.5} >
                                <IconButton color="primary"  aria-label="add circle" size={"small"}
                                    component="span" onClick={incrementarBuscar} style={{marginTop:.6+'rem'}}
                                >
                                    <SearchIcon/>
                                </IconButton>                                                                                                                              
                            </Box>
                            <Box >                                                                          
                                {/* listStatus.length !==0 ? 
                                    <FormControl component="fieldset" style={{width:8+'rem',marginLeft:.5+'rem'}} size="small">   
                                        <InputLabel htmlFor="estatus1-native"> Estatus </InputLabel>                                           
                                        <MaterialSelect
                                            native value={estatus} onChange={onChangeEstatus}
                                            inputProps={{ name: 'estatus', id: 'estatus'}}
                                        >
                                        {listStatus.map((status,index) =>{
                                            return(
                                            <option value={status.value} id={index}>
                                                {status.label}</option>
                                            )
                                            
                                        })}   
                                        </MaterialSelect>                                               
                                    </FormControl>  
                                :null  */}
                                <Box   component="span" >                                                                                                                                                                               
                                    <Relojes fechasSeleccionadas={fechasSeleccionadas} 
                                        setFechasSeleccionadas={setFechasSeleccionadas} />
                                    <TextField id="outlined-basic" label="Folio" variant="outlined" 
                                        size="small" style={({width:8+"rem",marginTop: .3+'rem',marginLeft:.4+'rem'})} 
                                        name="orden" value={orden} onChange={onChange}                                                   
                                    />   
                                </Box>                                                                                               
                            </Box>                                                        
                        </Box>                                                                                                        
                    </CardContent>                                
                </Card>
            </Box>  
            <Box  display="flex" flexDirection="row">                
                <Box width="40%"  >                                                        
                    <Box  mt={1} >
                        <TablaBitacora orden={orden} fSeleccionadas={fechasSeleccionadas} 
                            setOrdenSeleccion={setOrdenSeleccionada} cargaEdit={cargaEdit}   
                            setOrdenBusq={setOrdenBusq} ordenBusq={ordenBusq} 
                            buscar={buscar} usuarioContxt={usuario} estatus={estatus}
                            setModalEdit={setModalEdit} datos={datos}  setDatos={setDatos}
                        />                       
                    </Box>
                </Box>                    
                <Box width="60%"   >                        
                    {contenido}
                </Box> 
            </Box>                
        </Box>      
    )
}

    return (
        <div>
            {principal()}
            {modNvo}
            {modEdit}
        </div>
    )
}

export default BitacoraCCTV
