import React from 'react'
import {AuthContext} from '../context/AuthContext'
import {GeneralesContext} from '../context/GeneralesContext'
import { Box } from '@material-ui/core'
import Reporte from './Reporte'
import ReporteSN from './ReporteSNDatos'
import moment from 'moment';
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import Reloj from './Reloj';
import LinearProgress from '@material-ui/core/LinearProgress';
import {findNumber} from '../funciones/FindNumber'
const RepoBitacora = () => {
    const [datos,setDatos]=React.useState([])
    const [espera,setEspera]=React.useState(false)
    const {usuario,guardarUsuario}=React.useContext(AuthContext);
    const {guardarGenerales}=React.useContext(GeneralesContext);  
    let history = useHistory();
    const [fechas,setFechas]=React.useState({auxFecha:new Date()})
    const {auxFecha}=fechas
    React.useEffect(()=>{
        const source = axios.CancelToken.source();  
        async function datosBitacora()  {       
            setEspera(true);
            let auxU=process.env.REACT_APP_LINK +`/reporte-bitacora-rango-1-1`;               
            let fInicio=moment(auxFecha).format("YYYY-MM-DD")            
            let token =localStorage.getItem('token20') ;                     
            let dataInfo = qs.stringify({                            
                'fechaIni':fInicio+' 00:00:00' ,
                'fechaFin':fInicio+' 23:59:59',                                  
                'idDispositivo':'ifrekvjkxcdwdcwe'
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};           
            await axios(config)
            .then(function (response) {              
              aux=  response.data;                                         
              if (aux[0].length!==0) 
              {                
                setDatos(aux[0]);                                      
              }                                            
            })
            .catch(function (error) {                  
              console.log(`Error: ${error}`);              
              setDatos([]);   
             if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
              {
                console.log('datos incorrectos' );  
              }else
              {
                if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
                    ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
                {
                  console.log('loguearse de nuevo');
                  localStorage.clear();                      
                  history.push("/");                              
                } 
              } 
            });             
            setEspera(false);         
          };
          const veriSesion =  () => 
          {            
            const usu= localStorage.getItem('UsuarioNom');
            const nvl= localStorage.getItem('Tipo');            
            if(usu===null)
            {                                
              guardarUsuario([])						
              guardarGenerales([])     
              localStorage.clear();           
              history.push("/")	
            }
            else
            { 
              if (usuario.length === 0) 
              {
                  guardarUsuario({Nombre:usu,nivel:nvl});                                      
              }
              guardarGenerales({Titulo:'Reporte Bitacora'})                     
            }
          }
          veriSesion()
          datosBitacora()
          
          return ()=> {
            source.cancel();
          }
          // eslint-disable-next-line react-hooks/exhaustive-deps	
    },[auxFecha])
    function verificaReporte(auxJson){
      let auxValido=false;
      if (auxJson?.terminal !== undefined && auxJson.terminal[0].paros) {
        auxValido=true;
        console.log('no esta vacio');
      } 
      else 
      {
        auxValido=false;
        console.log('si esta vacio');
      }
      return auxValido;
    }
    return (
        <div>            
          <Box display="flex" flexDirection="row">
            <Box display="flex" width="17%">
              <Reloj fechas={fechas} setFechas ={setFechas } />
              {/* <span style={{backgroundColor:'red'}}>Dentro de la columna</span> */}
            </Box>
            <Box display="flex" width="83%" >
              { !espera ? 
                datos.length !== 0 ?  ( verificaReporte(datos) ?
                      <Reporte urlReport={"reports/BitacoraReport220922.mrt"} informacion={datos}
                        nombre={' Reporte Bitacora'} mostrarTB={findNumber('|54|')?true:false  }
                      />
                    :<span>{<ReporteSN urlReport={"reports/BitacoraReport220922.mrt"} informacion={datos}
                    nombre={' Reporte Bitacora'} mostrarTB={findNumber('|54|')?true:false }
                  />/*   */ } </span>
                  ) :<Reporte urlReport={"reports/BitacoraReport220922.mrt"} informacion={datos}
                  nombre={' Reporte Bitacora'} mostrarTB={findNumber('|54|')?true:false }
                />
                :
                <Box style={{width:'100%'}} pt={2}>
                  <LinearProgress color="primary" /> 
                </Box>
              }                
            </Box>
          </Box>                        
        </div>
    )
}

export default RepoBitacora