import React, { useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {   Table, TableBody, TableCell, 
        TableContainer, TableHead, TableRow,  } from '@material-ui/core';
import {celda} from '../Generales/TablaGeneral';
import {useHistory} from "react-router-dom";
const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: window.innerHeight - 170,
        minHeight: 280,
        width: '9rem',
     
    },
    container2: {
        maxHeight: 550,
    },
    tableRow: {
        "&$selected, &$selected:hover": {
            backgroundColor: "#F46239"
        }
    },
    tableCell: {
        "$selected &": {
            color: "white"
        }
    },
    hover: {},
    selected: {}
});

export default function TablaPerfilTipo({  datos,setTipoSelect,tipoSelect}) {
    const classes = useStyles();
    let history = useHistory();
    const [selectedID, setSelectedID] = useState(null);
 
   // const [datos, setDatos] = useState([])
    let nvlSNPermiso = '5'   
    React.useEffect(()=>{
        setSelectedID(null)
        //console.log(datos);
    },[datos])
    const seleccionado = (auxId, auxOrden) => {
       // setSelectedID(auxId)
        setTipoSelect(auxOrden)
    }
 
    function Row(props) {
        const {row} = props;
        //console.log(row);
        return (        
        <TableRow onClick={() => { seleccionado(row.Id, row) }}
            className={classes.tableRow} selected={ tipoSelect?.Id === row.Id}
            classes={{hover: classes.hover, selected: classes.selected}}
        >  
            {celda("left", {padding:.3+'rem' }, row.Nom, 5, classes.tableCell)}                    
        </TableRow>           
      
        );
    }

    function tablaCompleta(auxlista) {
        return (
        <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
            <TableHead>
                <TableRow>                 
                    <TableCell align="center" padding='none'>Categoría</TableCell>    
                </TableRow>
            </TableHead>
            <TableBody>
                {auxlista.length !== 0 ? (auxlista.map((row) => (
                    <Row key={row.Id} row={row}/>
                ))) : null}
            </TableBody>
        </Table>
        </TableContainer>
        )
    }

    const tablaSinDatos = () => {
        return (
        <TableContainer className={classes.container2}>
        <Table stickyHeader aria-label="sticky table">
            <TableHead>
                <TableRow>            
                    <TableCell align="left">Tipo</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow className={classes.tableRow}>
                    {celda("left", {}, "Sin Perfiles", 1, classes.tableCell)}                 
                </TableRow>
            </TableBody>
        </Table>
        </TableContainer>
        )
    }    

    const contenido = datos.length !== 0 ? tablaCompleta(datos) : tablaSinDatos()

    return (
    <span>
        {contenido}
    </span>
    );
}