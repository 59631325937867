import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {InputLabel,FormControl,Select as MaterialSelect  ,Box 
  ,RadioGroup,FormControlLabel,Radio,FormLabel
  ,LinearProgress} from '@material-ui/core';  
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import Relojes from './RelojeDDrive'
import Modal from './ModalDummy';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import esLocale from "date-fns/locale/es";
import { TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        //width: '20ch',
      }, 
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
      Textnumero: {
        width: 5+"px",
      },
      formControlFull: {
        //margin: theme.spacing(1),
        //minWidth: 120,
        width:5+"rem"
      },
      alignContent:"center"
    },
  }));

const EditarDrive = ({modalAbierto,setModalAbierto,titulo,nvLectura,setNvLectura,fechDiaSel,seleccionadoEdit}) => {
  let history = useHistory();
  const classes = useStyles();
  const [espera,setEspera]=React.useState(false);
  const [mensaje, setMensaje] = React.useState('');
  const [tipoAdvertencia, setTipoAdvertencia] = React.useState('success');
  const [aviso, setAviso] = React.useState(false);
  const [datosAfectacion, setDatosAfectacion] = React.useState([]);
  const [nvLecturaDriv,guardarNvLecturaDriv]= React.useState({DrivHorario:new Date(),DrivTemAmb:0.0,DrivVelSistem:0.0,
                                                              DrivTempRoda:0.0,DrivTempInv1:0.0,DrivTempInv2:0.0,
                                                              DrivTempInv3:0.0,DrivTempInv4:0.0,DrivDifPreP1P3:0.0,
                                                              DrivDifPreP2P3:0.0 ,DrivTempMaxDev:0.0,DrivTempMinDev:0.0,Estado:'0'
                                                            ,TerminalSel:0,DriveTipo:0});  
  const {DrivHorario,DrivTemAmb,DrivVelSistem,DrivTempRoda,DrivTempInv1,DrivTempInv2,
        DrivTempInv3,DrivTempInv4,DrivDifPreP1P3,DrivDifPreP2P3,DrivTempMaxDev
        //,TerminalSel
        ,DrivTempMinDev,Estado,DriveTipo}=nvLecturaDriv
  const [listLugarTerminal, guardarListLugarTerminal] = React.useState([])
  const [listTipo, guardarListTipo] = React.useState([])
  React.useEffect(()=>{ 
    //let auxTerminal= localStorage.getItem('UsuTerminal');  
    const{  ValDifPreP1P3,ValDifPreP2P3,ValTemAmb,ValTempInv1,ValTempInv2,ValTempInv3
            ,ValTempInv4,ValTempMaxDev,ValTempMinDev,ValTempRoda,ValVelSistem,TermId,tpLectId}=seleccionadoEdit           
    
              
   const source = axios.CancelToken.source();       
   async function conectarJSon()  {       
    setEspera(true)       
    let auxU=process.env.REACT_APP_LINK +`/drive-edoafectacion`;        
    let token =localStorage.getItem('token20') ;                
    let dataInfo = qs.stringify({                                        
        'idDispositivo':'8978yuhjnfrsdsd'
        });
    let config = {
        url:auxU ,
        method: 'POST',
        headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
        data: dataInfo,
        cancelToken: source.token
    };
    let aux ={};           
    await axios(config)
    .then(function (response) {         
      aux=  response.data;                 
      if (aux[0].Id != -1) 
      {  // guardarSinDatos(true)                                            
          setDatosAfectacion(aux)
          let auxEdoAfec = aux.filter(edoAfecta => edoAfecta.Tipo ==seleccionadoEdit.Tipo)
          
        guardarNvLecturaDriv({
        ...nvLecturaDriv,
        DrivHorario: 'Thu Jun 17 2021 '+seleccionadoEdit.Horario+' GMT-0500 (hora de verano central)' ,
        Estado:auxEdoAfec[0].Id.toString(),
        DrivTemAmb:ValTemAmb,DrivVelSistem:ValVelSistem,DrivTempRoda:ValTempRoda,DrivTempInv1:ValTempInv1,DrivTempInv2:ValTempInv2 ,
        DrivTempInv3:ValTempInv3,DrivTempInv4:ValTempInv4,DrivDifPreP1P3:ValDifPreP1P3,DrivDifPreP2P3:ValDifPreP2P3,
        DrivTempMaxDev:ValTempMaxDev,DrivTempMinDev:ValTempMinDev,TerminalSel:TermId,DriveTipo:tpLectId
      })    
      }else{
      // guardarSinDatos(false)
      }                                            
    })
    .catch(function (error) {                
      console.log(`Error: ${error}`);      
      if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
      {
        console.log('datos incorrectos' );  
      }else
      {
        if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
            ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
        {
          console.log('loguearse de nuevo');
          localStorage.clear();                      
          history.push("/");                              
        } 
      }
     });   
     setEspera(false)              
   };
   async function llenarListTerminal()  {       
    let auxU=process.env.REACT_APP_LINK +`/lugar-terminal-list`;       
    let token =localStorage.getItem('token20') ;         
    let idusu= localStorage.getItem('UsuId'); 
    let dataInfo = qs.stringify({                            
        'usuario':idusu,
        'idDispositivo':'9uccjcsdcsdcsd'
        });
    let config = {
        url:auxU ,
        method: 'POST',
        headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
        data: dataInfo,
        cancelToken: source.token
    };
    let aux ={};           
    await axios(config)
    .then(function (response) {
      // console.log("entrando ");
      aux=  response.data;                           
      if (aux[0].Id!=-1) 
      {
        let auxArre=[];   
        aux.map((tipo) => {
          if (tipo.Id!=0) 
          {
            auxArre.push({ value : tipo.Id, label :tipo.Descr })  
          }            
        })                    
        guardarListLugarTerminal(auxArre);                                      
      }                                            
    })
    .catch(function (error) {                  
      console.log(`Error: ${error} `);
      if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
      {
        console.log('datos incorrectos' );  
      }else
      {
        if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
            ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
        {
          console.log('loguearse de nuevo');
          localStorage.clear();                      
          history.push("/");                              
        } 
      }
    });                      
  };   
    async function llenarListTipoDrive()  {       
      let auxU=process.env.REACT_APP_LINK +`/drive-tipo`;               
      let token =localStorage.getItem('token20') ;             
      let dataInfo = qs.stringify({                                    
          'idDispositivo':'9uccjcsdcsdcsd'
          });
      let config = {
          url:auxU ,
          method: 'POST',
          headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
          data: dataInfo,
          cancelToken: source.token
      };
      let aux ={};           
      await axios(config)
      .then(function (response) {      
        aux=  response.data;                           
        if (aux[0].Id!=-1) 
        {//console.log(aux);
          let auxArre=[];   
          aux.map((tipo) => {
            if (tipo.Id!=0) 
            {
              auxArre.push({ value : tipo.Id, label :tipo.Tipo })  
            }            
          })                    
          guardarListTipo(auxArre);                                      
        }                                            
      })
      .catch(function (error) {                  
        console.log(`Error: ${error}`);      
        if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
        {
          console.log('datos incorrectos' );  
        }else
        {
          if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
              ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
          {
            console.log('loguearse de nuevo');
            localStorage.clear();                      
            history.push("/");                              
          } 
        }
      });                      
    };
    if (listLugarTerminal.length==0) 
    {
      llenarListTerminal();  
    }
    if (listTipo.length==0) 
    {
      llenarListTipoDrive()
    }
     conectarJSon();
  
     return ()=> {
         source.cancel();
     }     
    
  },[]);

  const onChange = e =>
  { let expNopermitida = new RegExp('[#A-Za-z_:$!%-({})/*=?|]');
    let expMenos = new RegExp('-');
    let expMas = new RegExp('[+]');     
    let expCadena = new RegExp('[0-9]');
    if (expCadena.test(e.target.value)&&!expNopermitida.test(e.target.value)&&!expMenos.test(e.target.value)&&
        !expMas.test(e.target.value)||e.target.value===' '||e.target.value==='') 
    {
      guardarNvLecturaDriv({
        ...nvLecturaDriv,
        [e.target.name] : e.target.value  
      })
      if (aviso) 
      {
        setMensaje('');
        setAviso(false);
      }
    }
  };

  
  function valNumero(auxNumero) {
    let valida=false
    //console.log(auxNumero);
    if (typeof parseInt(auxNumero) === 'number') 
    {
      if (parseInt(auxNumero) ==0) 
      {
        valida=true
      }  
      else{
        if (parseInt(auxNumero) >0) 
        {  
          valida=true
        } 
      }
    }
    return valida
  }
  const guardTerminal = e =>
  {
    guardarNvLecturaDriv({
      ...nvLecturaDriv,
      [e.target.name] : e.target.value  
    })
  };
 
  const guardaEstado = e =>
  {  
    guardarNvLecturaDriv({
      ...nvLecturaDriv,
      Estado : e.target.value  
    })  
    if (aviso) 
    {
      setMensaje('');
      setAviso(false);
    }      
  };
  return ( 
    <Modal
      titulo={titulo}
      modalAbierto={modalAbierto}
      setModalAbierto={setModalAbierto}      
      mensaje={mensaje}
      tipoAdvertencia={tipoAdvertencia}
      aviso={aviso}
      setAviso={setAviso}
      espera={espera}
      setEspera={setEspera}
    >
      <form className={classes.root} noValidate autoComplete="off"   fullWidth> 
      
      <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>      
      <TimePicker
        clearable disabled ampm={false} size='small'
        label="Horario" value={DrivHorario} style={{width:6.5+'rem'}}     
      />    
    
    </MuiPickersUtilsProvider>
      {listTipo.length!=0 ?  
        <FormControl className={classes.formControl} size="small" style={{width:5.6+'rem'}}>
          <InputLabel htmlFor="age-native-simple">Tipo</InputLabel>
          <MaterialSelect
            native disabled value={DriveTipo} onChange={guardTerminal}
            inputProps={{
                name: 'DriveTipo',
                id: 'DriveTipo',
            }}
          >
            <option aria-label="None" value="" />      
            {listTipo.map((row) => (
                <option value={row.value}>{row.label}</option>
            ))}   
          </MaterialSelect>
        </FormControl>     
      :null}            
      {datosAfectacion.length!=0? 
        <FormControl component="fieldset" style={{width:30+'rem'}}>      
        
             <FormLabel component="legend" style={{color:'#F46239',fontWeight:500}}>Estado Afectación</FormLabel>      
          <RadioGroup   row   aria-label="position" //name="estatus"row
                defaultValue="top"   name="Estado" value={Estado} onChange={guardaEstado}
          >            
          {datosAfectacion.map((nvAfectacion)=>{
           return  <span> <FormControlLabel value={nvAfectacion.Id.toString()} style={{color:'#F46239'}} 
                      control={<Radio disabled color="primary" />} label={nvAfectacion.Tipo} />    
                      <br/>
                    </span>
          })}                        
          </RadioGroup>                     
        </FormControl>     
        :<LinearProgress color="primary" />}           
      
      <Box  display="flex" flexDirection="row" style={{width:32+'rem'}}  >                
        <Box >
        
        <Box display="flex" flexDirection="row"  style={{width:31+'rem'}}>
          <span style={{paddingRight:2+'rem',width:26+'rem',color:'#F46239',fontWeight:600}}>
              Temperatura Ambiente
          </span>           
          <input type="text" id="input-tempAmb" value={DrivTemAmb} 
            disabled name="DrivTemAmb" style={{width:4+'rem',height:1.5+'rem',fontWeight:700}}/>
        </Box>
        <Box display="flex" flexDirection="row"  style={{width:31+'rem',paddingTop:.4+'rem'}}>
          <span style={{paddingRight:2+'rem',width:26+'rem',color:'#F46239',fontWeight:600}}>
            Valor Referencia de Velocidad del Sistema
          </span>           
          <input type="text" id="input-velSistem" style={{width:4+'rem',height:1.5+'rem',fontWeight:700}}
          value={DrivVelSistem} disabled name="DrivVelSistem" />
        </Box>
        <Box display="flex" flexDirection="row"  style={{width:31+'rem',paddingTop:.4+'rem'}}>
          <span style={{paddingRight:2+'rem',width:26+'rem',color:'#F46239',fontWeight:600}}>
            Temperatura de Rodamiento del Motor
          </span>           
          <input type="text" id="input-DrivTempRoda" style={{width:4+'rem',height:1.5+'rem',fontWeight:700}}
          value={DrivTempRoda} disabled name="DrivTempRoda" />
        </Box>
        <Box display="flex" flexDirection="row"  style={{width:31+'rem',paddingTop:.4+'rem'}}>
          <span style={{paddingRight:2+'rem',width:26+'rem',color:'#F46239',fontWeight:600}}>
            Temperatura Inverter LeitDrive 1
          </span>           
          <input type="text" id="input-DrivTempInv1" style={{width:4+'rem',height:1.5+'rem',fontWeight:700}}
            value={DrivTempInv1} disabled name="DrivTempInv1" />
        </Box>
        <Box display="flex" flexDirection="row"  style={{width:31+'rem',paddingTop:.4+'rem'}}>
          <span style={{paddingRight:2+'rem',width:26+'rem',color:'#F46239',fontWeight:600}}>
            Temperatura Inverter LeitDrive 2
          </span>           
          <input type="text" id="input-DrivTempInv2" style={{width:4+'rem',height:1.5+'rem',fontWeight:700}}
            value={DrivTempInv2} disabled name="DrivTempInv2"/>
        </Box>
        <Box display="flex" flexDirection="row"  style={{width:31+'rem',paddingTop:.4+'rem'}}>
          <span style={{paddingRight:2+'rem',width:26+'rem',color:'#F46239',fontWeight:600}}>
            Temperatura Inverter LeitDrive 3
          </span>           
          <input type="text" id="input-DrivTempInv3" style={{width:4+'rem',height:1.5+'rem',fontWeight:700}}
            value={DrivTempInv3} disabled name="DrivTempInv3"/>
        </Box>
        <Box display="flex" flexDirection="row"  style={{width:31+'rem',paddingTop:.4+'rem'}}>
          <span style={{paddingRight:2+'rem',width:26+'rem',color:'#F46239',fontWeight:600}}>
            Temperatura Inverter LeitDrive 4
          </span>           
          <input type="text" id="input-DrivTempInv4" style={{width:4+'rem',height:1.5+'rem',fontWeight:700}}
            value={DrivTempInv4} disabled name="DrivTempInv4"/>
        </Box>
        <Box display="flex" flexDirection="row"  style={{width:31+'rem',paddingTop:.4+'rem'}}>
          <span style={{paddingRight:2+'rem',width:26+'rem',color:'#F46239',fontWeight:600}}>
            Dif. Presión P1-P3
          </span>           
          <input type="text" id="input-DrivDifPreP1P3" style={{width:4+'rem',height:1.5+'rem',fontWeight:700}}
            value={DrivDifPreP1P3} disabled name="DrivDifPreP1P3" />
        </Box>
        <Box display="flex" flexDirection="row"  style={{width:31+'rem',paddingTop:.4+'rem'}}>
          <span style={{paddingRight:2+'rem',width:26+'rem',color:'#F46239',fontWeight:600}}>
            Dif. Presión P2-P3
          </span>           
          <input type="text" id="input-DrivDifPreP2P3" style={{width:4+'rem',height:1.5+'rem',fontWeight:700}}
            value={DrivDifPreP2P3} disabled name="DrivDifPreP2P3"  />
        </Box>
        <Box display="flex" flexDirection="row"  style={{width:31+'rem',paddingTop:.4+'rem'}}>
          <span style={{paddingRight:2+'rem',width:26+'rem',color:'#F46239',fontWeight:600}}>
            Temperatura Máxima Instantánea Devanados Motor
          </span>           
          <input type="text" id="input-DrivTempMaxDev" style={{width:4+'rem',height:1.5+'rem',fontWeight:700}}
            value={DrivTempMaxDev} disabled name="DrivTempMaxDev" />
        </Box>
        <Box display="flex" flexDirection="row"  style={{width:31+'rem',paddingTop:.4+'rem'}}>
          <span style={{paddingRight:2+'rem',width:26+'rem',color:'#F46239',fontWeight:600}}>
            Temperatura Mínima Instantánea Devanados Motor
          </span>           
          <input type="text" id="input-DrivTempMinDev" style={{width:4+'rem',height:1.5+'rem',fontWeight:700}}
            value={DrivTempMinDev} name="DrivTempMinDev" disabled />
        </Box>

        </Box>
        </Box>
      </form>
    </Modal>
  );
}
 
export default EditarDrive;