import React from 'react'
import Grafica from './Grafica'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const Disponib = ({listDispo}) => {
  const [opcion, setOpcion] = React.useState('dispo');

  const handleRadioChange = (event) => {
    setOpcion(event.target.value);    
  };

  return (
  <div>
    <FormControl component="fieldset">
      <FormLabel component="legend">Filtro</FormLabel>
      <RadioGroup row aria-label="position" name="opcion" defaultValue="top"
        onChange={handleRadioChange} value={opcion}
      >
        <FormControlLabel
          value="dispo"  control={<Radio color="primary" />}
          label="Disponibilidad" //labelPlacement="top"
        />
        <FormControlLabel
          value="usr"  control={<Radio color="primary" />}
          label="Usuarios" //labelPlacement="value"
        />                  
      </RadioGroup>
    </FormControl>
    <Grafica listDispo={listDispo} opcion={opcion} /> 
  </div>
  )
}

export default Disponib