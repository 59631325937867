import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Box,Collapse,IconButton,Paper,Table,TableBody,TableCell,
      TableContainer,TableHead,TableRow} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import axios from 'axios';
import qs from 'qs';
import moment from 'moment';
import { useHistory } from "react-router-dom";
import Rating from '@material-ui/lab/Rating';
//import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import AddIcon from '@material-ui/icons/Add';
import NuevaActividad from './NuevaActividad';
import EditarActividad from './EditarActividad';
import EditarActividadDummy from './EditarActividadDummy';
import CreateIcon from '@material-ui/icons/Create';
import {AuthContext} from '../context/AuthContext'
import {findNumber} from "../funciones/FindNumber";
const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 420,
  },
  tableRow: {
    "&$selected, &$selected:hover": {
      backgroundColor: "#F46239"
    }
  },
  tableCell: {
    "$selected &": {
      color: "white"
    }
  },
  hover: {},
  selected: {}
});

export default function TablaActividades({orden}) {
  const classes = useStyles();
  let history = useHistory();
  const [selectedID, setSelectedID] = useState(null);
  const [datos, guardarDatos] = useState([])
  const[sinDatos,guardarSinDatos]= useState(false)
  const [ordenSelecc, setOrdenSelecc] = useState(0);
  const [open, setOpen] = useState(false);  
  const [modalAbierto, setModalAbierto] = useState(false);
  const [selectActividad, setSelectActividad] = useState([])
  const [modalAbierto2, setModalAbierto2] = useState(false);
  const {usuario}=React.useContext(AuthContext);
  const [actualizar, setActualizar] = useState(false);
  let nvlSNPermiso ='5' 
  React.useEffect(()=>{ 
    const source = axios.CancelToken.source();    
    async function conectarJSon()  {       
      let auxU= process.env.REACT_APP_LINK +`/mtto-actividad-lista`;         
      let token =localStorage.getItem('token20') ;               
      let dataInfo = qs.stringify({
        'Mtto':orden.Id,
        'idDispositivo':'89y7gttvjhj98h'
          });
      let config = {
          url:auxU ,
          method: 'POST',
          headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
          data: dataInfo,
          cancelToken: source.token
      };
      let aux ={};           
      await axios(config)
      .then(function (response) {        
        aux=  response.data;         
        if (aux[0].Id!=-1) 
        {
          guardarSinDatos(false)
          //console.log(aux);
          guardarDatos(aux)
        }
        else
        {
          guardarSinDatos(true)
        }                                            
      })
      .catch(function (error) {
                
        console.log(`Error: ${error} `);        
        if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
        {
          console.log('datos incorrectos' );  
        }else
        {
          if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
              ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
          {
            console.log('loguearse de nuevo');
            localStorage.clear();                      
            history.push("/");                              
          } 
        }
      });                
    };
    setSelectedID('')
    conectarJSon();
    
    return ()=> {
        source.cancel();
    }                

},[orden,actualizar]);

const funcionActuliza=()=>{
  setActualizar(!actualizar)
}

const OrdenarFecha =(auxfecha)=>{      
  let modificada2= moment.utc(auxfecha).local(true).format("DD/MM/YYYY HH:mm");
  //console.log(modificada2);
  return modificada2
}
function verMas(auxId) {
  if (auxId === ordenSelecc) 
  {
    setOpen(!open)
  }
  else
  {
    setOpen(true)
  }    
  setOrdenSelecc(auxId)
}

function nuevaActiv() {  
  setModalAbierto(true)
}
function editarActiv(auxFalla) {
  setSelectActividad(auxFalla)
 // console.log(auxFalla);
  setModalAbierto2(true)
}
function tipoEstado(estado,idSelecc)
  { let auxEtiqueta=''
    let auxColor=''
    if (estado==1) 
    {
      auxEtiqueta='Registrado'
      auxColor='#F46239'
    }
    else
    {
      if (estado==3) 
      {
        auxEtiqueta='Solucionado'
        auxColor='#049b4f'
      }
      else
      {
        if (estado == 0) 
        {
          auxEtiqueta='Cancelado'
          auxColor='red'
        } 
      }
    }
    let renglon=<div></div>
    selectedID === idSelecc?
    renglon=<TableCell align="center" style={{ color:'white',fontWeight:700 ,width:100+'px'}} padding={'none'}>
              {auxEtiqueta}
            </TableCell>:
    renglon=<TableCell align="center" style={{ color:auxColor,fontWeight:700,width:100+'px'}} padding={'none'}>{auxEtiqueta}</TableCell>
    return renglon
  }
function Row(props) {
  const { row } = props;
  //console.log(row);
  return (
    <React.Fragment>
      <TableRow //className={classes.root}
        onClick={() => {
          setSelectedID(row.Id);
        }}
        selected={selectedID === row.Id}
        classes={{ hover: classes.hover, selected: classes.selected }}
        className={classes.tableRow}
      > 
        <TableCell align="center" padding={'none'} style={{ width:30+'px'}} >
         {row.solucion.length!=0? 
          <IconButton color="primary"  aria-label="expand row" size="small" onClick={() => verMas(row.Id)}>
            {open && ordenSelecc=== row.Id? (selectedID === row.Id? <KeyboardArrowUpIcon style={{color:'white'}} /> :<KeyboardArrowUpIcon />): 
              selectedID === row.Id? <KeyboardArrowDownIcon style={{color:'white'}}  />:<KeyboardArrowDownIcon  />}            
          </IconButton>:null}
        </TableCell>   
        <TableCell align="center" padding={'none'} style={{ width:30+'px'}} >
            <IconButton   aria-label="add circle" size="small" onClick={() => editarActiv(row)} component="span">
            {selectedID === row.Id? <CreateIcon style={{color:'white'}} />:<CreateIcon color="primary"/>}
            </IconButton>                                                                                                                                                                          
        </TableCell>       
        <TableCell  className={classes.tableCell} align="center" padding={'none'} 
          style={{ color: selectedID === row.Id ? 'white':'#0AC15D',fontWeight: '980',fontSize: 18,width:10+'px'}}>
            {row.folio}
        </TableCell>
        <TableCell  className={classes.tableCell} align="left" padding={'none'} style={{ width:125+'px',paddingLeft:5+'px'}}>
          {OrdenarFecha(row.fecha)}
        </TableCell>
        <TableCell  className={classes.tableCell} align="center" padding={'none'} style={{ width:75+'px'}}>          
          <Rating name="rating-prioridad" value={row.prioridad}  
                size="small" max={3}  readOnly 
                style={{color: selectedID === row.Id?'white':'#F46239'}}        
          />
        </TableCell>   
        <TableCell  className={classes.tableCell} align="left" padding={'none'} style={{ width:145+'px'}}>
          {row.tipoNom}
        </TableCell>  

        {tipoEstado(row.statusId,row.Id)}              

        <TableCell  className={classes.tableCell} align="left" padding={'none'} >{row.descr}</TableCell>           
      </TableRow>
        <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open && ordenSelecc=== row.Id} timeout="auto" unmountOnExit>
          <Box >                
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>                                    
                    <TableCell align="left" padding={'none'}>Solución</TableCell>                
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={row.Id}>                        
                    <TableCell  className={classes.tableCell} align="left"
                       padding={'none'} style={{ width:120+'px'}}>
                        <span style={{ fontWeight:700, color:'#F46239'}} >{OrdenarFecha(row.fsolucion)}</span>
                      { ' '+row.solucion }
                    </TableCell>                              
                    </TableRow>
                </TableBody>
              </Table>
            </Box>              
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
  const tablaCompleta=()=>{
    
    return(<TableContainer className={classes.container}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>            
            <TableCell align="left" padding={'none'} ></TableCell>  
            <TableCell align="left" padding={'none'} ></TableCell>  
            <TableCell align="center" padding={'none'} >Folio </TableCell>            
            <TableCell align="center" padding={'none'} >Fecha</TableCell>
            <TableCell align="center" padding={'none'} >Prioridad</TableCell>               
            <TableCell align="left" padding={'none'} >Tipo</TableCell>                                       
            <TableCell align="center" padding={'none'} >Estatus</TableCell>       
            <TableCell align="left" padding={'none'} >Descripción</TableCell>          
            {/* <TableCell align="left" padding={'none'} >Solución Fecha </TableCell>   */}
          </TableRow>
        </TableHead>
        <TableBody>
          { usuario?.nivel !=undefined && usuario.nivel!== nvlSNPermiso ?
            <TableRow className={classes.tableRow}> 
              <TableCell  className={classes.tableCell} align="left" padding={'none'} style={{ width:30+'px'}} >
              {findNumber('|30|') ?
                <IconButton   aria-label="agregar-actividad" size="small" onClick={() => nuevaActiv()} component="span">
                  <AddIcon color="primary"/>
                </IconButton>  
              : null} 
              </TableCell>            
              <TableCell  className={classes.tableCell} align="left" padding={'none'} style={{fontWeight:700,color:'#F46239'}}>
                {findNumber('|30|') ? "Nueva":""} </TableCell>
              <TableCell  className={classes.tableCell} align="center" padding={'none'} style={{ paddingLeft:5+'px',fontWeight:700,color:'#F46239'}} >
              {findNumber('|30|') ? "Actividad":""}</TableCell>
              <TableCell  className={classes.tableCell} align="left" padding={'none'} ></TableCell>             
              <TableCell  className={classes.tableCell} align="left" padding={'none'} ></TableCell>       
              <TableCell  className={classes.tableCell} align="left" padding={'none'} ></TableCell>             
              <TableCell  className={classes.tableCell} align="left" padding={'none'} ></TableCell>       
              <TableCell  className={classes.tableCell} align="left" padding={'none'} ></TableCell>                                           
            </TableRow>
            :null
          }
          {datos.length!=0? (datos.map((row) => (
              <Row key={row.Id} row={row} />
          ))):null}
        </TableBody>
      </Table>
    </TableContainer>
   )
  }
  const tablaSinDatos=()=>{
   return(<TableContainer className={classes.container}>
    <Table stickyHeader aria-label="sticky table">
      <TableHead>
        <TableRow>                    
            <TableCell align="center" padding={'none'} >Folio </TableCell>            
            <TableCell align="center" padding={'none'} >Fecha</TableCell>
            <TableCell align="center" padding={'none'} >Prioridad</TableCell>               
            <TableCell align="left" padding={'none'} >Tipo</TableCell>                                       
            <TableCell align="left" padding={'none'} >Descripción</TableCell>                   
        </TableRow>
      </TableHead>
      <TableBody>
        {usuario?.nivel !=undefined && usuario.nivel!== nvlSNPermiso ?
          <TableRow className={classes.tableRow}>           
            <TableCell  className={classes.tableCell} align="center" padding={'none'} style={{ width:40+'px',paddingLeft:10+'px'}} >
            {findNumber('|30|') ?  <IconButton   aria-label="agregar-actividad" size="small" onClick={() => nuevaActiv()} component="span">
                <AddIcon color="primary"/>
              </IconButton> : null}  
            </TableCell>            
            <TableCell  className={classes.tableCell} align="center" padding={'none'} 
              style={{ width:120+'px',paddingLeft:5+'px',fontWeight:700,color:'#F46239'}}  >
                {findNumber('|30|') ? 'Nueva' : ""} 
            </TableCell>
            <TableCell  className={classes.tableCell} align="center" padding={'none'} 
              style={{ width:30+'px',paddingLeft:5+'px',fontWeight:700,color:'#F46239'}}  >
                 {findNumber('|30|') ? 'Actividad' : ""}
            </TableCell>
            <TableCell  className={classes.tableCell} align="left" padding={'none'} ></TableCell>             
            <TableCell  className={classes.tableCell} align="left" padding={'none'} ></TableCell>                       
          </TableRow>
          : null
        }
        <TableRow className={classes.tableRow}>          
          <TableCell  className={classes.tableCell} align="left" padding={'none'} style={{ width:30+'px'}} >            
          </TableCell>            
          <TableCell  className={classes.tableCell} align="center" padding={'none'} style={{ width:120+'px',paddingLeft:5+'px'}}  >Mtto </TableCell>
          <TableCell  className={classes.tableCell} align="center" padding={'none'} style={{ width:120+'px',paddingLeft:5+'px'}}>Sin</TableCell>
          <TableCell  className={classes.tableCell} align="left" padding={'none'} >Actividades</TableCell>             
          <TableCell  className={classes.tableCell} align="left" padding={'none'} ></TableCell>                                            
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
 ) 
  }
  const contenido = !sinDatos ?tablaCompleta() :tablaSinDatos()
  const modal =modalAbierto ? 
              ( usuario?.nivel !=undefined && usuario.nivel!== nvlSNPermiso ?
                (<NuevaActividad titulo={'Nueva Actividad'} modalAbierto={modalAbierto} 
                    setModalAbierto={setModalAbierto} auxOrdenId={orden.Id}
                    funcionActuliza={funcionActuliza}
                />)
                :null
              )
              :null
  const modal2 =modalAbierto2  ? 
              (usuario?.nivel !=undefined && usuario.nivel!== nvlSNPermiso ?
                ( 
                <EditarActividad titulo={'Editar Mtto '+orden.Id+' Actividad '+selectActividad.folio} modalAbierto={modalAbierto2} 
                  setModalAbierto={setModalAbierto2} auxActividad={selectActividad}
                  funcionActuliza={funcionActuliza}
                />)
              :(<EditarActividadDummy titulo={'Mtto '+orden.Id+' Actividad '+selectActividad.folio} modalAbierto={modalAbierto2} 
                setModalAbierto={setModalAbierto2} auxActividad={selectActividad}
                />)
              )
              :null              
  return (
    <Paper className={classes.root}>
      {contenido}
      {modal}
      {modal2}
    </Paper>
  );
}
