import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {InputLabel,FormControl,Select as MaterialSelect  ,Box 
  ,RadioGroup,FormControlLabel,Radio,FormLabel,
  } from '@material-ui/core';
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import Relojes from './RelojeDDrive'
import Modal from './Modal';
import moment from 'moment';
import {toast} from "react-toastify";
import {ErrorGeneral, llamaApiCargaToken, llamadaApiToken} from "../funciones/ClienteAxios";
const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '20ch',
      }, 
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
      Textnumero: {
        width: 5+"px",
      },
      formControlFull: {
        //margin: theme.spacing(1),
        //minWidth: 120,
        width:5+"rem"
      },
      margin: {
        margin: theme.spacing(1),
      },
    },
  }));

const NuevaDrive = ({modalAbierto,setModalAbierto,titulo,nvLectura,setNvLectura,fechDiaSel,funcionActualiza}) => {
  let history = useHistory();
  const classes = useStyles();
  const [espera,setEspera]=useState(false);
  const [mensaje, setMensaje] =useState('');
  const [tipoAdvertencia, setTipoAdvertencia] = useState('success');
  const [aviso, setAviso] = useState(false);
  const [datosAfectacion, setDatosAfectacion] = useState([]);
  const [nvLecturaDriv,guardarNvLecturaDriv]= useState({DrivHorario:'00:00:00',DrivTemAmb:0.0,DrivVelSistem:0.0,
                                                              DrivTempRoda:0.0,DrivTempInv1:0.0,DrivTempInv2:0.0,
                                                              DrivTempInv3:0.0,DrivTempInv4:0.0,DrivDifPreP1P3:0.0,
                                                              DrivDifPreP2P3:0.0 ,DrivTempMaxDev:0.0,DrivTempMinDev:0.0,Estado:'0'
                                                              ,TerminalSel:0,DrivTipo:0});  
  const {DrivHorario,DrivTemAmb,DrivVelSistem,DrivTempRoda,DrivTempInv1,DrivTempInv2,
        DrivTempInv3,DrivTempInv4,DrivDifPreP1P3,DrivDifPreP2P3,DrivTempMaxDev,
        DrivTempMinDev,Estado,TerminalSel,DrivTipo}=nvLecturaDriv
  const [listLugarTerminal, guardarListLugarTerminal] = useState([])
  const [listTipo, guardarListTipo] = useState([])
  const source = axios.CancelToken.source(); 
  React.useEffect(()=>{ 
    let idusu= localStorage.getItem('UsuId'); 
    const{  Horario,TemAmb,VelSistem,TempRoda,TempInv1,TempInv2,
      TempInv3,TempInv4,DifPreP1P3,DifPreP2P3,TempMaxDev,TempMinDev}=nvLectura      
      let auxTerminal= localStorage.getItem('UsuTerminal');     
    guardarNvLecturaDriv({
      ...nvLecturaDriv,
      //DrivHorario: 'Thu Jun 17 2021 '+Horario+' GMT-0500 (hora de verano central)' ,
      DrivHorario:new Date(),
      DrivTemAmb:TemAmb,DrivVelSistem:VelSistem,DrivTempRoda:TempRoda,DrivTempInv1:TempInv1,DrivTempInv2:TempInv2 ,
      DrivTempInv3:TempInv3,DrivTempInv4:TempInv4,DrivDifPreP1P3:DifPreP1P3,DrivDifPreP2P3:DifPreP2P3,
      DrivTempMaxDev:TempMaxDev,DrivTempMinDev:TempMinDev,TerminalSel:auxTerminal
   })    
   const llenarListEdoAfec = () => {
    let data = qs.stringify({
        idDispositivo: '987gyubjhkkjh'
    });
    let url = "/drive-edoafectacion";

    function respuesta(aux) {
      if (aux[0].Id != -1) 
        {  // guardarSinDatos(true)                                            
            setDatosAfectacion(aux)
        }else{
        // guardarSinDatos(false)
        }         
    }
    llamaApiCargaToken(data, url,respuesta,ErrorGeneral,setEspera,history,source);
  };  
  const llenarListTerminal = () => {
    let data = qs.stringify({
        usuario:idusu,
        idDispositivo: '987gyubjhkkjh'
    });
    let url = "/lugar-terminal-list";

    function respuesta(aux) {
      if (aux[0].Id!=-1) 
      {
        let auxArre=[];   
        aux.map((tipo) => {
          if (tipo.Id!=0) 
          {
            auxArre.push({ value : tipo.Id, label :tipo.Descr })  
          }            
        })                    
        guardarListLugarTerminal(auxArre);                                      
      }              
    }
    llamadaApiToken(data, url,respuesta,ErrorGeneral,history,source);
  };

  const llenarListTipoDrive = () => {
    let data = qs.stringify({        
        idDispositivo: '987gyubjhkkjh'
    });
    let url = "/drive-tipo";

    function respuesta(aux) {
      if (aux[0].Id!=-1) 
      {//console.log(aux);
        let auxArre=[];   
        aux.map((tipo) => {
          if (tipo.Id!=0) 
          {
            auxArre.push({ value : tipo.Id, label :tipo.Tipo })  
          }            
        })                    
        guardarListTipo(auxArre);                                      
      }               
    }
    llamadaApiToken(data, url,respuesta,ErrorGeneral,history,source);
  };
 
  if (listLugarTerminal.length==0) 
  {
    llenarListTerminal();  
  }
  if (listTipo.length==0) 
  {
    llenarListTipoDrive()
  }
  llenarListEdoAfec();  
  return ()=> {
      source.cancel();
  }     
    
},[]);

  const registrarNVLectura=(auxHorario)=>{     
    const llenarNvLect = () => {
      let idusu= localStorage.getItem('UsuId'); 
      //let NomUsu= localStorage.getItem('UsuarioNom');   
      let data = qs.stringify({
        Conductor:0,
        EdoAfec:Estado,
        Termial:TerminalSel,
        Usuario:idusu,
        //'Fecha':'2021-07-19 09:23:00',
        Fecha:fechDiaSel +' '+auxHorario+':00',
        //'Horario':'09:23',auxHorario
        Horario:auxHorario,
        TempAmbiente:DrivTemAmb,
        VelSist:DrivVelSistem,
        TempRoda:DrivTempRoda,
        TempInv1:DrivTempInv1,
        TempInv2:DrivTempInv2,
        TempInv3:DrivTempInv3,
        TempInv4:DrivTempInv4,
        DifPreP1P3:DrivDifPreP1P3,
        DifPreP2P3:DrivDifPreP2P3,
        TempMaxDev:DrivTempMaxDev,
        TempMinDev:DrivTempMinDev, 
        Tipo:DrivTipo,
        idDispositivo:'987gyubjhkkjh'
      });
      let url = "/drive-nuevo_1_1";
    
      function respuesta(aux) {
        if (aux.respuesta==1) 
        { //console.log(aux);
          let mensaje="Se registro correctamente la Nueva Lectura"
          setTipoAdvertencia("success")      
          setMensaje(mensaje);
          setAviso(true)    
          toast.success(mensaje, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }); 
          funcionActualiza()
          setModalAbierto(false)                       
        }     
      }
      llamaApiCargaToken(data, url,respuesta,ErrorGeneral,setEspera,history,source);
    };    

   llenarNvLect();    

  }

  const onChange = e =>
  { let expNopermitida = new RegExp('[#A-Za-z_:$!%-({})/*=?|]');
    let expMenos = new RegExp('-');
    let expMas = new RegExp('[+]');     
    let expCadena = new RegExp('[0-9]');
    if (expCadena.test(e.target.value)&&!expNopermitida.test(e.target.value)&&!expMenos.test(e.target.value)&&
        !expMas.test(e.target.value)||e.target.value===' '||e.target.value==='') 
    {  
      guardarNvLecturaDriv({
        ...nvLecturaDriv,
        [e.target.name] : e.target.value  
      })
      if (aviso) 
      {
        setMensaje('');
        setAviso(false);
      }
    }
  };
  
  function valNumero(auxNumero,auxCampo) {
    let valida=false    
    if (typeof parseInt(auxNumero) === 'number') 
    {
      if (parseInt(auxNumero) ==0) 
      {
        valida=true
      }  
      else{
        if (parseInt(auxNumero) >0) 
        {  
          valida=true
        } 
      }
    }
    if (!valida) {
      setTipoAdvertencia("warning")
      let mensaje="Llenar campo "+auxCampo            
      setMensaje(mensaje);
      setAviso(true)  
    }
    return valida
  }
  const guardTerminal = e =>
  {
    guardarNvLecturaDriv({
      ...nvLecturaDriv,
      [e.target.name] : e.target.value  
    })
  };
  const mostrarAlertas =(auxCampo)=>{
    setTipoAdvertencia("warning")
    let mensaje="Seleccionar "+auxCampo            
    setMensaje(mensaje);
    setAviso(true)   
  }
  const guardarDatos = () => 
  {
    if (DrivHorario!= '' &&DrivHorario!= '0' && DrivHorario!= null && moment(DrivHorario).format("HH:mm")!='00:00') 
    { if ( TerminalSel != ''&&TerminalSel != '0') 
      { 
        if ( DrivTipo != ''&&DrivTipo != '0') 
        { 
          if (Estado != ''&&Estado != '0') 
          { 
            if (valNumero(DrivTemAmb,"Temperatura Ambiente") && valNumero(DrivVelSistem,"Referencia de Velocidad del Sistema" )  ) 
            {            
              if (valNumero( DrivTempRoda,"Temperatura de Rodamiento del Motor") && valNumero(DrivTempInv1,"Temperatura Inverter LeitDrive 1")   ) 
              {                 
                if (valNumero(DrivTempInv2,"Temperatura Inverter LeitDrive 2") && valNumero(DrivTempInv3,"Temperatura Inverter LeitDrive 3")  ) 
                {                  
                  if (valNumero(DrivTempInv4,"Temperatura Inverter LeitDrive 4") && valNumero(DrivDifPreP1P3,"Dif. Presión P1-P3")   ) 
                  {                      
                    if (valNumero(DrivDifPreP2P3,"Dif. Presión P2-P3") && valNumero(DrivTempMaxDev,"Temperatura Máxima Instantánea Devanados Motor")   ) 
                    {                      
                      if (valNumero(DrivTempMinDev,"Temperatura Mínima Instantánea Devanados Motor")  ) 
                      {
                        let fec = moment(DrivHorario);          
                        console.log( fec.format("HH:mm"));  
                        console.log(fechDiaSel);                                                                    
                        registrarNVLectura(fec.format("HH:mm"))                       
                      }                                                                             
                    }                                                                   
                  }                                                          
                }                                                       
              }           
            }         
          }   
          else
          { mostrarAlertas("Estado de Afectación")            
          }
        }   
        else
        { mostrarAlertas("Tipo")            
        }
      }   
      else
      { mostrarAlertas("Terminal")          
      }   
    } 
    else 
    { mostrarAlertas("Horario de Inicio")        
    }                           
  };
  const guardaEstado = e =>
  {  
    guardarNvLecturaDriv({
      ...nvLecturaDriv,
      Estado : e.target.value  
    })  
    if (aviso) 
    {
      setMensaje('');
      setAviso(false);
    }      
  };
  return ( 
    <Modal
      titulo={titulo} modalAbierto={modalAbierto}
      setModalAbierto={setModalAbierto} guardarDatos={guardarDatos}
      mensaje={mensaje} tipoAdvertencia={tipoAdvertencia} aviso={aviso}
      setAviso={setAviso} espera={espera} setEspera={setEspera}
    >
    <form className={classes.root} noValidate autoComplete="off" fullWidth>       
      <Relojes  nvLecturaDriv={nvLecturaDriv}  guardarNvLecturaDriv={guardarNvLecturaDriv}/>        
      {listLugarTerminal.length!=0 ?  
        <FormControl className={classes.formControl} size="small" style={{width:7+'rem'}}>
          <InputLabel htmlFor="age-native-simple">Terminal</InputLabel>
          <MaterialSelect
            native value={TerminalSel} onChange={guardTerminal}
            inputProps={{ name: 'TerminalSel', id: 'TerminalSel', }}
          >
            <option aria-label="None" value="" />      
            {listLugarTerminal.map((row) => (
                <option value={row.value}>{row.label}</option>
            ))}   
          </MaterialSelect>
        </FormControl>     
      :null}  
      {listTipo.length!=0 ?  
        <FormControl className={classes.formControl} size="small" style={{width:5.6+'rem'}}>
          <InputLabel htmlFor="age-native-simple">Tipo</InputLabel>
          <MaterialSelect
            native value={DrivTipo} onChange={guardTerminal}
            inputProps={{ name: 'DrivTipo', id: 'DrivTipo', }}
          >
            <option aria-label="None" value="" />      
            {listTipo.map((row) => (
                <option value={row.value}>{row.label}</option>
            ))}   
          </MaterialSelect>
        </FormControl>     
      :null}   
      {datosAfectacion.length!=0? 
        <FormControl component="fieldset" style={{width:30+'rem'}}>              
          <FormLabel component="legend" style={{color:'#F46239',fontWeight:500}}>Estado Afectación</FormLabel>      
          <RadioGroup   row   aria-label="position" //name="estatus"row
                defaultValue="top"   name="Estado" value={Estado} onChange={guardaEstado}
          >            
            {datosAfectacion.map((nvAfectacion)=>{
            return(  
              <span> <FormControlLabel value={nvAfectacion.Id.toString()} style={{color:'#F46239'}} 
                control={<Radio color="primary" />} label={nvAfectacion.Tipo} />    
                <br/>
              </span>)
            })}                        
          </RadioGroup>                     
        </FormControl>     
      :null}                   

      <Box  display="flex" flexDirection="row" style={{width:32+'rem'}}  >                
        <Box >
        
        <Box display="flex" flexDirection="row"  style={{width:31+'rem'}}>
          <span style={{paddingRight:2+'rem',width:26+'rem',color:'#F46239',fontWeight:600}}>
              Temperatura Ambiente
          </span>           
          <input type="text" id="input-tempAmb" value={DrivTemAmb} 
            onChange={onChange} name="DrivTemAmb" style={{width:4+'rem',height:1.5+'rem',fontWeight:700}}/>
        </Box>
        <Box display="flex" flexDirection="row"  style={{width:31+'rem',paddingTop:.4+'rem'}}>
          <span style={{paddingRight:2+'rem',width:26+'rem',color:'#F46239',fontWeight:600}}>
            Valor Referencia de Velocidad del Sistema
          </span>           
          <input type="text" id="input-velSistem" style={{width:4+'rem',height:1.5+'rem',fontWeight:700}}
          value={DrivVelSistem} onChange={onChange} name="DrivVelSistem" />
        </Box>
        <Box display="flex" flexDirection="row"  style={{width:31+'rem',paddingTop:.4+'rem'}}>
          <span style={{paddingRight:2+'rem',width:26+'rem',color:'#F46239',fontWeight:600}}>
            Temperatura de Rodamiento del Motor
          </span>           
          <input type="text" id="input-DrivTempRoda" style={{width:4+'rem',height:1.5+'rem' ,fontWeight:700}}
          value={DrivTempRoda} onChange={onChange} name="DrivTempRoda" />
        </Box>
        <Box display="flex" flexDirection="row"  style={{width:31+'rem',paddingTop:.4+'rem'}}>
          <span style={{paddingRight:2+'rem',width:26+'rem',color:'#F46239',fontWeight:600}}>
            Temperatura Inverter LeitDrive 1
          </span>           
          <input type="text" id="input-DrivTempInv1" style={{width:4+'rem',height:1.5+'rem',fontWeight:700}}
            value={DrivTempInv1} onChange={onChange} name="DrivTempInv1" />
        </Box>
        <Box display="flex" flexDirection="row"  style={{width:31+'rem',paddingTop:.4+'rem'}}>
          <span style={{paddingRight:2+'rem',width:26+'rem',color:'#F46239',fontWeight:600}}>
            Temperatura Inverter LeitDrive 2
          </span>           
          <input type="text" id="input-DrivTempInv2" style={{width:4+'rem',height:1.5+'rem',fontWeight:700}}
            value={DrivTempInv2} onChange={onChange} name="DrivTempInv2"/>
        </Box>
        <Box display="flex" flexDirection="row"  style={{width:31+'rem',paddingTop:.4+'rem'}}>
          <span style={{paddingRight:2+'rem',width:26+'rem',color:'#F46239',fontWeight:600}}>
            Temperatura Inverter LeitDrive 3
          </span>           
          <input type="text" id="input-DrivTempInv3" style={{width:4+'rem',height:1.5+'rem',fontWeight:700}}
            value={DrivTempInv3} onChange={onChange} name="DrivTempInv3"/>
        </Box>
        <Box display="flex" flexDirection="row"  style={{width:31+'rem',paddingTop:.4+'rem'}}>
          <span style={{paddingRight:2+'rem',width:26+'rem',color:'#F46239',fontWeight:600}}>
            Temperatura Inverter LeitDrive 4
          </span>           
          <input type="text" id="input-DrivTempInv4" style={{width:4+'rem',height:1.5+'rem',fontWeight:700}}
            value={DrivTempInv4} onChange={onChange} name="DrivTempInv4"/>
        </Box>
        <Box display="flex" flexDirection="row"  style={{width:31+'rem',paddingTop:.4+'rem'}}>
          <span style={{paddingRight:2+'rem',width:26+'rem',color:'#F46239',fontWeight:600}}>
            Dif. Presión P1-P3
          </span>           
          <input type="text" id="input-DrivDifPreP1P3" style={{width:4+'rem',height:1.5+'rem',fontWeight:700}}
            value={DrivDifPreP1P3} onChange={onChange} name="DrivDifPreP1P3" />
        </Box>
        <Box display="flex" flexDirection="row"  style={{width:31+'rem',paddingTop:.4+'rem'}}>
          <span style={{paddingRight:2+'rem',width:26+'rem',color:'#F46239',fontWeight:600}}>
            Dif. Presión P2-P3
          </span>           
          <input type="text" id="input-DrivDifPreP2P3" style={{width:4+'rem',height:1.5+'rem',fontWeight:700}}
            value={DrivDifPreP2P3} onChange={onChange} name="DrivDifPreP2P3"  />
        </Box>
        <Box display="flex" flexDirection="row"  style={{width:31+'rem',paddingTop:.4+'rem'}}>
          <span style={{paddingRight:2+'rem',width:26+'rem',color:'#F46239',fontWeight:600}}>
            Temperatura Máxima Instantánea Devanados Motor
          </span>           
          <input type="text" id="input-DrivTempMaxDev" style={{width:4+'rem',height:1.5+'rem',fontWeight:700}}
            value={DrivTempMaxDev} onChange={onChange} name="DrivTempMaxDev" />
        </Box>
        <Box display="flex" flexDirection="row"  style={{width:31+'rem',paddingTop:.4+'rem'}}>
          <span style={{paddingRight:2+'rem',width:26+'rem',color:'#F46239',fontWeight:600}}>
            Temperatura Mínima Instantánea Devanados Motor
          </span>           
          <input type="text" id="input-DrivTempMinDev" style={{width:4+'rem',height:1.5+'rem',fontWeight:700}}
            value={DrivTempMinDev} onChange={onChange} name="DrivTempMinDev"  />
        </Box>                                           
        </Box>
        </Box>
      </form>
    </Modal>
  );
}
 
export default NuevaDrive;