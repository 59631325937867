import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {InputLabel,FormControl,Select as MaterialSelect  ,Box 
  ,LinearProgress ,TextField,RadioGroup,FormControlLabel,Radio,FormLabel//,Typography
  } from '@material-ui/core';
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import Modal from '../Generales/ModalDummy';
//import moment from 'moment';
const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '20ch',
      }, 
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
      Textnumero: {
        width: 5+"px",
      },
      formControlFull: {
        //margin: theme.spacing(1),
        //minWidth: 120,
        width:5+"rem"
      },
      margin: {
        margin: theme.spacing(1),
      },
    },
  }));

const EditarMtto = ({modalAbierto,setModalAbierto,titulo,mttoActividad}) => {
  let history = useHistory();
  const classes = useStyles();
  const [espera,setEspera]=React.useState(false);
  const [mensaje, setMensaje] = React.useState('');
  const [tipoAdvertencia, setTipoAdvertencia] = React.useState('success');  
  const [aviso, setAviso] = React.useState(false); 
  const[nvoMtto,setNvoMtto]=React.useState({Estado:'0' , verifica:'',
                                            MttoID:0,CabId:0,MttoIdCab:0,Cantidad:0})

  const{ Estado,CabId,MttoIdCab,Cantidad,verifica}=nvoMtto                                                                
 
  const[listStatus,setListStatus]= React.useState([])
 
 
  React.useEffect(()=>{ 
   // console.log(mttoActividad);
    setNvoMtto({...nvoMtto, Estado:mttoActividad.stId.toString(), CabId:mttoActividad.cabinaId,
      MttoIdCab:mttoActividad.Id, Cantidad:mttoActividad.cantidad,verifica:mttoActividad.verifica
    })
   const source = axios.CancelToken.source();      
 
    async function llenarListStatus()  {           
    setEspera(true)       
    let auxU=process.env.REACT_APP_LINK +`/cabina-mtto-status`;          
    let token =localStorage.getItem('token20') ;                
    let dataInfo = qs.stringify({                                        
        'idDispositivo':'8978yuhjnfrsdsd'
        });
    let config = {
        url:auxU ,
        method: 'POST',
        headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
        data: dataInfo,
        cancelToken: source.token
    };
    let aux ={};           
    await axios(config)
    .then(function (response) {         
        aux=  response.data;                 
        if (aux[0].Id != -1) 
        { //guardarSinDatos(true)                                            
          setListStatus(aux)
        }else{
        // guardarSinDatos(false)
        }                                            
    })
    .catch(function (error) {          
      console.log(`Error: ${error}`);         
      if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
      {
        console.log('datos incorrectos' );  
      }else
      {
        if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
            ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
        {
          console.log('loguearse de nuevo');
          localStorage.clear();                      
          history.push("/");                              
        } 
      }
    });                
    setEspera(false)     
    }; 
    
    if (listStatus.length === 0) 
    {
      llenarListStatus();    
    }  

    return ()=> {
      source.cancel();
    }     
      
  },[]);

  
  
  const onChangeNum = e =>
  { let expNopermitida = new RegExp('[#A-Za-z_:$!%-({})/*=?|]');
    let expMenos = new RegExp('-');
    let expMas = new RegExp('[+]');     
    let expCadena = new RegExp('[0-9]');
    if ( ( expCadena.test(e.target.value)&&!expNopermitida.test(e.target.value)&&!expMenos.test(e.target.value)&&
        !expMas.test(e.target.value)) ||e.target.value===' '||e.target.value==='') 
    {
      setNvoMtto({
        ...nvoMtto, [e.target.name] : e.target.value })
      if (aviso) 
      {
        setMensaje('');
        setAviso(false);
      }
    }
  };
  
  
  const mostrarAlertas =(auxMensaje)=>{
    setTipoAdvertencia("warning")
    let mensaje=auxMensaje            
    setMensaje(mensaje);
    setAviso(true)   
  }

  function valNumero(auxNumero,auxCampo) 
  { let valida=false    
    if (typeof parseInt(auxNumero) === 'number') 
    {
      if (parseInt(auxNumero) ==0) 
      { valida=true
      }  
      else{
        if (parseInt(auxNumero) >0) 
        { valida=true
        } 
      }
    }
    if (!valida) 
    { 
      mostrarAlertas("Llenar campo "+auxCampo)
    }
    return valida
  }  

  function valNulos(auxMensaje,auxCampo) 
  { let valida=false    
    if (auxCampo !== '' && auxCampo !== '0'&& auxCampo !==0 && auxCampo !== null && auxCampo!==" " ) 
    {      
      valida=true              
    }
    if (!valida) 
    { 
      mostrarAlertas(auxMensaje)
    }
    return valida
  }
   
 

  const guardaEstado = e =>
  {  
    setNvoMtto({
      ...nvoMtto, Estado : e.target.value  })  
    if (aviso) 
    { setMensaje('');
      setAviso(false);
    }      
  };
  const guardaVerifi = e =>
  {  
    setNvoMtto({
      ...nvoMtto, verifica : e.target.value  })  
    if (aviso) 
    { setMensaje('');
      setAviso(false);
    }      
  };
   

  return ( 
    <Modal
      titulo={titulo} modalAbierto={modalAbierto}
      setModalAbierto={setModalAbierto}  mensaje={mensaje} 
      tipoAdvertencia={tipoAdvertencia} aviso={aviso} setAviso={setAviso} 
      espera={espera} setEspera={setEspera} tamanio={"sm"}
    >
    <form  noValidate autoComplete="off" fullWidth>       
      <Box  display="flex" flexDirection="row">                
        <Box style={{width:35+'rem'}} className={classes.root}>  
          <FormControl component="fieldset" style={{width:15+'rem'}}>                          
            <RadioGroup row aria-label="position" defaultValue="top"   
              name="verifica" value={verifica} onChange={guardaVerifi}
            >                          
              <span> 
                <FormControlLabel value={"OK"} style={{color:"#0ac15d"}} 
                  control={<Radio color="primary"disabled />} label={"OK"} />    
                <br/>
              </span>
              <span> 
                <FormControlLabel value={"CAMBIO"} style={{color:"#FF6439"}} 
                  control={<Radio color="primary" disabled/>} label={"CAMBIO"} />    
                
              </span>                        
            </RadioGroup>                     
          </FormControl> 
          <TextField id="outlinedReferen"           
            label="Cantidad"  size="small" disabled
            value={Cantidad} onChange={onChangeNum}  
            name="Cantidad" style={{width:5+'rem'}}                                               
          />  
          <br/>
          {/* listStatus.length!=0? 
            <FormControl component="fieldset" style={{width:35+'rem'}}>              
              <FormLabel component="legend" style={{color:'#FF6439',fontWeight:500}}>
                Estatus
              </FormLabel>      
              <RadioGroup row aria-label="position" defaultValue="top"   
                name="Estado" value={Estado} onChange={guardaEstado}
              >            
                {listStatus.map((nvStatus)=>{
                  if (nvStatus.Id!== -1&&nvStatus.Id!== 0) 
                  { return(  
                    <span> 
                      <FormControlLabel value={nvStatus.Id.toString()} style={{color:nvStatus.Color}} 
                        control={<Radio color="primary" disabled/>} label={nvStatus.Descr} />    
                      <br/>
                    </span>)
                  }
                })}                        
              </RadioGroup>                     
            </FormControl>     
          :null */}            
          <FormControl component="fieldset" style={{width:35+'rem'}}>              
            <FormLabel component="legend" style={{color:'#FF6439',fontWeight:500}}>
              Estatus
            </FormLabel>      
            <RadioGroup row aria-label="position" defaultValue="top"   
              name="Estado" value={Estado} onChange={guardaEstado}
            >            
              
              <span> 
                <FormControlLabel value={"1"} style={{color:"#ff6400"}} 
                  control={<Radio color="primary" disabled/>} label={"Pendiente"} />    
                <br/>
              </span> 
              <span> 
                <FormControlLabel value={"2"} style={{color:"#fdca32"}} 
                  control={<Radio color="primary" disabled/>} label={"En Proceso"} />    
                <br/>
              </span> 
              <span> 
                <FormControlLabel value={"3"} style={{color:"#0ac15d"}} 
                  control={<Radio color="primary" disabled/>} label={"Finalizado"} />    
                <br/>
              </span>                
            </RadioGroup>                     
          </FormControl>               
        </Box>
       
      </Box>
    </form>
  </Modal>
  );
}
 
export default EditarMtto;