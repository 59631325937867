import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Box , LinearProgress ,TextField, } from '@material-ui/core';
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import Modal from '../Generales/Modal2';
import {llamaApiCargaToken,ErrorGeneral } from '../funciones/ClienteAxios';
import {toast} from "react-toastify";

const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '20ch',
      }, 
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
      Textnumero: {
        width: 5+"px",
      },
      formControlFull: {        
        width:5+"rem"
      },
      margin: {
        margin: theme.spacing(1),
      },
    },
  }));

const EditarPersonal = ({modalAbierto,setModalAbierto,titulo,personal,actualizar2,setNomPersonal}) => {
  let history = useHistory();
  const classes = useStyles();
  const [espera,setEspera]=React.useState(false);
  const [mensaje, setMensaje] = React.useState('');
  const [tipoAdvertencia, setTipoAdvertencia] = React.useState('success');
  const [aviso, setAviso] = React.useState(false);
  const[nvoMtto,setNvoMtto]=React.useState({Auxiliar:"",Tecnico:"",MttoID:0,cabina:0,llave:0})
  const{Auxiliar,Tecnico,MttoID,cabina,llave}=nvoMtto                                                                  
  const source = axios.CancelToken.source();      
  const [btnDesact,setBtnDesact]=React.useState(false);
  React.useEffect(()=>{    
    setNvoMtto({...nvoMtto,Auxiliar:personal.auxiliar ,
      MttoID:personal.MttoId ,Tecnico :personal.tecnico,     
      llave:personal.original.Id
    })            
console.log(personal);
    return ()=> {
      source.cancel();
    }     
      
  },[]);

  const registrarNVLectura=( )=>{    
    const llenarEditarCabs = () => {      
      let data = qs.stringify({      
        MttoId:llave,  
        Llave:llave,
        Tecnico:Tecnico,
        Auxiliar:Auxiliar,
        idDispositivo:'987gyubjhkkjh'
      });
      let url = "/pilona-mtto-det-personal-editar";
      function respuestaEditCab(auxiliar) {
        if (auxiliar.respuesta==1) 
        {  console.log(auxiliar);
          let mensaje="Se edito correctamente el personal"
          setTipoAdvertencia("success")      
          setMensaje(mensaje);
          setAviso(true)                 
          //actualizar2()//llamada de funcionamiento   
          setBtnDesact(true)
          setModalAbierto(false)
          let auxEditPer={...personal,auxiliar:Auxiliar ,
            tecnico:Tecnico }
          toast.success(mensaje, {
            toastId: `edit-personal-pil${llave}`,
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setNomPersonal(auxEditPer)
        }       
      }  
      llamaApiCargaToken(data, url,respuestaEditCab,ErrorGeneral,setEspera,history,source);
    }

    llenarEditarCabs()   
  }

  const onChange = e =>
  { let expNopermitida = new RegExp('[#_:$!%-({})/*=?|]');
    let expMenos = new RegExp('-');
    let expMas = new RegExp('[+]');     
    let expCadena = new RegExp('[A-Za-z0-9]');
    if ( ( expCadena.test(e.target.value)&&!expNopermitida.test(e.target.value)&&!expMenos.test(e.target.value)&&
        !expMas.test(e.target.value) )||e.target.value===' '||e.target.value==='') 
    { setNvoMtto({
        ...nvoMtto, [e.target.name] : e.target.value })
      if (aviso) 
      { setMensaje('');
        setAviso(false);
      }
    }
  };

  const mostrarAlertas =(auxMensaje)=>{
    setTipoAdvertencia("warning")
    let mensaje=auxMensaje            
    setMensaje(mensaje);
    setAviso(true)   
  }

  function valNulos(auxMensaje,auxCampo) 
  { let valida=false    
    if (auxCampo !== '' && auxCampo !== '0'&& auxCampo !==0 && auxCampo !== null && auxCampo!==" " ) 
    {      
      valida=true              
    }
    if (!valida) 
    { 
      mostrarAlertas(auxMensaje)
    }
    return valida
  }

  
    const guardarDatos = () => 
    {
        if ( valNulos("Debe escribir Técnico",Tecnico) && valNulos("Debe escribir un Auxiliar",Auxiliar)) 
        {
            registrarNVLectura()                                                                                                                                                                   
        }       
    };
    
    const cerrarBorrar = () => {        
        setAviso(false);
        setModalAbierto(false)
    };

    return ( 
    <Modal
        titulo={titulo} modalAbierto={modalAbierto}
        setModalAbierto={setModalAbierto} guardarDatos={guardarDatos}
        mensaje={mensaje} tipoAdvertencia={tipoAdvertencia}
        aviso={aviso} setAviso={setAviso} espera={espera} btnDesact={btnDesact}
        setEspera={setEspera} tamanio={"sm"} cerrarBorrar={cerrarBorrar}
    >
    <form  noValidate autoComplete="off" fullWidth>       
        <Box  display="flex" flexDirection="column" className={classes.root}>                        
            <TextField id="outlinedTecnico"           
            label="Técnico"  size="small" value={Tecnico} 
            onChange={onChange} name="Tecnico" style={{width:31+'rem'}}                                               
            />    
            <TextField id="outlinedTecnico"           
            label="Auxiliar"  size="small" value={Auxiliar} 
            onChange={onChange} name="Auxiliar" style={{width:31+'rem'}}                                               
            />                
        </Box>
    </form>
    </Modal>
    );
}
 
export default EditarPersonal;