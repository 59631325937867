import {useEffect, useState} from 'react';
import { FormControl, FormControlLabel,
    FormLabel, Grid, InputLabel, Radio, RadioGroup,
    Select as MaterialSelect, TextField,Input,InputAdornment,
    IconButton,Checkbox
} from '@material-ui/core';
import axios from 'axios';
import qs from 'qs';
import {useHistory} from "react-router-dom";
import Modal from '../Generales/Modal2';
import {ErrorGeneral, llamaApiCargaToken, llamadaApiToken} from "../funciones/ClienteAxios";
import {toast} from "react-toastify";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
const EditarUsuario = ({modalAbierto, setModalAbierto, titulo, seleccionadoEdit, user, isUpdate,actualizar}) => {
    let history = useHistory();
    let idusu = localStorage.getItem('UsuId');

    const source = axios.CancelToken.source();
    const [espera, setEspera] = useState(false);
    const [mensaje, setMensaje] = useState('');
    const [tipoAdvertencia, setTipoAdvertencia] = useState('success');
    const [aviso, setAviso] = useState(false);
    const [listLugarTerminal, setListLugarTerminal] = useState([])
    const [listPerfil, setListPerfil] = useState([])
    const [listPuesto, setListPuesto] = useState([])
    const [listaPerfiles, setListaPerfiles] = useState([])
    const [btnDesact, setBtnDesact] = useState(false);
    const [datosUsuario, setDatosUsuario] = useState([]);
    const [bandera, setBandera] = useState(true);
    const[visiblePwd,setVisiblePwd]= useState(false)    
    const [usuarioEditado, setUsuarioEditado] = useState({Id:0, Nombre:"", Puesto:"", Terminal:0, Tipo:0, Turno:0, Pass:"", Email:"", Estatus:0, Usuario:""})
    const {Id, Nombre, Puesto, Terminal, Tipo, Turno, Pass, Email, Estatus, Usuario} = usuarioEditado    

    useEffect(() => {
        const userData = () => {
            let data = qs.stringify({
                llave: seleccionadoEdit.Id, usuario: idusu, idDispositivo: 'WRF8754SD4SD'
            });
            let url = "/usuario-id";

            function respuesta(aux) {
                if (aux[0].Id!==-1) {
                    setUsuarioEditado(
                        {
                            Id: aux[0].Id,
                            Tipo: aux[0].Tipo,
                            Terminal: aux[0].Terminal,
                            Estatus: aux[0].Status.toString(),
                            Nombre: aux[0].Nombre,
                            Puesto: aux[0].Puesto,
                            Pass: aux[0].Pass,
                            Email: aux[0].Email,
                            Turno: aux[0].Turno ===0 ? false:true,
                            Usuario: aux[0].Usuario
                        }
                    )
                    setDatosUsuario(aux[0])
                }                
               
            }
            llamaApiCargaToken(data, url, respuesta,ErrorGeneral,setEspera,history,source)
        };

        if(isUpdate=== true){
            userData()
        }  

    }, [user]);

    useEffect(() => {
        const llenarListTerminal = () => {
            let data = qs.stringify({
                usuario: idusu, idDispositivo: '89y7gttvjhj98h'
            });
            let url = "/lugar-terminal-list";

            function respuesta(aux) {
                if (aux[0].Id !== -1) {
                    let auxArre = [];
                    aux.map((tipo) => {
                        if (tipo.Id != 0) {
                            auxArre.push({value: tipo.Id, label: tipo.Descr})
                        }
                    })
                    setListLugarTerminal(auxArre);
                }
            }

            llamadaApiToken(data, url, respuesta, ErrorGeneral, history, source)
        };

        if (listLugarTerminal.length === 0) {
            llenarListTerminal();
        }

        const llenarListaPerfil = () => {
            let data = qs.stringify({
                usuario: idusu, idDispositivo: 'WRF8754SD4SD'
            });
            let url = "/usuario-perfil-list";

            function respuesta(aux) {
                if (aux[0].Id !== -1) {
                    let auxArre = [];
                    aux.map((tipo) => {
                        if (tipo.Id != 0) {
                            auxArre.push({value: tipo.Id, label: tipo.Nom})
                        }
                    })
                    setListPerfil(auxArre);
                    setListaPerfiles(aux);
                }
            }

            llamadaApiToken(data, url, respuesta, ErrorGeneral, history, source)
        };

        if (listPerfil.length === 0) {
            llenarListaPerfil();
        }

    }, []);

    useEffect(() => {
        
       // console.log(listaPerfiles);
        if (listaPerfiles.length !== 0) {
            let array = listaPerfiles.filter(puesto => puesto.Id === parseInt(Tipo))
            let array2 = array.map((element) => {
                return element.tipo
            })

            array2.forEach((element) => {
                return setListPuesto(element)
            })
        }
    }, [listaPerfiles, Tipo]);

    const editarUsuario = () => {

        const llenarEditarUsuario = () => {
            let data = qs.stringify({
                llave: Id,
                tipo: parseInt(Tipo, 10),
                terminal: parseInt(Terminal, 10),
                estatus: Estatus,
                nombre: Nombre,
                usuario: Usuario,
                pass: Pass,
                email: Email,
                puesto: Puesto,
                turno: Turno?1:0,
                idDispositivo: 'rjvikcmkcmsdkdcdsmc'
            });
            let data2 = qs.stringify({                
                tipo: parseInt(Tipo, 10),
                terminal: parseInt(Terminal, 10),            
                nombre: Nombre,
                usuario: Usuario,
                pass: Pass,
                email: Email,
                puesto: Puesto,
                turno: Turno?1:0,
                idDispositivo: 'rjvikcmkcmsdkdcdsmc'
            });
            let url = isUpdate ? "/usuario-editar-1-1" : '/usuario-nuevo-1-1';

            function response(auxiliar) {
                let mensaje =""
                if (isUpdate) 
                {
                    if (auxiliar.respuesta == 1) 
                    {   mensaje = "Se edito correctamente el usuario"
                        setTipoAdvertencia("success")
                        setMensaje(mensaje);
                        setAviso(true)
                        setBtnDesact(true)
                        setModalAbierto(false)
                        actualizar()                                               
                    } 
                    else 
                    {   mensaje='Error al editar usuario'
                    }    
                }
                else
                {   if (auxiliar.respuesta == 1) 
                    {   mensaje = "Se creo correctamente el usuario"
                        setTipoAdvertencia("success")
                        setMensaje(mensaje);
                        setAviso(true)
                        setBtnDesact(true)
                        setModalAbierto(false)
                        actualizar()                        
                    } 
                    else 
                    {   mensaje='Error al crear usuario'
                    }                       
                }
                toast.success(mensaje, {
                    toastId: `edit-user-pil${Id}`,
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }

            llamaApiCargaToken( isUpdate ? data : data2 , 
                url, response, ErrorGeneral, setEspera, history, source);
        }

        llenarEditarUsuario()
    }

    const onChange = e => {
        setUsuarioEditado({
            ...usuarioEditado,
            [e.target.name]: e.target.value
        })
    };

    const mostrarAlertas = (auxMensaje) => {
        setTipoAdvertencia("warning")
        let mensaje = auxMensaje
        setMensaje(mensaje);
        setAviso(true)
    }

    function valNulos(auxMensaje, auxCampo) {
        let valida = false
        if (auxCampo !== '' && auxCampo !== '0' && auxCampo !== 0 && auxCampo !== null && auxCampo !== " ") {
            valida = true
        }
        if (!valida) {
            mostrarAlertas(auxMensaje)
        }
        return valida
    }

    const guardarDatos = () => {
        if (valNulos("Debe elegir una Terminal", Terminal) &&
            valNulos("Debe elegir un Perfil", Tipo) &&
            valNulos("Debe escribir Nombre", Nombre) &&                        
            valNulos("Debe escribir un Puesto", Puesto)&&
            valNulos("Debe escribir un Email", Email) &&
            valNulos("Debe escribir una Contraseña", Pass) 
        ) {
            editarUsuario()
        }        
    };

    const cerrarBorrar = () => {
        setAviso(false);
        setModalAbierto(false)
    };

    const mostrarPwd = () => {        
        setVisiblePwd(!visiblePwd)
      };
    
    const mostrarPwdUp = (event) => {
        event.preventDefault();
        //setVisiblePwd(!visiblePwd)
    };
    
    const onChangeCheck = (event) => {
        setUsuarioEditado({
          ...usuarioEditado,
            [event.target.name]: event.target.checked 
        });
    };

    const contenido=()=>{
        return(
        <form noValidate autoComplete="off" fullWidth>
            <Grid container alignItems={'center'} spacing={2}>
                <Grid item xs={6}>
                    {listLugarTerminal.length !== 0 ?
                        <FormControl size="small" disabled={user.nivel !== '1' ? true : false}>
                            <InputLabel htmlFor="age-native-simple">Terminal</InputLabel>
                            <MaterialSelect
                                native value={Terminal} onChange={onChange}
                                inputProps={{ name: 'Terminal', id: 'Terminal', }}
                            >
                                <option aria-label="None" value=""/>
                                {listLugarTerminal.map((row) => (
                                    <option value={row.value}>{row.label}</option>
                                ))}
                            </MaterialSelect>
                        </FormControl>
                    : null}
                </Grid>
                <Grid item xs={6}>
                    {listPerfil.length !== 0 ?
                        <FormControl size="small" disabled={user.nivel !== '1' ? true : false}>
                            <InputLabel htmlFor="age-native-simple">Perfil</InputLabel>
                            <MaterialSelect
                                native value={Tipo} onChange={onChange}
                                inputProps={{ name: 'Tipo', id: 'Tipo', }}
                            >
                                <option aria-label="None" value=""/>
                                {listPerfil.map((row) => (
                                    <option value={row.value}>{row.label}</option>
                                ))}
                            </MaterialSelect>
                        </FormControl>
                    : null}
                </Grid>
                <Grid item xs={6}>
                    <FormControlLabel style={{marginLeft:.1+'rem'}}
                        control={
                            <Checkbox checked={Turno} onChange={onChangeCheck} 
                                name="Turno" />}
                        label="Cambio de Turno"
                    />   
                    
                </Grid>
                {isUpdate?<Grid item xs={6}>
                    <FormControl component="fieldset" disabled={user.nivel !== '1' ? true : false}>
                        <FormLabel component="legend">Estatus</FormLabel>
                        <RadioGroup row aria-label="position" size={"small"}
                            defaultValue="top" name="Estatus" value={Estatus} onChange={onChange}
                        >
                            <FormControlLabel value="1" control={<Radio color="primary" size={"small"}/>}
                                label="Activo"/>
                            <FormControlLabel value="0" control={<Radio color="primary" size={"small"}/>}
                                label="Inactivo"/>
                        </RadioGroup></FormControl>
                </Grid>:null}
                <Grid item xs={6}>
                    <TextField id="outlinedTecnico" fullWidth={'true'}
                        disabled={user.nivel !== '1' ? true : false}
                        label="Nombre" size="small" value={Nombre}
                        onChange={onChange} name="Nombre"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField id="outlinedTecnico" fullWidth={'true'}
                        disabled={user.nivel !== '1' ? true : false}
                        label="Puesto" size="small" value={Puesto}
                        onChange={onChange} name="Puesto"
                    />                        
                </Grid>
              
                <Grid item xs={6}>
                    <TextField id="outlinedUsuario" fullWidth={'true'}
                        disabled={user.nivel !== '1' ? true : false}
                        label="Usuario" size="small" value={Usuario}
                        onChange={onChange} name="Usuario"
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormControl  size="small"  fullWidth >
                    <InputLabel htmlFor="pwd-usu1-txt" size="small">
                        Contraseña
                    </InputLabel>
                    <Input
                        id="input-p-usu1" onChange={onChange } size="small"
                        type={visiblePwd ? 'text' : 'password'}
                        value={Pass}  autoComplete='do-not-autofill' name="Pass"
                        disabled={user.nivel !== '1' ? true : false}
                        endAdornment={
                            <InputAdornment position="end" size="small">
                                <IconButton size="small" 
                                    aria-label="icono password visibility"
                                    onClick={mostrarPwd}  onMouseDown={mostrarPwdUp}
                                >
                                {visiblePwd ? <Visibility size="small"/> : <VisibilityOff size="small"/>}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    </FormControl>  
                    
                </Grid>
                <Grid item xs={6}>
                    <TextField id="outlinedEmail" fullWidth={'true'}
                        disabled={user.nivel !== '1' ? true : false}
                        label="Email" size="small" value={Email}
                        onChange={onChange} name="Email"
                    />
                </Grid>
            </Grid>
        </form>
        )
    }
    return (
        <Modal
            titulo={titulo} modalAbierto={modalAbierto}
            setModalAbierto={setModalAbierto} guardarDatos={guardarDatos}
            mensaje={mensaje} tipoAdvertencia={tipoAdvertencia}
            aviso={aviso} setAviso={setAviso} espera={espera} btnDesact={user.nivel !== '1' ? true : btnDesact}
            setEspera={setEspera} tamanio={'sm'} cerrarBorrar={cerrarBorrar}
        >
            {  contenido() }
            
        </Modal>
    );
}

export default EditarUsuario;