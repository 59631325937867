import React from 'react';
import {Button,Dialog,DialogActions,DialogContent
        ,DialogTitle,Typography} from '@material-ui/core';
//import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import Alert from '@material-ui/lab/Alert';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    //margin: theme.spacing(0),
    marginTop: 0,
    paddingTop:0,
    position: 'relative',
  },
 
  buttonGuardar:{color: green[500],},
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function ModalDummy({titulo,children,modalAbierto,setModalAbierto,mensaje,tipoAdvertencia,aviso, setAviso,espera,tamanio,subtitulo,maxTam}) {
  const [open, setOpen] = React.useState(false);
  //const [aviso, setAviso] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');
  //const [mensaje, setMensaje] = React.useState('');
  //const [tipoAdvertencia, setTipoAdvertencia] = React.useState('success');
  
  //let history = useHistory();
  /*const handleClickOpen = (scrollType) => () => {
    setOpen(true);
  
    setScroll(scrollType);
  };
  const abrirAviso = () => () => {
    setAviso(true);
    
  };*/

  const cerrarBorrar = () => {
    
    setOpen(false);
    setAviso(false);
    setModalAbierto(false)
  };
  const handleClose = () => {
    setOpen(false);
    setModalAbierto(false)
  };
  const handleCloseSN = () => {
    setAviso(false);
    //setModalAbierto(false)
  };
  
  const classes = useStyles();
  //const [espera,setEspera]=React.useState(false);
  
  
  
  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (modalAbierto) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }    
  }, [modalAbierto]);
  
  return (
  <div>         
    <Dialog
      open={modalAbierto} onClose={handleClose}
      scroll={scroll} aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth={maxTam} maxWidth={tamanio}
    >
                
      <DialogTitle style={{color:'#F46239', padding:0,marginBottom:.5+'rem',marginTop:.5+'rem'}} id="modal-dummy" align="center" >
        {titulo}
        {
          subtitulo !== undefined ?
            subtitulo()
            :
            null
        }
      </DialogTitle>
            
      <DialogContent dividers={scroll === 'paper'} >        
        { aviso&&tipoAdvertencia &&mensaje.length!=0 ? 
          <Alert severity={tipoAdvertencia}  onClose={handleCloseSN} style={{marginBottom:.35+'rem'}}>{mensaje}</Alert>:<span></span>
        }
        {children}
      </DialogContent>
    
      <DialogActions>
        <Button onClick={cerrarBorrar} disabled={espera} color="secondary" variant="outlined">
          Cerrar
        </Button>             
      </DialogActions>
      
    </Dialog>    
  </div>
  );
}
