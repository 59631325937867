import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {InputLabel,FormControl,Select as MaterialSelect  ,Box ,
  //TextareaAutosize ,RadioGroup,FormControlLabel,Radio,Typography,
  LinearProgress,TextField,FormLabel, Typography
  } from '@material-ui/core';
import axios from 'axios';
import qs from 'qs';
import CabinaContainerSelec from './CabinaContainerSelec';
import { useHistory } from "react-router-dom";
import Modal from '../Generales/Modal';
import {toast} from "react-toastify";
//import moment from 'moment';
import {llamaApiCargaToken,ErrorGeneral,llamadaApiToken } from '../funciones/ClienteAxios';
const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '20ch',
      }, 
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
      Textnumero: {
        width: 5+"px",
      },
      formControlFull: {
        //margin: theme.spacing(1),
        //minWidth: 120,
        width:5+"rem"
      },
      margin: {
        margin: theme.spacing(1),
      },
    },
  }));

const NvoMtto = ({modalAbierto,setModalAbierto,titulo,actualizar,listLugarTerminal}) => {
  let history = useHistory();
  const classes = useStyles();
  const [espera,setEspera]=React.useState(false);
  const [btnDesact,setBtnDesact]=React.useState(false);
  const [mensaje, setMensaje] = React.useState('');
  const [tipoAdvertencia, setTipoAdvertencia] = React.useState('success');
  const [arregloFiltrado, setArregloFiltrado] = useState([])
  const [aviso, setAviso] = React.useState(false);
  const [cabinaSelec, setCabinaSelec] = React.useState([]);
  //const [datosAfectacion, setDatosAfectacion] = React.useState([]);  
  const[nvoMtto,setNvoMtto]=React.useState({Terminal:0,Tipo:0,Auxiliar:"",Tecnico:"",Estado:'0' , Descripcion:"",
                                            Horometro:0,Observ:"",Cabinas:"",RefManual:""})

  const{Terminal ,Tipo ,Descripcion ,Horometro,Observ ,Estado,Cabinas,RefManual,Auxiliar,Tecnico}=nvoMtto                                                                
  //const [listLugarTerminal, setListLugarTerminal] = React.useState([])
  const [listTipo, setListTipo] = React.useState([])
 // const[listStatus,setListStatus]= React.useState([])
  const[listCabs,setListCabs]= React.useState([])
  const[arrCabSelect,setArrCabSelect]= React.useState([])
  //const [cabinaObjLista, setCabinaObjLista] = useState([])
  const source = axios.CancelToken.source();      
    let idusu= localStorage.getItem('UsuId');
  React.useEffect(()=>{             
   let auxTerminal= localStorage.getItem('UsuTerminal');      
   /* const llenarListStatus = () => {      
    let data = qs.stringify({
      orden:orden.Id,
      idDispositivo:'89y7gttvjhj98h'
    });
    let url = "/cabina-mtto-status";
    function respuestaListStat(auxiliar) {
      if (auxiliar[0].Id !== -1) 
      { //guardarSinDatos(true)                                            
        setListStatus(auxiliar)
      }else{
      // guardarSinDatos(false)
      }      
    }

    llamaApiCargaToken(data, url,respuestaListStat,ErrorGeneral,setEspera,history,source);
  };*/ 
   
    
    const llenarListCabs = () => {      
      let data = qs.stringify({
        usuario:idusu,
        idDispositivo:'89y7gttvjhj98h'
      });
      let url = "/cabina-list";
      function respuestaListCabs(aux) {
        if (aux[0].Id !== -1) 
        { setListCabs(aux);     
          // console.log(aux)                    
          var au1x = aux.filter(function (val) {
            return val.TermId === parseInt(auxTerminal);
          });        
          au1x.sort(function (a, b) { return a.Id - b.Id; });              
          setArregloFiltrado(au1x) 
        }
      }
      
      llamadaApiToken(data, url, respuestaListCabs,ErrorGeneral,history,source)
    };

    const llenarListTipo = () => {      
      let data = qs.stringify({
        idDispositivo:'89y7gttvjhj98h'
      });
      let url = "/cabina-mtto-tipo";
      function respuestaListCabs(aux) {
        if (aux[0].Id !== -1) 
        {  let auxArre=[];  
          //console.log(aux); 
          aux.map((tipo) => {
            if (tipo.Id!==0) 
            {
              auxArre.push({ value : tipo.Id, label :tipo.Descr })  
            }            
          })                    
          setListTipo(auxArre); 
        }
      }
      
      llamadaApiToken(data, url, respuestaListCabs,ErrorGeneral,history,source)
    };
   
    if (listTipo.length === 0) 
    {
      llenarListTipo()
    }

    if (listCabs.length === 0) 
    {
      llenarListCabs();  
    }
    /* if (listStatus.length === 0) 
    {
      llenarListStatus();    
    }   */

    if (Terminal===0) {
     // let auxTerminal= localStorage.getItem('UsuTerminal');     
      setNvoMtto({
        ...nvoMtto, Terminal : auxTerminal  })  
   /*    var au1x = aux.filter(function (val) {
        return val.TermId === auxTerminal;
      });        
      au1x.sort(function (a, b) { return a.Id - b.Id; });              
      setArregloFiltrado(au1x)  */
    }    

    return ()=> {
      source.cancel();
    }     
      
  },[]);

  const registrarNVLectura=(auxCabinas)=>{
    const llenarNvLect = () => {      
      let data = qs.stringify({
        Usuario:idusu,
        Terminal:Terminal,
        Tipo:Tipo,
        Status:1,
        Descr:Descripcion,//'Mantenimiento programado',
        Observ:Observ,//'Pendiente',
        Cabinas:auxCabinas,//'1,2,3,45,65',        
        Horometro:Horometro,
        RefManual:RefManual,
        idDispositivo:'987gyubjhkkjh'
      });
      let url = "/cabina-mtto-nuevo_1_2";
      function respuestaNvLect(auxiliar) {
        if (auxiliar.respuesta==1) 
        { // console.log(auxiliar);
          let mensaje="Se registro correctamente el Nuevo Mantenimiento"
          setTipoAdvertencia("success")      
          setMensaje(mensaje);
          setAviso(true)                 
          actualizar()//llamada de funcionamiento  
          setBtnDesact(true) 
          toast.success(mensaje, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setModalAbierto(false)
        }       
      }
  
      llamaApiCargaToken(data, url,respuestaNvLect,ErrorGeneral,setEspera,history,source);
    }            
    llenarNvLect();    
  }

  const onChangeRef = e =>
  { let expNopermitida = new RegExp('[$!?]');
    let expMenos = new RegExp('-');
    let expMas = new RegExp('[+]');     
    let expCadena = new RegExp('[A-Za-z0-9#_:%-({})/*=|]');
    if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) ) 
        || e.target.value===' ' || e.target.value==='') 
    {    
      setNvoMtto({
        ...nvoMtto, [e.target.name] : e.target.value  })
      if (aviso) 
      { setMensaje('');
        setAviso(false);
      }
    }
  };

  const onChange = e =>
  { let expNopermitida = new RegExp('[#_:$!%-({})/*=?|]');
    let expMenos = new RegExp('-');
    let expMas = new RegExp('[+]');     
    let expCadena = new RegExp('[A-Za-z0-9]');
    if ( ( expCadena.test(e.target.value)&&!expNopermitida.test(e.target.value)&&!expMenos.test(e.target.value)&&
        !expMas.test(e.target.value) )||e.target.value===' '||e.target.value==='') 
    { setNvoMtto({
        ...nvoMtto, [e.target.name] : e.target.value })
      if (aviso) 
      { setMensaje('');
        setAviso(false);
      }
    }
  };
  
  const onChangeNum = e =>
  { let expNopermitida = new RegExp('[#A-Za-z_:$!%-({})/*=?|]');
    let expMenos = new RegExp('-');
    let expMas = new RegExp('[+]');     
    let expCadena = new RegExp('[0-9]');
    if ( ( expCadena.test(e.target.value)&&!expNopermitida.test(e.target.value)&&!expMenos.test(e.target.value)&&
        !expMas.test(e.target.value)) ||e.target.value===' '||e.target.value==='') 
    {
      setNvoMtto({
        ...nvoMtto, [e.target.name] : e.target.value })
      if (aviso) 
      {
        setMensaje('');
        setAviso(false);
      }
    }
  };
  
  const guardTerminal = e =>
  {
    if (parseInt(e.target.value) > 0) 
    { setArrCabSelect([])  
      var aux = listCabs.filter(function (val) 
      {
        return val.TermId === parseInt(e.target.value);
      });
      aux.sort(function (a, b) { return a.Id - b.Id; });    
                    
      setArregloFiltrado(aux)
    }
    else
    { setArrCabSelect([]) 
      setArregloFiltrado([])
    }
    setNvoMtto({
      ...nvoMtto, [e.target.name] : e.target.value  })
    if (aviso) 
    { setMensaje('');
      setAviso(false);
    }
  };

  const mostrarAlertas =(auxMensaje)=>{
    setTipoAdvertencia("warning")
    let mensaje=auxMensaje            
    setMensaje(mensaje);
    setAviso(true)   
  }

  function valNumero(auxNumero,auxCampo) 
  { let valida=false    
    if (typeof parseInt(auxNumero) === 'number') 
    {
      if (parseInt(auxNumero) !== 0 ) 
      { valida=true
      }  
      else{
        if (parseInt(auxNumero) > 0 ) 
        { valida=true
        } 
      }
    }
    if (!valida) 
    { 
      mostrarAlertas("Debe escribir un valor de "+auxCampo)
    }
    return valida
  }  

  function valNulos(auxMensaje,auxCampo) 
  { let valida=false    
    if (auxCampo !== '' && auxCampo !== '0'&& auxCampo !==0 && auxCampo !== null && auxCampo!==" " ) 
    {      
      valida=true              
    }
    if (!valida) 
    { 
      mostrarAlertas(auxMensaje)
    }
    return valida
  }
  
  function valCabinas() 
  { let valida=false    
    if (arrCabSelect.length>0 ) 
    {      
      valida=true              
    }
    if (!valida) 
    { 
      mostrarAlertas("Seleccionar Cabinas")
    }
    return valida
  }
  
  const guardarDatos = () => 
  {//Observ ,Cabinas,RefManual,Auxiliar,Tecnico
    if (  valNulos("Seleccionar Terminal",Terminal)  && valNulos("Seleccionar Tipo",Tipo) && valNumero(Horometro,"Horómetro")
    && valNulos("Debe escribir Referencia de Manual",RefManual)) 
    {       //  
      if ( (Descripcion.length>=5 && valNulos("Llenar campo de Descripción (mayor a 5 caracteres)",Descripcion) )
          || (Descripcion.length<5 && valNulos("Llenar campo de Descripción (mayor a 5 caracteres)",0))
      ) 
      {   
        if (valCabinas()) 
        {
          let auxCadena=""
          arrCabSelect.map((cabina)=>{ auxCadena=auxCadena + "" + cabina.Id + "," })
          //console.log(auxCadena);
          //console.log(auxCadena.slice(0,auxCadena.length-1));
          registrarNVLectura(auxCadena.slice(0,auxCadena.length-1))
        } 
                                                                                                                                               
      }                 
    }       
  };

  /* const guardaEstado = e =>
  {  
    setNvoMtto({
      ...nvoMtto, Estado : e.target.value  })  
    if (aviso) 
    { setMensaje('');
      setAviso(false);
    }      
  }; */

  const onChangeSelect = e =>
  {  
    setNvoMtto({
      ...nvoMtto,   [e.target.name] : e.target.value })

    if (aviso) 
    { setMensaje('');
      setAviso(false);
    }   
  };

  return ( 
    <Modal
      titulo={titulo} modalAbierto={modalAbierto}
      setModalAbierto={setModalAbierto} guardarDatos={guardarDatos}
      mensaje={mensaje} tipoAdvertencia={tipoAdvertencia}
      aviso={aviso} setAviso={setAviso} espera={espera}
      setEspera={setEspera} tamanio={"md"} btnDesact={btnDesact}
    >
    <form  noValidate autoComplete="off" fullWidth>       
      <Box  display="flex" flexDirection="row">                
        <Box style={{width:33+'rem'}} className={classes.root}>  
          {/* {listStatus.length!=0? 
            <FormControl component="fieldset" style={{width:40+'rem'}}>              
              <FormLabel component="legend" style={{color:'#FF6439',fontWeight:500}}>
                Estatus
              </FormLabel>      
              <RadioGroup row aria-label="position" defaultValue="top"   
                name="Estado" value={Estado} onChange={guardaEstado}
              >            
                {listStatus.map((nvStatus)=>{
                  if (nvStatus.Id!== -1&&nvStatus.Id!== 0) 
                  { return(  
                      <span> 
                        <FormControlLabel value={nvStatus.Id.toString()} style={{color:nvStatus.Color}} 
                          control={<Radio color="primary" />} label={nvStatus.Descr} />    
                        <br/>
                      </span>)
                  }
                })}                        
              </RadioGroup>                     
            </FormControl>     
          :null}      
          <br/>  */}                  
          {listLugarTerminal.length!=0 ?  
            <FormControl className={classes.formControl} size="small" style={{width:8+'rem'}}>
              <InputLabel htmlFor="age-native-simple">Terminal</InputLabel>
              <MaterialSelect
                native value={Terminal} onChange={guardTerminal}
                inputProps={{ name: 'Terminal', id: 'Terminal'}}
              >
                <option aria-label="None" value="" />      
                {listLugarTerminal.map((row) => (
                    <option value={row.value}>{row.label}</option>
                ))}   
              </MaterialSelect>
            </FormControl>     
          :null}  
          {listTipo.length!=0 ?  
            <FormControl className={classes.formControl} size="small" style={{width:10+'rem'}}>
              <InputLabel htmlFor="age-native-simple">Tipo</InputLabel>
              <MaterialSelect
                native value={Tipo} onChange={onChangeSelect}
                inputProps={{ name: 'Tipo', id: 'Tipo'}}
              >
                <option aria-label="None" value="" />      
                {listTipo.map((row) => (
                    <option value={row.value}>{row.label}</option>
                ))}   
              </MaterialSelect>
            </FormControl>     
          :null}  
     
          <TextField id="outlinedReferen"           
            label="Horómetro"  size="small" value={Horometro} 
            onChange={onChangeNum} name="Horometro" style={{width:9+'rem'}}                                               
          /> 
          <TextField id="outlinedReferen"           
            label="Referencia Manual"  size="small" value={RefManual} 
            onChange={onChangeRef} name="RefManual" style={{width:31+'rem'}}                                               
          />
          <br/>          
          
          <Box  display="flex" flexDirection="row" style={{width:30+'rem'}}  >                
            <Box >        
              <Box display="flex" flexDirection="column"  style={{width:20+'rem'}} >         
                <Typography component="p" variant="body2" color='primary'>Descripción</Typography>                 
                <textarea id="Descr-TextArea" name="Descripcion" rows="3" //placeholder="Descripción"
                  cols="100" style={{width: 31+"rem",borderRadius:3+'px'}} 
                  value={Descripcion} onChange={onChange}  
                />
              </Box>
              <Box display="flex" flexDirection="column"  style={{width:31+'rem',paddingTop:.4+'rem'}}>
              <Typography component="p" variant="body2" color='primary'>Observación</Typography>                          
                <textarea id="Descr-TextArea" name="Observ" rows="3" //placeholder="Observación" 
                  cols="100" style={{width: 31+"rem",borderRadius:3+'px'}} 
                  value={Observ} onChange={onChange} 
                />
              </Box>                                            
            </Box>
          </Box> 
        </Box>
      <Box style={{width:27+'em'}} display={"flex"} flexDirection="column">  
        <FormLabel component="legend" style={{color:'#FF6439',fontWeight:500}}>
          Selección de Cabina
        </FormLabel>      
        {Terminal!==0&& arregloFiltrado.length>0 ?
          <Box style={{marginTop:'1rem'}} >
            <CabinaContainerSelec cabinasTotales={arregloFiltrado.length} setCabinaSelec={setCabinaSelec} 
              cabSelecc={cabinaSelec} arrCabinas={arregloFiltrado} arrCabSelect={arrCabSelect}
              setArrCabSelect={setArrCabSelect} //cabinaObjLista={cabinaObjLista} setCabinaObjLista={setCabinaObjLista}
            />
          </Box>
          : <Box pl={1} pt={5}><LinearProgress/></Box>
        }
        </Box>
      </Box>
    </form>
  </Modal>
  );
}
 
export default NvoMtto;