import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Box , LinearProgress ,TextField, FormControl,
  FormControlLabel,IconButton,Typography,
  Radio,
  RadioGroup,} from '@material-ui/core';
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import Modal from '../Generales/Modal2';
import {llamaApiCargaToken,ErrorGeneral } from '../funciones/ClienteAxios';
import {toast} from "react-toastify";

const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '20ch',
      }, 
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
      Textnumero: {
        width: 5+"px",
      },
      formControlFull: {        
        width:5+"rem"
      },
      margin: {
        margin: theme.spacing(1),
      },
    },
  }));

const EditarPersonal = ({modalAbierto,setModalAbierto,titulo,personal,actualizar2,setNomPersonal,cabinaSelec,arrCabinas, setArrCabinas,setAuxCabSelecc}) => {
  let history = useHistory();
  const classes = useStyles();
  let idusu= localStorage.getItem('UsuId');
  const [espera,setEspera]=React.useState(false);
  const [mensaje, setMensaje] = React.useState('');
  const [tipoAdvertencia, setTipoAdvertencia] = React.useState('success');
  const [aviso, setAviso] = React.useState(false);
  const[nvoMtto,setNvoMtto]=React.useState({Auxiliar:"",Tecnico:"",MttoID:0,cabina:0,llave:0,Pinza:0,Suspension:0})
  const{Auxiliar,Tecnico,MttoID,cabina,llave ,Pinza,Suspension}=nvoMtto                                                                  
  const source = axios.CancelToken.source();      
  const [btnDesact,setBtnDesact]=React.useState(false);
  React.useEffect(()=>{    
    setNvoMtto({...nvoMtto,Auxiliar:personal.auxiliar ,
      MttoID:personal.original.MttoId ,Tecnico :personal.tecnico,     
      cabina:personal.original.CabId,
      llave:personal.original.Id , 
      Pinza:cabinaSelec[0].Pinza ,Suspension:cabinaSelec[0].Suspension 
    })            
     //console.log(personal,cabinaSelec);
     //console.log(arrCabinas);
    return ()=> {
      source.cancel();
    }     
      
  },[]);

  const registrarNVLectura=( )=>{    
    const llenarEditarCabs = () => {      
      let data = qs.stringify({      
        MttoIdCabina:MttoID,  
        Cabina:cabina,
        Llave:llave,
        Tecnico:Tecnico,
        Auxiliar:Auxiliar,
        idDispositivo:'987gyubjhkkjh'
      });
      let url = "/cabina-mtto-det-personal-edit";
      function respuestaEditCab(auxiliar) {
        if (auxiliar.respuesta==1) 
        {  //console.log(auxiliar);
          let mensaje="Se edito correctamente el personal"
          setTipoAdvertencia("success")      
          setMensaje(mensaje);
          setAviso(true)                 
          //actualizar2()//llamada de funcionamiento   
          setBtnDesact(true)
          setModalAbierto(false)
          let auxEditPer={...personal,auxiliar:Auxiliar ,
            tecnico:Tecnico }
          toast.success(mensaje, {
            toastId: `edit-personal-pil${llave}`,
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setNomPersonal(auxEditPer)
        }       
      }  
      llamaApiCargaToken(data, url,respuestaEditCab,ErrorGeneral,setEspera,history,source);
    }

    llenarEditarCabs()   
  }

  const regisPlan=( )=>{    
    const llenarEditarPlan = () => {      
      let data = qs.stringify({              
        Usuario:idusu,
        Llave:llave,
        Pinza:Pinza,
        Suspension:Suspension,
        idDispositivo:'987gyubjhkkjh'
      });
      let url = "/cabina-mtto-det-plan-idmtto-editar";
      function respuestaEditCab(auxiliar) {
        if (auxiliar.respuesta==1) 
        { //console.log(auxiliar);
          let mensaje="Se edito el plan de la cabina"          
          //actualizar2()//llamada de funcionamiento   
          setBtnDesact(true)
          setModalAbierto(false)          
          toast.success(mensaje, {
            toastId: `edit-plan-cab${llave}`,
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });          
          let auxEditPlan={...cabinaSelec[0],Pinza:Pinza ,
            Suspension:Suspension }
          let nvoArr=[]
          arrCabinas.forEach(element => {
            if (parseInt(element.Id)!==llave) 
            { nvoArr.push(element)
            }
            else
            {
              nvoArr.push(auxEditPlan)
            }
          });
         // console.log(nvoArr);
          setAuxCabSelecc(cabinaSelec[0].cabinaId)
          setArrCabinas(nvoArr)
        }       
      }  
      llamaApiCargaToken(data, url,respuestaEditCab,ErrorGeneral,setEspera,history,source);
    }

    llenarEditarPlan()   
  }  

  const onChange = e =>
  { let expNopermitida = new RegExp('[#_:$!%-({})/*=?|]');
    let expMenos = new RegExp('-');
    let expMas = new RegExp('[+]');     
    let expCadena = new RegExp('[A-Za-z0-9]');
    if ( ( expCadena.test(e.target.value)&&!expNopermitida.test(e.target.value)&&!expMenos.test(e.target.value)&&
        !expMas.test(e.target.value) )||e.target.value===' '||e.target.value==='') 
    { setNvoMtto({
        ...nvoMtto, [e.target.name] : e.target.value })
      if (aviso) 
      { setMensaje('');
        setAviso(false);
      }
    }
  };

  const mostrarAlertas =(auxMensaje)=>{
    setTipoAdvertencia("warning")
    let mensaje=auxMensaje            
    setMensaje(mensaje);
    setAviso(true)   
  }

  function valNulos(auxMensaje,auxCampo) 
  { let valida=false    
    if (auxCampo !== '' && auxCampo !== '0'&& auxCampo !==0 && auxCampo !== null && auxCampo!==" " ) 
    {      
      valida=true              
    }
    if (!valida) 
    { 
      mostrarAlertas(auxMensaje)
    }
    return valida
  }
  
  const guardarDatos = () => 
  {
    
    if ( valNulos("Debe escribir Técnico",Tecnico) && valNulos("Debe escribir un Auxiliar",Auxiliar)) 
    {
      if (Tecnico!==personal.auxiliar || Auxiliar !== personal.tecnico ) 
      {
        registrarNVLectura()
      }                                                                                                                                                                         
      if (Pinza!==cabinaSelec[0].Pinza || Suspension !== cabinaSelec[0].Suspension ) 
      {
        regisPlan( )
      }
    }       
    
  };
  
  const cerrarBorrar = () => {        
    setAviso(false);
    setModalAbierto(false)
  };

  const onChangeRadio = (e) => {    
    setNvoMtto({ ...nvoMtto, [e.target.name]: parseInt(e.target.value) });        
  };

  return ( 
    <Modal
      titulo={titulo} modalAbierto={modalAbierto}
      setModalAbierto={setModalAbierto} guardarDatos={guardarDatos}
      mensaje={mensaje} tipoAdvertencia={tipoAdvertencia}
      aviso={aviso} setAviso={setAviso} espera={espera} btnDesact={btnDesact}
      setEspera={setEspera} tamanio={"sm"} cerrarBorrar={cerrarBorrar}
    >
    <form  noValidate autoComplete="off" fullWidth>       
      <Box  display="flex" flexDirection="column" className={classes.root}>                        
        <TextField id="outlinedTecnico"           
          label="Técnico"  size="small" value={Tecnico} 
          onChange={onChange} name="Tecnico" style={{width:31+'rem'}}                                               
        />    
        <TextField id="outlinedTecnico"           
          label="Auxiliar"  size="small" value={Auxiliar} 
          onChange={onChange} name="Auxiliar" style={{width:31+'rem'}}                                               
        />                
      </Box>
      <Box display="flex">
        <Box row>
        <Typography component="p" variant="body" style={{marginLeft:1+'rem'}}>
          Pinza
        </Typography>
        <FormControl component="fieldset" size="small">
          <RadioGroup
            row aria-label="position"
            size="small" name={"Pinza"}
            id={"pinza-radio1"} value={Pinza}
            onChange={onChangeRadio}
          >
            <FormControlLabel
              value={1} label={"OK"}
              style={{ color: "#0ac15d" }}            
              control={<Radio style={{ color: "#0ac15d" }} size="small" />}
              labelPlacement="start" size="small"
            />
            <FormControlLabel
              value={0} label={"Pendiente"}
              style={{ color: "#FF6439" }}            
              control={<Radio style={{ color: "#FF6439" }} size="small" />}
              labelPlacement="start" size="small"
            />
          </RadioGroup>
        </FormControl>
        </Box>
        <Box row ml={3}>
        <Typography component="p" variant="body" style={{marginLeft:1+'rem'}}>
          Suspensión
        </Typography>
        <FormControl component="fieldset" size="small">
          <RadioGroup
            row aria-label="position"
            size="small" name={"Suspension"}
            id={"Suspension-radio1"} value={Suspension}
            onChange={onChangeRadio}
          >
            <FormControlLabel
              value={1} label={"OK"}
              style={{ color: "#0ac15d" }}            
              control={<Radio style={{ color: "#0ac15d" }} size="small" />}
              labelPlacement="start" size="small"
            />
            <FormControlLabel
              value={0} label={"Pendiente"}
              style={{ color: "#FF6439" }}            
              control={<Radio style={{ color: "#FF6439" }} size="small" />}
              labelPlacement="start" size="small"
            />
          </RadioGroup>
        </FormControl>
        </Box>
      </Box>
    </form>
    </Modal>
  );
}
 
export default EditarPersonal;