import React,{useState,useContext,useEffect} from 'react';
import {Button,CssBaseline,TextField,Typography
  ,Container,CircularProgress  ,Box,CardMedia} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {AuthContext} from '../context/AuthContext'
import {GeneralesContext} from '../context/GeneralesContext'
import { useHistory } from "react-router-dom";
import axios from 'axios';
import Alert from '@material-ui/lab/Alert'
import {llamadaApiCargaNTk} from "../funciones/ClienteAxios";
import {toast} from "react-toastify";
import qs from 'qs';
import { findNumberCad } from '../funciones/FindNumber';
import { v4 as uuidv4 } from 'uuid';
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),    
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const Login = () => {
  let history = useHistory();
  const classes = useStyles();
  const [aviso, setAviso] = useState(false);
  const [mensaje, setMensaje] = useState('');
  const {guardarUsuario}= useContext(AuthContext);
  const {guardarGenerales}= useContext(GeneralesContext);  
  const [espera,setEspera]= useState(false);
  const [usuario,setUsuario]= useState( { nombre:'',contrasenia:'' })
  const {nombre,contrasenia}=usuario    
  
  useEffect(()=>{ 
    const veriSesion = async () => {
      const usu= localStorage.getItem('UsuarioNom');
      const nvl= localStorage.getItem('Tipo');
      const auxTerminal= localStorage.getItem('UsuTerminal');
      const auxUserOperacion= localStorage.getItem('UserOperacion');
      if(usu!=null)
      {
        guardarUsuario({Nombre:usu,nivel:nvl});   
        guardarGenerales({Titulo:'Paros y/o Fallas',Terminal:auxTerminal,userOperacion:auxUserOperacion})     
        history.push("/paros-fallas")							
      }
      else
      {
        guardarUsuario([]);   
        guardarGenerales([])     
      }
    }
    veriSesion();			
    // eslint-disable-next-line react-hooks/exhaustive-deps	
  },[]);

  function llamada() {
    async function autenticar(){                   
    
    let data = qs.stringify({                
      user:nombre,
      pass:contrasenia,
      idDispositivo:"987ygubhjnjik" 
    });
    let url = '/autenticar-movil';
    function ErrorGeneral(auxerror) 
    { if(auxerror && auxerror.response && auxerror.response.status === 500)
      { console.log(auxerror.response.data.mensaje);          
      }  
    }      
    function response(aux) 
    {  
      if (aux) 
      { // console.log(aux)
        let nvl = ""+aux.userTipo
        const auxCode=uuidv4()       
        const auxRecorte=auxCode.slice(auxCode.lastIndexOf("-")+1,auxCode.length)   
        localStorage.setItem('UsuarioNom',aux.userNombre);
        localStorage.setItem('UserOperacion', aux.userOperacion);
        localStorage.setItem('UsuId',aux.userId);              
        localStorage.setItem('Tipo',nvl);
        localStorage.setItem('token20',aux.userToken);  
        localStorage.setItem('UsuTerminal',aux.userTerminal);         
        localStorage.setItem('UsuApertura',0);    
        localStorage.setItem('Dispvo',auxRecorte);           
        guardarGenerales({Titulo:'Paros y/ó Fallas',Terminal:aux.userTerminal,userOperacion:aux.userOperacion})   
        guardarUsuario({Nombre:aux.userNombre,nivel:nvl,usrId:aux.userId});  
        if (findNumberCad('|11|',aux.userOperacion)) 
        {
          history.push("/paros-fallas")		    
        }
        else
        {
          history.push("/vacio")		  
        }
        
      }
      else
      {
        setAviso(true);
        setMensaje("Usuario o contraseña incorrecta")
      }          
    }
    llamadaApiCargaNTk(data, url, response,ErrorGeneral,setEspera,history) 
    }  
    autenticar();    
  }

  const handleCloseSN = () => {
    setMensaje('');
    setAviso(false);
  };
    
  const onSubmit = e =>
  { e.preventDefault();    
    if (nombre.length>=4&&contrasenia.length>=4)
    { llamada();     
      const usu= localStorage.getItem('UsuarioNom');
      if(usu)
      { history.push("/ParosFallas")		  
      }        
    }
    else
    { setAviso(true);
      setMensaje("Debe llenar  Usuario o Contraseña correctamente")
    }                    
  }

  const onChange = e =>
  {    
    let expNopermitida = new RegExp('[.%{}*|`]');    
    let expMenos = new RegExp("'");
    let expMas = new RegExp('"');         
    let expCadena = new RegExp('[A-Za-z0-9$@#_&-+()/:;!?,<>=]');      
    if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) && !expMenos.test(e.target.value) 
      && !expMas.test(e.target.value) ) || e.target.value===' '|| e.target.value==='') 
    {
      setUsuario({ ...usuario, 
        [e.target.name] : e.target.value  
      })
      if (aviso) 
      { setMensaje('');
        setAviso(false);
      }
    }      
  };
   
  return(
  <Container component="main" maxWidth="xs">
    <CssBaseline />
    <div className={classes.paper}>
      <Box pt={0}>
        <CardMedia               
          style={{marginBottom:2+"rem",width:400+'px',height:"100 px"}}
          component="img" alt="Logo mexicable" title="Logo mexicable" 
          image="/mexicable_logo.svg"            
        />            
      </Box>
      <Typography component="h1" variant="h5">
        Iniciar Sesión
      </Typography>
      { aviso && mensaje.length !== 0 ? 
        <Alert severity="warning" onClose={handleCloseSN}>{mensaje}</Alert>
      :null}
      <form className={classes.form} onSubmit={onSubmit} noValidate >
        <TextField
          variant="outlined"  margin="normal" required fullWidth 
          id="nombre" label="Usuario" name="nombre" type="text" 
          value={nombre} onChange={onChange} autoFocus
        />
        <TextField
          variant="outlined" margin="normal" required fullWidth 
          name="contrasenia" label="Contraseña" type="password"
          id="contrasenia" value={contrasenia} onChange={onChange}
        />          
        <Box align="center">
        { !espera ?
          <Button
            type="submit" fullWidth variant="contained" 
            color="primary" className={classes.submit}
          >
            Iniciar Sesion
          </Button>
          : <Box mt={2}> <CircularProgress /> </Box>
        }
        </Box>                       
      </form>
    </div>        
  </Container>
  );
}

export default Login
