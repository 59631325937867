import React, { useState,useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {  TableRow,Checkbox,Box   ,//Paper, Table, TableBody, TableCell, 
        //TableContainer, TableHead,
         } from '@material-ui/core';
import {celda} from '../Generales/TablaGeneral';
import {useHistory} from "react-router-dom";
import {ErrorGeneral, llamaApiCargaToken, } from "../funciones/ClienteAxios";
import {toast} from "react-toastify";
import qs from 'qs';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: window.innerHeight - 170,
        minHeight: 280,
        width: '15rem',
     
    },
    container2: {
        maxHeight: 550,
    },
    tableRow: {
        "&$selected, &$selected:hover": {
            backgroundColor: "#F46239"
        }
    },
    tableCell: {
        "$selected &": {
            color: "white"
        }
    },
    hover: {},
    selected: {}
});

export default function CheckPermisos({registro ,usrId,llamaApi}) {
    const classes = useStyles();
    let history = useHistory();
    let idusu = localStorage.getItem("UsuId");
    const [selectedID, setSelectedID] = useState(null);
    const [operacion, setOperacion] = useState({Opera:0,status:false}); 
    const [espera,setEspera]=useState(false);
    const {Opera,status}=operacion;    
    const source = axios.CancelToken.source();
    useEffect(() => {
      //  console.log(registro,usrId);        
        setOperacion({Opera:registro.permisoId,
            status: registro.permisoStatus === 1 ? true : false})          
    }, [registro])
    
    const seleccionado = (auxId, auxOrden) => {
        setSelectedID(auxId)       
    }

    const editar = (cambio) => {

        const editPermiso = () => {
          
            let data = qs.stringify({            
                usuario: idusu,    
                llave:usrId,  
                permisos:JSON.stringify([{id:Opera,valor:cambio?1:0}] ),   
                idDispositivo: 'rjvikcmkcmsdkdcdsmc'
            });

            let url = '/usuario-permiso-editar';

            function response(auxiliar) {
                let mensaje =""
                if (auxiliar.respuesta == 1) 
                {   mensaje = `Se modifico permiso ${registro.Nom}`    
                    llamaApi(usrId)
                }                                
                toast.success(mensaje, {
                    toastId: `edit-user-per${Opera}`,
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }

            
            llamaApiCargaToken( data ,url, response, ErrorGeneral, setEspera, history, source);
        }

        editPermiso()
    }
    const handleChange = (event) => {        
        event.preventDefault();    
        setOperacion({...operacion,status :event.target.checked});
        editar(event.target.checked)
      };

    function Row(props) {
        const {row} = props;
        //console.log(row);
        return (        
        <TableRow //onClick={() => { seleccionado(row.Id, row) }}
            className={classes.tableRow} selected={selectedID === row.Id}
            classes={{hover: classes.hover, selected: classes.selected}}
        >  
            {celda("center",{},
            
                espera ?
                    <Box style={{padding:.2+'rem'}}>
                        <CircularProgress size={28}/> 
                    </Box>
                :   <Checkbox disabled={row.Id <1 || parseInt(usrId)===1 }
                        checked={status}  onChange={handleChange}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />                                                  
                ,
                1, classes.tableCell
            )}
            {celda("left", { }, `${row.Nom} `, 5, classes.tableCell)}                    
        </TableRow>           
      
        );
    }


    return (
  
        <Row row={registro}/>
  
    );
}