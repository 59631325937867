import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Box,Collapse,IconButton,Paper,Table,TableBody,TableCell,LinearProgress,
        TableContainer,TableHead,TableRow,} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import axios from 'axios';
import qs from 'qs';
import moment from 'moment';
import { useHistory } from "react-router-dom";
import Editar from './EditOrden'
import EditarOrdenDummy from './EditarOrdenDummy'
import CreateIcon from '@material-ui/icons/Create';
import {ErrorGeneral, llamaApiCargaToken,llamadaApiToken} from '../funciones/ClienteAxios';
const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    //maxHeight: 550,
    maxHeight: window.innerHeight-170,
    minHeight: 280,
    minWidth:'100%',
  },
  container2: {
    maxHeight: 550,
    //maxHeight: window.innerHeight-170,
    //minHeight: 280,
  },
  tableRow: {
    "&$selected, &$selected:hover": {
      backgroundColor: "#F46239"
    }
  },
  tableCell: {
    "$selected &": {
      color: "white"
    }
  },
  hover: {},
  selected: {}
});

export default function TablaOrden({ordenSelec,setOrdenSelec,orden,fechas,buscar,turno,usrContxt,Bucle,estatus,ordenMas,setOrdenMas}) {
  const classes = useStyles();
  const history = useHistory();
  const nvlSNPermiso ='5'      
  const source = axios.CancelToken.source();      
  const [open, setOpen] =  useState(false);     
  const [espera,setEspera]=useState(false);    
  const [modalAbierto, setModalAbierto] = useState(false);
  const [datos, guardarDatos] = useState([])  
  const[sinDatos,guardarSinDatos]= useState(true)
  
  React.useEffect(()=>{             
      
    llenaListTabla(1);      
    return ()=> {
      source.cancel();
    }                

  },[buscar]);

  const llenaListTabla = (tipo) => {
    
    const fInicio=moment(fechas.inicio).format("YYYY-MM-DD")
    const fFinal=moment(fechas.final).format("YYYY-MM-DD")  
    const data = qs.stringify({
      terminal: Bucle,
      fechaIni: fInicio + 'T00:00:00',
      fechaFin: fFinal + 'T23:59:59',
      status: estatus,
      idDispositivo: '89y7gttvjhj98h'
    });
    const url = "/mtto-lista-intervalo-filtro";

    function respuestaListTabla(aux) {
      if (aux[0].Id !== -1) 
      { guardarSinDatos(true)
        guardarDatos(aux)
        if (tipo===2) {
          const filtrado =aux.filter((ord)=>ord.Id===ordenSelec.Id)
          if (filtrado.length!==0) {
            setOrdenSelec(filtrado[0])  
          }else{
            setOrdenSelec([])  
          }          
        }
      } 
      else 
      { guardarSinDatos(false)
      }
    }

    if (tipo===1) {
      setOrdenSelec([])
      if (open) 
      {
        setOrdenMas(0)  
        setOpen(false)
      }
      llamaApiCargaToken(data, url, respuestaListTabla, ErrorGeneral, setEspera, history, source);
    }
    else{
      llamadaApiToken(data, url, respuestaListTabla, ErrorGeneral, setEspera, history, source);
    }
    
  };

  const funcionActualiza = () => {
    llenaListTabla(2) 
  }

  const seleccionado=(auxOrden)=>{    
    setOrdenSelec(auxOrden)  
  }

  function editarFall() {   
    setModalAbierto(true)
  }
   
  function verMas(auxId) {    

    if (auxId === ordenMas) 
    { setOpen(!open)
    }
    else
    { setOpen(true)
    }        
    setOrdenMas(auxId)
  }

  function tipoEstado(estado,idSelecc)
  { let auxEtiqueta='', auxColor=''
    switch (parseInt(estado)) {
      case 0:
        auxEtiqueta='Registrado'
        auxColor='#F46239'  
        break;
      case 1:
        auxEtiqueta='Solucionado'
        auxColor='#049b4f'
        break;
      case -1:
        auxEtiqueta='Cancelado'
        auxColor='red'
        break;
      default:
        break;
    }    
    
    return(
    <TableCell align="center" padding={'none'}
      style={{ color: ordenSelec?.Id === idSelecc ? 'white':auxColor,fontWeight:700 ,width:100+'px'}}
    >
      {auxEtiqueta}
    </TableCell> )
  }

function Row(props) {
  const { row } = props;
  
  return (
  <React.Fragment>
    <TableRow onClick={ () => { seleccionado(row); } }
      className={classes.tableRow} selected={ ordenSelec?.Id === row.Id}
      classes={{ hover: classes.hover, selected: classes.selected }}        
    >
      <TableCell align="center" padding={'none'} style={{ width:30+'px'}} >
        <IconButton color="primary"  aria-label="expand row" size="small" onClick={() => verMas(row.Id)}>
          { open && ordenMas=== row.Id ?
            ( ordenSelec?.Id === row.Id ? <KeyboardArrowUpIcon style={{color:'white'}} /> :  <KeyboardArrowUpIcon  />) 
            : (ordenSelec?.Id === row.Id ? <KeyboardArrowDownIcon style={{color:'white'}}  /> : <KeyboardArrowDownIcon  />)
          }            
        </IconButton>
      </TableCell>
      <TableCell align="center" padding={'none'} style={{ width:30+'px'}} >
        <IconButton   aria-label="add circle" size="small" onClick={() => editarFall()} component="span">
          {ordenSelec?.Id === row.Id ? <CreateIcon style={{color:'white'}} />:<CreateIcon color="primary"/>}
        </IconButton>                                                                                                                                                                          
      </TableCell>      
      <TableCell  className={classes.tableCell} align="center" padding={'none'} style={{ fontWeight:700,width:100+'px'}} >
        {row.Id}
      </TableCell>          
      <TableCell  className={classes.tableCell} align="left" padding={'none'} style={{ width:75+'px'}} >
          {moment.utc(row.fecha).local(true).format("DD/MM/YYYY")}
      </TableCell>        
      {tipoEstado(row.Status,row.Id)}     
    </TableRow>
    <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5} >
        <Collapse in={open && ordenMas === row.Id} timeout="auto" unmountOnExit>
          <Box >                
            <Table size="small" aria-label="purchases">
              <TableHead>
                <TableRow color="primary">
                  <TableCell style={{ color:'#F46239'}} padding={'none'} >Ubicación</TableCell>                       
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key={row.Id}>
                  <TableCell align="left" padding={'none'} >
                    {row.ubicDescr }
                  </TableCell>                    
                </TableRow>
              </TableBody>
            </Table>
          </Box>
          
        </Collapse>
      </TableCell>
    </TableRow>
  </React.Fragment>
  );
  }

  function tablaCompleta(auxlista){
    //console.log("entrando sin preguntar");
    return(
    <TableContainer className={classes.container}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>            
            <TableCell align="center" padding='none'></TableCell> 
            <TableCell align="center" padding='none'>{auxlista.length}</TableCell>            
            <TableCell align="center" padding='none'>Mtto </TableCell>            
            <TableCell align="left" padding='none'>Fecha</TableCell>
            <TableCell align="center" padding='none'>Estatus</TableCell>                    
          </TableRow>
        </TableHead>
        <TableBody>
          {auxlista.length!=0 ? (auxlista.map((row) => (
              <Row key={row.Id} row={row} />
          ))):null}
        </TableBody>
      </Table>
    </TableContainer>
   )
  }

  const tablaSinDatos=()=>{
   return(<TableContainer className={classes.container2}>
    <Table stickyHeader aria-label="sticky table">
      <TableHead>
        <TableRow>            
          <TableCell align="center">Mtto </TableCell>            
          <TableCell align="left">Fecha</TableCell>
          <TableCell align="left">Estatus</TableCell>                                           
        </TableRow>
      </TableHead>
      <TableBody>
      <TableRow //className={classes.root}         
          className={classes.tableRow}
        >          
          <TableCell  className={classes.tableCell} align="center">Dia</TableCell>          
          <TableCell  className={classes.tableCell} align="left">Sin</TableCell>             
          <TableCell  className={classes.tableCell} align="left">Reportes</TableCell>   
                   
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
  ) 
  }

  const tablaSinResultados=()=>{
    return(<TableContainer className={classes.container2}>
     <Table stickyHeader aria-label="sticky table">
       <TableHead>
         <TableRow>            
            <TableCell align="center">Mtto </TableCell>            
            <TableCell align="left">Fecha</TableCell>
            <TableCell align="left">Estatus</TableCell>                    
         </TableRow>
       </TableHead>
       <TableBody>
       <TableRow //className={classes.root}         
           className={classes.tableRow}
         >          
           <TableCell  className={classes.tableCell} align="center">Mtto</TableCell>          
           <TableCell  className={classes.tableCell} align="left">Sin</TableCell>             
           <TableCell  className={classes.tableCell} align="left">coincidencias</TableCell>                  
         </TableRow>
       </TableBody>
     </Table>
   </TableContainer>
   ) 
   }

  function filtro()
  {        
    let info=[]      
    if (turno.length!=0) 
    { let auxCadena=turno.trim();       
      info=datos.filter((dat)=>dat.turno.toString().search(auxCadena.toUpperCase() ) !== -1)   
     
    } 
    else 
    {
      if (orden.length!=0) 
      { let auxCadena=orden.trim();
        info=datos.filter((dat)=>dat.Id.toString().search(auxCadena) !== -1)           
        
      }
    }
    //let info=datos.filter((dat)=>dat.Id.toString().search(auxCadena.toUpperCase() ) !== -1)         
    let metodos=null
    if (info.length>0) {
       
      metodos= tablaCompleta(info)
    } else {
      metodos=tablaSinResultados()
    }
    return metodos ;
  }

  const contenido2= turno!=''||  orden!=''  ? filtro() :tablaCompleta(datos) 
  const contenido = sinDatos&&datos.length!=0 ?contenido2:tablaSinDatos()
  const tabla = espera ?  (<Box><LinearProgress/> </Box>) :( contenido )
  const modal =ordenSelec.length!==0 && modalAbierto  ? 
              (  usrContxt?.nivel !=undefined && usrContxt.nivel!== nvlSNPermiso ?
                (<Editar titulo={'Editar Mantenimiento '+ordenSelec.Id} modalAbierto={modalAbierto} 
                  setModalAbierto={setModalAbierto} orden={ordenSelec} funcionActuliza={funcionActualiza}            
                />)
              
              :(<EditarOrdenDummy titulo={'Mantenimiento '+ordenSelec.Id} modalAbierto={modalAbierto} 
                  setModalAbierto={setModalAbierto} eleccionadoEdit={ordenSelec}                 
                />)              
              )        
              : null
  return (
  <Paper className={classes.root}>
    {tabla}
    {modal}
  </Paper>
  );
}