import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {Container,InputLabel,MenuItem,FormControl
        ,Select,Grid,LinearProgress, Box, IconButton} from "@material-ui/core";
//import AddCircleIcon from '@material-ui/icons/AddCircle';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RefreshIcon from '@material-ui/icons/Refresh';
import axios from "axios";
import qs from "qs";
import Registrar from "./NuevoConsumo2";
import moment from "moment";
import { useHistory } from "react-router-dom";
import {authUser} from "../funciones/AuthUser";
import {AuthContext} from "../context/AuthContext";
import {GeneralesContext} from '../context/GeneralesContext'
import GraficaEnergia from "./GraficaEnergia";
import TablaEnergia from "./TablaEnergia";
const CancelToken = axios.CancelToken;
const source = CancelToken.source();
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  botones: {
    marginTop:theme.spacing(3),
  },
  formStyle: {
    marginBottom: 30,
    justifyContent: "left",
  },
  
  graficainicial:{
    marginLeft: '1em',
  },
  graficasiguiente:{
    marginTop: ".1em",
    marginLeft: '1em',
  },
}));

export default function CatalogoEnerInicio() {
  const classes = useStyles();
  const [terminal, setTerminal] = useState("");
  const [listaTerminales, setListaTerminales] = useState([]);
  const [listaEnergia, setListaEnergia] = useState([]);
  const [listaEnergiaMostrar, setListaEnergiaMostrar] = useState([]);
  const [modalRegistroAbierto, setModalRegistroAbierto] = useState(false);
  const [modalTitulo, setModalTitulo] = useState("");
  const [listaTerminalesModal, setListaTerminalesModal] = useState([]);
  const [datosTabla, actualizarDatosTabla] = useState(() => {});
  const {usuario,guardarUsuario}=React.useContext(AuthContext);
  const {guardarGenerales}=React.useContext(GeneralesContext);  
  let nvlSNPermiso ='5';
  const modRegistro = modalRegistroAbierto ? ( usuario?.nivel !==undefined && usuario.nivel!== nvlSNPermiso ? <Registrar modalAbierto={modalRegistroAbierto}  setModalAbierto={setModalRegistroAbierto} 
                                                titulo={modalTitulo} terminales={listaTerminalesModal} actualizarDatosTabla={verificarDatos}/>
                                    : null):null;

  const historial = useHistory();
  const [listaDiasTerminal, setlistaDiasTerminal] = useState([]);  
  const [listaPreciosTerminal, setlistaPreciosTerminal] = useState([]);
  const [listaDiasSinFiltro, setlistaDiasSinFiltro] = useState([]);  
  const [listaPreciosSinFiltro, setlistaPreciosSinFiltro] = useState([]); 
  const [filtradoGraficas, setFiltradoGraficas] = useState(false); 
  const [labelGrafica, setLabelGrafica] = useState("");
  const [labelGraficas, setLabelGraficas] = useState("");
  const [listaMedidores, setlistaMedidores] = useState([]);
  const [listaMedidoresSinFiltro, setlistaMedidoresSinFiltro] = useState([]);
  const [alturaGrafica, setAlturaGrafica] = useState(window.innerWidth>1300 ? (window.innerWidth>=1490 && window.innerWidth<1700 ? window.innerWidth-930 : window.innerWidth-1000) : window.innerWidth/2)
  useEffect(() => {
      async function getTerminales() {
        let auxU = process.env.REACT_APP_LINK + `/energia-terminal`;
        let token = localStorage.getItem("token20");
        let idusu = localStorage.getItem("UsuId");
        let dataInfo = qs.stringify({
          usuario: idusu,
          idDispositivo: "9CJIDSKCSDC",
        });
        let config = {
          url: auxU,
          method: "POST",
          data: dataInfo,
          headers: {
            "access-token": token,
            "Content-Type": "application/x-www-form-urlencoded",
          },
          cancelToken: source.token,
        };
        let aux = {};
        await axios(config)
          .then(async (response) => {
            aux = response.data;
            if (aux[0].Id>-1) {
              let aux1 = [];
              aux1.push({
                Id: aux[0].Id,
                Descr: "Todos los Registros",
              });
              for (var i = 1; i < aux.length; i++) {
                aux1.push(aux[i]);
              }
              setListaTerminales(aux1);
              var auxterminal = aux1.filter(function (val) {
                return val.Id !== 0;
              });
              auxterminal.splice(0,0,{
                Id: 0,
                Descr: "Seleccione una Opción",
              });
              setListaTerminalesModal(auxterminal);
              setTerminal(0);
              
            }
          })
          .catch(function (error) {
            console.log(`Error: ${error}`);
            if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
            {
              console.log('datos incorrectos' );  
            }else
            {
              if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
                  ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
              {
                console.log("Token inválido");
                localStorage.clear();                      
                historial.push("/");                              
              } 
            }
          });
      }
  
      async function getEnergia() {
        let auxU = process.env.REACT_APP_LINK + `/energia-list_1_1`;
        let token = localStorage.getItem("token20");
        setListaEnergia([]);
        let dataInfo = qs.stringify({
          idDispositivo: "987yugbhcdsscs",
        });
        let config = {
          url: auxU,
          method: "POST",
          data: dataInfo,
          headers: {
            "access-token": token,
            "Content-Type": "application/x-www-form-urlencoded",
          },
          cancelToken: source.token,
        };
        let aux = {};
        await axios(config)
          .then(async (response) => {
            aux = response.data;
            if (aux[0].Id>-1) {
              setListaEnergia(aux);
              setListaEnergiaMostrar(aux);
            }
          })
          .catch(function (error) {
            console.log(`Error: ${error}`);
            if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
            {
              console.log('datos incorrectos' );  
            }else
            {
              if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
                  ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
              {
                console.log("Token inválido");
                localStorage.clear();                      
                historial.push("/");                              
              } 
            }
          });
      }

      const autenticado = authUser();
          if(!autenticado){
            localStorage.clear();
            historial.push('/');
            guardarUsuario([]);
            guardarGenerales([])     
          }else{
            const usu= localStorage.getItem('UsuarioNom');
            const nvl= localStorage.getItem('Tipo');
            guardarUsuario({Nombre: usu, nivel: nvl});
            guardarGenerales({Titulo:'Catalogo Energia'}) 
            getTerminales();
            getEnergia();
            actualizarDatosTabla(()=>getEnergia);
            generarDatosTodasGraficas();  
          }
          console.log(window.innerWidth)
          window.addEventListener('resize', function() {
            if(window.innerWidth>1300){
              if(window.innerWidth>=1490 && window.innerWidth<1700){
                setAlturaGrafica(window.innerWidth-930)
              }else{
                setAlturaGrafica(window.innerWidth-1000)
              }
              
            }else{
              setAlturaGrafica(window.innerWidth/2)
            }
            /*
            if(window.innerHeight<570){
              setDimensionGrafica({...dimensionGrafica, customHeight: (window.innerHeight/2)+50})
            }*/
          });
  }, []);

  const handleChange = (event) => {
    setTerminal(event.target.value);
    if (listaEnergia.length > 0) {
      if (event.target.value === 0) {
        setFiltradoGraficas(false);
        setListaEnergiaMostrar(listaEnergia);
        setlistaPreciosTerminal([]);
        setlistaDiasTerminal([]);
      }
      if (event.target.value > 0) {
        setFiltradoGraficas(true);
        var aux = listaEnergia.filter(function (val) {
          return val.TerminalId === event.target.value;
        });
        const arrayDiasTemp = [];
            const arrayPreciosTemp = [];
        const labelsMedidores = []
            aux.map(function (registro) {
                arrayDiasTemp.push(moment.utc(registro.Fecha).local(true).format("DD/MM/YYYY"));
                let auxLabelMedidor = registro.NumMedidor ? `Medidor: ${registro.NumMedidor}` : "Medidor: N/A";
                labelsMedidores.push(auxLabelMedidor);
                arrayPreciosTemp.push(registro.Consumo / registro.PrecioPromedio);// se modifico a la division y no la multiplicacion
            });
        var aux1 = listaTerminales.find(function (val) {
              return val.Id === event.target.value;
        });
        setLabelGrafica(aux1.Descr);   
        setlistaPreciosTerminal(arrayPreciosTemp);
        setlistaDiasTerminal(arrayDiasTemp);
        setlistaMedidores(labelsMedidores)
        setListaEnergiaMostrar(aux);
      }
    }
  };

  function generarDatosTodasGraficas(){
    setlistaPreciosSinFiltro([]);
    setlistaDiasSinFiltro([]);
    var mayor = Math.max.apply(Math, listaTerminales.map(function(elem) { return elem.Id; }))
    var indice = 1;
    var tempDiasTotales = [];
    var tempPreciosTotales = [];
    var tempDiasPorTerminal = [];
    var tempPreciosPorTerminal = [];
    var tempMedidores = [];
    var labelGraficas = [];
    var labelsMedidores = [];
    while(indice<=mayor){
      tempDiasPorTerminal=[];
      tempPreciosPorTerminal=[];
      var auxEnergiaTerminal = listaEnergia.filter(function (val) {
        return val.TerminalId === indice;
      });
      auxEnergiaTerminal.map(function (registro) {
        tempDiasPorTerminal.push(moment.utc(registro.Fecha).local(true).format("DD/MM/YYYY"));
        tempPreciosPorTerminal.push(registro.Consumo / registro.PrecioPromedio);// se modifico a la division y no la multiplicacion
        let auxLabelMedidor = registro.NumMedidor ? `Medidor: ${registro.NumMedidor}` : "Medidor: N/A";
        labelsMedidores.push(auxLabelMedidor);
      });;
      var auxNombreTerminal = listaTerminales.find(function (val) {
        return val.Id === indice;
      });
      tempDiasTotales.push(tempDiasPorTerminal);
      tempPreciosTotales.push(tempPreciosPorTerminal);
      labelGraficas.push(auxNombreTerminal.Descr);
      tempMedidores.push(labelsMedidores)
      indice++;
    }
    setlistaDiasSinFiltro(tempDiasTotales);
    setlistaPreciosSinFiltro(tempPreciosTotales);
    setLabelGraficas(labelGraficas);
    setlistaMedidoresSinFiltro(tempMedidores);
  }

  function verificarDatos(){
     setListaEnergiaMostrar([]);
     setlistaPreciosTerminal([]);
     setlistaDiasTerminal([]);
     setlistaDiasSinFiltro([]);
     setlistaMedidoresSinFiltro([]);
     setlistaMedidores([])
     setlistaPreciosSinFiltro([]);
     setTerminal(0);
     setFiltradoGraficas(false);
     datosTabla();
  }

  function abrirModal() {
    setModalTitulo("Nuevo Registro");
    setModalRegistroAbierto(!modalRegistroAbierto);
  }

  const mostrarGraficas = () =>{
      var graficas=[];
      for (var i = 0; i < labelGraficas.length; i++) {
        graficas
        .push(<div key= {i} className={i!==0 ? classes.graficasiguiente : classes.graficainicial} style={{width:alturaGrafica}}>
                <GraficaEnergia arrayPrecios={listaPreciosSinFiltro[i]} arrayDias={listaDiasSinFiltro[i]} labelGrafica={labelGraficas[i]} arrayMedidores={listaMedidoresSinFiltro[i]} graficaFiltrada={filtradoGraficas}/> 
              </div>);
      }
      return graficas;
  }


  const grafica = () =>{
    return <div className={classes.graficainicial} style={{width:alturaGrafica}}>
           <GraficaEnergia arrayPrecios={listaPreciosTerminal} arrayDias={listaDiasTerminal} labelGrafica={labelGrafica} arrayMedidores={listaMedidores} graficaFiltrada={filtradoGraficas}/>
           </div>;
  }

  const visualizartabla = listaEnergiaMostrar.length > 0 ? <TablaEnergia listaEnergia={listaEnergiaMostrar} listaTerminales={listaTerminalesModal} actualizarDatos={datosTabla} usuarioContext={usuario}/>
                                                     : <Box sx={{ width: '100%' }}><LinearProgress /></Box>;
  const visualizarGraficaFiltro = listaPreciosTerminal.length>0 && listaDiasTerminal.length>0 ? grafica() : null;
  const visualizarGraficaSinFiltro = labelGraficas.length>0 && listaDiasSinFiltro.length>0 && listaPreciosSinFiltro.length>0 ? mostrarGraficas() : null;
  const visualizargrafica = filtradoGraficas ? visualizarGraficaFiltro : visualizarGraficaSinFiltro; 
  
  return (
    <>
      <Grid container  style={{paddingLeft:1+'rem'}}>
        <Grid item  sx={{ width: '55%' }}>            
          <div className={classes.formStyle}>
            <FormControl className={classes.formControl}>
              <InputLabel id="inputTerminal">
                Terminal
              </InputLabel>
              <Select
                labelId="inputTerminal"   id="selectTerminal"
                value={terminal}          onChange={handleChange}
              >
                {listaTerminales.map((terminal, index) => (
                  <MenuItem value={terminal.Id} key={terminal.Id}>
                    <em>{terminal.Descr}</em>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl  className={classes.botones}>
              <IconButton onClick={verificarDatos} size={'small'}>
              <RefreshIcon  color="primary" style={{ marginLeft: 1+'rem'}} />
              </IconButton>
            </FormControl>
            {usuario.nivel !==undefined && usuario.nivel!== nvlSNPermiso ? 
                <FormControl  className={classes.botones}>
                  <IconButton onClick={abrirModal} size={'small'} >
                    <AddCircleOutlineIcon color="primary" style={{  marginLeft: 1+'rem'}}  />
                  </IconButton>
                </FormControl>
            : null
            }
          </div>
            {visualizartabla}
            </Grid>

            <Grid item  sx={{ width: '45%' , marginTop: 20}}>
              {listaTerminales.length>0 && listaEnergia.length>0 ? (visualizargrafica) : null}
              {(listaTerminales.length>0 && listaEnergia.length>0) && (listaDiasSinFiltro.length===0 && 
                listaPreciosSinFiltro.length===0 && !filtradoGraficas)? generarDatosTodasGraficas(): null}
              </Grid>
          </Grid>
      {modRegistro}
      
    </>
  );
}