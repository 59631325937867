import React, { useState } from 'react';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import esLocale from "date-fns/locale/es";
import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { Box,TextField    ,Tooltip
          } from '@material-ui/core'
const Relojes2 = ({fechasSeleccionadas, setFechasSeleccionadas}) => {
  const [selectedDate, handleDateChange] = useState(new Date());
  
  const {inicio,final}=fechasSeleccionadas
  

  const guardarInicio = auxfecha =>
  {
    setFechasSeleccionadas({
      ...fechasSeleccionadas,
      inicio : auxfecha  
    })
  };
  const guardarFinal = auxfecha =>
  {
    setFechasSeleccionadas({
      ...fechasSeleccionadas,
      final : auxfecha  
    })
  };
    
    return (
    <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>    
    <Box component="span" pb={1}>
    <Tooltip title="Seleccionar Fecha Inicial">
      <Box  ml={1}  component="span" width={1/4}>           
        <DatePicker
          disableFuture
          //openTo="month"
          format="dd/MM/yyyy"
          views={["year", "month", "date"]}
          label="Fecha inicial"
          size="small"
          minDate="2017-03-14"
          maxDate={new Date()}
          value={inicio}
          onChange={guardarInicio}     
          style={{width:7+'rem'}}       
        />
                      
      </Box>    
      </Tooltip>                             
      <Tooltip title="Seleccionar Fecha Final">
      <Box ml={1}  component="span" width="25%">      
        <DatePicker
          disableFuture
          id="final"
         // openTo="year"
          format="dd/MM/yyyy"
          label="Fecha Final"
          views={["year", "month", "date"]}
          minDate={inicio}
          maxDate={new Date()}
          value={final}
          name="final"
          size="small"
          onChange={guardarFinal}
          style={{width:7+'rem'}}       
        />        
      </Box> 
      </Tooltip>
  </Box>                         
    </MuiPickersUtilsProvider>
    );
}

export default Relojes2
