import {useState, useEffect} from 'react';
import axios from 'axios';
import qs from 'qs';
import {useHistory} from "react-router-dom";
import {Typography, Box} from '@material-ui/core';
import ModalDummy from '../Generales/ModalDummy';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CardDetallePilona from './CardDetallePilona'
import {llamadaApiToken, llamaApiCargaToken, ErrorGeneral} from '../funciones/ClienteAxios';

const EditarDetallePilona = ({
                              modalAbierto,
                              setModalAbierto,
                              titulo,
                              mttoActividad,
                              pilona,                              
                              setAvTotal,
                              setAuxCabSelecc,
                              setEsperaTabla,
                              nomPersonal,
                              setUpdate,
                              update,setAuxPilDet
                          }) => {

    let history = useHistory();
    const [espera, setEspera] = useState(false);
    const [mensaje, setMensaje] = useState('');
    const [tipoAdvertencia, setTipoAdvertencia] = useState('success');
    const [aviso, setAviso] = useState(false);
    const [listCambios, setListCambios] = useState(false);
    const [hidden, setHidden] = useState(true);
    const [actualiza, setActualiza] = useState(false);
    const source = axios.CancelToken.source();
    let idusu = localStorage.getItem('UsuId');
    useEffect(() => {
        setAuxPilDet(0)    
    }, [])
    
    const llamadaMtto = () => {
       
       //console.log("prb");
    }

    const editActividad = (actividad, actStd, setAvailable, setCarga) => {
        const llamaEditAct = () => {
            let data = qs.stringify({
                MttoId:pilona.MttoId,
                MttoDetId:actividad.MttoDetId,
                MttoDetPlanId:actividad.TpPlanId,        
                Status: actStd,
                Usuario: idusu,                
                LadoA:actividad.Verifica,
                LadoB:actividad.Cantidad,                                
                idDispositivo: '89y7gttvjhj98h'
            });
            let url = "/pilona-mtto-det-plan-editar";
            setEspera(true)

            function respuesta(aux) {
                if (aux.respuesta == 1) {
                    let mensaje = `Se edito correctamente el plan "${actividad.planDescr}" `
                    toast.success(mensaje, {
                        pauseOnFocusLoss: false,
                        toastId: `nvo-edicion${actividad.TpPlanId}`
                    })
                    setListCambios(true)
                    setTipoAdvertencia("success")
                    setMensaje(mensaje);
                    setAvailable(false)
                    setEspera(false)
                }
            }

            llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCarga, history, source);
        };

        llamaEditAct();
    }

    const mostrarAlertas = (auxMensaje) => {
        setTipoAdvertencia("warning")
        let mensaje = auxMensaje
        setMensaje(mensaje);
        setAviso(true)
    }

    function valNulos(auxMensaje, auxCampo) {
        let valida = false
        if (auxCampo !== '' && auxCampo !== '0' && auxCampo !== 0 && auxCampo !== null && auxCampo !== " ") {
            valida = true
        }
        if (!valida) {
            mostrarAlertas(auxMensaje)
        }
        return valida
    }


    const guardarDatos = (actividad, actStd, setAvailable, setCarga) => {
        if (valNulos("Seleccionar Estatus", true)) {
          //  editActividad(actividad, actStd, setAvailable, setCarga)
        }
    };


    const cerrarModal = (aux) => {
        setEspera(true)
        setAuxCabSelecc(mttoActividad[0].MttoDetId)
        //console.log(mttoActividad[0].MttoDetId);           
        if (actualiza) {
            setUpdate(!update)  
            setAuxPilDet(pilona.MttoId)        
        }
        setModalAbierto(aux) 
    }


    const list2 = () => {
        //console.log(pilona);
        console.log(mttoActividad);
        return (
        mttoActividad.map((element, index) => {
            return( 
            <CardDetallePilona element={element} pilona={pilona}
                index={index} setAviso={setAviso} setMensaje={setMensaje}
                setUpdate={setUpdate}   update={update}
                setEsperaModal={setEspera} setActualiza={setActualiza}
            />)
        }))
    }

    const auxSubtitulo = (titulo, campo) => {
        return (<Box display={"flex"} alignItems="center" justifyContent="center" ml={1}>
            <Typography color="textPrimary"
                style={{
                    fontWeight: 600, fontSize: 16, marginBottom: 0,
                    paddingBottom: 0, marginRight: .5 + 'rem'
                }}
            >
                {titulo}
            </Typography>
            <Typography color="textPrimary"
                style={{fontSize: 12, marginBottom: 0, paddingBottom: 0}}
            >
                {campo}
            </Typography>
        </Box>)
    }

    const subtitulo = () => {
        return (
            <Box display={"flex"} row justifyContent="center">
                {nomPersonal.tecnico.length !== 0 ? auxSubtitulo("Técnico", nomPersonal.tecnico) : null}
                {nomPersonal.auxiliar.length !== 0 ? auxSubtitulo("Auxiliar", nomPersonal.auxiliar) : null}

            </Box>)
    }

    return (
        <ModalDummy
            titulo={titulo} modalAbierto={modalAbierto}
            setModalAbierto={cerrarModal}
            mensaje={mensaje} tipoAdvertencia={tipoAdvertencia}
            aviso={aviso} setAviso={setAviso} espera={espera}
            tamanio={false} subtitulo={subtitulo}
        >
            {list2()}
        </ModalDummy>)
}
export default EditarDetallePilona;