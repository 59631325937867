import React from 'react';
import {Button,Dialog,DialogActions,DialogContent
        ,DialogTitle,} from '@material-ui/core';
//import { useHistory } from "react-router-dom";
import Alert from '@material-ui/lab/Alert';

export default function ModalDummy({titulo,children,modalAbierto,setModalAbierto,mensaje,tipoAdvertencia,aviso, setAviso,espera,}) {

  //const [aviso, setAviso] = React.useState(false);
  const [scroll] = React.useState('paper');
  //const [mensaje, setMensaje] = React.useState('');
  //const [tipoAdvertencia, setTipoAdvertencia] = React.useState('success');
  
  //let history = useHistory();
  /*const handleClickOpen = (scrollType) => () => {
    setOpen(true);
  
    setScroll(scrollType);
  };
  const abrirAviso = () => () => {
    setAviso(true);
    
  };*/

  const cerrarBorrar = () => {

    setAviso(false);
    setModalAbierto(false)
  };
  const handleClose = () => {
  
    setModalAbierto(false)
  };
  const handleCloseSN = () => {
    setAviso(false);
    //setModalAbierto(false)
  };
  
  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (modalAbierto) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }    
  }, [modalAbierto]);
  
  return (
  <div>         
    <Dialog
      open={modalAbierto}
      onClose={handleClose}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth={true}
      maxWidth="sm"
    >
                
      <DialogTitle style={{color:'#F46239'}} id="scroll-dialog-title" align="center" >{titulo}</DialogTitle>
            
      <DialogContent dividers={scroll === 'paper'} >        
      { aviso&&tipoAdvertencia &&mensaje.length!==0 ? 
          <Alert severity={tipoAdvertencia}  onClose={handleCloseSN}>{mensaje}</Alert>:<span></span>}
      {children}
      </DialogContent>
    
      <DialogActions>
        <Button onClick={cerrarBorrar} disabled={espera} color="secondary" variant="outlined">
          Cerrar
        </Button>
             
      </DialogActions>
      
    </Dialog>    
  </div>
  );
}
