import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Box,Collapse,IconButton,Paper,Table,TableBody,TableCell,LinearProgress,
        TableContainer,TableHead,TableRow,} from '@material-ui/core';
//import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
//import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import axios from 'axios';
import qs from 'qs';
import moment from 'moment';
import { useHistory } from "react-router-dom";

import CreateIcon from '@material-ui/icons/Create';
import {llamaApiCargaToken,ErrorGeneral } from '../funciones/ClienteAxios';
import {findNumber} from "../funciones/FindNumber";
const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    //maxHeight: 550,
    maxHeight: window.innerHeight-170,
    minHeight: 280,
    minWidth:'100%',
  },
  container2: {
    maxHeight: 550,
    //maxHeight: window.innerHeight-170,
    //minHeight: 280,
  },
  tableRow: {
    "&$selected, &$selected:hover": {
      backgroundColor: "#F46239"
    }
  },
  tableCell: {
    "$selected &": {
      color: "white"
    }
  },
  hover: {},
  selected: {}
});

export default function TablaBitacora({setOrdenSeleccion,orden,fSeleccionadas,buscar,setModalEdit,estatus,datos, setDatos,cargaEdit}) {
  const classes = useStyles();
  let history = useHistory();
  const [selectedID, setSelectedID] = useState(null);
  //const [open, setOpen] = React.useState(false);     
  const [espera,setEspera]=useState(false);
  //const [ordenSelecc, setOrdenSelecc] = React.useState(0);  
  //const [datos, setDatos] = React.useState([])    
  let nvlSNPermiso ='5'      
  React.useEffect(()=>{ 
    const source = axios.CancelToken.source();    
    setSelectedID(null)    
    setOrdenSeleccion([])
    let idusu= localStorage.getItem('UsuId');
    let fInicio=moment(fSeleccionadas.inicio).format("YYYY-MM-DD")
    let fFinal=moment(fSeleccionadas.final).format("YYYY-MM-DD")
    const llenaListTabla = () => { 
      setOrdenSeleccion([])     
      let data = qs.stringify({    
        Usuario:idusu,    
        fechaIni:fInicio+'T00:00:00' ,
        fechaFin:fFinal+'T23:59:59',    
        Status: estatus !== 0 ? parseInt(estatus): 0,
        idDispositivo:'89y7gttvjhj98h'
      });
      let url = "/cctv-bitacora-list";
      function respuestaListTabla(aux) {
        if (aux[0].Id !== -1) 
        { console.log(aux);                                                                  
          setDatos(aux)
         // setListCCTV(aux)
        }
        else
        { setDatos([])
          //setListCCTV([])
        }           
      }

      llamaApiCargaToken(data, url,respuestaListTabla,ErrorGeneral,setEspera,history,source);
    }; 
   
    llenaListTabla();      
    return ()=> {
      source.cancel();
    }                

  },[buscar]); 

  const seleccionado=(auxId,auxOrden)=>{
    setSelectedID(auxId)    
    setOrdenSeleccion(auxOrden)  
  }

  function editarFall(auxFalla) {
    //console.log(datos);
    //console.log(auxFalla);
    setOrdenSeleccion(auxFalla)
    setModalEdit(true)
  }
   
  function tipoEstado(estado,idSelecc)
  { let auxEtiqueta=''
    let auxColor=''
    if (estado===0) 
    {
      auxEtiqueta='Registrado'
      auxColor='#F46239'
    }
    else
    {
      if (estado ===1) 
      {
        auxEtiqueta='Activo'
        auxColor='#049b4f'
      }
      else
      {
        if (estado === 2) 
        {
          auxEtiqueta='Cancelado'
          auxColor='red'
        } 
      }
    }
    let renglon=<div></div>
    selectedID === idSelecc?
    renglon=<TableCell align="center" style={{ color:'white',fontWeight:700 ,width:120+'px'}} padding={'none'}>
              {auxEtiqueta}
            </TableCell>:
    renglon=<TableCell align="center" style={{ color:auxColor,fontWeight:700,width:120+'px'}} padding={'none'}>{auxEtiqueta}</TableCell>
    return renglon
  }

  const celda = (alinear, estilo, contenido) => {
    return (
      <TableCell
        align={alinear} className={classes.tableCell}
        padding={"none"} style={estilo} 
      >
        {contenido}
      </TableCell>
    );
  };

function Row(props) {
  const { row } = props;
  
  return (
    <React.Fragment>
      <TableRow onClick={ () => { seleccionado(row.Id,row); } }
        className={classes.tableRow} selected={ selectedID === row.Id}
        classes={{ hover: classes.hover, selected: classes.selected }}        
      >
        {/* <TableCell align="center" padding={'none'} style={{ width:30+'px'}} >
          <IconButton color="primary"  aria-label="expand row" size="small" onClick={() => verMas(row.Id)}>
            { open && ordenSelecc=== row.Id ?( selectedID === row.Id?<KeyboardArrowUpIcon style={{color:'white'}} />:
                                                  <KeyboardArrowUpIcon  />) : 
              selectedID === row.Id ? <KeyboardArrowDownIcon style={{color:'white'}}  />:<KeyboardArrowDownIcon  />}            
          </IconButton>
        </TableCell> */}
        {celda("center",{width:30+'px'},
           findNumber('|35|') ?<IconButton   aria-label="add circle" size="small"  
            component="span" onClick={() => editarFall(row)} >
            {selectedID === row.Id 
              ?   <CreateIcon style={{color:'white'}} />
              :   <CreateIcon color="primary"/>
            }
          </IconButton>:null                                                                                                                                                                  
        )}         
        {celda("center",{fontWeight:700,width:30+'px'},row.Id)}      
        {celda("left",{width:120+'px'},moment.utc(row.FEvento).local(true).format("DD/MM/YYYY HH:mm"))}                           
        {celda("left",{width:250+'px'},row.Titulo)}     
      </TableRow>
      <TableRow>        
      </TableRow>
    </React.Fragment>
    );
  }

  function tablaCompleta(auxlista){
    //console.log("entrando sin preguntar");
    return(
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>            
              <TableCell align="center" padding='none'></TableCell>               
              <TableCell align="center" padding='none'></TableCell>            
              <TableCell align="center" padding='none'>Fecha</TableCell>
              <TableCell align="left" padding='none'>Titulo</TableCell>                    
            </TableRow>
          </TableHead>
          <TableBody>
            {auxlista.length!=0 ? (auxlista.map((row) => (
                <Row key={row.Id} row={row} />
            ))):null}
          </TableBody>
        </Table>
      </TableContainer>
   )
  }

  const tablaSinDatos=()=>{
   return(<TableContainer className={classes.container2}>
    <Table stickyHeader aria-label="sticky table">
      <TableHead>
        <TableRow>            
          <TableCell align="center"> </TableCell>            
          <TableCell align="left">Fecha</TableCell>
          <TableCell align="left">Estatus</TableCell>                                           
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow className={classes.tableRow} >          
          <TableCell  className={classes.tableCell} align="center">Dia</TableCell>          
          <TableCell  className={classes.tableCell} align="left">Sin</TableCell>             
          <TableCell  className={classes.tableCell} align="left">Registros</TableCell>                      
        </TableRow>
      </TableBody>
    </Table>
    </TableContainer>
    ) 
  }

  const tablaSinResultados=()=>{
    return(<TableContainer className={classes.container2}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>            
            <TableCell align="center"> </TableCell>            
            <TableCell align="left">Fecha</TableCell>
            <TableCell align="left">Estatus</TableCell>                    
          </TableRow>
        </TableHead>
        <TableBody>
        <TableRow className={classes.tableRow} >          
          <TableCell  className={classes.tableCell} align="center"></TableCell>          
          <TableCell  className={classes.tableCell} align="left">Sin</TableCell>             
          <TableCell  className={classes.tableCell} align="left">coincidencias</TableCell>                  
         </TableRow>
       </TableBody>
      </Table>
    </TableContainer>
    ) 
   }

  function filtro()
  {        
    let info=[] , metodos=null
    if (orden.length!=0) 
    { let auxCadena=orden.trim();
      info=datos.filter((dat)=>dat.Id.toString().search(auxCadena) !== -1)                 
    }    
    
    if (info.length>0) 
    { metodos= tablaCompleta(info)
    } 
    else 
    { metodos=tablaSinResultados()
    }
    return metodos ;
  }

  const contenido2= orden!==''  ? filtro() :tablaCompleta(datos) 
  const contenido = datos.length!== 0 ?contenido2:tablaSinDatos()
  const tabla = espera|| cargaEdit ?  (<Box><LinearProgress/> </Box>) :( contenido )
 
  return (
    <Paper className={classes.root}>
      {tabla}
    </Paper>
  );
}