import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {InputLabel,FormControl,Select as MaterialSelect  ,Box 
  ,TextareaAutosize ,TextField,RadioGroup,FormControlLabel,Radio
  } from '@material-ui/core';
//import {  KeyboardTimePicker,KeyboardDatePicker} from '@material-ui/pickers';
//import  Select   from 'react-select';

//import CreatableSelect from 'react-select/creatable';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
//import { TimePicker } from "@material-ui/pickers";
import Relojes from './Relojes'
const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '20ch',
      }, 
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
      Textnumero: {
        width: 5+"px",
      },
      formControlFull: {
        //margin: theme.spacing(1),
        //minWidth: 120,
        width:5+"rem"
      },
    },
  }));

const EditFormDummy = ({nuevaFalla, guardarNuevaFalla}) => {
  let history = useHistory();
  const classes = useStyles();
  const filter = createFilterOptions();
  //const [datos, guardarDatos] = React.useState([])
  
  const {Conductor,Terminal,Usuario,Tipo,Ala,Lugar ,Estado,Fecha
    ,Horario,Informacion,SolucionId,Solucion,Tiempo,TipoDescr,Ubicacion,UbicDescr} = nuevaFalla;
    
  const [listParoTipo, guardarListParoTipo] = React.useState([])
  const [listParoAla, guardarListParoAla] = React.useState([])
  const [listParoSolucion, guardarListParoSolucion] = React.useState([])
  const [listLugar, guardarListLugar] = React.useState([])
  const [listLugarTerminal, guardarListLugarTerminal] = React.useState([])
  const [listUbicacion, guardarListUbicacion] = React.useState([])
  const [auxNumTipo,guardarAuxNumTipo]=React.useState('')
  let numUbiOtro=234;
  React.useEffect(()=>{ 
   // console.log(nuevaFalla);
    const source = axios.CancelToken.source();
          
    async function llenarListParo()  {       
      let auxU=process.env.REACT_APP_LINK +`/parofalla-tipo`;         
      let token =localStorage.getItem('token20') ;               
      let dataInfo = qs.stringify({                  
          'idDispositivo':'8978yuhjnfrsdsd'
          });
      let config = {
          url:auxU ,
          method: 'POST',
          headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
          data: dataInfo,
          cancelToken: source.token
      };
      let aux ={};           
      await axios(config)
      .then(function (response) {                 
        aux=  response.data;                                   
        if (aux[0].Id!=-1) 
        {                                                                 
          let auxArre=[];   
          aux.map((tipo) => {
            auxArre.push({ value : tipo.Id, label :tipo.Tipo })
          })                    
          guardarListParoTipo(auxArre);                                        
        }                                            
      })
      .catch(function (error) {                  
        console.log(`Error: ${error} `);        
        if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
        {
          console.log('datos incorrectos' );  
        }else
        {
          if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
              ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
          {
            console.log('loguearse de nuevo');
            localStorage.clear();                      
            history.push("/");                              
          } 
        }
      });
            
        
    };
    async function llenarListParoAla()  {       
      let auxU=process.env.REACT_APP_LINK +`/parofalla-ala`;          
      let token =localStorage.getItem('token20') ;               
      let dataInfo = qs.stringify({                  
          'idDispositivo':'8978yuhjnfrsdsd'
          });
      let config = {
          url:auxU ,
          method: 'POST',
          headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
          data: dataInfo,
          cancelToken: source.token
      };
      let aux ={};           
      await axios(config)
      .then(function (response) {
        
        aux=  response.data;                           
        //console.log(aux);
        if (aux[0].Id!=-1) 
        {                    
          let auxArre=[];   
          aux.map((tipo) => {
            if (tipo.Id!=0) 
            {
              auxArre.push({ value : tipo.Id, label :tipo.Tipo })  
            }            
          })                    
          guardarListParoAla(auxArre);                    
          //guardarListParoAla(aux)
        }                                            
      })
      .catch(function (error) {                  
        console.log(`Error: ${error} `);        
        if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
        {
          console.log('datos incorrectos' );  
        }else
        {
          if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
              ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
          {
            console.log('loguearse de nuevo');
            localStorage.clear();                      
            history.push("/");                              
          } 
        }
      });                
    };
    async function llenarListParoSolucion()  {       
      let auxU=process.env.REACT_APP_LINK +`/parofalla-solucion`;        
      let token =localStorage.getItem('token20') ;                   
      let dataInfo = qs.stringify({                  
          'idDispositivo':'8978yuhjnfrsdsd'
          });
      let config = {
          url:auxU ,
          method: 'POST',
          headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
          data: dataInfo,
          cancelToken: source.token
      };
      let aux ={};           
      await axios(config)
      .then(function (response) {
        // console.log("entrando ");
        aux=  response.data;                           
        if (aux[0].Id!=-1) 
        {
          let auxArre=[];   
          aux.map((tipo) => {
            auxArre.push({ value : tipo.Id, label :tipo.Tipo })
          })                    
          guardarListParoSolucion(auxArre);                                      
        }                                            
      })
      .catch(function (error) {                  
        console.log(`Error: ${error} `);        
        if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
        {
          console.log('datos incorrectos' );  
        }else
        {
          if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
              ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
          {
            console.log('loguearse de nuevo');
            localStorage.clear();                      
            history.push("/");                              
          } 
        }
      });                      
    };
    async function llenarListLugar()  {       
      let auxU=process.env.REACT_APP_LINK +`/lugar-list`;       
      let token =localStorage.getItem('token20') ;               
      let dataInfo = qs.stringify({                  
          'idDispositivo':'8978yuhjnfrsdsd'
          });
      let config = {
          url:auxU ,
          method: 'POST',
          headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
          data: dataInfo,
          cancelToken: source.token
      };
      let aux ={};           
      await axios(config)
      .then(function (response) {
        // console.log("entrando ");
        aux=  response.data;                           
        if (aux[0].Id!=-1) 
        {
          let auxArre=[];   
          aux.map((tipo) => {
            if (tipo.Id!=0) 
            {
              auxArre.push({ value : tipo.Id, label :tipo.Tipo })  
            }            
          })                    
          guardarListLugar(auxArre);                                      
        }                                            
      })
      .catch(function (error) {                  
        console.log(`Error: ${error} `);        
        if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
        {
          console.log('datos incorrectos' );  
        }else
        {
          if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
              ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
          {
            console.log('loguearse de nuevo');
            localStorage.clear();                      
            history.push("/");                              
          } 
        }
      });                      
    };
    async function llenarListTerminal()  {       
      let auxU=process.env.REACT_APP_LINK +`/lugar-terminal-list`;         
      let token =localStorage.getItem('token20') ;         
      let idusu= localStorage.getItem('UsuId'); 
      let dataInfo = qs.stringify({                            
          'usuario':idusu,
          'idDispositivo':'9uccjcsdcsdcsd'
          });
      let config = {
          url:auxU ,
          method: 'POST',
          headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
          data: dataInfo,
          cancelToken: source.token
      };
      let aux ={};           
      await axios(config)
      .then(function (response) {
        // console.log("entrando ");
        aux=  response.data;                           
        if (aux[0].Id!=-1) 
        {
          let auxArre=[];   
          aux.map((tipo) => {
            if (tipo.Id!=0) 
            {
              auxArre.push({ value : tipo.Id, label :tipo.Descr })  
            }            
          })                    
          guardarListLugarTerminal(auxArre);                                      
        }                                            
      })
      .catch(function (error) {                  
        console.log(`Error: ${error} `);        
        if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
        {
          console.log('datos incorrectos' );  
        }else
        {
          if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
              ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
          {
            console.log('loguearse de nuevo');
            localStorage.clear();                      
            history.push("/");                              
          } 
        }
      });                      
    };    

    function llenarUbicaciones() {
      const auxUbi= JSON.parse(localStorage.getItem('LisUbicOrd'));
      //console.log(auxUbi);
      let auxArre=[];
      auxUbi.map((ubica) => {
        if (ubica.LugarId==Lugar&& (ubica.Terminal.search(Terminal ) !== -1)) 
        {
          auxArre.push({ value : ubica.Id, label :ubica.Tipo })  
        }            
      })
      //console.log(auxArre);
      if (Lugar==5) 
      {
        guardarNuevaFalla({
          ...nuevaFalla,
          Ubicacion :numUbiOtro,
          UbicDescr :(UbicDescr.length!=0?UbicDescr:''),
        }) 
      }
      guardarListUbicacion(auxArre)
    }
    if (listLugar.length==0) 
    {
      llenarListLugar()  
    }
    if (listParoTipo.length==0) 
    {
      llenarListParo();
    }
    if (listParoAla.length==0) 
    {
      llenarListParoAla();
    }
    if (listParoSolucion.length==0) 
    {
      llenarListParoSolucion()  
    }
    if (listLugarTerminal.length==0) 
    {
      llenarListTerminal();  
    }
    if (Lugar!='' &&Terminal!='')  
    {
      llenarUbicaciones()
    }
    return ()=> {
        source.cancel();
    }
              
          
    
  },[Terminal,Lugar]);
      
  
 
  return ( 
  <form className={classes.root} noValidate autoComplete="off" fullWidth>                    
    { listLugarTerminal.length!=0 ?  
    <FormControl className={classes.formControl} size="small">
      <InputLabel htmlFor="age-native-simple">Terminal</InputLabel>
      <MaterialSelect
        native disabled value={Terminal}  
        inputProps={{
            name: 'Terminal',
            id: 'Terminal',
        }}
      >
        <option aria-label="None" value="" />          
        {listLugarTerminal.map((row) => (
            <option value={row.value}>{row.label}</option>
          ))}   
      </MaterialSelect>
    </FormControl>     
    :null}
    { listParoAla.length!=0 ?  
      <FormControl className={classes.formControl} size="small">
        <InputLabel htmlFor="Ala">Sección</InputLabel>
        <MaterialSelect
          native disabled value={Ala} 
          inputProps={{
              name: 'Ala',
              id: 'Ala',
          }}
        >                    
          <option aria-label="None" value="" />
          { listParoAla.map((row) => (
            <option value={row.value}>{row.label}</option>
          ))}                   
        </MaterialSelect>
      </FormControl>           
    :null}
    { listLugar.length!=0 ?  
    <FormControl className={classes.formControl} size="small">
      <InputLabel htmlFor="age-native-simple">Lugar</InputLabel>
      <MaterialSelect
        native disabled value={Lugar} 
        inputProps={{
            name: 'Lugar',
            id: 'Lugar',
        }}
      >
        <option aria-label="None" value="" />        
        {listLugar.map((row) => (
            <option value={row.value}>{row.label}</option>
          ))}   
      </MaterialSelect>
    </FormControl>
    :null}
     { listUbicacion.length!=0 ?  (Lugar!=5 ?  

    <FormControl className={classes.formControl} size="small">
      <InputLabel htmlFor="age-native-simple">Ubicación</InputLabel>
      <MaterialSelect
        native disabled value={Ubicacion} 
        inputProps={{
            name: 'Ubicacion',
            id: 'Ubicacion',
        }}
      >
        <option aria-label="None" value="" />        
        {listUbicacion.map((row) => (
            <option value={row.value}>{row.label}</option>
          ))}   
      </MaterialSelect>
    </FormControl>:  <TextField id="outlinedUbicacion" 
                        //style={{ width: 10+"rem" }}
                        label="Ubicación"  size="small"
                        value={UbicDescr}  disabled
                        name="UbicDescr"                                               
                      />  )
    :null}
    <br/>         
    <FormControl component="fieldset" style={{width:30+'rem'}}>      
      
      <RadioGroup  row aria-label="position" 
            defaultValue="top"   name="Estado" value={Estado} 
      >

        <FormControlLabel value="0" disabled style={{color:'#F46239'}} control={<Radio color="primary" />} label="Registrado" />    
        <FormControlLabel value="1" disabled style={{color:'#049b4f'}} control={<Radio color="primary" />} label="Solucionado" />
        <FormControlLabel value="-1" disabled style={{color:'red'}} control={<Radio color="primary" />} label="Cancelado" />            
      </RadioGroup>                     
    </FormControl>        
    <br/>       
    
    <TextField id="input-Horario" label="Hora de Paro" size="small" disabled
            value={Horario}  name="Horario" style={{width:6.5+'rem'}}            
    />
    <TextField id="input-Horario" label="Tiempo total de Paro" size="small" disabled
            value={Tiempo}  name="Tiempo" style={{width:10+'rem'}}            
    />
    <Autocomplete
      value={TipoDescr}  disabled selectOnFocus
      clearOnBlur handleHomeEndKeys
      id= 'AutoSelect-Solucion'
      options={listParoTipo}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.label;
      }}
      renderOption={(option) => option.label}
      style={{ width: 900 }}
      loading={listUbicacion.length!=0 }
      freeSolo
      size="small"
      color="primary"
      renderInput={(params) => (
        <TextField {...params} label="Selecciona una descripción de Paro y/o Fallas" variant="outlined" />
      )}
    />
  
    <Autocomplete
      value={Solucion} disabled
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        // Suggest the creation of a new value
        if (params.inputValue.trim() !== '') {
          filtered.push({
            inputValue: params.inputValue,
            label: `Agregar "${params.inputValue}"`,
          });
        }
        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id= 'AutoSelect-Solucion'
      options={listParoSolucion}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.label;
      }}
      renderOption={(option) => option.label}
      style={{ width: 900 }}
      freeSolo
      size="small"
      color="primary"
      renderInput={(params) => (
        <TextField {...params} label="Escriba una solución" variant="outlined" />
      )}
    />
    <textarea id="Informacion" name="Informacion" rows="5"
      cols="100"placeholder="Información Adicional" style={{width: 56+"rem"}} 
      value={Informacion}  disabled
      />
    <br/>   
    <br/>   
    
    </form>
  );
}
 
export default EditFormDummy;