import { useEffect, useState } from "react";
import {
  Card,
  FormControl,
  FormControlLabel,
  Box,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  statusCard: {
    textAlign: "center",
    color: "white",
    marginRight: 5, //padding: '24px 12px',
    height: "100%",
    paddingLeft: 0.5 + "rem",
    paddingRight: 0.5 + "rem",
  },
  marginBottom: {
    marginBottom: theme.spacing(1),
  },
}));

const CardDetalleCabina = ({
  element,
  index,
  aviso,
  setAviso,
  setMensaje,
  guardarDatos,
  mttoActividad,
  wait,
}) => {
  const classes = useStyles();
  const [available, setAvailable] = useState(false);
  const [value, setValue] = useState(0);
  const [color, setColor] = useState(element.StatusId);
  const [espera, setEspera] = useState(false);

  const [actividad, setActividad] = useState({
    MttoIdCabina: 0,
    Cabina: 0,
    stDescr: "",
    Verifica: "",
    Cantidad: 0,
    planDescr: "",
    stId: 0,
    Observ:"",
  });
  const { stDescr, Verifica, Cantidad, planDescr,Observ } = actividad;
  //console.log("ELEMENT" + index, element);

  useEffect(() => {
    const source = axios.CancelToken.source();
    //console.log(element);
    setActividad({
      MttoIdCabina: element.Id,
      Cabina: element.MttoId,
      stDescr: element.StatusNom,
      Verifica: element.Verifica,
      Cantidad: element.Cantidad,
      stId: element.StatusId,
      planDescr: element.PlanNum + " " + element.PlanDescr,
      Observ:element.Observacion,
    });
    setColor(element.StatusId)
    return () => {
      source.cancel();
    };
  }, [index]);

  useEffect(() => {
    setColor(actividad.stId);
  }, [actividad.stId]);

  const onChangeNum = (e) => {
    let expNopermitida = new RegExp("[#A-Za-z_:$!%-({})/*=?|]");
    let expMenos = new RegExp("-");
    let expMas = new RegExp("[+]");
    let expCadena = new RegExp("[0-9]");
    if (
      (expCadena.test(e.target.value) &&
        !expNopermitida.test(e.target.value) &&
        !expMenos.test(e.target.value) &&
        !expMas.test(e.target.value)) ||
      e.target.value === " " ||
      e.target.value === ""
    ) {
      setActividad({ ...actividad, Cantidad: e.target.value });
      if (actividad.Verifica != "") {
        setAvailable(true);
      }
      if (aviso) {
        setMensaje("");
        setAviso(false);
      }
    }
  };

  const onChangeObserv = (e) => {
    setValue(3);
    setActividad({ ...actividad, [e.target.name]: e.target.value });
    setAvailable(true);
  };

  const guardaVerifi = (e) => {
    setValue(3);
    setActividad({ ...actividad,
       Verifica: e.target.value ,
      Observ: e.target.value ==="OK"?"":Observ
    ,});
    //setActividad({...actividad, stId: 3})
    setAvailable(true);
    if (aviso) {
      setMensaje("");
      setAviso(false);
    }
  };

  const guardaCambios = () => {
    guardarDatos(actividad, value, setAvailable, setEspera, setActividad);
  };

  const btnCancelar = () => {
    setActividad({
      MttoIdCabina: element.Id,
      Cabina: element.MttoId,
      stDescr: element.StatusNom,
      stId: element.StatusId,
      Verifica: element.Verifica,
      Cantidad: element.Cantidad,
      planDescr: element.PlanNum + " " + element.PlanDescr,
      Observ:element.Observacion,
    });
    setAvailable(false);
  };

  const tamMax = 1 === element.CantidadReq ||"CAMBIO" === Verifica ? 3.5 + "rem" : 2.2 + "rem";

  const stdColor = color === 1 ? "#F46239" : color === 3 ? "#049b4f" : "red";

  return (
    <div key={index} style={{ overflowY: "auto" }}>
      <Card
        className={classes.marginBottom}
        style={{ padding: 0, height: tamMax }}
      >
        <Grid container alignItems={"center"} justifyContent={"center"}>
          <Grid item>
            <Card
              className={classes.statusCard}
              style={{
                backgroundColor: stdColor,
                height: tamMax,
                alignItems: "center",
                display: "flex",
              }}
            >
              <Typography component="p" variant="h6">
                {" "}
                {color === 1
                  ? "Pendiente"
                  : color === 3
                  ? "Finalizado"
                  : "Error"}
              </Typography>
            </Card>
          </Grid>
          <Grid item style={{ marginRight: 1 + "rem" }}>
            <FormControl component="fieldset" size="small">
              <RadioGroup
                row
                aria-label="position"
                size="small"
                name={"Verifica"}
                id={element.Id}
                value={Verifica}
                onChange={guardaVerifi}
              >
                <FormControlLabel
                  value={"OK"}
                  style={{ color: "#0ac15d" }}
                  label={"OK"}
                  control={<Radio style={{ color: "#0ac15d" }} size="small" />}
                  labelPlacement="start"
                  size="small"
                />
                <FormControlLabel
                  value={"CAMBIO"}
                  style={{ color: "#FF6439" }}
                  label={"CAMBIO"}
                  control={<Radio style={{ color: "#FF6439" }} size="small" />}
                  labelPlacement="start"
                  size="small"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          {1 === element.CantidadReq ? (
            <Grid item>
              <TextField
                id="outlinedReferen"
                style={{ width: 4.5 + "rem", marginRight: 1 + "rem" }}
                label="Medida"
                size="small"
                value={Cantidad}
                onChange={onChangeNum}
                name="Cantidad"
              />
            </Grid>
          ) : null}
          <Grid item style={{ marginRight: 0.5 + "rem" }}>
            {" "}
            {planDescr}{" "}
          </Grid>
          {"CAMBIO" === Verifica ? (
            <Grid item>
              <TextField
                id="outlinedObserv"
                style={{ width: 25+ "rem", marginLeft: .5 + "rem" }}
                label="Cambio" size="small" value={Observ}
                onChange={onChangeObserv} name="Observ" variant="outlined"
              />
            </Grid>
          ) : null}
          <Grid item style={{ marginLeft: "auto" }}>
            <Grid container>
              {!espera ? (
                available ? (
                  <>
                    <Grid item>
                      <IconButton
                        size={"small"}
                        aria-label="delete"
                        onClick={(e) => btnCancelar()}
                      >
                        <CancelIcon size="small" color={"error"} />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <IconButton
                        size={"small"}
                        aria-label="update"
                        onClick={guardaCambios}
                      >
                        <CheckCircleIcon
                          size="small"
                          style={{ color: "#0ac15d" }}
                        />
                      </IconButton>
                    </Grid>
                  </>
                ) : null
              ) : (
                <Box mt={1} mr={1} ml={0.5}>
                  <CircularProgress size={20} />
                </Box>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

export default CardDetalleCabina;
