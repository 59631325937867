import {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
    Box, Paper, Table, TableBody, TableCell, LinearProgress,
    TableContainer, TableHead, TableRow,
} from '@material-ui/core';
import axios from 'axios';
import qs from 'qs';
import moment from 'moment';
import {useHistory} from "react-router-dom";
import {llamaApiCargaToken, ErrorGeneral} from '../funciones/ClienteAxios';

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: window.innerHeight - 170,
        minHeight: 280,
        minWidth: '100%',
    },
    container2: {
        maxHeight: 550,
    },
    tableRow: {
        "&$selected, &$selected:hover": {
            backgroundColor: "#F46239"
        }
    },
    tableCell: {
        "$selected &": {
            color: "white"
        }
    },
    hover: {},
    selected: {}
});

export default function TablaOrden({
                                       history,
                                       source,
                                       guardarOrdenSeleccionada,
                                       orden,
                                       fechasSeleccionadas,
                                       buscar,
                                       turno,
                                       terminal,
                                       estatus,
                                       selectedID,
                                       setSelectedID
                                   }) {
    const classes = useStyles();
    const [espera, setEspera] = useState(false);

    const [datos, guardarDatos] = useState([])
    const [sinDatos, guardarSinDatos] = useState(true)

    useEffect(() => {
        let fInicio = moment(fechasSeleccionadas.inicio).format("YYYY-MM-DD")
        let fFinal = moment(fechasSeleccionadas.final).format("YYYY-MM-DD")
        const llenaListTabla = () => {
            setSelectedID(null)
            guardarOrdenSeleccionada([])
            let data = qs.stringify({
                terminal: terminal,
                fechaIni: fInicio + 'T00:00:00',
                fechaFin: fFinal + 'T23:59:59',
                status: estatus,
                idDispositivo: '89y7gttvjhj98h'
            });
            let url = "/mtto-lista-evidencia-desactiva";

            function respuestaListTabla(aux) {
                if (aux[0].Id != -1) {// console.log(aux);
                    guardarSinDatos(true)
                    guardarDatos(aux)
                } else {
                    guardarSinDatos(false)
                }
            }

            llamaApiCargaToken(data, url, respuestaListTabla, ErrorGeneral, setEspera, history, source);
        };

        llenaListTabla();
        return () => {
            source.cancel();
        }

    }, [buscar]);

    const seleccionado = (auxId, auxOrden) => {
        setSelectedID(auxId)
        guardarOrdenSeleccionada(auxOrden)
    }

    function tipoEstado(estado, idSelecc) {
        let auxEtiqueta = ''
        let auxColor = ''
        if (estado == 0) {
            auxEtiqueta = 'Registrado'
            auxColor = '#F46239'
        } else {
            if (estado == 1) {
                auxEtiqueta = 'Solucionado'
                auxColor = '#049b4f'
            } else {
                if (estado == -1) {
                    auxEtiqueta = 'Cancelado'
                    auxColor = 'red'
                }
            }
        }

        let renglon = <div></div>
        selectedID === idSelecc ?
            renglon =
                <TableCell align="center" style={{color: 'white', fontWeight: 700, width: 100 + 'px'}} padding={'none'}>
                    {auxEtiqueta}
                </TableCell> :
            renglon = <TableCell align="center" style={{color: auxColor, fontWeight: 700, width: 100 + 'px'}}
                                 padding={'none'}>{auxEtiqueta}</TableCell>
        return renglon
    }

    function Row(props) {
        const {row} = props;
        return (
            <>
                <TableRow onClick={() => {
                    seleccionado(row.Id, row);
                }}
                          className={classes.tableRow} selected={selectedID === row.Id}
                          classes={{hover: classes.hover, selected: classes.selected}}
                >
                    <TableCell className={classes.tableCell} align="center" padding={'none'}
                               style={{fontWeight: 700, width: 100 + 'px'}}>
                        {row.Id}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="left" padding={'none'} style={{width: 75 + 'px'}}>
                        {moment.utc(row.fecha).local(true).format("DD/MM/YYYY")}
                    </TableCell>
                    {tipoEstado(row.Status, row.Id)}
                </TableRow>
            </>
        );
    }

    function tablaCompleta(auxlista) {
        return (
            <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" padding='none'>Orden </TableCell>
                            <TableCell align="left" padding='none'>Fecha</TableCell>
                            <TableCell align="center" padding='none'>Estatus</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {auxlista.length != 0 ? (auxlista.map((row) => (
                            <Row key={row.Id} row={row}/>
                        ))) : null}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    const tablaSinDatos = () => {
        return (<TableContainer className={classes.container2}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Orden </TableCell>
                            <TableCell align="left">Fecha</TableCell>
                            <TableCell align="left">Estatus</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow
                            className={classes.tableRow}
                        >
                            <TableCell className={classes.tableCell} align="center">Dia</TableCell>
                            <TableCell className={classes.tableCell} align="left">Sin</TableCell>
                            <TableCell className={classes.tableCell} align="left">Reportes</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }
    const tablaSinResultados = () => {
        return (<TableContainer className={classes.container2}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Orden </TableCell>
                            <TableCell align="left">Fecha</TableCell>
                            <TableCell align="left">Estatus</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow //className={classes.root}
                            className={classes.tableRow}
                        >
                            <TableCell className={classes.tableCell} align="center">Orden</TableCell>
                            <TableCell className={classes.tableCell} align="left">Sin</TableCell>
                            <TableCell className={classes.tableCell} align="left">coincidencias</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    function filtro() {
        let info = []
        if (turno.length != 0) {
            let auxCadena = turno.trim();
            info = datos.filter((dat) => dat.turno.toString().search(auxCadena.toUpperCase()) !== -1)
        } else {
            if (orden.length != 0) {
                let auxCadena = orden.trim();
                info = datos.filter((dat) => dat.Id.toString().search(auxCadena) !== -1)
            }
        }

        let metodos = null
        if (info.length > 0) {

            metodos = tablaCompleta(info)
        } else {
            metodos = tablaSinResultados()
        }
        return metodos;
    }

    const contenido2 = turno != '' || orden != '' ? filtro() : tablaCompleta(datos)
    const contenido = sinDatos && datos.length != 0 ? contenido2 : tablaSinDatos()
    const tabla = espera ? (<Box mt={2}><LinearProgress/> </Box>) : (contenido)

    return (
        <Paper className={classes.root}>
            {tabla}
        </Paper>
    );
}