import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {InputLabel,FormControl,Select as MaterialSelect  ,Box ,
  //TextareaAutosize ,RadioGroup,FormControlLabel,Radio,Typography,
  LinearProgress,TextField,FormLabel, Typography
  } from '@material-ui/core';
import axios from 'axios';
import qs from 'qs';
import PilonaContainerSelec from './PilonaContainerSelec';
import { useHistory } from "react-router-dom";
import Modal from '../Generales/ModalTam';
import {llamaApiCargaToken,ErrorGeneral,llamadaApiToken } from '../funciones/ClienteAxios';
import {toast} from "react-toastify";
const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '20ch',
      }, 
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
      Textnumero: {
        width: 5+"px",
      },
      formControlFull: {
        //margin: theme.spacing(1),
        //minWidth: 120,
        width:5+"rem"
      },
      margin: {
        margin: theme.spacing(1),
      },
    },
  }));

const NvoMtto = ({modalAbierto,setModalAbierto,titulo,actualizar,listLugarTerminal}) => {
  let history = useHistory();
  const classes = useStyles();
  const source = axios.CancelToken.source();      
  let idusu= localStorage.getItem('UsuId');
  const [espera,setEspera]=React.useState(false);
  const [btnDesact,setBtnDesact]=React.useState(false);
  const [mensaje, setMensaje] = React.useState('');
  const [tipoAdvertencia, setTipoAdvertencia] = React.useState('success');
  const [arregloFiltrado, setArregloFiltrado] = useState([])
  const [aviso, setAviso] = React.useState(false);
  const [cabinaSelec, setCabinaSelec] = React.useState([]);  
  const[nvoMtto,setNvoMtto]=React.useState({Terminal:0,Tipo:0,Estado:'0' , Descripcion:"",
                                      Especial:"",Cabinas:""})

  const{Terminal ,Tipo ,Descripcion ,Especial}=nvoMtto                                                                
  const [listTipo, setListTipo] = React.useState([])
  const[listCabs,setListCabs]= React.useState([])
  const[arrCabSelect,setArrCabSelect]= React.useState([])  
  
  React.useEffect(()=>{             
   let auxTerminal= localStorage.getItem('UsuTerminal');      
       
    const llenarListCabs = () => {      
      let data = qs.stringify({
        usuario:idusu,
        idDispositivo:'89y7gttvjhj98h'
      });
      let url = "/pilona-list";
      function respuestaListCabs(aux) {
        if (aux[0].Id !== -1) 
        { setListCabs(aux);     
          // console.log(aux)                    
          var au1x = aux.filter(function (val) {
            return val.TermId === parseInt(auxTerminal);
          });        
          au1x.sort(function (a, b) { return a.Id - b.Id; });              
          setArregloFiltrado(au1x) 
        }
      }
      
      llamadaApiToken(data, url, respuestaListCabs,ErrorGeneral,history,source)
    };

    const llenarListTipo = () => {      
      let data = qs.stringify({
        idDispositivo:'89y7gttvjhj98h'
      });
      let url = "/pilona-mtto-tipo";
      function respuesta(aux) {
        if (aux[0].Id !== -1) 
        {  let auxArre=[];  
          //console.log(aux); 
          aux.map((tipo) => {
            if (tipo.Id!==0) 
            {
              auxArre.push({ value : tipo.Id, label :tipo.Tipo })  
            }            
          })                    
          setListTipo(auxArre); 
        }
      }
      
      llamadaApiToken(data, url, respuesta,ErrorGeneral,history,source)
    };
   
    if (listTipo.length === 0) 
    {
      llenarListTipo()
    }

    if (listCabs.length === 0) 
    {
      llenarListCabs();  
    }
 
    if (Terminal===0) {   
      setNvoMtto({
        ...nvoMtto, Terminal : auxTerminal  })   
    }    

    return ()=> {
      source.cancel();
    }     
      
  },[]);

  const registrarNVLectura=(auxPilonas)=>{
    const llenarNvLect = () => {      
      let data = qs.stringify({
        Tipo:Tipo,
        Status:1,
        Usuario:idusu,
        Terminal:Terminal,        
        Descr:Descripcion,//'Mantenimiento programado',                
        Especial:Especial,        
        Pilonas:auxPilonas,
        idDispositivo:'987gyubjhkkjh'
      });
      let url = "/pilona-mtto-nuevo";
      function respuestaNvLect(auxiliar) {
        if (auxiliar.respuesta==1) 
        { // console.log(auxiliar);
          let mensaje="Se registro correctamente el Nuevo Mantenimiento"
          setTipoAdvertencia("success")      
          setMensaje(mensaje);
          setAviso(true)                 
          actualizar()//llamada de funcionamiento  
          setBtnDesact(true)
          toast.success(mensaje, {
            toastId: `nvo-pilona${1}`,
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }); 
          setModalAbierto(false)
        }       
      }
  
      llamaApiCargaToken(data, url,respuestaNvLect,ErrorGeneral,setEspera,history,source);
    }            
    llenarNvLect();    
  }

  const onChange = e =>
  { let expNopermitida = new RegExp('[#_:$!%-({})/*=?|]');
    let expMenos = new RegExp('-');
    let expMas = new RegExp('[+]');     
    let expCadena = new RegExp('[A-Za-z0-9]');
    if ( ( expCadena.test(e.target.value)&&!expNopermitida.test(e.target.value)&&!expMenos.test(e.target.value)&&
        !expMas.test(e.target.value) )||e.target.value===' '||e.target.value==='') 
    { setNvoMtto({
        ...nvoMtto, [e.target.name] : e.target.value })
      if (aviso) 
      { setMensaje('');
        setAviso(false);
      }
    }
  };
  
  const onChange2 = e =>
  { let expNopermitida = new RegExp('[_:$!%({})/*=?|]');
    let expMenos = new RegExp('-');
    let expMas = new RegExp('[+]');     
    let expCadena = new RegExp('[A-Za-z0-9#-]');
    if ( ( expCadena.test(e.target.value)&&!expNopermitida.test(e.target.value)&&!expMenos.test(e.target.value)&&
        !expMas.test(e.target.value) )||e.target.value===' '||e.target.value==='') 
    { setNvoMtto({
        ...nvoMtto, [e.target.name] : e.target.value })
      if (aviso) 
      { setMensaje('');
        setAviso(false);
      }
    }
  };
  
  const guardTerminal = e =>
  {
    if (parseInt(e.target.value) > 0) 
    { setArrCabSelect([])  
      var aux = listCabs.filter(function (val) 
      {
        return val.TermId === parseInt(e.target.value);
      });
      aux.sort(function (a, b) { return a.Id - b.Id; });    
                    
      setArregloFiltrado(aux)
    }
    else
    { setArrCabSelect([]) 
      setArregloFiltrado([])
    }
    setNvoMtto({
      ...nvoMtto, [e.target.name] : e.target.value  })
    if (aviso) 
    { setMensaje('');
      setAviso(false);
    }
  };

  const mostrarAlertas =(auxMensaje)=>{
    setTipoAdvertencia("warning")
    let mensaje=auxMensaje            
    setMensaje(mensaje);
    setAviso(true)   
  }

  function valNulos(auxMensaje,auxCampo) 
  { let valida=false    
    if (auxCampo !== '' && auxCampo !== '0'&& auxCampo !==0 && auxCampo !== null && auxCampo!==" " ) 
    {      
      valida=true              
    }
    if (!valida) 
    { 
      mostrarAlertas(auxMensaje)
    }
    return valida
  }
  
  function valPilonas() 
  { let valida=false    
    if (arrCabSelect.length>0 ) 
    {      
      valida=true              
    }
    if (!valida) 
    { 
      mostrarAlertas("Seleccionar Pilonas")
    }
    return valida
  }
  
  const guardarDatos = () => 
  {
    if (  valNulos("Seleccionar Terminal",Terminal)  && valNulos("Seleccionar Periodo",Tipo)
      && ( Tipo!== "7" || valNulos("Llenar campo de Especial",Especial ) ) ) 
    {  
      if ( (Descripcion.length>=5 && valNulos("Llenar campo de Descripción (mayor a 5 caracteres)",Descripcion) )
        || (Descripcion.length<5 && valNulos("Llenar campo de Descripción (mayor a 5 caracteres)",0))
        && valPilonas()
      ) 
      {   
        
        let auxCadena=""
        arrCabSelect.forEach((pilona)=>{
          let auxArr=listCabs.filter((pil)=>pil.Id===pilona.Id ) 
          if (auxArr.length!==0) 
          {
            auxCadena=auxCadena + "" + auxArr[0].Folio + ","   
          }
        })           
        console.log(auxCadena);        
        if (auxCadena.length>0) {
          registrarNVLectura( auxCadena.slice(0,auxCadena.length-1) )           
        }          
        
                                                                                                                                               
      }                 
    }       
  };
 
  const onChangeSelect = e =>
  {  
    if (e.target.name==="Tipo") 
    {
      let arrFilt=listTipo.filter((tipo)=>""+tipo.value === e.target.value ) 
      //console.log(listTipo,e.target.value,arrFilt);
      let auxEspecial=e.target.value !=="7" ? arrFilt[0].label:""
      setNvoMtto({
        ...nvoMtto, Tipo : e.target.value ,Especial:auxEspecial})  
    }
    else{
      setNvoMtto({
        ...nvoMtto,   [e.target.name] : e.target.value })
    }
    

    if (aviso) 
    { setMensaje('');
      setAviso(false);
    }   
  };

  return ( 
    <Modal
      titulo={titulo} modalAbierto={modalAbierto}
      setModalAbierto={setModalAbierto} guardarDatos={guardarDatos}
      mensaje={mensaje} tipoAdvertencia={tipoAdvertencia}
      aviso={aviso} setAviso={setAviso} espera={espera}
      setEspera={setEspera} tamanio={"md"} btnDesact={btnDesact}
    >
    <form  noValidate autoComplete="off" fullWidth>       
      <Box  display="flex" flexDirection="row">                
        <Box style={{width:31+'rem'}} className={classes.root}>          
          {listLugarTerminal.length!=0 ?  
            <FormControl className={classes.formControl} size="small" style={{width:8+'rem'}}>
              <InputLabel htmlFor="age-native-simple">Terminal</InputLabel>
              <MaterialSelect
                native value={Terminal} onChange={guardTerminal}
                inputProps={{ name: 'Terminal', id: 'Terminal'}}
              >
                <option aria-label="None" value="" />      
                {listLugarTerminal.map((row) => (
                  <option value={row.value}>{row.label}</option>
                ))}   
              </MaterialSelect>
            </FormControl>     
          :null}  
          {listTipo.length!=0 ?  
            <FormControl className={classes.formControl} size="small" style={{width:10+'rem'}}>
              <InputLabel htmlFor="age-native-simple">Periodo</InputLabel>
              <MaterialSelect
                native value={Tipo} onChange={onChangeSelect}
                inputProps={{ name: 'Tipo', id: 'Tipo'}}
              >
                <option aria-label="None" value="" />      
                {listTipo.map((row) => (
                  <option value={row.value}>{row.label}</option>
                ))}   
              </MaterialSelect>
            </FormControl>     
          :null}           
          
          {Tipo==="7"?      
            <TextField id="input-EspecialDesc" style={{width:29+'rem'}}           
              label="Especial"  size="small" value={Especial} 
              onChange={onChange2} name="Especial"     maxLength={500}                                           
            /> 
          :null}                                                  
          <Box display="flex" flexDirection="column"  style={{width:18+'rem'}} >         
            <Typography component="p" variant="body2" color='primary'>Descripción</Typography>                 
            <textarea id="Descr-TextArea" name="Descripcion" rows="3" //placeholder="Descripción"
              cols="100" style={{width: 29+"rem",borderRadius:3+'px'}} 
              value={Descripcion} onChange={onChange}  maxLength={2000}
            />              
          </Box>          
        </Box>
      <Box style={{width:20+'em'}} display={"flex"} flexDirection="column" mt={1}>  
        <FormLabel component="legend" style={{color:'#FF6439',fontWeight:500}}>
          Selección de Pilonas
        </FormLabel>      
        {Terminal!==0&& arregloFiltrado.length>0 ?
          <Box style={{marginTop:'1rem'}} >
            <PilonaContainerSelec cabinasTotales={arregloFiltrado.length} 
              setCabinaSelec={setCabinaSelec}  cabSelecc={cabinaSelec} 
              arrCabinas={arregloFiltrado} arrCabSelect={arrCabSelect}
              setArrCabSelect={setArrCabSelect} 
            />
          </Box>
          : <Box pl={1} pt={5}><LinearProgress/></Box>
        }
        </Box>
      </Box>
    </form>
  </Modal>
  );
}
 
export default NvoMtto;