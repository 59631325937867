import React,{useState,useEffect} from 'react'
import ModalTam from '../Generales/ModalTamCerrar'
import { FormControl, FormControlLabel, Box,   
     TextField,    Checkbox,InputLabel,Select
} from '@material-ui/core';
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import {toast} from "react-toastify";
import {ErrorGeneral , llamaApiCargaToken } from '../funciones/ClienteAxios';
import moment from "moment/moment";
import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import esLocale from "date-fns/locale/es";
import DateFnsUtils from "@date-io/date-fns";
const NewEditMonit = ({modalAbierto,setModalAbierto,auxMonitoreo,llenaListTabla,listPersonal}) => {
  const history = useHistory();
  const [espera,setEspera]= useState(false);    
  const [btnCancelar,setbtnCancelar]= useState(false);
  const [aviso, setAviso] = useState(false);    
  const [mensaje, setMensaje] = useState('');
  const idusu= localStorage.getItem('UsuId');
  const Dispvo = localStorage.getItem("Dispvo");
  const auxDispV = Dispvo.length >= 12 ? Dispvo : "451224452121";
  const source = axios.CancelToken.source();
  const [tipoAdvertencia, setTipoAdvertencia] = useState('success');          
     
  const fechaFinalLim=new Date()
  const [monitoreo, setMonitoreo] = useState({llave:0, Ffin:fechaFinalLim,
      Fini:new Date(), Status:1, Titulo:"", Descr:"",Bandera:false});          
  const{llave,Ffin,Fini,Status,Titulo,Descr,Bandera}=monitoreo

  useEffect(()=>{
    if(auxMonitoreo.length!==0)
    {
      const aux2=listPersonal.filter((aux)=>aux.Descr===auxMonitoreo.Titulo)
      let nvo=[]      
      if (aux2.length!==0) {
        nvo=aux2[0]
      }
      let auxBand=false
      if (auxMonitoreo.FFin!==auxMonitoreo.FIni) 
      {
        auxBand=true
      }
      
      setMonitoreo({...monitoreo,
        llave:auxMonitoreo.Id,Ffin:moment.utc(auxMonitoreo.FFin).local(true),
        Fini:moment.utc(auxMonitoreo.FIni).local(true),Titulo:auxMonitoreo.Titulo,//nvo?.Id,
        Descr:auxMonitoreo.Descr,Bandera:auxBand
      })
    }
  },[auxMonitoreo])
    

  const onChange=(e)=>{        
    setMonitoreo({...monitoreo,[e.target.name]:e.target.value})
  }

  const registrar=(auxFini,auxFfin,nom)=>{                       
    const data = qs.stringify({                    
        Llave:llave,
        Usuario:idusu,              
        Fini:auxFini,
        Ffin:Bandera?auxFfin:auxFini,
        Status:Status,
        Titulo:nom,
        Descr:Descr,              
        idDispositivo:auxDispV,
    });   
    const url = "/cctv-monitoreo-new-1-1";
    function respuesta(aux) 
    {
      if (aux.respuesta === 1) 
      { const mensaje="Se registro correctamente "
        setTipoAdvertencia("success")      
        setMensaje(mensaje);
        setAviso(true)           
        //setRegistroExitoso(true) 
        llenaListTabla()
        setModalAbierto(false)
        toast.success(mensaje,{
          pauseOnFocusLoss: false,                    
          toastId: `nvo-cctv${aux.mensaje}`
        })
      }       
    }    
    llamaApiCargaToken(data, url,respuesta,ErrorGeneral,setEspera,history,source);
  } 

  const handleChange = (event) => {
      setMonitoreo({ ...monitoreo, [event.target.name]: event.target.checked });
  };

  const mostrarAlertas = (auxMensaje) => {
    setTipoAdvertencia("warning")
    let mensaje = auxMensaje
    setMensaje(mensaje);
    setAviso(true)
  }
  const guardarDatos=()=>{
    //console.log(monitoreo);
    if (Titulo!=="" && Titulo!==0 && Titulo!=="0" ) 
    { //const aux2=listPersonal.filter((aux)=>aux.Id===parseInt(Titulo))
    // const nom=aux2[0]
      registrar(moment(Fini).format("YYYY-MM-DDTHH:mm:ss"),
      moment(Ffin).format("YYYY-MM-DDTHH:mm:ss"),Titulo) 
    }
    else
    {
      mostrarAlertas("Escriba Nombre de Evento")
    }
  }
  
  const onChangeFech1 = e => {     
    setMonitoreo({
      ...monitoreo, Fini: e
    })
  };

  const onChangeFech2 = e => {     
    setMonitoreo({
      ...monitoreo, Ffin: e
    })
  };

  return (
  <ModalTam titulo={llave===0 ?"Nuevo Monitoreo":`Edición Monitoreo ${llave}`} 
    modalAbierto={modalAbierto} setModalAbierto={setModalAbierto} 
    guardarDatos={guardarDatos} tipoAdvertencia={tipoAdvertencia} 
    aviso={aviso} setAviso={setAviso}  espera={espera} 
    btnCancelar={btnCancelar} tamanio="md"  mensaje={mensaje}
  >
    <Box display="flex" flexDirection="column" width={"18rem"}>
      <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
        <Box mr={1} mb={2} mt={1} display="flex" flexDirection="column" justifyContent={"center"}>
          <DateTimePicker
            label="Fecha Inicio" size={"small"} ampm={false}
            style={{width: 10 + 'rem' ,justifyContent:"center"}}
            onChange={onChangeFech1} name="Fini"
            minDate="2017-03-14" maxDate={fechaFinalLim}
            format="dd/MM/yyyy HH:mm" value={Fini}             
            cancelLabel={"Cancelar"} okLabel="Seleccionar"                                                           
          />
          <FormControlLabel label="Finalizar Bitácora"
            control={
              <Checkbox name="Bandera" color="primary"
                checked={Bandera} onChange={handleChange}              
              />
            }        
          />
          {Bandera?
            <DateTimePicker  
              label="Fecha Final" size={"small"} ampm={false}
              style={{width: 10 + 'rem'}}
              onChange={onChangeFech2} name="Ffin"
              minDate={Fini} maxDate={fechaFinalLim}
              format="dd/MM/yyyy HH:mm" value={Ffin}       
              cancelLabel={"Cancelar"} okLabel="Seleccionar"                                                               
            />
            :null}
        </Box>
      </MuiPickersUtilsProvider>
      {/* listPersonal.length !== 0 ? 
        <FormControl size="small" style={{marginBottom:"1rem"}}>
          <InputLabel htmlFor="pers-inp-simp"> Evento</InputLabel>
          <Select
            native value={Titulo} onChange={onChange}
            inputProps={{ name: 'Titulo', id: 'Titulo', }}
          >
            <option aria-label="None" value=""/>
            {listPersonal.map((row) => 
              <option value={row.Id}>{row.Descr}</option>
            )}
          </Select>
        </FormControl>
      : null */}     
      <TextField id="monitTitulo" name="Titulo"  
        label="Evento" size="small" value={Titulo}
        variant="outlined" onChange={onChange} 
        style={{marginBottom:"1rem"}}
      />
     {/*  <TextField id="monitTitulo" variant="outlined"
        label="Título" size="small" value={Titulo}
        onChange={onChange} name="Titulo"
        style={{marginBottom:'1rem'}}  
      /> */}
        
      <TextField id="monitDescr" name="Descr"  
        label="Notas" size="small" value={Descr}
        multiline minRows={4}   variant="outlined"
        onChange={onChange}  maxRows={4}
      />
      {/* llave!==0 ? 
        
      :null */}
    </Box>        
  </ModalTam>
  )
}

export default NewEditMonit