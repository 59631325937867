import React from 'react'
import { useHistory } from "react-router-dom";
import {AuthContext} from '../context/AuthContext'
import {GeneralesContext} from '../context/GeneralesContext'
//import axios from 'axios';
//import qs from 'qs';
//import CreateIcon from '@material-ui/icons/Create';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import { Box,Card  ,CardContent ,IconButton,Tooltip,
    //Radio ,RadioGroup,FormLabel,FormControlLabel,Typography,TextField,SvgIcon,
    FormControl,InputLabel,Select as MaterialSelect } from '@material-ui/core'
import TablaActividades from './TablaActividades';
//import RepoTab from './RepoTab';
import Relojes from './Relojes2'
//import NuevaOrden from './RegistrarDialog'
import GraficaDirect from './GraficaDirect';
const useStyles = makeStyles((theme)=>({
    root: {
      minWidth: 275,
    },    
    title: {
      fontSize: 28,
    },
    title2: {
        fontSize: 18,
      },
    subTitle2: {
        fontSize: 12,
      },
    pos: {
      marginBottom: 12,
    },
    addButton: {
        marginRight: theme.spacing(2),       
      },
      altura2: {
        height: 70,
        //width:'100%',
        marginTop:0,
        paddingTop:0,
       // width:275
      },
      altura3: {
        height: 70,
        marginTop:0,
        paddingTop:0,
       // width:275
      },
      altura1: {
        height: 90,        
      },
  }));
const RepoInicio = () => {
    let history = useHistory();
    const classes = useStyles();
    const {usuario,guardarUsuario}=React.useContext(AuthContext);
    const {guardarGenerales}=React.useContext(GeneralesContext);  
    //const [tablaSeccionada,guardarTablaSeleccionada]=React.useState(0)
    //const [ordenLista,guardarOrdenLista]=React.useState([])
    const [ordenSeleccionada,guardarOrdenSeleccionada]=React.useState([])
    const [listaDirectDV, guardarListaDirectDV] = React.useState([]) 
    const [ordenBusq, setOrdenBusq] = React.useState({orden:'',turno:''});
    const [fechasSeleccionadas, setFechasSeleccionadas] = React.useState({inicio:new Date(),final:new Date()});
    const {orden,turno}=ordenBusq;
    const [Bucle, setBucle] = React.useState('');
    const [buscar, setBuscar] = React.useState(true);
    React.useEffect(()=>{                     
		const veriSesion =  () => 
        {            
            const usu= localStorage.getItem('UsuarioNom');
            const nvl= localStorage.getItem('Tipo');
             
            if(usu==null)
            {                                
                guardarUsuario([])						
                guardarGenerales([])     
                localStorage.clear();           
                history.push("/")	
            }
            else
            {
               
                if (usuario.length==0) 
                {
                    guardarUsuario({Nombre:usu,nivel:nvl});                                      
                }
                guardarGenerales({Titulo:'Direct Drive'})                     
            }
        }
        let auxTerm =localStorage.getItem('UsuTerminal') ; 
        setBucle(auxTerm)
	    veriSesion();	
        		
},[]);
    
    
    const incrementarBuscar = e =>
    {     
        setBuscar(!buscar)    
    };
    const cambiarBucle = (event) => {        
        setBucle(event.target.value);
      };


const contenido =  listaDirectDV.length!=0 ?(<GraficaDirect listaDirectDV={listaDirectDV} Bucle={Bucle}/>):(null)

const principal=()=>{
    //console.log(auxTotal);
    return(
        <Box display="flex" flexDirection="column" >
            <Box  >
                <Card className={classes.altura2}  >
                    <CardContent >
                        <Box display="flex" flexDirection="row" //alignItems="center"
                        >                                                                
                            <Box  >
                                <IconButton color="primary"  aria-label="add circle" 
                                    component="span" onClick={incrementarBuscar}
                                >
                                    <Tooltip title="Actualizar">
                                    <SearchIcon/>
                                    </Tooltip>
                                </IconButton>                                                                                                                              
                            </Box>
                            
                            <Box   component="span" >                                                                                                                                            
                                <Relojes fechasSeleccionadas={fechasSeleccionadas} setFechasSeleccionadas={setFechasSeleccionadas} />
                            </Box>                                
                            <Box>
                            <FormControl className={classes.formControl} size="small" style={{ marginLeft: 8 }}>
                                <InputLabel htmlFor="age-native-simple">Terminal</InputLabel>
                                <MaterialSelect
                                    native
                                    value={Bucle}
                                    onChange={cambiarBucle}
                                    inputProps={{
                                        name: 'Bucle',
                                        id: 'Bucle',
                                    }}
                                >
                                    <option aria-label="None" value="" />
                                    <option value={1}>Bucle A</option>
                                    <option value={2}>Bucle B</option>                                                                                
                                </MaterialSelect>
                                </FormControl>
                            </Box>
                        </Box>                                                                                                        
                    </CardContent>                                
                </Card>
            </Box>  
            <Box  display="flex" flexDirection="row">                
                <Box width="55%"  >                                                        
                    <Box p={1} >
                        <TablaActividades folioSelecc={orden} fechasSeleccionadas={fechasSeleccionadas} 
                            guardarOrdenSeleccionada={guardarOrdenSeleccionada} buscar={buscar}
                            turno={turno} setOrdenBusq={setOrdenBusq} ordenBusq={ordenBusq} Bucle={Bucle}
                            listaDirectDV={listaDirectDV} guardarListaDirectDV={guardarListaDirectDV}
                            usuarioContxt={usuario}                                    
                        />                
                    </Box>
                </Box>                    
                <Box width="45%"   >                        
                    <Box  >
                        {contenido}
                    </Box>                    
                </Box> 
            </Box>                
        </Box>      
    )
}
    return (
        <div>
            {principal()}            
        </div>
    )
}

export default RepoInicio
