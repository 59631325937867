import React from 'react'
import {AuthContext} from '../context/AuthContext'
import {GeneralesContext} from '../context/GeneralesContext'
import { Box,Radio,RadioGroup ,FormControlLabel,FormControl
        ,IconButton,Tooltip,LinearProgress
        } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search';        
import Reporte from './Reporte'
import ReporteSN from './ReporteSNDatos'
import moment from 'moment';
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
//import Reloj from './Reloj';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import esLocale from "date-fns/locale/es";
import { DatePicker,MuiPickersUtilsProvider} from '@material-ui/pickers';
import {llamaApiCargaToken, ErrorGeneral} from '../funciones/ClienteAxios';
import {findNumber} from "../funciones/FindNumber";

const RepoBitacora = () => {
  let history = useHistory();  
  const {usuario,guardarUsuario}=React.useContext(AuthContext);
  const {guardarGenerales}=React.useContext(GeneralesContext);  
  const [datos,setDatos]=React.useState([])
  const [espera,setEspera]=React.useState(false)  
  const [buscar, setBuscar] = React.useState(true);  
  const [fechas,setFechas]=React.useState({auxFecha:new Date(),fechaFin:new Date()})
  const [turno,setTurno]=React.useState('D')
  const {auxFecha,fechaFin}=fechas
  React.useEffect(()=>{
    const source = axios.CancelToken.source();  
    async function datosBitacora(usuID) {
      let fInicio = moment(auxFecha).format("YYYY-MM-DD");
      let fecFinal = moment(fechaFin).format("YYYY-MM-DD");            
      let data = qs.stringify({
          fechaIni:fInicio+' 00:00:00' ,
          fechaFin:fecFinal+' 23:59:59',        
          usuario:usuID,
          turno:turno,                 
          idDispositivo:'ifrekvjkxcdwdcwe'
      });
      let url = "/reporte-mtto-edificio-intervalo";

      function respuesta(aux) {
        if (aux[0].length!==0) 
        {                
          setDatos(aux[0]);                                      
        } 
      }
      llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEspera, history, source);    
    }
 
    const veriSesion =  () => 
    {            
      const usu= localStorage.getItem('UsuarioNom');
      const nvl= localStorage.getItem('Tipo');
      const usuID= localStorage.getItem('UsuId');      
      if(usu===null || usu===undefined ||usu==="" ||usuID===null|| usuID===undefined ||usuID==="")
      {                                
        guardarUsuario([])						
        guardarGenerales([])     
        localStorage.clear();           
        history.push("/")	
      }
      else
      {
        if (usuario.length===0) 
        {
            guardarUsuario({Nombre:usu,nivel:nvl});                                      
        }
        guardarGenerales({Titulo:'Reporte Mtto Edificios'})                     
        datosBitacora(usuID)
      }
    }
    veriSesion()      
      
    return ()=> {
      source.cancel();
    }
     //la siguiente linea puede generar errores o no en el useEffect
   // eslint-disable-next-line react-hooks/exhaustive-deps
  },[buscar])

    const guardaTurno = e =>
    { setTurno(        
        e.target.value  
      )            
    };

    const incrementarBuscar = e =>
    { // e.preventDefault();    
        if(turno==='D'|| turno==='N')
        {
          setBuscar(!buscar)        
        }
    };

    function verificaReporte(auxJson){
      let auxValido=false;
      if (auxJson.ordenes) {
        auxValido=true;
        console.log('no esta vacio');
      } 
      else 
      {
        auxValido=false;
        console.log('si esta vacio');
      }
      return auxValido;
    }

    const guardarFin = auxF =>
    {
      setFechas({
        ...fechas,
        fechaFin : auxF  
      })
    };  

    const guardarInicio = auxF =>
    {
      setFechas({
        ...fechas,
        auxFecha : auxF  
      })
    };  

    return (
      <div>            
        <Box display="flex" flexDirection="row">
          <Box display="flex" width="20%" flexDirection="column">
            <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>   
              <Box component="span" pb={1}  >
                <Tooltip title="Seleccionar Fecha Inicial" >
                  <Box  ml={1}  component="span"  >           
                    <DatePicker
                      disableFuture format="dd/MM/yyyy" size="small"
                      views={["year", "month", "date"]} label="Fecha Inicial"                       
                      minDate="2021-07-14" maxDate={new Date()}
                      value={auxFecha} onChange={guardarInicio}            
                      style={{width:7+'rem'}}
                    />                      
                  </Box>    
                </Tooltip>                                   
              </Box>  
              <Box component="span" pb={1}    >
                <Tooltip title="Seleccionar Fecha Final" >
                  <Box  ml={1}  component="span"  >           
                    <DatePicker
                      disableFuture format="dd/MM/yyyy" size="small"
                      views={["year", "month", "date"]} label="Fecha Final"                       
                      minDate={auxFecha} maxDate={new Date()}
                      value={fechaFin} onChange={guardarFin}            
                      style={{width:7+'rem'}}
                    />                      
                  </Box>    
                </Tooltip>                                   
              </Box>                         
            </MuiPickersUtilsProvider>  
           {/*  <Reloj fechas={fechas} setFechas ={setFechas } />             */}
            <Box ml={1} component="span" width={1/4} >
              <FormControl component="fieldset" >                                        
                <RadioGroup  aria-label="position" name="turno"
                    defaultValue="top"  value={turno} onChange={guardaTurno}
                >
                  <FormControlLabel value='D' control={<Radio color="primary" />} label="T.Diurno" />
                  <FormControlLabel value='N' control={<Radio color="primary" />} label="T.Nocturno" />            
                </RadioGroup>  
                
              </FormControl>                
            </Box> 
            <Box display="flex" style={{justifyContent:'center'}}>
              <Box display="flex">
            <Tooltip title="Buscar reporte" >                   
              <IconButton color="inherit"  aria-label="add circle" 
                component="span" onClick={incrementarBuscar} 
              >
                <SearchIcon color="primary" style={{ fontSize: 35 }}/>
              </IconButton>  
            </Tooltip>   
            </Box>
            </Box>
          </Box>
          <Box display="flex" width="80%" >
            { datos.length !== 0 && !espera ? (
              verificaReporte(datos)?
                <Reporte urlReport={"reports/Reporte-Edificio221018.mrt"} informacion={datos} 
                  nombre={' Reporte Mantenimiento'}  mostrarTB={findNumber('|58|') ?  true : false}
                /> :
                <ReporteSN urlReport={"reports/Reporte-Edificio221018.mrt"} informacion={datos} 
                  nombre={' Reporte Mantenimiento'}  mostrarTB={findNumber('|58|') ?  true : false}
              /> 
              )
              : 
              <Box style={{width:'100%'}} pt={2}>
                <LinearProgress color="primary" /> 
              </Box>      
            }              
          </Box>
        </Box>                      
      </div>
    )
}

export default RepoBitacora