import React from 'react'
import { Box,Card  ,CardContent ,Typography} from '@material-ui/core'
import Grafica from './GraficaParo2'
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme)=>({
    root: {
      minWidth: 275,
    },    
    title: {
      fontSize: 24,
    },
   
  }));
const FallaLadoDerecho = ({paroTotalHora,enservicio,paro,tParos}) => {
    const classes = useStyles();
    return (
    <React.Fragment>
        <Box width="25%"  ml={1} >                        
        <Box  justifyContent="center"  height="25%" >
            <Box mb={2} style={{ color: 'red'+100 }} >
                <Card   >
                    <CardContent >
                        <Box display="flex" flexDirection="row"  justifyContent="center" >
                        <Box mr={2} mt={1}>
                        <Typography className={classes.title}  variant="h4"
                             component="h1" align="center" //color={'secondary'} //textStyle="bold"   
                             style={{ fontSize:35,color: '#FF0D0D',fontWeight: '980'}} 
                        >
                          {paroTotalHora}
                        </Typography>                                    
                        <Typography color="textSecondary" align="center">
                            Tiempo de Paro
                        </Typography>
                        </Box> 
                        
                        <Box mr={2} mt={1}>
                            <Typography className={classes.title}  variant="h4"
                                component="h1" align="center" //color={'secondary'} //textStyle="bold"   
                                style={{ fontSize:35 ,color: '#FF0D0D',fontWeight: '980'}} 
                            >
                            {tParos}
                            </Typography>                                    
                            <Typography color="textSecondary" align="center">
                                Paros
                            </Typography>
                        </Box> 
                        </Box>
                    </CardContent>                                
                </Card>
            </Box>     
        </Box>
        <Box p={1} mt={1} height="75%">
            <Box display="flex" flexDirection="row" justifyContent="space-around">
                <Typography   variant="h5" component="h3"  //color={'secondary'} 
                      style={{ color: '#5DE163', fontSize:35 ,fontWeight:700}} //gutterBottom textStyle="bold" 
                >
                   {enservicio+"%"}
                </Typography>          
                <Typography variant="h5" component="h3"  //color={'secondary'}
                        style={{ color: '#FF0D0D',  fontSize:35,fontWeight:700 }} //gutterBottomalign="center" textStyle="bold"
                >
                    {paro+"%"}
                </Typography>
                                                              
            </Box>                         

        <Grafica    enservicio={enservicio} paro ={paro}/>
        </Box>
    </Box> 
</React.Fragment>
)
}

export default FallaLadoDerecho
