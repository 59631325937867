import React,{useEffect} from 'react'
import {Box,Typography, TextField } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Modal from '../Generales/ModalTamCerrar';
import axios from 'axios';
import qs from 'qs';
import {toast} from 'react-toastify';
import {llamadaApiToken,llamaApiCargaToken,ErrorGeneral } from '../funciones/ClienteAxios';
const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        //width: '20ch',
       // width: '30 rem',
      }, 
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
      Textnumero: {
        width: 5+"px",
      },
      formControlFull: {
        //margin: theme.spacing(1),
        //minWidth: 120,
        width:5+"rem"
      },
    },
  }));
function NvoTurno({modalAbierto,setModalAbierto,titulo,recargaTabla,turnoBit}) {
    const classes = useStyles();
    let history = useHistory();    
    const source = axios.CancelToken.source();      
    let idusu= localStorage.getItem('UsuId');
    const [espera,setEspera]=React.useState(false);    
    const [btnCancelar,setbtnCancelar]=React.useState(true);
    const [aviso, setAviso] = React.useState(false);    
    const [mensaje, setMensaje] = React.useState('');
    const [tipoAdvertencia, setTipoAdvertencia] = React.useState('success');          
    const [turno,setTurno]= React.useState({ Usuario:1, Bit1:0, Bit1Campo1:"", Bit1Campo2:"", 
                                            Bit2:0, Bit2Campo1:"", Bit2Campo2:"",nombTerm:"" })
    const {Bit1, Bit1Campo1, Bit1Campo2, Bit2, Bit2Campo1, Bit2Campo2,nombTerm} = turno;            
    

    useEffect(() => {
        let bitacora1 =0
        let bitacora2 =0
        let turnoA1="",turnoB1="",turnoA2="",turnoB2=""
        console.log(turnoBit);
        if (turnoBit.bitacoras) {
            let bita=turnoBit.bitacoras
            console.log(bita);
            
            /* if (bita[0] ) {                
                bitacora1 = bita[0].TermId===1 ? bita[0].Id   :0  
                bitacora2 = bita[0].TermId===2 ? bita[0].Id   :0  
            }   
            //console.log(bitacora1,bitacora2);     
            if (bita[1] ) {                
                bitacora1 = bita[1].TermId===1 && bitacora1 === 0 ? bita[1].Id   :bitacora1  
                bitacora2 = bita[1].TermId===2 && bitacora2 === 0  ? bita[1].Id   :bitacora2  
            }                        
            //console.log(bitacora1,bitacora2);     
            if (bitacora1!==0) 
            {   turnoA1=bita[0].TermId===1 ? bita[0].Turno1 :( bita[1].TermId===1 ? bita[1].Turno1 : "")
                turnoB1=bita[0].TermId===1 ? bita[0].Turno2 :( bita[1].TermId===1 ? bita[1].Turno2 : "")                
            }
            if (bitacora2!==0) 
            {   turnoA2=bita[0].TermId===2 ? bita[0].Turno1 :( bita[1].TermId===2 ? bita[1].Turno1 : "")
                turnoB2=bita[0].TermId===2 ? bita[0].Turno2 :( bita[1].TermId===2 ? bita[1].Turno2 : "")                
            } */
            if (bita[0] ) {                
                bitacora1 =  bita[0].Id   
            }                                       
            if (bitacora1!==0) 
            {   turnoA1= bita[0].Turno1 
                turnoB1=bita[0].Turno2 
            }            
        }
        
        setTurno({
            ...turno, Bit1:bitacora1,Bit2:bitacora2, Bit1Campo1:turnoA1, Bit1Campo2:turnoB1,
            Bit2Campo1:turnoA2, Bit2Campo2:turnoB2,nombTerm:turnoBit.bitacoras[0].TermNom
            
          })
        return ()=> {
            source.cancel();
        }  

    }, [])

    const onChange = e =>
    {
        setTurno({
        ...turno, [e.target.name] : e.target.value  
      })
    };


    const registrarNvoParo=()=>{
        const llenarNvoParo = () => {               
            let data = qs.stringify({                                
                Usuario:idusu,
                Bit1:Bit1,
                Bit1Campo1:Bit1Campo1.trim(),
                Bit1Campo2:Bit1Campo2.trim(),                
                idDispositivo:"wf4v5sd12sac1as2"
            });   
            let url = "/bitacora-turno-fecha-terminal-edit";
            function respuesta(aux) {
                if (aux.respuesta === 1) 
                {   let mensaje="Se edito correctamente el Turno ";
                    setbtnCancelar(false)
                    setTipoAdvertencia("success")      
                    setMensaje(mensaje);
                    setAviso(true)           
                    //setRegistroExitoso(true) 
                    toast.success(mensaje,{
                        pauseOnFocusLoss: false,                    
                        toastId: `nvo-Turno${11}`
                    })
                    recargaTabla()                    
                    setModalAbierto(false)
                }       
            }    
            llamaApiCargaToken(data, url,respuesta,ErrorGeneral,setEspera,history,source);
        }            
        llenarNvoParo();    
    }  

    const mostrarAlertas =(auxMensaje)=>{
        setTipoAdvertencia("warning")
        let mensaje=auxMensaje            
        setMensaje(mensaje);
        setAviso(true)   
    }

     function valNulos(auxMensaje,auxCampo) 
    {    let valida=false    
        if ( auxCampo !== '' && auxCampo !== '0' && auxCampo !==0 
            && auxCampo !== null && auxCampo!==" " ) 
        {   valida=true              
        }
        if (!valida) 
        {   mostrarAlertas(auxMensaje)
        }
        return valida
    }  

    const guardarParo = () => {
        if ( valNulos(`Debe llenar  el Turno 1 de ${nombTerm}`,Bit1Campo1)) 
        {
            if ( valNulos(`Debe llenar  el Turno 2 de ${nombTerm}`,Bit1Campo2)) 
            {
                registrarNvoParo()                                  
            }   
        }                                             
                                        
    };

    const contenido=()=>{
        return(
        <form className={classes.root} noValidate autoComplete="off"  id="modalTurno">                            
        <Box id="turno1" style={{width: 40+"rem"}} >
            <Typography component="p" variant="body2" color='primary' 
                style={{margin:0,marginLeft:.5+'rem'}} >
                {nombTerm}
            </Typography>                             
         {/*    <textarea id="Bit1Campo1" name="Bit1Campo1" minRows="4" maxRows={4}
                cols="100" placeholder="Turno 1" style={{width: 40+"rem"}} 
                value={Bit1Campo1} onChange={onChange}  maxlength={1000}
            /> */}
            <TextField style={{width: 40+"rem",marginTop:.8+'rem'}}
                id="outlined-Turnoa" size='small'
                label="Turno 1" multiline name="Bit1Campo1"
                minRows={3}    variant="outlined" disabled={Bit1===0?true:false}
                value={Bit1Campo1} onChange={onChange} maxRows={3}        
            />
           {/*  <Box display={"flex"} justifyContent="flex-start" width={"100%"} >
                <Typography variant="body" color="initial" >{Bit1Campo1.length}</Typography>
            </Box> */}
            {/* <textarea id="Bit1Campo2" name="Bit1Campo2" minRows="4" maxRows={4}
                cols="100" placeholder="Turno 2" style={{width: 40+"rem"}} 
                value={Bit1Campo2} onChange={onChange}  maxlength={1000}
            /> */}
            <TextField style={{width: 40+"rem",marginTop:.5+'rem'}}
                id="outlined-Turnb" size='small'
                label="Turno 2" multiline name="Bit1Campo2" maxRows={3}
                minRows={3}    variant="outlined" disabled={Bit1===0?true:false}
                value={Bit1Campo2} onChange={onChange}                
            />
            {/* <Box display={"flex"} justifyContent="flex-start" width={"100%"} >
                <Typography variant="body" color="initial" >{Bit1Campo2.length}</Typography>
            </Box> */}
        </Box>
   

    </form>
    )
    }

  return (
    <Modal
      titulo={titulo} modalAbierto={modalAbierto}
      setModalAbierto={setModalAbierto} guardarDatos={guardarParo}
      mensaje={mensaje} tipoAdvertencia={tipoAdvertencia}
      aviso={aviso} setAviso={setAviso} espera={espera}
      setEspera={setEspera} tamanio={"md"} btnCancelar={false}
    >
        {contenido()}
    </Modal>
  )
}

export default NvoTurno