import React, {useEffect, useState} from 'react'
import {Box, FormControl, InputLabel, Select as MaterialSelect,Typography} from '@material-ui/core';
import axios from 'axios';
import qs from 'qs';
import {useHistory} from "react-router-dom";
import {makeStyles} from '@material-ui/core/styles';
import {green} from '@material-ui/core/colors';
import ModalTam from '../Generales/ModalTam';
import {toast} from 'react-toastify';
import {ErrorGeneral, llamaApiCargaToken} from '../funciones/ClienteAxios';
import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import esLocale from "date-fns/locale/es";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment/moment";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex', flexWrap: 'noWrap',  flexDirection: "column" ,//alignItems: 'center',
    }, wrapper: {
        marginTop: 0, paddingTop: 0, position: 'relative',
    },
    buttonGuardar: {color: green[500],}, buttonProgress: {
        color: green[500], position: 'absolute', top: '50%', left: '50%', marginTop: -12, marginLeft: -12,
    },
}));

export default function ModalEditar({
                                        modalAbierto, setModalAbierto,
                                        titulo, listStatus,
                                        listUbic, listTipo,
                                        seleccionadoEdit, datos,
                                        setSeleccionado,
                                        setCargaEdit
                                    }) {
    let history = useHistory();
    const classes = useStyles();
    const source = axios.CancelToken.source();
    const [aviso, setAviso] = React.useState(false);
    const [mensaje, setMensaje] = React.useState('');
    const [tipoAdvertencia, setTipoAdvertencia] = React.useState('success');
    const [falla, guardarFalla] = React.useState({
        Id: 0,
        Descr: "",
        UbicId: 0,
        TipoId: 0,
        StatusId: 0,
        CancelMotivo: "",
        FEvento: seleccionadoEdit.FEvento,
        Titulo:""
    })
    const [espera, setEspera] = React.useState(false);
    const {Id, Descr, UbicId, TipoId, StatusId, CancelMotivo, FEvento,Titulo} = falla;
    const [fechas, setFechas] = useState({inicio: seleccionadoEdit.FEvento});
    const {inicio} = fechas

    useEffect(() => {
        guardarFalla({...falla, Id: seleccionadoEdit.Id,
            Descr: seleccionadoEdit.Descr,
            UbicId: seleccionadoEdit.UbicId,
            TipoId: seleccionadoEdit.TipoId,
            StatusId: seleccionadoEdit.StatusId,
            CancelMotivo: seleccionadoEdit.CancelMotivo,
            FEvento: seleccionadoEdit.FEvento,
            Titulo:seleccionadoEdit.Titulo,
            })
    }, [seleccionadoEdit])

    const registrarNvo = (UbicNom) => {
        let idusu = localStorage.getItem('UsuId');
        let auxF=moment(inicio).format("YYYY-MM-DDTHH:mm:ss")
        const llenarNvoParo = () => {
            let data = qs.stringify({
                Llave: Id,
                Fecha:auxF ,
                Status: 1,
                Usuario: idusu,
                UbicId: UbicId,
                UbicNom: UbicNom,
                Descr: Descr,
                Motivo: "",
                Tipo: 1,
                Titulo:Titulo,
                idDispositivo: 'iu87ygubhjjij',
            });
            let url = "/cctv-bitacora-editar-1-2";
            let auxi = datos
            setCargaEdit(true)

            function respuesta(aux) {
                if (aux.respuesta === 1) {
                    let mensaje = "Se edito correctamente."
                    setTipoAdvertencia("success")
                    setMensaje(mensaje);
                    setAviso(true)
                    toast.success(mensaje, {
                        pauseOnFocusLoss: false, toastId: `nvo-cctv${aux.mensaje}`
                    })
                    setModalAbierto(false)

                    let posicion = auxi.findIndex((element) => element.Id === falla.Id)
                    let auxTipoNom = listTipo.filter((ubic) => ubic.value === parseInt(TipoId))
                    let auxStatusNom = listStatus.filter((ubic) => ubic.value === parseInt(StatusId))
                    let arrEdit = {
                        ...seleccionadoEdit, Status: StatusId,FEvento:auxF,
                        UsrId: idusu, UbicId: UbicId, UbicNom: UbicNom,
                        Descr: Descr, CancelMotivo: CancelMotivo,Titulo:Titulo,
                        TipoId: TipoId, TipoNom: auxTipoNom[0].label, StatusNom: auxStatusNom[0].label
                    }

                    setSeleccionado(arrEdit)
                    auxi.splice(posicion, 1, arrEdit)

                }
                setCargaEdit(false)
            }

            llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEspera, history, source);
        }
        llenarNvoParo();
    }

    const mostrarAlertas = (auxMensaje) => {
        setTipoAdvertencia("warning")
        let mensaje = auxMensaje
        setMensaje(mensaje);
        setAviso(true)
    }

    function valNulos(auxMensaje, auxCampo) {
        let valida = false
        if (auxCampo !== '' && auxCampo !== '0' && auxCampo !== 0 && auxCampo !== null && auxCampo !== " ") {
            valida = true
        }
        if (!valida) {
            mostrarAlertas(auxMensaje)
        }
        return valida
    }

    const guardarParo = () => {
        if (valNulos("Escribir Titulo",Titulo )//valNulos("Seleccionar Estatus", StatusId) 
            //&& valNulos("Seleccionar Tipo", TipoId) 
            && valNulos("Seleccionar Ubicación", UbicId)) {
            if (Descr.length === 0 && valNulos("Escribir Descripción", '') || Descr.length >= 5) {
                setAviso(false)
                let auxUbiNom = listUbic.filter((ubic) => ubic.value === parseInt(UbicId))
                registrarNvo(auxUbiNom[0].label)
            }
        }
    };

    const onChange = e => {
        guardarFalla({
            ...falla, [e.target.name]: e.target.value
        })
    };

    const guardarInicio = auxfecha => {
        setFechas({
            ...fechas,
            inicio: auxfecha
        })
    };


    return (
        <ModalTam titulo={titulo} modalAbierto={modalAbierto}
            setModalAbierto={setModalAbierto} guardarDatos={guardarParo}
            mensaje={mensaje} tipoAdvertencia={tipoAdvertencia}
            aviso={aviso} setAviso={setAviso} espera={espera}
            setEspera={setEspera} tamanio="md" btnDesact={false}
        >
            <form className={classes.root} noValidate autoComplete="off" fullWidth>
                <Box mb={2}>
                    <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
                        <Box mr={1} component="span">
                            <DateTimePicker
                                label="Hora" size={"small"} ampm={false}
                                value={inicio} style={{width: 10 + 'rem'}}
                                onChange={guardarInicio}
                                minDate="2017-03-14" maxDate={new Date()}
                                format="dd/MM/yyyy HH:mm"                                                                
                            />
                        </Box>
                    </MuiPickersUtilsProvider>
                    {/* listStatus.length !== 0 ? <FormControl className={classes.formControl} size="small">
                        <InputLabel htmlFor="age-native-simple">Estatus</InputLabel>
                        <MaterialSelect
                            native value={StatusId} onChange={onChange}
                            inputProps={{name: 'StatusId', id: 'StatusId',}}
                        >
                            {listStatus.map((row, index) => (
                                <option value={row.value} key={index}>{row.label}</option>))}
                        </MaterialSelect>
                    </FormControl> : null */}
                    {/* listTipo.length !== 0 ?
                        <FormControl className={classes.formControl} size="small" style={{marginLeft: 1 + 'rem'}}>
                            <InputLabel htmlFor="age-native-simple">Tipo</InputLabel>
                            <MaterialSelect
                                native value={TipoId} onChange={onChange}
                                inputProps={{name: 'TipoId', id: 'TipoId',}}
                            >
                                <option aria-label="None" value=""/>
                                {listTipo.map((row, index) => (
                                    <option value={row.value} key={index}>{row.label}</option>))}
                            </MaterialSelect>
                        </FormControl> : null */}
                    {listUbic.length !== 0 ?
                        <FormControl className={classes.formControl} size="small" style={{marginLeft: 1 + 'rem'}}>
                            <InputLabel htmlFor="age-native-simple">Ubicación</InputLabel>
                            <MaterialSelect
                                native value={UbicId} onChange={onChange}
                                inputProps={{name: 'UbicId', id: 'UbicId',}}
                            >
                                <option aria-label="None" value=""/>
                                {listUbic.map((row, index) => (
                                    <option value={row.value} key={index}>{row.label}</option>))}
                            </MaterialSelect>
                        </FormControl> : null}

                </Box>
                <Box>
                    <Typography component="p" variant="body2" color='primary' 
                        style={{margin:0,marginLeft:.5+'rem'}} 
                    >
                        Titulo
                    </Typography>   
                    <textarea id="Titulo-input" name="Titulo" rows="3" placeholder="Titulo"
                        cols="100"  style={{width: 24+"rem",borderRadius:3+'px'}} 
                        value={Titulo} onChange={onChange} maxlength={500}
                    />
                </Box>
                <Box>
                    <Typography component="p" variant="body2" color='primary' 
                        style={{margin:0,marginLeft:.5+'rem'}} >
                        Descripción
                    </Typography>  
                    <textarea id="descripcion-input" name="Descr" rows="5" maxlength={2000}
                        cols="100" style={{width: 24 + "rem", borderRadius: 3 + 'px'}}
                        value={Descr} onChange={onChange} placeholder="Descripción"
                    />
                </Box>
                {/* StatusId === "2" || StatusId === 2 ?
                    <textarea id="CancelMotivo-input" name="CancelMotivo" rows="5"
                              cols="100" placeholder="Motivo Cancelación"
                              style={{width: 24 + "rem", borderRadius: 3 + 'px', marginTop: 1 + 'rem'}}
                              value={CancelMotivo} onChange={onChange}
                    />
                    : null */}
        </form>
    </ModalTam>);
}
