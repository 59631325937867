import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Box, Collapse, IconButton, LinearProgress,
    Paper, Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Tooltip, TablePagination
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import axios from 'axios';
import qs from 'qs';
import moment from 'moment';
import {useHistory} from "react-router-dom";
import EditarOrden from './EditarOrden'
import EditarOrdenDummy from './EditarOrdenDummy'
import CreateIcon from '@material-ui/icons/Create';
import {ErrorGeneral, llamadaApiToken, llamaApiCargaToken} from '../funciones/ClienteAxios';
import PaginacionTab from '../layout/PaginacionTab'

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: window.innerHeight - 170,
        minHeight: 280,
        minWidth: '100%',
    },
    container2: {
        maxHeight: 550,
    },
    tableRow: {
        "&$selected, &$selected:hover": {
            backgroundColor: "#F46239"
        }
    },
    tableCell: {
        "$selected &": {
            color: "white"
        }
    },
    hover: {},
    selected: {}
});

export default function TablaOrden({setOrdenSelec,ordenSelec, orden, fechas, buscar,setBuscar, turno, usuarioContxt,
                                       terminal, estatus
                                   }) {
    const classes = useStyles();
    let history = useHistory();
    const source = axios.CancelToken.source();
    //const [selectedID, setSelectedID] = useState(null);
    const [open, setOpen] = useState(false);
    const [espera, setEspera] = useState(false);
    //const [ordenSelecc, setOrdenSelecc] = useState(0);
    const [ordenMas, setOrdenMas] = useState(0);
    //const [seleccionadoEdit, setSeleccionadoEdit] = useState([]);
    const [modalAbierto, setModalAbierto] = useState(false);

    const [datos, guardarDatos] = useState([])
    const [sinDatos, guardarSinDatos] = useState(true)
    const [listOriginal, setOriginal] = useState([])
    let nvlSNPermiso = '5'
    const [totalPaginas,setTotalPaginas]=useState(1);
    const [numPagina,setNumPagina]=useState(0);

    const limiteRow=150
    useEffect(() => {
        
        
        
        llenaListTabla(1);
        return () => {
            source.cancel();
        }
    }, [buscar]);

    const llenaListTabla = (tipo) => {        
        const fInicio = moment(fechas.inicio).format("YYYY-MM-DD")
        const fFinal = moment(fechas.final).format("YYYY-MM-DD")
        const data = qs.stringify({
            terminal: terminal,
            fechaIni: fInicio + 'T00:00:00',
            fechaFin: fFinal + 'T23:59:59',
            status: estatus,
            idDispositivo: '89y7gttvjhj98h'
        });
        const url = "/orden-lista-intervalo-filtro";

        function respuestaListTabla(aux) {
            if (aux[0].Id !== -1) 
            {   guardarSinDatos(true)
                 //guardarDatos(aux)
                if(aux.length>limiteRow)
                {   let auxTam = aux.length;
                    if(auxTam%limiteRow===0)
                    {
                        let pagina=Math.trunc(auxTam/limiteRow)
                        setTotalPaginas(pagina)   
                    }
                    else
                    {   let pagina=Math.trunc(auxTam/limiteRow)
                        pagina=pagina+1;
                        setTotalPaginas(pagina)
                    }
                    setNumPagina(0) 
                    guardarDatos(aux.slice(0,limiteRow))
                }
                else
                {   setTotalPaginas(1)
                    setNumPagina(0)
                    guardarDatos(aux)
                }
                setOriginal(aux)  
                if (tipo===2) 
                {   const filtrado =aux.filter((ord)=>ord.Id===ordenSelec.Id)
                    if (filtrado.length!==0) 
                    {   setOrdenSelec(filtrado[0])  
                    }
                    else
                    {   setOrdenSelec([])  
                    }          
                }
            } 
            else 
            {   guardarSinDatos(false)
            }
        }

        if (tipo===1) 
        {   setOrdenSelec([])
            if (open) 
            {   setOrdenMas(0)  
                setOpen(false)
            }
            llamaApiCargaToken(data, url, respuestaListTabla, ErrorGeneral, setEspera, history, source);
        }
        else
        {   llamadaApiToken(data, url, respuestaListTabla, ErrorGeneral, setEspera, history, source);
        }        
    };


    const seleccionado = ( auxOrden) => {       
        setOrdenSelec(auxOrden)
    }

    function editarFall(auxFalla) {        
        setModalAbierto(true)
    }

    function verMas(auxId) {

        if (auxId === ordenMas) {
            setOpen(!open)
        } else {
            setOpen(true)
        }
        setOrdenMas(auxId)
    }

    function tipoEstado(estado, idSelecc) {
        let auxEtiqueta = '',  auxColor = ''     
        switch (parseInt(estado)) {
            case 0:
              auxEtiqueta='Registrado'
              auxColor='#F46239'  
              break;
            case 1:
              auxEtiqueta='Solucionado'
              auxColor='#049b4f'
              break;
            case -1:
              auxEtiqueta='Cancelado'
              auxColor='red'
              break;
            default:
              break;
        }                                
        return(
        <TableCell align="center" padding={'none'}
            style={{color: ordenSelec?.Id  === idSelecc ? 'white': auxColor, fontWeight: 700, width: 100 + 'px'}} 
        >
            {auxEtiqueta}
        </TableCell> 
        )
    }

    const funcionActualiza = () => {
        llenaListTabla(2) 
    }

    function Row(props) {
        const {row} = props;

        return (
        <>
            <TableRow onClick={() => { seleccionado( row); }}
                className={classes.tableRow} selected={ordenSelec?.Id  === row.Id}
                classes={{hover: classes.hover, selected: classes.selected}}
            >
                <TableCell align="center" padding={'none'} style={{width: 30 + 'px'}}>
                    <Tooltip title={open && ordenMas === row.Id ? 'Ver menos' : 'Ver más'}>
                        <IconButton color="primary" aria-label="expand row" size="small"
                            onClick={() => verMas(row.Id)}>
                            {open && ordenMas === row.Id ? 
                                ( ordenSelec?.Id  === row.Id ? <KeyboardArrowUpIcon style={{color: 'white'}}/> : <KeyboardArrowUpIcon/> ) 
                                :   ordenSelec?.Id  === row.Id ? <KeyboardArrowDownIcon style={{color: 'white'}}/> :<KeyboardArrowDownIcon/>
                            }
                        </IconButton>
                    </Tooltip>
                </TableCell>
                <TableCell align="center" padding={'none'} style={{width: 30 + 'px'}}>
                    <Tooltip title={'Editar'}>
                        <IconButton aria-label="add circle" size="small" onClick={() => editarFall(row)} component="span">
                            {ordenSelec?.Id  === row.Id ? <CreateIcon style={{color: 'white'}}/> : <CreateIcon color="primary"/>}
                        </IconButton>
                    </Tooltip>
                </TableCell>
                <TableCell className={classes.tableCell} align="center" padding={'none'}
                    style={{fontWeight: 700, width: 100 + 'px'}}>
                    {row.Id}
                </TableCell>
                <TableCell className={classes.tableCell} align="left" padding={'none'} style={{width: 75 + 'px'}}>
                    {moment.utc(row.fecha).local(true).format("DD/MM/YYYY")}
                </TableCell>
                {tipoEstado(row.Status, row.Id)}
            </TableRow>
            <TableRow>
                <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={5}>
                    <Collapse in={open && ordenMas === row.Id} timeout="auto" unmountOnExit>
                        <Box>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow color="primary">
                                        <TableCell style={{color: '#F46239'}}
                                            padding={'none'}>Paro/Falla</TableCell>
                                        <TableCell style={{color: '#F46239'}} padding={'none'}>Ubicación</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow key={row.Id}>
                                        <TableCell align="left" padding={'none'} style={{paddingRight: .7 + 'rem'}}>
                                            {row.paroId === 0 ? "Ninguno" : row.paroId}
                                        </TableCell>
                                        <TableCell align="left" padding={'none'}>
                                            {row.ubicDescr}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
        );
    }

    function tablaCompleta(auxlista) {
        return (
        <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>
                        <TableCell align="center" padding='none'></TableCell>
                        <TableCell align="center" padding='none'>{auxlista.length}</TableCell>
                        <TableCell align="center" padding='none'>Orden </TableCell>
                        <TableCell align="left" padding='none'>Fecha</TableCell>
                        <TableCell align="center" padding='none'>Estatus</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {auxlista.length !== 0 ? (auxlista.map((row) => (
                        <Row key={row.Id} row={row}/>
                    ))) : null}
                </TableBody>
            </Table>
        </TableContainer>
        )
    }

    const tablaSinDatos = () => {
        return (
        <TableContainer className={classes.container2}>
            <Table stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>
                        <TableCell align="center">Orden </TableCell>
                        <TableCell align="left">Fecha</TableCell>
                        <TableCell align="left">Estatus</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow className={classes.tableRow} >
                        <TableCell className={classes.tableCell} align="center">Dia</TableCell>
                        <TableCell className={classes.tableCell} align="left">Sin</TableCell>
                        <TableCell className={classes.tableCell} align="left">Reportes</TableCell>

                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
        )
    }
    const tablaSinResultados = () => {
        return (
        <TableContainer className={classes.container2}>
            <Table stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>
                        <TableCell align="center">Orden </TableCell>
                        <TableCell align="left">Fecha</TableCell>
                        <TableCell align="left">Estatus</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow className={classes.tableRow} >
                        <TableCell className={classes.tableCell} align="center">Orden</TableCell>
                        <TableCell className={classes.tableCell} align="left">Sin</TableCell>
                        <TableCell className={classes.tableCell} align="left">coincidencias</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
        )
    }

    const onChangePagina=(event, newPage)=>{
        event.preventDefault();
        if (newPage ===0) 
        {   guardarDatos(listOriginal.slice(0,limiteRow))   
        }
        else
        {   guardarDatos(listOriginal.slice(newPage * limiteRow, newPage * limiteRow + limiteRow))   
        }  
        setNumPagina(newPage)
    }

    const onChangePrb=(event,prb)=>{
        event.preventDefault();
      console.log(prb);
    }

    function generarFooter(){
        return(
        <TablePagination onPageChange={onChangePagina}
          rowsPerPageOptions={false}
          style={{padding:0}} rowsPerPage={limiteRow}
          count={listOriginal.length} page={numPagina}
          lang='es'  
          ActionsComponent={PaginacionTab}
          labelDisplayedRows={
              ({ from, to, count }) => {
              return '' + from + '-' + to + ' de ' + count
              }
          }
        />)
    }          

    function filtro() {
        let info = []
        if (turno.length !== 0) {
            let auxCadena = turno.trim();
            info = datos.filter((dat) => dat.turno.toString().search(auxCadena.toUpperCase()) !== -1)

        } else {
            if (orden.length !== 0) {
                let auxCadena = orden.trim();
                info = datos.filter((dat) => dat.Id.toString().search(auxCadena) !== -1)

            }
        }

        let metodos = null
        if (info.length > 0) {
            metodos = tablaCompleta(info)
        } else {
            metodos = tablaSinResultados()
        }
        return metodos;
    }
    const paginacion = totalPaginas>1 ? generarFooter() : null;         
    const contenido2 = turno !== '' || orden !== '' ? filtro() : tablaCompleta(datos)
    const contenido = sinDatos && datos.length !== 0 ? contenido2 : tablaSinDatos()
    const tabla = espera ? (<Box><LinearProgress/> </Box>) : (contenido)
    const modal = ordenSelec.length !== 0 && modalAbierto ?
        (usuarioContxt?.nivel !== undefined && usuarioContxt.nivel !== nvlSNPermiso ?
                (<EditarOrden titulo={'Editar Orden de Trabajo ' + ordenSelec?.Id} modalAbierto={modalAbierto}
                    setModalAbierto={setModalAbierto} funcionActuliza={funcionActualiza}
                    seleccionadoEdit={ordenSelec}
                />)

                : (<EditarOrdenDummy titulo={'Orden ' + ordenSelec.Id} modalAbierto={modalAbierto}
                        setModalAbierto={setModalAbierto}
                        seleccionadoEdit={ordenSelec} setBuscar={setBuscar} buscar={buscar}
                />)
        )
        : null
    return (
    <Paper className={classes.root}>
        <Box display={'flex'}>{paginacion}   </Box>  
        
        {tabla}
        {modal}
    </Paper>
    );
}