import React,{useContext,useState} from 'react'
import { useHistory } from "react-router-dom";
import {AuthContext} from '../context/AuthContext'
import {GeneralesContext} from '../context/GeneralesContext'
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import { Box,Card  ,CardContent ,Typography,IconButton,TextField,
    InputLabel ,FormControl,LinearProgress,Tooltip,//,FormControlLabel
    Select as MaterialSelect } from '@material-ui/core'
import TablaOrden from './TablaOrden';
import TablaCabDet from './TablaCabDetalle';
import moment from 'moment';
import Relojes from './Relojes2'
import axios from 'axios';
import qs from 'qs';
import CabinaContainer from './CabinaContainer'
import NvoMtto from './NvoMtto';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {llamadaApiToken,llamaApiCargaToken,ErrorGeneral } from '../funciones/ClienteAxios';
import EditarPersonal from './EditPersonal';
import {findNumber} from "../funciones/FindNumber";
import CreateIcon from '@material-ui/icons/Create';
const useStyles = makeStyles((theme)=>({
    root: {
      minWidth: 275,
    },    
    title: {
      fontSize: 28,
    },
    title2: {
        fontSize: 18,
      },
    subTitle2: {
        fontSize: 12,
    },    
    addButton: {
        marginRight: theme.spacing(2),       
    },
    altura2: {        
        marginTop:0,
        paddingTop:0,       
    },
    altura3: {
        minHeight: 100,
        marginTop:0,
        paddingTop:0,       
    },
    altura4: {
       // minHeight: 100,
        marginTop:0,
        paddingTop:0,       
    },
    altura1: {
        height: 90,        
    },
  }));
  const arrEstatus=[
    {valor:-2,nombre:"Todo"},
    {valor:0,nombre:"Registrada"},
    {valor:1,nombre:"Finalizada"},
    {valor:-1,nombre:"Cancelada"},
]
  
const CabinaInicio = () => {
    let history = useHistory();
    const classes = useStyles();
    let nvlSNPermiso ='5'  
    let colorTitulo='#f68160'
    const {usuario,guardarUsuario}= useContext(AuthContext);
    const {guardarGenerales}= useContext(GeneralesContext);      
    const [ordenSelect,setOrdenSelect]= useState([])
    const [ordenBusq, setOrdenBusq] =  useState({orden:'',turno:''});
    const [cabinaSelec, setCabinaSelec] = useState([]);
    const [arrCabinas, setArrCabinas] = useState([]);
    const [fechasSeleccionadas, setFechasSeleccionadas] = useState({inicio:new Date(),final:new Date()});
    const [nomPersonal, setNomPersonal] = useState({auxiliar:"",tecnico:""});
    const [avTotal, setAvTotal] = useState({avance:"",original:[]});
    const {orden,turno}=ordenBusq;
    const [buscar, setBuscar] = useState(true);    
    const [terminal, setTerminal] = useState(1);    
    const [estatus, setEstatus] = useState(-2);    
    const [esperCab, setEsperCab] = useState(false);        
    const[modalNvo,setModalNvo]= useState(false);    
    const [listLugarTerminal, setListLugarTerminal] = useState([])
    const [modalPersonal, setModalPersonal] = useState(false);
    const [actualizaPersnl, setActualizaPersnl] = useState(false);    
    const [espera2, setEspera2] = useState(false);    
    const [auxCabSelecc, setAuxCabSelecc] = useState(0);    
    const [idCab, setIdCab] = useState(0);    
    const source = axios.CancelToken.source();
    let idusu= localStorage.getItem('UsuId');
    React.useEffect(()=>{ 
                
        const llenarListTerminal = () => {      
            let data = qs.stringify({
              usuario:idusu,
              idDispositivo:'89y7gttvjhj98h'
            });
            let url = "/lugar-terminal-list";
            function respuestaListTermin(aux) {
              if (aux[0].Id !== -1) 
              {let auxArre=[];   
              //console.log(aux);
                aux.map((tipo) => {
                  if (tipo.Id!=0) 
                  { auxArre.push({ value : tipo.Id, label :tipo.Descr })  
                  }            
                })    
                let auxTerm =localStorage.getItem('UsuTerminal') ;                
                setListLugarTerminal(auxArre);  
                setTerminal(parseInt(auxTerm))
              }
            }            
            llamadaApiToken(data, url, respuestaListTermin,ErrorGeneral,history,source)
        };

        const llamarUbicacionOrd = () => {      
            let data = qs.stringify({
                tipo:'ORD',
                idDispositivo:'89y7gttvjhj98h'
            });
            let url = "/lugar-ubicacion-list-1_1";
            function respuestaListUbi(auxiliar) {
              if (auxiliar ) 
              { localStorage.setItem('LisUbicOrd', JSON.stringify(auxiliar)); 
              }               
            }      
            llamadaApiToken(data, url,respuestaListUbi,ErrorGeneral,history,source);
        };  
        
		const veriSesion =  () => {            
            const usu= localStorage.getItem('UsuarioNom');
            const nvl= localStorage.getItem('Tipo');   
            if(usu==null)
            {                                
                guardarUsuario([])						
                guardarGenerales([])     
                localStorage.clear();           
                history.push("/")	
            }
            else{               
                if (usuario.length==0) 
                {
                    guardarUsuario({Nombre:usu,nivel:nvl});                                      
                }                
                guardarGenerales({Titulo:'Mantenimiento Cabinas'})           
                const auxUbi= JSON.parse(localStorage.getItem('LisUbicOrd'));
                if (auxUbi === null ||auxUbi === undefined ) 
                {   llamarUbicacionOrd()    
                }     
                if (listLugarTerminal.length === 0) 
                {
                    llenarListTerminal();  
                }             
            }
        }
	    veriSesion();	    
        
        return ()=> {
            source.cancel();
        } 
    },[]);

    React.useEffect(()=>{ 
        
        const llamarDetPersonal = () => {      
           //console.log(cabinaSelec[0]);
            let data = qs.stringify({
                MttoIdCabina:cabinaSelec[0].mttoId,
                Cabina:cabinaSelec[0].cabinaId,
                idDispositivo:'89y7gttvjhj98h'
            });
            let url = "/cabina-mtto-det-personal-list";
            function respuestaDetPers(auxiliar) {
              if (auxiliar ) 
              { //console.log(auxiliar);
                setNomPersonal({
                    auxiliar:auxiliar[0].AuxNom,tecnico:auxiliar[0].TecNom,original:auxiliar[0]
                })
                setIdCab(0)
              }               
            }      
            llamaApiCargaToken(data, url,respuestaDetPers,ErrorGeneral,setEspera2,history,source);            
        };          

        if (cabinaSelec.length!==0) 
        {   setNomPersonal({ auxiliar:"",tecnico:"" })
           llamarDetPersonal()          
        }

    },[cabinaSelec,actualizaPersnl]);

    const onChange = e =>
    {   let expNopermitida = new RegExp('[#._:$!%-({})/*=?|]');
        let expMenos = new RegExp('-');
        let expMas = new RegExp('[+]');     
        let expCadena = new RegExp('[A-Za-z0-9]');
        if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) &&
            !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) ||e.target.value===' '||e.target.value==='') 
        {
            setOrdenBusq({
                ...ordenBusq,
                [e.target.name] : e.target.value.trim()  
            })
        }        
    };
    
    const onChangeTerminal = (event) => 
    {   setTerminal(event.target.value);        
    }; 

    const onChangeEstatus = (event) => 
    {   setEstatus(event.target.value);        
    }; 

    function editarPersonal() {
        setModalPersonal(true)
    }
       
    const incrementarBuscar = e =>
    {  setBuscar(!buscar)    
    };

    const actualizar2=()=>{
        setActualizaPersnl(!actualizaPersnl)
    }

    function tipoEstado(estado)
    {   let auxEtiqueta=''
        let auxColor=''
        if (estado === 1) 
        {   auxEtiqueta='Pendiente'
            auxColor='#D83906'
        }
        else
        {   if (estado === 3) 
            {   auxEtiqueta='Finalizado'
                auxColor='#107014'
            }
            else
            {   if (estado === 2) 
                {   auxEtiqueta='En Proceso'
                    auxColor='#E1AA06'
                } 
            }
        }
        let renglon={  color:auxColor,etiqueta:auxEtiqueta}                   
        return renglon
    }
        
    const Encabezado=({espacio,estiloCampo,descripcion,campo})=>{
        return(
        <Box {...espacio} >
            <Typography className={classes.title2}  variant="h5" color="secondary" 
                component="h2" align="center"  style={estiloCampo}
            >                                        
                {campo}
            </Typography>                                            
            <Typography  className={classes.subTitle2} color="textSecondary"  align="center" >
                {descripcion }
            </Typography>
        </Box>
        )
    }

    const CampoRenglon=({estiloBox,estiloTitulo,titulo,campo})=>{
        return(
        <Box  {...estiloBox} >
            <span style={estiloTitulo}>{titulo} </span>  
            <Typography variant='body'> {campo}  </Typography>                        
        </Box>
        )
    }

    const botonPersonal =( <Box>
        <Tooltip title="Editar Personal de Mantenimiento">
            <IconButton   aria-label="edit personal" size="small" onClick={() => editarPersonal()} >
                <CreateIcon color="primary"/>
            </IconButton>  
        </Tooltip></Box>)
        
    const costadoDerecho =(orden)=>{
        let fecha = moment.utc(orden.Fecha);  
        let modificada= fecha.local(true).format("DD/MM/YYYY HH:mm");            
        let arrEstado= tipoEstado(orden.stId)
        //console.log(orden,avTotal);
       //console.log(typeof(orden.descr.length) , orden.descr.length);
        return(    
        <Box  mb={.5} >
        <Card className={classes.altura3}  >
        <CardContent style={{ padding:0,marginBottom:.5+'rem'}}>
            <Box display="flex" flexDirection="row" justifyContent="center" mt={1}>
                <Encabezado espacio={{ }} estiloCampo={ {fontWeight: '900'}} descripcion={"Mtto"} campo={orden.Id} />                                                                                            
                <Encabezado espacio={{ ml:1 }} estiloCampo={ {color:arrEstado.color,fontWeight:700}} 
                    descripcion={"Estatus"} campo={arrEstado.etiqueta} />                     
                <Encabezado espacio={{ ml:1 }} estiloCampo={ {fontWeight: '900'}} 
                    descripcion={"Fecha"} campo={modificada} />                                                                                                  
                {/* <Encabezado espacio={{ ml:1 }} estiloCampo={ {fontWeight: '900'}} 
                    descripcion={"Terminal"} campo={orden.termNom} />              */}                                   
                <Encabezado espacio={{ ml:1 }} estiloCampo={ {fontWeight: '900'}} 
                    descripcion={"Horometro"} campo={orden.Horometro} />  
                <Encabezado espacio={{ ml:1 }} estiloCampo={ {fontWeight: '900'}} 
                    descripcion={"Tipo"} campo={orden.tpDescr} />        
                {avTotal.avance !=="" && avTotal.avance !==" " ?
                    <Encabezado espacio={{ ml:1 }} estiloCampo={ { fontWeight: '900'}} 
                        descripcion={"Avance"} campo={avTotal.avance} />
                    :null
                }               
                {(orden.stId === 3  ) && (orden.usrFinaliza !=="" && orden.usrFinaliza !==" " ) ?
                    <Encabezado espacio={{ ml:1 }} estiloCampo={ { fontWeight: '900'}} 
                        descripcion={"Finalizo"} campo={orden.usrFinaliza} />
                    :null
                }                                                                
            </Box>   
            {orden.RefManual && orden.RefManual.length!== 0 && orden.RefManual!==' ' ?
                <CampoRenglon estiloBox={{display:"flex",ml:1}} titulo="Ref. Manual " campo={orden.RefManual} 
                    estiloTitulo={{color:colorTitulo,fontWeight:700,paddingRight:0.2+'rem' }}
                />                                                                                      
            :null}             
            { orden.Descr.length !== 0 && orden.Descr !==" " ?
                <CampoRenglon estiloBox={{display:"flex",ml:1}} titulo="Descripción " campo={orden.Descr} 
                    estiloTitulo={{color:colorTitulo,fontWeight:700,paddingRight:0.2+'rem' }}
                />                                                                                      
            :null} 
            { orden.Observ.length !== 0 && orden.Observ !==" " ?
                <CampoRenglon estiloBox={{display:"flex",ml:1}} titulo="Observación " campo={orden.Observ} 
                    estiloTitulo={{color:colorTitulo,fontWeight:700,paddingRight:0.2+'rem' }}
                />                                                                                      
            :null}                                             
        </CardContent>                     
        </Card>
        </Box>                                            
        )
    }

    const detCabinas=()=>{
        let auxpiz= cabinaSelec[0]?.Pinza 
        let auxsus= cabinaSelec[0]?.Suspension 
        let fecha = cabinaSelec[0]?.FFinaliza!=="1900-01-01T00:00:00.000Z"? moment.utc(cabinaSelec[0]?.FFinaliza):"";  
        let modificada= fecha!==""?fecha.local(true).format("DD/MM/YYYY HH:mm:ss"):"";   
        return<Card className={classes.altura4}  >
        <CardContent style={{ padding:0,marginBottom:.5+'rem'}}>
        {cabinaSelec[0]?.cabinaId ?
            <Box display={"flex"} row>            
                <CampoRenglon estiloBox={{display:"flex",ml:1}} titulo="Detalle Cabina " 
                    estiloTitulo={{color:colorTitulo,fontWeight:700,paddingRight:0.2+'rem' }}
                    campo={<b>{cabinaSelec[0].cabinaId}</b>} 
                /> 
                { modificada!==""?
                    <CampoRenglon estiloBox={{display:"flex",ml:1}} titulo="Fecha Cierre " 
                        estiloTitulo={{color:colorTitulo,fontWeight:700,paddingRight:0.2+'rem' }}
                        campo={ modificada} 
                    /> 
                :null}
            </Box>
            : null} 
         {cabinaSelec[0]?.cabinaId ?
         <Box display={"flex"} row>            
            <CampoRenglon estiloBox={{display:"flex",ml:1}} titulo="Pinza" campo={auxpiz===1?"OK":"Pendiente"} 
                estiloTitulo={{color:colorTitulo,fontWeight:700,paddingRight:0.2+'rem' }}
            /> 
            <CampoRenglon estiloBox={{display:"flex",ml:1}} titulo="Suspensión" campo={auxsus===1?"OK":"Pendiente"} 
                estiloTitulo={{color:colorTitulo,fontWeight:700,paddingRight:0.2+'rem' }}
            /> 
            </Box>
            : null}             
        {!espera2?
            <Box display={"flex"} row>
                <Box mr={2}>                    
                    <CampoRenglon estiloBox={{display:"flex",ml:1}} titulo="Técnico " campo={nomPersonal.tecnico} 
                        estiloTitulo={{color:colorTitulo,fontWeight:700,paddingRight:0.2+'rem' }}
                    />                                                                                                                      
                    <CampoRenglon estiloBox={{display:"flex",ml:1}} titulo="Auxiliar " campo={nomPersonal.auxiliar} 
                        estiloTitulo={{color:colorTitulo,fontWeight:700,paddingRight:0.2+'rem' }}
                    />                                                                                                      
                </Box>
                {findNumber('|48|') ? botonPersonal:null}
            </Box>         
            :(<Box ml={2} mr={1} mt={2} mb={2}><LinearProgress/></Box>) 
            }
            </CardContent>                     
            </Card>
    }

    function abrirNvoMtto() 
    {   setModalNvo(true)
    }
    
    const modal =modalNvo !='' ? 
            ( usuario?.nivel !=undefined && usuario.nivel!== nvlSNPermiso ?
                (<NvoMtto titulo={'Nuevo Mantenimiento '} modalAbierto={modalNvo}
                    setModalAbierto={setModalNvo}    actualizar={incrementarBuscar}
                    listLugarTerminal={listLugarTerminal}
                />)
            : null
            )
            :null
    const ladoDerecho=<Box pl={1} mt={.5} display={"flex"} flexDirection="column" >
                <Box display="flex" flexDirection="column" >                
                    { ordenSelect.length !== 0 ? costadoDerecho(ordenSelect) : null}
                    { arrCabinas.length > 0 && avTotal.original.length!==0?
                        (<CabinaContainer  setCabinaSelec={setCabinaSelec} 
                            cabSelecc={auxCabSelecc} arrCabinas={arrCabinas}
                            avTotal={avTotal} idCab={idCab}
                        />)
                        : null  
                    }
                </Box>
                <Box display="flex" pt={2} flexDirection="column">        
                { cabinaSelec.length !== 0 ? detCabinas():null }        
                    {cabinaSelec.length !== 0 ?
                        <TablaCabDet orden={orden} fechasSeleccionadas={fechasSeleccionadas}                             
                            turno={turno} setOrdenBusq={setOrdenBusq} ordenBusq={ordenBusq}
                            usuarioContxt={usuario}   cabinaSelec={cabinaSelec}
                            setArrCabinas={setArrCabinas} setAvTotal={setAvTotal}
                            setAuxCabSelecc={setAuxCabSelecc} nomPersonal={nomPersonal}
                            ordenSelect={ordenSelect} setOrdenSelect={setOrdenSelect}
                        />
                        :null 
                    }        
                </Box>   
            </Box>   

const contenido2 = ordenSelect.length !== 0 ? (ladoDerecho) : (esperCab ?(<Box pl={1} pt={5}><LinearProgress/></Box>) :null)
const principal=()=>{

    return(
        <Box display="flex" flexDirection="column" >
            <Box  >
                <Card className={classes.altura2}  >
                <CardContent style={{padding:0,marginTop:.5+'rem',marginBottom:.8+'rem'}}>
                    <Box display="flex" flexDirection="row" >
                        <Box mx={1} >                                            
                            { usuario?.nivel !=undefined && usuario.nivel!== nvlSNPermiso && findNumber('|47|') ?
                                <IconButton size={"small"} color="primary"  aria-label="add circle" component="span" 
                                    onClick={()=>abrirNvoMtto()} style={{marginTop:.5+'rem'}}
                                >
                                    <AddCircleOutlineIcon/>
                                </IconButton>  
                            :   null
                            }
                        </Box>                                                                  
                        <Box pr={1} >
                            <IconButton color="primary"  aria-label="add circle" size={"small"}
                                component="span" onClick={incrementarBuscar} style={{marginTop:.5+'rem'}}
                            >
                                <SearchIcon/>
                            </IconButton>                                                                                                                              
                        </Box>
                        {listLugarTerminal.length!=0 ?  
                            <FormControl className={classes.formControl} size="small" style={{width:8+'rem'}}>
                            <InputLabel htmlFor="terminal1-native-simple">Terminal</InputLabel>
                            <MaterialSelect
                                native value={terminal} onChange={onChangeTerminal}
                                inputProps={{ name: 'terminal', id: 'terminal'}}
                            >
                                <option aria-label="None" value="" />      
                                {listLugarTerminal.map((row) => (
                                    <option value={row.value}>{row.label}</option>
                                ))}   
                            </MaterialSelect>
                            </FormControl>     
                        :null} 
                        <FormControl component="fieldset" style={{width:8+'rem',marginLeft:.5+'rem'}} size="small">   
                            <InputLabel htmlFor="estatus1-native">Estatus</InputLabel>                                           
                            <MaterialSelect
                                native value={estatus} onChange={onChangeEstatus}
                                inputProps={{ name: 'estatus', id: 'estatus'}}
                            >
                            {arrEstatus.map((status) =>{
                                return(
                                <option value={status.valor} id={status.valor}>
                                    {status.nombre}
                                </option>
                                )
                                
                            })}   
                            </MaterialSelect>                                               
                        </FormControl>                                                                                                                                                                                                                     
                        <Box   component="span" >                                                                                                                                                                                 
                            <Relojes fechasSeleccionadas={fechasSeleccionadas} 
                                setFechasSeleccionadas={setFechasSeleccionadas} 
                            />
                            <TextField id="outlined-basic" label="Folio" variant="outlined" 
                                style={({width:8+"rem",marginTop: .3+'rem' , marginLeft:.5+'rem'})} size="small"
                                name="orden" value={orden} onChange={onChange}                                                     
                            /> 
                        </Box>                                                                                                                           
                    </Box>                                                                                                        
                </CardContent>                                
                </Card>
            </Box>  
            <Box  display="flex" flexDirection="row">                
                <Box width="40%"  pt={1} >                                                                            
                    <TablaOrden orden={orden} fechasSeleccionadas={fechasSeleccionadas} 
                        setOrdenSelect={setOrdenSelect} 
                        turno={turno} setOrdenBusq={setOrdenBusq} ordenBusq={ordenBusq}
                        setBuscar={setBuscar} buscar={buscar} usuarioContxt={usuario}  
                        setArrCabinas={setArrCabinas} setEsperCab={setEsperCab}
                        terminal={terminal} estatus={estatus} setAvTotal={setAvTotal}
                        setAuxCabSelecc={setAuxCabSelecc}
                    />                                           
                </Box>                    
                <Box width="60%"   >                        
                    {contenido2}
                </Box> 
            </Box>                
        </Box>      
    )
}
    const modPersonal = modalPersonal ? <EditarPersonal modalAbierto={modalPersonal} setModalAbierto={setModalPersonal} 
                                            titulo={"Personal de Mantenimiento"}  personal={nomPersonal} actualizar2={actualizar2}
                                            setNomPersonal={setNomPersonal} cabinaSelec={cabinaSelec}  setAuxCabSelecc={setAuxCabSelecc}
                                            arrCabinas={arrCabinas} setArrCabinas={setArrCabinas} setIdCab={setIdCab}
                                        /> :null
    return (
    <div>
        {principal()}
        {modal}
        {modPersonal}
    </div>
    )
}

export default CabinaInicio
