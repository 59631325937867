import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {InputLabel,FormControl,Select as MaterialSelect  ,Box 
  ,TextareaAutosize ,TextField,Checkbox ,RadioGroup,FormControlLabel,Radio,FormLabel
  ,Input,MenuItem,ListItemText
  } from '@material-ui/core';
import  Select   from 'react-select';
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        //width: '20ch',
      }, 
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
      formControl2: {
        margin: theme.spacing(1),        
        minWidth: 120,
        //maxWidth: 120,
      },
      formControl3: {
       // margin: theme.spacing(1),        
        //minWidth: 120,
        maxWidth: 120,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
      Textnumero: {
        width: 5+"px",
      },
      formControlFull: {
        //margin: theme.spacing(1),
        //maxWidth: 120,
        width:5+"rem"
      },
    } 
  }));

const EditOrdenFormDummy = ({nuevaFalla, guardarNuevaFalla,auxContador, setAuxContador}) => {
  let history = useHistory();
  const classes = useStyles();    
  
  const {Usuario ,Periodo ,Lugar ,Terminal ,FchCausa ,Turno ,
    Descripcion ,Solucion ,Causa,Finaliza,Usrfinaliza,Ubicacion,UbicDescr,SeccId,TipoId,Estado} = nuevaFalla;
    
  const [listPeriodo, guardarListPeriodo] = React.useState([])  
  const [listLugar, guardarListLugar] = React.useState([])
  const [listSeccion, guardarListSeccion] = React.useState([])
  const [listLugarTerminal, guardarListLugarTerminal] = React.useState([])
  const [listUbicacion, guardarListUbicacion] = React.useState([])
  const [listTipo, guardarTipo] = React.useState([])
  const [auxUbicacionTipo, guardarAuxUbicacionTipo] = React.useState([])
  const [auxUbicacionSelecc, guardarAuxUbicacionSelecc] = React.useState([])
  const [ubicacionSeleccion, guardarUbicacionSeleccion] = React.useState([])
  let numUbiOtro=234;
  
  React.useEffect(()=>{ 
    const source = axios.CancelToken.source();
          
    async function llenarListPeriodo()  {       
      let auxU=process.env.REACT_APP_LINK +`/orden-periodo`;         
      let token =localStorage.getItem('token20') ;               
      let dataInfo = qs.stringify({                  
          'idDispositivo':'8978yuhjnfrsdsd'
          });
      let config = {
          url:auxU ,
          method: 'POST',
          headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
          data: dataInfo,
          cancelToken: source.token
      };
      let aux ={};           
      await axios(config)
      .then(function (response) {                 
        aux=  response.data;                                   
        if (aux[0].Id!=-1) 
        {                                                                 
          let auxArre=[];   
          aux.map((tipo) => {
            if (tipo.Id!=0) 
            {
              auxArre.push({ value : tipo.Id, label :tipo.Tipo })  
            }            
          })                    
          guardarListPeriodo(auxArre);                                        
        }                                            
      })
      .catch(function (error) {                  
        console.log(`Error: ${error.response.data} mensaje: ${error.response.data.mensaje}`);
        if (error.response.data.error.name==="JsonWebTokenError"||error.response.data.error.name==="TokenExpiredError") 
        {
          console.log('loguearse de nuevo');
          localStorage.clear();                      
          history.push("/");                                      
        }
        if (error.response.data.mensaje.trim()==="Datos Incorrectos") 
        {
          console.log('sin datos' );                      
        }
      });                    
    };        
    async function llenarListLugar()  {       
      let auxU=process.env.REACT_APP_LINK +`/lugar-list-1_1`;         
      let token =localStorage.getItem('token20') ;               
      let dataInfo = qs.stringify({           
          'tipo':'MTTO',              
          'idDispositivo':'8978yuhjnfrsdsd'
          });
      let config = {
          url:auxU ,
          method: 'POST',
          headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
          data: dataInfo,
          cancelToken: source.token
      };
      let aux ={};           
      await axios(config)
      .then(function (response) {
        // console.log("entrando ");
        aux=  response.data;                           
        if (aux[0].Id!=-1) 
        {
          let auxArre=[];   
          aux.map((tipo) => {
            if (tipo.Id!=0) 
            {
              auxArre.push({ value : tipo.Id, label :tipo.Tipo })  
            }            
          })                    
          guardarListLugar(auxArre);                                      
        }                                            
      })
      .catch(function (error) {                          
        console.log(`Error: ${error} `);        
        if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
        {
          console.log('datos incorrectos' );  
        }else
        {
          if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
              ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
          {
            console.log('loguearse de nuevo');
            localStorage.clear();                      
            history.push("/");                              
          } 
        }
      });                      
    };
    async function llenarListTerminal()  {       
      let auxU=process.env.REACT_APP_LINK +`/lugar-terminal-list`;         
      let token =localStorage.getItem('token20') ;         
      let idusu= localStorage.getItem('UsuId'); 
      let dataInfo = qs.stringify({                            
          'usuario':idusu,
          'idDispositivo':'9uccjcsdcsdcsd'
          });
      let config = {
          url:auxU ,
          method: 'POST',
          headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
          data: dataInfo,
          cancelToken: source.token
      };
      let aux ={};           
      await axios(config)
      .then(function (response) {        
        aux=  response.data;                           
        if (aux[0].Id!=-1) 
        {
          let auxArre=[];   
          aux.map((tipo) => {
            if (tipo.Id!=0) 
            {
              auxArre.push({ value : tipo.Id, label :tipo.Descr })  
            }            
          })                    
          guardarListLugarTerminal(auxArre);                                      
        }                                            
      })
      .catch(function (error) {                  
        console.log(`Error: ${error} `);        
        if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
        {
          console.log('datos incorrectos' );  
        }else
        {
          if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
              ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
          {
            console.log('loguearse de nuevo');
            localStorage.clear();                      
            history.push("/");                              
          } 
        }
      });                      
    };    
    async function llenarListTipo()  {       
      let auxU=process.env.REACT_APP_LINK +`/orden-actividad-tipo`;          
      let token =localStorage.getItem('token20') ;         
      let idusu= localStorage.getItem('UsuId'); 
      let dataInfo = qs.stringify({                                            
          'idDispositivo':'9uccjcsdcsdcsd'
          });
      let config = {
          url:auxU ,
          method: 'POST',
          headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
          data: dataInfo,
          cancelToken: source.token
      };
      let aux ={};           
      await axios(config)
      .then(function (response) {        
        aux=  response.data;                           
        if (aux[0].Id!=-1) 
        {
          //console.log(aux);
          let auxArre=[];   
          aux.map((tipo) => {
            if (tipo.Id!=0) 
            {
              auxArre.push({ value : tipo.Id, label :tipo.Tipo })  
            }            
          })                    
          guardarTipo(auxArre);                                      
        }                                            
      })
      .catch(function (error) {                  
        console.log(`Error: ${error} `);        
        if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
        {
          console.log('datos incorrectos' );  
        }else
        {
          if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
              ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
          {
            console.log('loguearse de nuevo');
            localStorage.clear();                      
            history.push("/");                              
          } 
        }
      });                      
    };    
    async function llenarListParoAla()  {       
      let auxU=process.env.REACT_APP_LINK +`/orden-seccion-lista`;         
      let token =localStorage.getItem('token20') ;               
      let dataInfo = qs.stringify({                  
          'idDispositivo':'8978yuhjnfrsdsd'
          });
      let config = {
          url:auxU ,
          method: 'POST',
          headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
          data: dataInfo,
          cancelToken: source.token
      };
      let aux ={};           
      await axios(config)
      .then(function (response) {
        
        aux=  response.data;                           
       // console.log(aux);
        if (aux[0].Id!=-1) 
        {                    
          let auxArre=[];   
          aux.map((tipo) => {
            if (tipo.Id!=0) 
            {
              auxArre.push({ value : tipo.Id, label :tipo.Tipo })  
            }            
          })                    
          guardarListSeccion(auxArre);                              
        }                                            
      })
      .catch(function (error) {                  
        console.log(`Error: ${error} `);        
        if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
        {
          console.log('datos incorrectos' );  
        }else
        {
          if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
              ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
          {
            console.log('loguearse de nuevo');
            localStorage.clear();                      
            history.push("/");                              
          } 
        }
      });                
    };
    function llenarUbicaciones() {
      const auxUbi= JSON.parse(localStorage.getItem('LisUbicMtto'));      
      let auxArre=[];
      let auxArre2=[];
      auxArre2.push('TODO' )  
      auxUbi.map((ubica) => {
        if (ubica.LugarId==Lugar&& (ubica.Terminal.search(Terminal ) !== -1)) 
        {
          auxArre.push({ value : ubica.Id, label :ubica.Tipo })  
          auxArre2.push(ubica.Tipo )  
        }            
      })      
      if (Lugar==5) 
      {
        guardarNuevaFalla({
          ...nuevaFalla,
          Ubicacion :numUbiOtro,
          UbicDescr :(UbicDescr.length!=0?UbicDescr:''),
        }) 
      }
      else
      {
        if (auxContador==1&&UbicDescr.length!=0) 
        {
          let arregloExtraido=[]
          let arregloExtraido2=[]
          let contadorComa=0
         // console.log(UbicDescr);
          for (let index = 0; index < UbicDescr.length; index++) 
          {
            if (UbicDescr.charAt(index)!=','&&UbicDescr.charAt(index)!=' ') 
            {
              contadorComa++;              
            }
            else
            {
              if (UbicDescr.charAt(index)==',') 
              {
                let auxNumero=""
                for (let auxIndice = contadorComa; auxIndice > 0; auxIndice--) 
                {                  
                  auxNumero=auxNumero+UbicDescr.charAt(index-auxIndice)                  
                }                
                let auxFiltra1 = auxArre.filter(  (registro) => registro.label == auxNumero  )    
                arregloExtraido.push(auxFiltra1)                
                arregloExtraido2.push(auxNumero)  
                contadorComa=0
              }              
            }            
          }
          if (contadorComa>0) 
          {
            contadorComa++
            let auxNumero=""
            for (let auxIndice = contadorComa; auxIndice > 0; auxIndice--) 
            {                  
              if (UbicDescr.charAt(UbicDescr.length-auxIndice)!=' ') 
              {
                auxNumero=auxNumero+UbicDescr.charAt(UbicDescr.length-auxIndice)  
              }                                                  
            }            
            let auxFiltra1 = auxArre.filter(  (registro) => registro.label == auxNumero  )    
            arregloExtraido.push(auxFiltra1)            
            arregloExtraido2.push(auxNumero)  
            contadorComa=0
          }
          //console.log(UbicDescr);
          //console.log(arregloExtraido);
         // console.log(arregloExtraido2);
          guardarUbicacionSeleccion(arregloExtraido)   
          if (arregloExtraido.length==auxArre.length) 
          {
            let tod=[]
            tod.push('TODO')
            arregloExtraido2.map((ubica) => {
                              
              tod.push(ubica)  
                          
            })    
            
            guardarAuxUbicacionSelecc(tod);
          } 
          else 
          {
            guardarAuxUbicacionSelecc(arregloExtraido2);  
          }         
          guardarNuevaFalla({
            ...nuevaFalla,            
            UbicDescr :UbicDescr+', ',
          })  
        } 
        else 
        {
          guardarNuevaFalla({
            ...nuevaFalla,
            Ubicacion :0,
            UbicDescr :'',
          })           
          guardarUbicacionSeleccion([])      
          guardarAuxUbicacionSelecc([]);
        }
        
      }
      setAuxContador(auxContador+1)
      guardarListUbicacion(auxArre)
      guardarAuxUbicacionTipo(auxArre2)
    }
    if (listLugar.length==0) 
    {
      llenarListLugar()  
    }
    if (listTipo.length==0) 
    {
      llenarListTipo()  
    }
   
    if (listSeccion.length==0) 
    {
      llenarListParoAla()  
    }
    if (listLugarTerminal.length==0) 
    {
      llenarListTerminal();  
    }
    
    if (Lugar!='' &&Terminal!='')  
    {
      llenarUbicaciones()
    }
        
    return ()=> {
        source.cancel();
    }              
          
    
  },[Terminal,Lugar]);
      
  const onChange = e =>
  {
    guardarNuevaFalla({
      ...nuevaFalla,
      [e.target.name] : e.target.value  
    })
  };
  const cambioActualiza = (event) => {
    guardarNuevaFalla({
       ...nuevaFalla, 
        [event.target.name]: event.target.checked 
      });
  };
  const guardaTurno = e =>
    { 
      guardarNuevaFalla({
        ...nuevaFalla,
        Turno : e.target.value  
      })        
    };
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    };    
    function getStyles(name, personName, theme) {
      return {
        fontWeight:
          personName.indexOf(name) === -1
            ? theme.typography.fontWeightRegular
            : theme.typography.fontWeightMedium,
      };
    }        
    const filterArray = (arr1, arr2) => {
      const filtered = arr1.filter(el => {
          return arr2.indexOf(el) === -1;
      });
      return filtered;
    };

    const seleccionarUbicacion = (e) => {
      let resultados=[];
      let auxArreglo=[];      
     // console.log(e.target.value);
      if (e.target.value.length>1) 
      {
        let ubicNueva=filterArray(auxUbicacionSelecc,e.target.value)                       
        if (ubicNueva.length!=0) 
        { 
          if (ubicNueva=='TODO') 
          {          
            auxArreglo=[]                   
          }
          else
          {
            resultados= listUbicacion.filter(  (registro) => registro.label == ubicNueva  )
            if(ubicacionSeleccion.length>1) 
            { let arrFiltrado = ubicacionSeleccion.filter(  (registro)=>{                                        
                return(registro.value != resultados[0].value)})              
              auxArreglo=arrFiltrado;                                  
            } 
            else 
            {               
              auxArreglo.push({value:resultados[0].value ,label:resultados[0].label})
            } 
          }
        }
        else
        {
          if (e.target.value[e.target.value.length-1] =='TODO') 
          {          
            auxArreglo=listUbicacion           
          }
          else
          {
            resultados= listUbicacion.filter(  (registro) => registro.label == e.target.value[e.target.value.length-1]  )
            auxArreglo=ubicacionSeleccion            
            auxArreglo.push({value:resultados[0].value ,label:resultados[0].label})
          }
        }        
      }
      else
      { if (e.target.value[0] =='TODO') 
        {          
          auxArreglo=listUbicacion                 
        }
        else
        {
          resultados= listUbicacion.filter(  (registro) => registro.label == e.target.value  )                  
          if (resultados.length!=0) 
          {
            auxArreglo.push({value:resultados[0].value ,label:resultados[0].label})
          }else
          {
            auxArreglo= []
          }            
        }
      }    
      guardarUbicacionSeleccion(auxArreglo)
      //console.log(auxArreglo);      
      if (auxArreglo.length!=0) 
      { if (auxArreglo[0].length!=0) 
        {
          let nomUbiID='';
          auxArreglo.map((ubicacion)=>{
            nomUbiID=nomUbiID+ubicacion.value+','+' '
          })
          let nomUbi='';
          if (e.target.value[e.target.value.length-1] =='TODO') 
          {          
            auxArreglo=listUbicacion    
            auxArreglo.map((ubicacion)=>{
              nomUbi=nomUbi+ubicacion.label+','+' '
            })       
          }
          else
          {            
            e.target.value.map((ubicacion)=>{
              if (ubicacion!='TODO') 
              {
                nomUbi=nomUbi+ubicacion+','  +' '
              }              
            })            
          }
          guardarNuevaFalla({
            ...nuevaFalla,           
            Ubicacion :0,
            UbicDescr: nomUbi    ,
          })  
        } 
        else 
        {
          guardarNuevaFalla({
            ...nuevaFalla,
            Ubicacion :'',
            UbicDescr: ''    ,
          })          
        }        
      }
      else
      {
        guardarNuevaFalla({
          ...nuevaFalla,
          Ubicacion :'',
          UbicDescr: ''    ,
        })  
      }      
      if (e.target.value[e.target.value.length-1] =='TODO') 
      { 
        if (auxArreglo.length!=0) 
        {
          guardarAuxUbicacionSelecc(auxUbicacionTipo)  
        } 
        else 
        {
          guardarAuxUbicacionSelecc([])  
        }                  
      }
      else
      {
        let ubicNueva=filterArray(auxUbicacionSelecc,e.target.value)                
        if (ubicNueva[0]=='TODO') 
        {
          guardarAuxUbicacionSelecc([])  
        } 
        else 
        {
          guardarAuxUbicacionSelecc(e.target.value);  
        }                 
      }            
    }
    const guardaUbicacion=(e) =>{                   
      const resultados= listUbicacion.filter(  (registro) => registro.value == e.target.value  )
      console.log(resultados);
      if (resultados.length!=0) 
      {
        guardarNuevaFalla({
          ...nuevaFalla,
          Ubicacion :e.target.value  ,
          UbicDescr :resultados[0].label  ,        
        })  
      } 
      else 
      {
        guardarNuevaFalla({
          ...nuevaFalla,
          Ubicacion :e.target.value  ,
          UbicDescr :''  ,        
        })  
      }
      
    }
    const guardaUbicacionInput = e =>
    {  
      guardarNuevaFalla({
        ...nuevaFalla,
        Ubicacion :numUbiOtro,
        UbicDescr :e.target.value    ,
      })
    } 
    const guardaEstado = e =>
    {  
      guardarNuevaFalla({
        ...nuevaFalla,
        Estado : e.target.value  ,
        Finaliza:false
      })        
    };
  return ( 
  <form className={classes.root} noValidate autoComplete="off" fullWidth>                   
  <FormControl component="fieldset" style={{width:30+'rem'}}>      
      
      {/* <FormLabel component="legend">Turno</FormLabel>         */}
      <RadioGroup  row aria-label="position" //name="estatus"
        defaultValue="top"   name="Estado" value={Estado} onChange={guardaEstado}
      >
        <FormControlLabel value="0" style={{color:'#F46239'}} control={<Radio disabled color="primary" />} label="Registrado" />    
        <FormControlLabel value="1"  style={{color:'#049b4f'}} control={<Radio  disabled color="primary" />} label="Solucionado" />
        <FormControlLabel value="-1" style={{color:'red'}} control={<Radio disabled color="primary" />} label="Cancelado" />            
      </RadioGroup>                     
    </FormControl>  
    <br/> 
    {Estado=='-1' ?<span><TextField 
    size="small"
      id="causa-cancelacion-input"
      label="Causa de Cancelación"      
      disabled
      defaultValue=""
      variant="outlined"
      value={Causa} onChange={onChange} 
      style={{width:50+'rem'}}
      name="Causa"
    />
    <br/></span>
    :null}
    
    {listLugarTerminal.length!=0 ?<FormControl className={classes.formControl} size="small" >
      <InputLabel htmlFor="age-native-simple">Terminal</InputLabel>
      <MaterialSelect
        native disabled value={Terminal}        
        inputProps={{
            name: 'Terminal',
            id: 'Terminal',
        }}
      >
        <option aria-label="None" value="" />                     
        {listLugarTerminal.map((row) => (
            <option value={row.value}>{row.label}</option>
          ))}   
      </MaterialSelect>
    </FormControl>     
    :null}   
    
    {listTipo.length!=0 ?
      <FormControl className={classes.formControl} size="small">
        <InputLabel htmlFor="Periodo">Tipo</InputLabel>
        <MaterialSelect
          native disabled value={TipoId}        
          inputProps={{
              name: 'TipoId',
              id: 'TipoId',
          }}
        >                    
          <option aria-label="None" value="" />
          { listTipo.map((row) => (
            <option value={row.value}>{row.label}</option>
          ))}                   
        </MaterialSelect>
      </FormControl>  :null
    }  
    { listSeccion.length!=0 ?  
      <FormControl className={classes.formControl} size="small" style={{width:4.8+'rem'}}>
        <InputLabel htmlFor="Seccion-Input">Sección</InputLabel>
        <MaterialSelect native disabled
          value={SeccId} 
          inputProps={{
              name: 'SeccId',
              id: 'SeccId',
          }}
        >                    
          <option aria-label="None" value="" />
          { listSeccion.map((row) => (
            <option value={row.value}>{row.label}</option>
          ))}                   
        </MaterialSelect>
      </FormControl>           
    :null} 
    {listLugar.length!=0 ?
      <FormControl className={classes.formControl} size="small">
        <InputLabel htmlFor="age-native-simple">Lugar</InputLabel>
        <MaterialSelect
          native disabled
          value={Lugar}         
          inputProps={{
              name: 'Lugar',
              id: 'Lugar',
          }}
        >
          <option aria-label="None" value="" />               
          {listLugar.map((row) => (
              <option value={row.value}>{row.label}</option>
          ))}   
        </MaterialSelect>
      </FormControl>:null
    }
     {listUbicacion.length!=0 ?  
      ( Lugar!=5 ?  
        <FormControl className={classes.formControl2} style={{width:6+'rem'}} size="small">
          <InputLabel id="input-label-ubic">Ubicación</InputLabel>
          <MaterialSelect
            labelId="mutiple-checkbox-label-ubic"
            id="mutiple-checkbox-ubic"
            multiple disabled
            value={auxUbicacionSelecc}       
            input={<Input />}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={MenuProps} 
          >
            {auxUbicacionTipo.map((ubic) =>
            {            
              return(
                <MenuItem key={ubic} value={ubic}  >
                  <Checkbox checked={auxUbicacionSelecc.indexOf(ubic) > -1} />               
                  <ListItemText primary={ubic} />
                </MenuItem>
              )
            })}
          </MaterialSelect>
        </FormControl>:  
        <TextField id="outlinedUbicacion" label="Ubicación"  size="small"
          value={UbicDescr} disabled name="UbicDescr" style={{width:10+'rem'}}                                               
        />  
      )
    :null}           
    <br/>                  
    <FormControl component="fieldset">                  
      <RadioGroup  row aria-label="position" 
            defaultValue="top"   name="Turno" value={Turno} onChange={guardaTurno}
      >
        <FormControlLabel value="N" disabled control={<Radio color="primary" />} label="T.Nocturno" />    
        <FormControlLabel value="D" disabled control={<Radio color="primary" />} label="T.Diurno" />            
      </RadioGroup>                     
    </FormControl>           
    <br/>

      <textarea id="descripcion-input" name="Descripcion" rows="5"
      cols="100" placeholder="Descripción" style={{width: 50+"rem",borderRadius:3+'px'}} 
      value={Descripcion} disabled
      />
  <br/>
    
    {Estado == '1' ?<FormControlLabel
        control={<Checkbox checked={Finaliza} disabled name="Finaliza" />}
        label="Finaliza"
    />:null}
    <br/>
    {Finaliza&&Estado == '1' ?<span> 
    <textarea id="solucion-input" name="Solucion" rows="5"
      cols="100" placeholder="Solución" style={{width: 50+"rem",borderRadius:3+'px'}} 
      value={Solucion} disabled
      />
    </span>
    :null}       
  </form>
  );
}
 
export default EditOrdenFormDummy;