import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {Paper,Table, TableBody, TableCell
       ,TableHead,TableRow,TableContainer,IconButton} from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import NumberFormat from "react-number-format";
import Editar from './EditarConsumo';
import EditarConsumoDummy from './EditarConsumoDummy'
import moment from "moment";
const useStyles = makeStyles((theme) => ({
    root: {
        display: "block",
        width: '630px',
      },
    table: {
      maxWidth: '630',
    },
    tableRow: {
        backgroundColor: "#F46239",
        maxWidth: '630px',
    },
    alturaTabla:{
      minHeight: 280,
    },
  
    tableCellFecha: {
     // maxWidth: '10px',
     // borderColor: '#F46239' 
    },
    tableCellFechaSeleccionada: {
      color:'white',
    //  maxWidth: '10px',
    },
  
    tableCellTerminal: {
      maxWidth: '65px',
     // borderColor: '#F46239' 
    },
  
    tableCellTerminalSeleccionada: {
      color:'white',
       maxWidth: '65px',
    },

    tableCellMedidor: {
      maxWidth: '40px',
     // borderColor: '#F46239' 
    },
  
    tableCellMedidorSeleccionado: {
      maxWidth: '40px',
      color:'white',
     // borderColor: '#F46239' 
    },
  
    tableCellHeader: {
      witdh: 'auto',
      backgroundColor: '#F46239',
      color:'white'
    },
  
    TableCell: {
     // witdh: 'auto',
     //borderColor: '#F46239'     
    },
  
    tableCellSeleccionada: {
      color:'white',
     // witdh: 'auto',
      
    },
  }));

export default function TablaEnergia({listaEnergia,listaTerminales, actualizarDatos,usuarioContext}) {
    let nvlSNPermiso ='5'
    const [idBotonEditar, setIdBotonEditar] = useState(0);
    const [modalEditarAbierto, setModalEditarAbierto] = useState(false);
    const [modalTitulo, setModalTitulo] = useState("");
    const [energia, setEnergia] = useState(null);
    const [alturaTabla, setAlturaTabla] = useState(window.innerHeight<1300 ? window.innerHeight-230 : window.innerHeight-80)
    const modEditar = modalEditarAbierto ? ( usuarioContext?.nivel !=undefined && usuarioContext.nivel!== nvlSNPermiso ? 
                                            <Editar modalAbierto={modalEditarAbierto}  setModalAbierto={setModalEditarAbierto} 
                                            titulo={modalTitulo}  energia={energia} actualizarDatosTabla={actualizarDatos}
                                            terminales={listaTerminales}/>
                                            : <EditarConsumoDummy modalAbierto={modalEditarAbierto}  setModalAbierto={setModalEditarAbierto} 
                                            titulo={modalTitulo}  energia={energia}
                                            terminales={listaTerminales}/>):null;
    const classes = useStyles();
    const columnas=[{ id:1, titulo:"Fecha"},
      { id:2, titulo:"Terminal"},
      { id:3, titulo:"Medidor" },
      { id:4, titulo:"Consumo KWS"},
      { id:5, titulo:"Costo" },
      { id:6, titulo:"Precio" },
    ];

    useEffect(()=>{
      window.addEventListener('resize', function() {
        if(window.innerHeight<1300){
          setAlturaTabla(window.innerHeight-250)
        }else{
          setAlturaTabla(window.innerHeight-80)
        }
      });
    },[])

    function abrirModalEdit(row) {
      setModalEditarAbierto(!modalEditarAbierto);
      let date = new Date();
      setModalTitulo("Editar Registro " + 
      moment.utc(date).local(true).format("DD/MM/YYYY"));
      setEnergia(row);
    }
  
    function handleClickEditar(row) {
      if (row.Id !== idBotonEditar) {
        setIdBotonEditar(row.Id);
      }
    }

    const tablaSinResultados =()=>{
      return(
        <TableRow>
          <TableCell align="center" padding={"none"} style={{color: '#F46239',width:600+"px",fontWeight:600}} className={classes.TableCell}>
            Sin Resultados
          </TableCell>                
        </TableRow>
      )
    }

    return (
    <Paper className={classes.root}>
      <TableContainer className={classes.alturaTabla} style={{height:alturaTabla}}>
      <Table className={classes.table} size="small" aria-label="resultados" stickyHeader>
        <TableHead>
          <TableRow>
          <TableCell key={8} padding={"none"} align="center" className={classes.tableCellHeader}></TableCell>
          {
            columnas.map((row) => (
              <TableCell key={row.id} padding={"none"} align="center" className={classes.tableCellHeader}>{row.titulo}</TableCell>
            ))
          }            
          </TableRow>
        </TableHead>
        <TableBody>            
          { listaEnergia.length>0 ? listaEnergia.map((row) => (
            <TableRow
              key={row.Id} onClick={() => handleClickEditar(row)}
              className={ row.Id === idBotonEditar ? classes.tableRow : ""}
            >
              <TableCell align="center" padding={"none"} style={{width:30+"px"}} className={classes.TableCell}>
                <IconButton size="small" component={"span"} onClick={row.Id === idBotonEditar ? () => abrirModalEdit(row) : null}>
                  {row.Id === idBotonEditar ? <EditIcon style={{color: "white"}} /> : null}
                </IconButton>
              </TableCell>
              <TableCell align="center" padding={"none"} style={{width:50+'px',fontSize:"12px"}} className={ row.Id === idBotonEditar ? classes.tableCellFechaSeleccionada : classes.tableCellFecha}>
                {moment.utc(row.Fecha).format("DD/MM/YYYY")}
              </TableCell>
              <TableCell align="center" padding={"none"} style={{width:75+'px',fontSize:"12px"}} className={ row.Id === idBotonEditar ? classes.tableCellTerminalSeleccionada : classes.tableCellTerminal}>
                {row.TerminalNom}
              </TableCell>
              <TableCell align="center" padding={"none"} style={{fontSize:"12px",width:"90px"}} className={ row.Id === idBotonEditar ? classes.tableCellMedidorSeleccionado : classes.tableCellMedidor}>
                {row.NumMedidor}
              </TableCell>
              <TableCell align="center" padding={"none"} style={{width:100+'px'}}  className={ row.Id === idBotonEditar ? classes.tableCellSeleccionada : classes.TableCell}>
                <NumberFormat
                  thousandSeparator={true} displayType="text" value={row.Consumo}//
                />
              </TableCell>
              <TableCell align="center" padding={"none"} style={{width:130+'px'}} className={ row.Id === idBotonEditar ? classes.tableCellSeleccionada : classes.TableCell}>
                <NumberFormat
                  thousandSeparator={true} prefix={"$"} displayType="text" value={row.PrecioPromedio}//
                />
              </TableCell>
              <TableCell align="center" padding={"none"} style={{width:130+'px'}} className={ row.Id === idBotonEditar ? classes.tableCellSeleccionada : classes.TableCell}>
                <NumberFormat decimalScale={4}
                  thousandSeparator={true} prefix={"$"} displayType="text" value={row.Consumo / row.PrecioPromedio}//
                />
              </TableCell>                
            </TableRow>
            )):tablaSinResultados()}
          </TableBody>
        </Table>
      </TableContainer>
      {modEditar}
    </Paper>
    )
}

