import React,{useState} from 'react';
import { Bar } from 'react-chartjs-2';
import { Box,Card} from '@material-ui/core'
import moment from 'moment';
let arreColores=["#038545", "#EF8012", "#347AF4", "#FFC107", "#119A4F", "#B406D8", "#FFA318", "#DC4A3D", "#32CA81", 
                    "#F9B546", "#ED0808", "#0097A7", "#880E4F", "#4BAC7D", "#C52363", "#F57F06", "#7C594C", "#DBE772"]
const Grafica = ({listDispo,opcion}) => {
    const [arrGrafica1, setArrGrafica1] = useState([]) 
    const [nomHorario, setNomHorario] = useState([]) 
    const [auxColores, setAuxColores] = useState([]) 
    const [dataGraf, setDataGraf] = useState([]) 
    const DATA_COUNT = 12;
    const labels = [];
    for (let i = 0; i < DATA_COUNT; ++i) {
        labels.push(i.toString());
    }
    //const datapoints = [0, 20, 20, 60, 60, 120, NaN, 180, 120, 125, 105, 110, 170];
    const colores = opcion === "dispo" ? ["#5DE163" ] : ["#FFC400","#E91E63"] 
    const camposListas = opcion === "dispo" ? ['Disponible' ] :['Exento', 'Cobro']

    React.useEffect(()=>{                 
        
        let auxArreglo= opcion === "dispo" ? [[0]] : [[0], [0]];        
        //let auxArreglo = [[0], [0]];                
        let auxNomHora = [], contador = 0;
        //console.log(listDispo);
        if (listDispo.length>1) 
        {
            listDispo.forEach((lectura) =>
            {                         
                if (opcion === "dispo") 
                {   auxArreglo[0][contador]=lectura.disponible                
                    //auxArreglo[1][contador]=lectura.paros    
                }
                else
                {   auxArreglo[0][contador]=lectura.exento
                    auxArreglo[1][contador]=lectura.cobro    
                }             
                
                const fech= lectura.dia// moment(lectura.fecha).format("DD")      
                auxNomHora.push(fech)                               
                contador++                
            })                        
        }
        else
        {   const fech=listDispo[0].dia// moment(listDispo.fecha).format("DD")      
            if (opcion === "dispo") 
            {   auxArreglo[0][contador]=listDispo[0].disponible                
               // auxArreglo[1][contador]=listDispo[0].paros
            }
            else
            {   auxArreglo[0][contador]=listDispo[0].exento
                auxArreglo[1][contador]=listDispo[0].cobro    
            }
            //auxArreglo= [[listDispo.disponible],[listDispo.exento], [listDispo.cobro]];        
            auxNomHora=[fech]
        }

        //console.log(auxArreglo);
        const auxNumSecc = listDispo.length        
        let auxArregloColor=[] 
        for (let index = 0; index < auxNumSecc; index++) 
        {   const nivel= (index % 18 === 0) ? (index / 18===1 ? 0 :17): (index % 18)
            auxArregloColor[index] = arreColores[nivel]          
        }         
        //setAuxColores(auxArregloColor)        
        
        setNomHorario(auxNomHora);
        setArrGrafica1(auxArreglo);
        const res=generaData(auxArreglo)
        setDataGraf(res)
    },[listDispo,opcion]);

    const generaData=(auxArreglo)=>
    {   let arreData=[]
        for (let index = 0; index < colores.length; index++) 
        {
            arreData.push(
            {   type: 'line',
                label:camposListas[index],
                data: auxArreglo[index],
                backgroundColor: colores[index],
                borderColor: colores[index],
                fill: false                             
                ,tension: 0.4
            })
        }
        //console.log(arreData);
        return(arreData)
    }

    const data = {
        labels:nomHorario,
        /* datasets: [
            {type: 'line',
            label: camposListas[0],
            data: arrGrafica1[0],
            backgroundColor: colores[0],
            fill: false,
            cubicInterpolationMode: 'monotone',
            tension: 0.4
            }, 
            {type: 'line',
            label: camposListas[1],
            data: arrGrafica1[1],
            backgroundColor: colores[1],
            fill: false,
            tension: 0.4
            }
        ] */
        datasets:dataGraf
        };
   
    const options={
        animation: false,
        indexAxis: 'y',
        responsive: true,
        plugins: {
          title: {
            display: false,
            text: 'Lecturas '
          },
        },
        interaction: {
          intersect: false,
        },
        scales: {
          x: {
            display: true,
            title: {
              display: true
            },
            ticks: {
                beginAtZero:true
            }
          },
         /*   y: {
            display: true,
            title: {
              display: false,
              text: 'Value'
            },
            //suggestedMin: -10,
           // suggestedMax: 200
          } */
        }, 
        legend: {
            display: false,
        },
        tooltips: {
            enabled: true,
        },
    
    }

    return (
    <Box  pt={1}   style={{width:280}} >    
        {arrGrafica1.length!==0 && nomHorario.length!==0 && dataGraf!==0 ?
            <Bar data={data}  options={options}
                width={280} height={ window.innerHeight-170}
            />
            :null
        }                    
    </Box>  
    );
}
 
export default Grafica;