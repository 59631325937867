import React from "react";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import esLocale from "date-fns/locale/es";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import { Box } from "@material-ui/core";
import { findNumber } from "../funciones/FindNumber";

const Relojes2 = ({ finicio, fFinal, setFFinal, setFinicio }) => {
  //const [selectedDate, handleDateChange] = React.useState(new Date());
  const guardarInicio = (auxfecha) => {
    let auxiFecha = moment(auxfecha).format("YYYY/MM/DD ");
    setFinicio(auxiFecha);
  };
  const guardarFinal = (auxfecha) => {
    let auxiFecha = moment(auxfecha).format("YYYY/MM/DD ");
    setFFinal(auxiFecha);
  };

  return (
    <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
      <Box>
        <DatePicker
          disabled={findNumber("|7|") ? false : true}
          disableFuture
          size="small"
          style={{ width: 6.5 + "rem" }}
          //openTo="year"
          format="dd/MM/yyyy"
          label="Fecha inicial"
          views={["year", "month", "date"]}
          value={finicio}
          onChange={guardarInicio}
          minDate="2017-03-14"
          maxDate={new Date()}
        />
        <DatePicker
          disabled={findNumber("|7|") ? false : true}
          disableFuture
          id="final"
          size="small"
          style={{
            width: 6.5 + "rem",
            marginLeft: 1.7 + "rem",
            marginRight: 1.7 + "rem",
          }}
          //openTo="month"
          format="dd/MM/yyyy"
          label="Fecha Final"
          views={["year", "month", "date"]}
          value={fFinal}
          name="final"
          onChange={guardarFinal}
          minDate={finicio}
          maxDate={new Date()}
        />
      </Box>
    </MuiPickersUtilsProvider>
  );
};

export default Relojes2;
