import React from 'react';
import {Button,Dialog,DialogActions,DialogContent,DialogContentText
        ,DialogTitle,IconButton,CircularProgress} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import Alert from '@material-ui/lab/Alert';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    //margin: theme.spacing(0),
    marginTop: 0,
    paddingTop:0,
    position: 'relative',
  },
 
  buttonGuardar:{color: green[500],},
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function Modal({titulo,children,modalAbierto,setModalAbierto,guardarDatos,mensaje,tipoAdvertencia,aviso, setAviso,espera,setEspera}) {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');  

  const cerrarBorrar = () => 
  { setOpen(false);
    setAviso(false);
    setModalAbierto(false)
  };

  const handleClose = () => 
  { setOpen(false);
    setModalAbierto(false)
  };

  const handleCloseSN = () => 
  { setAviso(false);    
  };
  
  const classes = useStyles();
  
  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (modalAbierto) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }    
  }, [modalAbierto]);
  
  return (
  <div>         
    <Dialog
      open={modalAbierto} onClose={handleClose}
      scroll={scroll} aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth={true} maxWidth="md"
    >                
      <DialogTitle style={{color:'#F46239'}} id="scroll-dialog-title" align="center" >{titulo}</DialogTitle>            
      <DialogContent dividers={scroll === 'paper'} >        
        { aviso&&tipoAdvertencia &&mensaje.length!=0 ? 
          <Alert severity={tipoAdvertencia}  onClose={handleCloseSN}>{mensaje}</Alert>:<span></span>}
        {children}        
      </DialogContent>
    
      <DialogActions>
        <Button onClick={cerrarBorrar} disabled={espera} color="secondary" variant="outlined">
          Cerrar
        </Button>
        <div className={classes.wrapper}>
          <Button
            variant="outlined" className={classes.buttonGuardar}
            disabled={espera} onClick={guardarDatos}
          >
            Guardar
          </Button>
          {espera && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>                  
      </DialogActions>      
    </Dialog>    
  </div>
  );
}
