import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {InputLabel,FormControl,Select as MaterialSelect  ,Box 
  ,LinearProgress ,TextField,RadioGroup,FormControlLabel,Radio,FormLabel//,Typography
  } from '@material-ui/core';
import axios from 'axios';
import qs from 'qs';
import CabinaContainerBlock from './CabinaContainerBlock';
import { useHistory } from "react-router-dom";
import Modal from '../Generales/ModalDummy';
//import moment from 'moment';
const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '20ch',
      }, 
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
      Textnumero: {
        width: 5+"px",
      },
      formControlFull: {
        //margin: theme.spacing(1),
        //minWidth: 120,
        width:5+"rem"
      },
      margin: {
        margin: theme.spacing(1),
      },
    },
  }));

const EditarMtto = ({modalAbierto,setModalAbierto,titulo,lecturaSelect,auxListCabs}) => {
  let history = useHistory();
  const classes = useStyles();
  const [espera,setEspera]=React.useState(false);
  const [mensaje, setMensaje] = React.useState('');
  const [tipoAdvertencia, setTipoAdvertencia] = React.useState('success');
  const [arregloFiltrado, setArregloFiltrado] = useState([])
  const [aviso, setAviso] = React.useState(false);
  const [cabinaSelec, setCabinaSelec] = React.useState([]);
  //const [datosAfectacion, setDatosAfectacion] = React.useState([]);  
  const[nvoMtto,setNvoMtto]=React.useState({Terminal:0,Tipo:0,Auxiliar:"",Tecnico:"",Estado:'0' , Descripcion:"",
                                            Horometro:0,Observ:"",Cabinas:"",RefManual:"",MttoID:0})

  const{Terminal ,Tipo ,Descripcion ,Horometro,Observ ,Estado,Cabinas,RefManual,Auxiliar,Tecnico,MttoID}=nvoMtto                                                                
  const [listLugarTerminal, setListLugarTerminal] = React.useState([])
  //const [cabMtto, setCabMtto] = React.useState([])
  const [listTipo, setListTipo] = React.useState([])
  const[listStatus,setListStatus]= React.useState([])
  const[listCabs,setListCabs]= React.useState([])
  const[arrCabSelect,setArrCabSelect]= React.useState([])
  //const [cabinaObjLista, setCabinaObjLista] = useState([])
  //const[arrFiltrado,setArrFiltrado]= React.useState([])
  React.useEffect(()=>{ 
    //console.log(lecturaSelect);
    setNvoMtto({...nvoMtto,Descripcion:lecturaSelect.Descr ,Horometro:lecturaSelect.Horometro ,
      MttoID:lecturaSelect.Id ,Observ:lecturaSelect.Observ ,Estado:lecturaSelect.stId.toString(),
      Terminal:lecturaSelect.termId,Tipo:lecturaSelect.tpId
    })
   const source = axios.CancelToken.source();      
   let auxTerminal= localStorage.getItem('UsuTerminal');  
    async function llenarListStatus()  {           
    setEspera(true)       
    let auxU=process.env.REACT_APP_LINK +`/cabina-mtto-status`;          
    let token =localStorage.getItem('token20') ;                
    let dataInfo = qs.stringify({                                        
        'idDispositivo':'8978yuhjnfrsdsd'
        });
    let config = {
        url:auxU ,
        method: 'POST',
        headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
        data: dataInfo,
        cancelToken: source.token
    };
    let aux ={};           
    await axios(config)
    .then(function (response) {         
        aux=  response.data;                 
        if (aux[0].Id != -1) 
        { //guardarSinDatos(true)                                            
          setListStatus(aux)
        }else{
        // guardarSinDatos(false)
        }                                            
    })
    .catch(function (error) {          
      console.log(`Error: ${error}`);         
      if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
      {
        console.log('datos incorrectos' );  
      }else
      {
        if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
            ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
        {
          console.log('loguearse de nuevo');
          localStorage.clear();                      
          history.push("/");                              
        } 
      }
    });                
    setEspera(false)     
    };
  
    async function llenarListTerminal()  {       
      let auxU=process.env.REACT_APP_LINK +`/lugar-terminal-list`;       
      let token =localStorage.getItem('token20') ;         
      let idusu= localStorage.getItem('UsuId'); 
      let dataInfo = qs.stringify({                            
          'usuario':idusu,
          'idDispositivo':'9uccjcsdcsdcsd'
          });
      let config = {
          url:auxU ,
          method: 'POST',
          headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
          data: dataInfo,
          cancelToken: source.token
      };
      let aux ={};           
      await axios(config)
      .then(function (response) {      
        aux=  response.data;                           
        if (aux[0].Id!=-1) 
        {
          let auxArre=[];   
          aux.map((tipo) => {
            if (tipo.Id!=0) 
            { auxArre.push({ value : tipo.Id, label :tipo.Descr })  
            }            
          })                    
          setListLugarTerminal(auxArre);                                      
        }                                            
      })
      .catch(function (error) {                  
        console.log(`Error: ${error}`);      
        if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
        {
          console.log('datos incorrectos' );  
        }else
        {
          if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
              ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
          {
            console.log('loguearse de nuevo');
            localStorage.clear();                      
            history.push("/");                              
          } 
        }
      });                      
    };

    async function llenarListCabs()  {       
      let auxU=process.env.REACT_APP_LINK +`/cabina-list`;       
      let token =localStorage.getItem('token20') ;         
      let idusu= localStorage.getItem('UsuId'); 
      let dataInfo = qs.stringify({                            
          'usuario':idusu,
          'idDispositivo':'9uccjcsdcsdcsd'
          });
      let config = {
          url:auxU ,
          method: 'POST',
          headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
          data: dataInfo,
          cancelToken: source.token
      };
      let aux ={};           
      await axios(config)
      .then(function (response) {      
        aux=  response.data;                         
        if (aux[0].Id!=-1) 
        {                    
          setListCabs(aux);     
          //console.log(aux)                    
          var au1x = aux.filter(function (val) {
            return val.TermId === parseInt(auxTerminal);
          });        
          au1x.sort(function (a, b) { return a.Id - b.Id; });              
          setArregloFiltrado(au1x)       
        }                                            
      })
      .catch(function (error) {                  
        console.log(`Error: ${error}`);      
        if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
        {
          console.log('datos incorrectos' );  
        }else
        {
          if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
              ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
          {
            console.log('loguearse de nuevo');
            localStorage.clear();                      
            history.push("/");                              
          } 
        }
      });                      
    };

    
    async function llenarListTipo()  {       
      let auxU=process.env.REACT_APP_LINK +`/cabina-mtto-tipo`;        
      let token =localStorage.getItem('token20') ;             
      let dataInfo = qs.stringify({                                    
          'idDispositivo':'9uccjcsdcsdcsd'
          });
      let config = {
          url:auxU ,
          method: 'POST',
          headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
          data: dataInfo,
          cancelToken: source.token
      };
      let aux ={};           
      await axios(config)
      .then(function (response) {   
        aux=  response.data;                           
        if (aux[0].Id!=-1) 
        {
          let auxArre=[];   
          aux.map((tipo) => {
            if (tipo.Id!=0) 
            {
              auxArre.push({ value : tipo.Id, label :tipo.Descr })  
            }            
          })                    
          setListTipo(auxArre);                                      
        }                                            
      })
      .catch(function (error) {                  
        console.log(`Error: ${error}`); 
        if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
        {
          console.log('datos incorrectos' );  
        }else
        {
          if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
              ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
          {
            console.log('loguearse de nuevo');
            localStorage.clear();                      
            history.push("/");                              
          } 
        }
      });                      
    };
   
    if (listTipo.length === 0) 
    {
      llenarListTipo()
    }
    if (listLugarTerminal.length === 0) 
    {
      llenarListTerminal();  
    }
    if (listCabs.length === 0) 
    {
      llenarListCabs();  
    }
    if (listStatus.length === 0) 
    {
      llenarListStatus();    
    }  

   /*  if (Terminal===0) {    
      setNvoMtto({
        ...nvoMtto, Terminal : auxTerminal  })  
    }     */
  


    return ()=> {
      source.cancel();
    }     
      
  },[]);

   

  const onChangeRef = e =>
  { let expNopermitida = new RegExp('[$!?]');
    let expMenos = new RegExp('-');
    let expMas = new RegExp('[+]');     
    let expCadena = new RegExp('[A-Za-z0-9#_:%-({})/*=|]');
    if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) ) 
        || e.target.value===' ' || e.target.value==='') 
    {    
      setNvoMtto({
        ...nvoMtto, [e.target.name] : e.target.value  })
      if (aviso) 
      { setMensaje('');
        setAviso(false);
      }
    }
  };

  const onChange = e =>
  { let expNopermitida = new RegExp('[#_:$!%-({})/*=?|]');
    let expMenos = new RegExp('-');
    let expMas = new RegExp('[+]');     
    let expCadena = new RegExp('[A-Za-z0-9]');
    if ( ( expCadena.test(e.target.value)&&!expNopermitida.test(e.target.value)&&!expMenos.test(e.target.value)&&
        !expMas.test(e.target.value) )||e.target.value===' '||e.target.value==='') 
    { setNvoMtto({
        ...nvoMtto, [e.target.name] : e.target.value })
      if (aviso) 
      { setMensaje('');
        setAviso(false);
      }
    }
  };
  
  const onChangeNum = e =>
  { let expNopermitida = new RegExp('[#A-Za-z_:$!%-({})/*=?|]');
    let expMenos = new RegExp('-');
    let expMas = new RegExp('[+]');     
    let expCadena = new RegExp('[0-9]');
    if ( ( expCadena.test(e.target.value)&&!expNopermitida.test(e.target.value)&&!expMenos.test(e.target.value)&&
        !expMas.test(e.target.value)) ||e.target.value===' '||e.target.value==='') 
    {
      setNvoMtto({
        ...nvoMtto, [e.target.name] : e.target.value })
      if (aviso) 
      {
        setMensaje('');
        setAviso(false);
      }
    }
  };
  
  const guardTerminal = e =>
  {
    if (parseInt(e.target.value) > 0) 
    { setArrCabSelect([])  
      var aux = listCabs.filter(function (val) 
      {
        return val.TermId === parseInt(e.target.value);
      });
      aux.sort(function (a, b) { return a.Id - b.Id; });    
                    
      setArregloFiltrado(aux)
    }
    else
    { setArrCabSelect([]) 
      setArregloFiltrado([])
    }
    setNvoMtto({
      ...nvoMtto, [e.target.name] : e.target.value  })
    if (aviso) 
    { setMensaje('');
      setAviso(false);
    }
  };

  const mostrarAlertas =(auxMensaje)=>{
    setTipoAdvertencia("warning")
    let mensaje=auxMensaje            
    setMensaje(mensaje);
    setAviso(true)   
  }

   
 

  const guardaEstado = e =>
  {  
    setNvoMtto({
      ...nvoMtto, Estado : e.target.value  })  
    if (aviso) 
    { setMensaje('');
      setAviso(false);
    }      
  };

  const onChangeSelect = e =>
  {  
    setNvoMtto({
      ...nvoMtto,   [e.target.name] : e.target.value })

    if (aviso) 
    { setMensaje('');
      setAviso(false);
    }   
  };

  return ( 
    <Modal
      titulo={titulo} modalAbierto={modalAbierto}
      setModalAbierto={setModalAbierto}  mensaje={mensaje} 
      tipoAdvertencia={tipoAdvertencia} aviso={aviso} setAviso={setAviso} 
      espera={espera} setEspera={setEspera} tamanio={"md"}
    >
    <form  noValidate autoComplete="off" fullWidth>       
      <Box  display="flex" flexDirection="row">                
        <Box style={{width:35+'rem'}} className={classes.root}>  
          {listStatus.length!=0? 
            <FormControl component="fieldset" style={{width:40+'rem'}}>              
              <FormLabel component="legend" style={{color:'#FF6439',fontWeight:500}}>
                Estatus
              </FormLabel>      
              <RadioGroup row aria-label="position" defaultValue="top"   
                name="Estado" value={Estado} onChange={guardaEstado}                
              >            
                {listStatus.map((nvStatus)=>{
                  if (nvStatus.Id!== -1&&nvStatus.Id!== 0) 
                  { return(  
                    <span> 
                      <FormControlLabel value={nvStatus.Id.toString()} style={{color:nvStatus.Color}} 
                        control={<Radio color="primary" disabled/>} label={nvStatus.Descr} />    
                      <br/>
                    </span>)
                  }
                })}                        
              </RadioGroup>                     
            </FormControl>     
          :null}      
          <br/>                   
          {listLugarTerminal.length!=0 ?  
            <FormControl className={classes.formControl} size="small" style={{width:5.5+'rem'}}>
              <InputLabel htmlFor="age-native-simple">Terminal</InputLabel>
              <MaterialSelect
                native value={Terminal} onChange={guardTerminal}
                inputProps={{ name: 'Terminal', id: 'Terminal'}}
                disabled
              >
                <option aria-label="None" value="" />      
                {listLugarTerminal.map((row) => (
                    <option value={row.value}>{row.label}</option>
                ))}   
              </MaterialSelect>
            </FormControl>     
          :null}  
          {listTipo.length!=0 ?  
            <FormControl className={classes.formControl} size="small" style={{width:10+'rem'}}>
              <InputLabel htmlFor="age-native-simple">Tipo</InputLabel>
              <MaterialSelect
                native value={Tipo} onChange={onChangeSelect}
                inputProps={{ name: 'Tipo', id: 'Tipo'}}
                disabled
              >
                <option aria-label="None" value="" />      
                {listTipo.map((row) => (
                    <option value={row.value}>{row.label}</option>
                ))}   
              </MaterialSelect>
            </FormControl>     
          :null}  
         {/*  <br/> 
          <TextField id="outlinedReferen"           
            label="Técnico"  size="small"
            value={Tecnico} onChange={onChange}  
            name="Tecnico" style={{width:15+'rem'}}                                               
          /> 
          <TextField id="outlinedReferen"           
            label="Auxiliar"  size="small"
            value={Auxiliar} onChange={onChange}  
            name="Auxiliar" style={{width:15+'rem'}}                                               
          /> 
          <br/>
          <TextField id="outlinedReferen"           
            label="Horómetro"  size="small"
            value={Horometro} onChange={onChangeNum}  
            name="Horometro" style={{width:9+'rem'}}                                               
          /> 
          <TextField id="outlinedReferen"           
            label="Referencia Manual"  size="small"
            value={RefManual} onChange={onChangeRef}  
            name="RefManual" style={{width:9+'rem'}}                                               
          />  */}
          <br/>          
          
          <Box  display="flex" flexDirection="row" style={{width:32+'rem'}}  >                
            <Box >        
              <Box display="flex" flexDirection="row"  style={{width:31+'rem'}}>            
                <textarea id="Descr-TextArea" name="Descripcion" rows="3"
                  cols="100"placeholder="Descripción" style={{width: 33.2+"rem",borderRadius:3+'px'}} 
                  value={Descripcion} onChange={onChange} disabled
                />
              </Box>
              <Box display="flex" flexDirection="row"  style={{width:31+'rem',paddingTop:.4+'rem'}}>                          
                <textarea id="Descr-TextArea" name="Observ" rows="3"
                  cols="100"placeholder="Observación" style={{width: 33.2+"rem",borderRadius:3+'px'}} 
                  value={Observ} onChange={onChange} disabled
                />
              </Box>                                            
            </Box>
          </Box> 
        </Box>
        <Box style={{width:25+'em'}} display={"flex"} flexDirection="column">  
        <FormLabel component="legend" style={{color:'#FF6439',fontWeight:500}}>
                Selección de Cabina
        </FormLabel>      
        {Terminal!==0&& auxListCabs.length>0  ?
          <Box style={{marginTop:'1rem'}} >
            <CabinaContainerBlock cabinasTotales={auxListCabs.length} setCabinaSelec={setCabinaSelec} 
              cabSelecc={cabinaSelec} arrCabinas={auxListCabs} arrCabSelect={arrCabSelect}
              setArrCabSelect={setArrCabSelect} //auxListCabs={auxListCabs}
            />
          </Box>
          : <Box pl={1} pt={5}><LinearProgress/></Box>
        }
        </Box>
      </Box>
    </form>
  </Modal>
  );
}
 
export default EditarMtto;