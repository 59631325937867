import React,{useState,useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Box, IconButton,Paper,Table,TableBody,TableCell,LinearProgress,
        TableContainer,TableHead,TableRow,} from '@material-ui/core'; 
import axios from 'axios';
import moment from 'moment';
import CreateIcon from '@material-ui/icons/Create'; 
import {findNumber} from "../funciones/FindNumber";
const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    //maxHeight: 550,
    maxHeight: window.innerHeight-170,
    minHeight: 280,
    minWidth:'100%',
  },
  container2: {
    maxHeight: 550,
    //maxHeight: window.innerHeight-170,
    //minHeight: 280,
  },
  tableRow: {
    "&$selected, &$selected:hover": {
      backgroundColor: process.env.REACT_APP_COLOR
    }
  },
  tableCell: {
    "$selected &": {
      color: "white"
    }
  },
  hover: {},
  selected: {}
});

export default function TablaBitacora({orden,setOrden,filtro,llenaListTabla,buscar,datos, espera}) {
  const classes = useStyles();
  //const nvlSNPermiso ='5'      
  const [selectedID, setSelectedID] = useState(null);      
  const source = axios.CancelToken.source();    
  useEffect(()=>{ 
     
     llenaListTabla();      
    return ()=> {
      source.cancel();
    }                

  },[buscar]); 

  const seleccionado=(auxId,auxOrden)=>{
  
    setOrden(auxOrden)  
  }

 
  const celda = (alinear, estilo, contenido) => {
    return (
    <TableCell
      align={alinear} className={classes.tableCell}
      padding={"none"} style={estilo} 
    >
      {contenido}
    </TableCell>
    );
  };

  function Row(props) {
    const { row } = props;
    const bandera=row.FIni!==row.FFin?true:false
    return (    
    <TableRow onClick={ () => { seleccionado(row.Id,row); } }
      className={classes.tableRow} selected={ orden?.Id === row.Id}
      classes={{ hover: classes.hover, selected: classes.selected }}        
    >           
      {celda("center",{fontWeight:700,width:65+'px'},row.Folio)}      
      {celda("left",{width:120+'px'},
        moment.utc(row.FIni).local(true).format("DD/MM/YYYY HH:mm"))}     
      {celda("left",{width:120+'px'},
        bandera ?moment.utc(row.FFin).local(true).format("DD/MM/YYYY HH:mm"):"")}                          
      {celda("left",{marginLeft:1+'rem'},row.Titulo)}     
    </TableRow>          
    );
  }

  function tablaCompleta(auxlista){
    //console.log("entrando sin preguntar");
    return(
    <TableContainer className={classes.container}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>            
            <TableCell align="center" padding='none'></TableCell>               
            <TableCell align="center" padding='none'>Fecha Inicio</TableCell>            
            <TableCell align="center" padding='none'>Fecha Final</TableCell>
            <TableCell align="left" padding='none'>Evento</TableCell>                    
          </TableRow>
        </TableHead>
        <TableBody>
          {auxlista.length!=0 ? (auxlista.map((row) => (
              <Row key={row.Id} row={row} />
          ))):null}
        </TableBody>
      </Table>
    </TableContainer>
   )
  }

  const tablaSinDatos=()=>{
   return(<TableContainer className={classes.container2}>
    <Table stickyHeader aria-label="sticky table">
      <TableHead>
        <TableRow>            
          <TableCell align="center" padding='none'>Fecha Inicio</TableCell>            
          <TableCell align="center" padding='none'>Fecha Final</TableCell>
          <TableCell align="left" padding='none'> Evento</TableCell>                                                    
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow className={classes.tableRow} >          
          <TableCell  className={classes.tableCell} align="center">Dia</TableCell>          
          <TableCell  className={classes.tableCell} align="center">Sin</TableCell>             
          <TableCell  className={classes.tableCell} align="left">Registros</TableCell>                      
        </TableRow>
      </TableBody>
    </Table>
    </TableContainer>
    ) 
  }

  const tablaSinResultados=()=>{
    return(<TableContainer className={classes.container2}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>            
            <TableCell align="center"> Fecha Inicio</TableCell>            
            <TableCell align="center">Fecha Final</TableCell>
            <TableCell align="left"> Evento</TableCell>                    
          </TableRow>
        </TableHead>
        <TableBody>
        <TableRow className={classes.tableRow} >          
          <TableCell  className={classes.tableCell} align="center"></TableCell>          
          <TableCell  className={classes.tableCell} align="center">Sin</TableCell>             
          <TableCell  className={classes.tableCell} align="left">coincidencias</TableCell>                  
         </TableRow>
       </TableBody>
      </Table>
    </TableContainer>
    ) 
   }

  function filtrar()
  {        
    let info=[] , metodos=null
    if (filtro.length!=0) 
    { let auxCadena=filtro.trim();
      info=datos.filter((dat)=>dat.Id.toString().search(auxCadena) !== -1)                 
    }    
    
    if (info.length>0) 
    { metodos= tablaCompleta(info)
    } 
    else 
    { metodos=tablaSinResultados()
    }
    return metodos ;
  }

  const contenido2= filtro!==''  ? filtrar() :tablaCompleta(datos) 
  const contenido = datos.length!== 0 ?contenido2:tablaSinDatos()
  const tabla = espera  ?  (<Box><LinearProgress/> </Box>) :( contenido )
 
  return (
    <Paper className={classes.root}>
      {tabla}
    </Paper>
  );
}