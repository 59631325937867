import React, {useEffect, useState} from 'react';
import {Box, Grid, makeStyles} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    img: {
        width: '35px',
        height: '30px',
    },
    contenedorimg: {
        width: '60px',
        height: '60px',
        position: "relative",
        textAlign: "center",
        backgroundColor: 'transparent',
        borderStyle: 'solid',
        borderColor: 'transparent',
    },
    contenedorimgSeleccionado: {
        width: '60px',
        height: '60px',
        position: "relative",
        textAlign: "center",
        backgroundColor: '#F3BAAA',
        borderStyle: 'solid',
        borderColor: '#F46239',
    },
    textoCentrado: {
        position: "absolute",
        color: 'white',
        top: "32%",
        left: "50%",
        transform: "translate(-50%, -50%)"
    },
    fila: {
        padding: 1
    },
    contenedor: {
        overflowX: 'auto',
    },
    pinza: {
        height: 10,
        width: 10,
        backgroundColor: '#473f3f',
        borderRadius: '50%',
        position: "absolute", color: 'white', top: "80%", left: "35%", transform: "translate(-50%, -50%)"
    },
    suspension: {
        height: 10,
        width: 10,
        backgroundColor: '#473f3f',
        borderRadius: '50%',
        position: "absolute", color: 'white', top: "80%", right: "15%", transform: "translate(-50%, -50%)"
    },

}));

export default function CabinaContainer({setCabinaSelec, cabSelecc, arrCabinas, avTotal,idCab}) {
    const classes = useStyles();
    const [filas, setFilas] = useState(0)
    const [restantes, setRestantes] = useState(0)
    const [numCabinas, setNumCabinas] = useState(0);
    const [bandera, setBandera] = useState(true);
    const [contador, setContador] = useState(1);
    let altuTamMax = 825
    let anchoTamMax = 1535
    const [alturaContenedor, setAlturaContenedor] = useState(window.innerHeight < altuTamMax ? window.innerHeight - 495 : window.innerHeight - 630)
    const [cabinaObjLista, setCabinaObjLista] = useState([])
    const [anchoContenedor, setAnchoContenedor] = useState(window.innerWidth < anchoTamMax ? window.innerWidth - 600 : window.innerWidth - 600)
    const [indice, setIndice] = useState(-1);
    useEffect(() => {
        let auxCabinas = Math.trunc(((alturaContenedor) / 55))
        setNumCabinas(auxCabinas)
        let auxArreCab = []
        arrCabinas.forEach((cab) => {
            if (auxArreCab.length === 0) {
                auxArreCab.push({Id: cab.cabinaId, status: cab.stId})
            }
            let aux = auxArreCab.filter((cabina) => cabina.Id === cab.cabinaId)
            if (aux.length === 0) {
                auxArreCab.push({Id: cab.cabinaId, status: cab.stId})
            }
        })
        auxArreCab.sort(function (a, b) {
            return a.Id - b.Id;
        });
        let nvoArreCab = []

        auxArreCab.forEach((cab) => {
            let statusCab = 0;

            let aux = avTotal.original.cabinas.filter((elemnto) => elemnto.cabina === cab.Id)

            let auxStatus = aux[0]
            if (auxStatus.avance < auxStatus.total && auxStatus.avance !== 0) {
                statusCab = 2
            } else {
                if (auxStatus.avance === auxStatus.total) {
                    statusCab = 3
                } else {
                    statusCab = 1
                }
            }
            nvoArreCab.push({Id: cab.Id, status: statusCab})
        })

        const auxfilas = Math.trunc((auxArreCab.length / auxCabinas))
        setFilas(auxfilas)
        if (auxArreCab.length % auxCabinas != 0) {
            const resta = auxArreCab.length - auxfilas * auxCabinas
            setRestantes(resta)
        }

        setCabinaObjLista(nvoArreCab)
        setCabinaSelec([])

        window.addEventListener('resize', function () {
            if (window.innerHeight < altuTamMax) {
                setAlturaContenedor(window.innerHeight - 495)
            } else {
                setAlturaContenedor(window.innerHeight - 630)
            }

            if (window.innerWidth < anchoTamMax) {
                setAnchoContenedor(window.innerWidth - 680)
            } else {
                setAnchoContenedor(window.innerWidth - 600)
            }
        });

        if (cabSelecc === 0) {
            setIndice(auxArreCab[0].Id)
            let arrSelecc = arrCabinas.filter((cab) => cab.cabinaId === auxArreCab[0].Id)
            setCabinaSelec(arrSelecc)
        } else {
            setIndice(cabSelecc)
            let arrSelecc = arrCabinas.filter((cab) => parseInt(cab.cabinaId) === parseInt(cabSelecc))
           // console.log(arrSelecc,arrCabinas,cabSelecc);
            setCabinaSelec(arrSelecc)
        }

    }, [arrCabinas])

    /* useEffect(() => {
        if (contador!==1) {
            setBandera(false)
            setTimeout(() => {
                setBandera(true)
                console.log("cambiando");
            }, 1000);      
        }
        setContador(contador+1)
        
    }, [arrCabinas]) */
    
    function seleccionarCabina(seleccionado) {

        let arrSelecc = arrCabinas.filter((cab) => cab.cabinaId === seleccionado.Id)
        if (indice !== seleccionado.Id) {
            setIndice(seleccionado.Id)
        }
        if (arrSelecc.length > 0) {
            setCabinaSelec(arrSelecc)
        }
    }

    const generarCabinas=()=> {
        let arr = [];
        let id = 0;
        for (let i = 0; i < filas; i++) {
            let arrCab = []
            for (let cabinas = 0; cabinas < numCabinas; cabinas++) {
                //console.log(arrCabinas[id].Suspension);
                let cabina = cabinaObjLista[id];
                let srcImg = "";
                switch (cabina.status) {
                    case 1:
                        srcImg = "TELEFERICO pendiente.svg"
                        break;
                    case 2:
                        srcImg = "TELEFERICO avance.svg"
                        break;
                    case 3:
                        srcImg = "TELEFERICO completo.svg"
                        break;
                    default:
                        srcImg = "TELEFERICO vacio.svg"
                        break;
                }
                arrCab.push(
                    <Box
                          className={cabina.Id === indice ? classes.contenedorimgSeleccionado : classes.contenedorimg}
                          onClick={() => {
                              seleccionarCabina(cabina)
                          }}
                    >
                        <img className={classes.img} src={srcImg}/>
                        <div className={classes.textoCentrado}>
                            <strong>{cabinaObjLista[id].Id}</strong>
                        </div>
                        <div></div>
                        <div className={classes.pinza} style={arrCabinas[id]?.Pinza === 1 ? {backgroundColor: '#19A11E'} : {backgroundColor: '#F46239'}}>
                        </div>
                        <div className={classes.suspension} style={arrCabinas[id]?.Suspension === 1 ? {backgroundColor: '#19A11E'} : {backgroundColor: '#F46239'}}  >
                        </div>
                    </Box>)
                id++;
            }
            let grid = <Box flexDirection={"column"} display="flex" className={classes.fila}>
                {arrCab}
            </Box>
            arr.push(grid);
        }

        if (restantes > 0) {
            let arrCab = []
            for (let cabinas = 0; cabinas < restantes; cabinas++) {
                if (id < cabinaObjLista.length) {
                    let cabina = cabinaObjLista[id];
                    let srcImg = "";
                    switch (cabina.status) {
                        case 1:
                            srcImg = "TELEFERICO pendiente.svg"
                            break;
                        case 2:
                            srcImg = "TELEFERICO avance.svg"
                            break;
                        case 3:
                            srcImg = "TELEFERICO completo.svg"
                            break;
                        default:
                            srcImg = "TELEFERICO vacio.svg"
                            break;
                    }
                    arrCab.push(<Box
                                      className={cabina.Id === indice ? classes.contenedorimgSeleccionado : classes.contenedorimg}
                                      onClick={() => {
                                          seleccionarCabina(cabina)
                                      }}
                    >
                        <img className={classes.img} src={srcImg}/>
                        <div className={classes.textoCentrado}>
                            <strong>{cabinaObjLista[id].Id}</strong>
                        </div>
                        <div></div>
                        <div className={classes.pinza}  style={arrCabinas[id]?.Pinza === 1 ? {backgroundColor: '#19A11E'} : {backgroundColor: '#F46239'}}>
                        </div>
                        <div className={classes.suspension} style={arrCabinas[id]?.Suspension === 1 ? {backgroundColor: '#19A11E'} : {backgroundColor: '#F46239'}}>
                        </div>
                    </Box>)
                    id++;
                }
            }
            if (arrCab.length > 0) {
                let grid = <Box flexDirection={"column"} display="flex" className={classes.fila}>
                    {arrCab}
                </Box>
                arr.push(grid)
            }
        }
        return arr;
    }

    const cabMostrar = cabinaObjLista.length > 0  ? generarCabinas() : null;
    return (

        <Box flexDirection={"row"} display="flex" className={classes.contenedor}
             style={{maxHeight: alturaContenedor, maxWidth: anchoContenedor, paddingRight: .5 + 'rem'}}
        >
            {cabMostrar}
        </Box>
    )
}