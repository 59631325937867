import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  IconButton,Tooltip
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import NumberFormat from "react-number-format";
import CarroTensorDummy from "./CarroTensorDummy";
import moment from "moment";
import EditarCarroTensor from "./EditarCarroTensor";
import {findNumber} from "../funciones/FindNumber";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    width: "25rem",
  },
  tableRow: {
    backgroundColor: "#F46239",
    maxWidth: "430px",
  },
  alturaTabla: {
    minHeight: 80,
  },

  tableCellHeader: {
    witdh: "auto",
    backgroundColor: "#F46239",
    color: "white",
  },

  tableCell: {
    "$selected &": {
      color: "white"
    }
  },
  tableRow: {
    "&$selected, &$selected:hover": {
      backgroundColor: "#F46239"
    }
  },
  hover: {},
  selected: {}
}));

export default function TablaCarroTensor({
  listaCTensor,
  listaTerminales,
  termSelect,
  usuarioContext,setListaCTensor
}) {
  let nvlSNPermiso = "5";
  const [idBotonEditar, setIdBotonEditar] = useState(0);
  const [modalEditarAbierto, setModalEditarAbierto] = useState(false);
  const [modalTitulo, setModalTitulo] = useState("");
  const [energia, setEnergia] = useState(null);
  const [alturaTabla, setAlturaTabla] = useState(
    window.innerHeight < 1300
      ? window.innerHeight - 230
      : window.innerHeight - 80
  );
  const modEditar = modalEditarAbierto ? (
    usuarioContext?.nivel != undefined &&
    usuarioContext.nivel !== nvlSNPermiso ? (
      <EditarCarroTensor
        modalAbierto={modalEditarAbierto}
        setModalAbierto={setModalEditarAbierto}
        titulo={modalTitulo}
        energia={energia}
        terminales={listaTerminales}
        listaCTensor={listaCTensor}       
        setListaCTensor={setListaCTensor} 
      />
    ) : (
      <CarroTensorDummy
        modalAbierto={modalEditarAbierto}
        setModalAbierto={setModalEditarAbierto}
        titulo={modalTitulo}
        energia={energia}
        terminales={listaTerminales}
      />
    )
  ) : null;
  const classes = useStyles();
  const columnasTerm1 = [
    {
      id: 1, titulo: "Fecha",
    },
    {
      id: 2, titulo: "Terminal",
    },
    {
      id: 3, titulo: "Estación 1",
    },
   /*  {
      id: 4, titulo: "Estación 4A",
    }, */
  ];
  const columnasTerm2 = [
    {
      id: 1, titulo: "Fecha",
    },
    {
      id: 2, titulo: "Terminal",
    },
    {
      id: 3, titulo: "Estación 7",
    },
   /*  {
      id: 4,
      titulo: "Estación 4B",
    }, */
  ];

  useEffect(() => {
    window.addEventListener("resize", function () {
      if (window.innerHeight < 1300) {
        if (listaCTensor.length > 22) {
          setAlturaTabla(window.innerHeight - 250);
        } else {
          let dimTotal = 21 * listaCTensor.length;
          setAlturaTabla(dimTotal);
        }
      } else {
        if (listaCTensor.length > 22) {
          setAlturaTabla(window.innerHeight - 80);
        } else {
          let dimTotal = 21 * listaCTensor.length;
          setAlturaTabla(dimTotal);
        }
      }
    });
  }, []);

  function abrirModalEdit(row) {
    setModalEditarAbierto(!modalEditarAbierto);    
    setModalTitulo("Editar Registro " + moment.utc(row.fBitacora).format("DD/MM/YYYY"));
    setEnergia(row);
  }

  function handleClickEditar(row) {
    if (row.Id !== idBotonEditar) {
      setIdBotonEditar(row.Id);
    }
  }

  const tablaSinResultados = () => {
    return (
    <TableRow>
      <TableCell
        align="center" padding={"none"} className={classes.TableCell}
        style={{ color: "#F46239", width: 600 + "px", fontWeight: 600 }}          
      >
        Sin Resultados
      </TableCell>
    </TableRow>
    );
  };

  const celda = (alinear, estilo, contenido) => {
    return (
    <TableCell
      align={alinear} className={classes.tableCell}
      padding={"none"} style={estilo} 
    >
      {contenido}
    </TableCell>
    );
  };

  function generarTablaTerm1() {
    let auxFilas = [];
    if (listaCTensor.length > 0) {
      for (let i = 0; i < listaCTensor.length; i++) {
        let row = listaCTensor[i];
        auxFilas.push(
          <TableRow selected={row.Id === idBotonEditar }
            key={row.Id} onClick={() => handleClickEditar(row)}
            className={ classes.tableRow }
            classes={{ hover: classes.hover, selected: classes.selected }} 
          >
            {celda("center",{width: 30 + "px" },
             row.Id === idBotonEditar && findNumber('|67|')?
              <Tooltip title={'Editar'}>
                <IconButton size="small" component={"span"}
                  onClick={
                    row.Id === idBotonEditar ? () => abrirModalEdit(row) : null
                  }
                >                 
                  <EditIcon style={{ color: "white" }} />                
                </IconButton>
              </Tooltip>
              : null
            )}                             
            {celda("center",{width: 80 + "px" },
              moment.utc(row.fBitacora).format("DD/MM/YYYY")
            )}             
            {celda("center",{width: 90 + "px"},row.termNom)}                 
            {celda("center",{width: 120 + "px"},
              <NumberFormat
                thousandSeparator={true}
                displayType="text" value={row.tensorE1} 
              />
            )}                                 
          </TableRow>
        );
      }
      return auxFilas;
    } else {
      tablaSinResultados();
    }
  }

  function generarTablaTerm2() {
    let auxFilas = [];
    if (listaCTensor.length > 0) {
      for (let i = 0; i < listaCTensor.length; i++) {
        let row = listaCTensor[i];
        auxFilas.push(
          <TableRow selected={row.Id === idBotonEditar }
            key={row.Id} onClick={() => handleClickEditar(row)}
            className={classes.tableRow}
            classes={{ hover: classes.hover, selected: classes.selected }} 
          >
            {celda("center",{width: 30 + "px" },
              <IconButton
                size="small" component={"span"}
                onClick={
                  row.Id === idBotonEditar  && findNumber('|67|') ? 
                  () => abrirModalEdit(row) : null
                }
              >
                {row.Id === idBotonEditar  && findNumber('|67|') ?
                  (<EditIcon style={{ color: "white" }} />) 
                  : null}
              </IconButton>
            )}              
            {celda("center",{width: 50 + "px"},
              moment.utc(row.fBitacora).format("DD/MM/YYYY")
            )}              
            {celda("center",{width: 90 + "px"},row.termNom)}    
            {celda("center",{width: 120 + "px" },
              <NumberFormat
                thousandSeparator={true} displayType="text"
                value={row.tensorE7} 
              />
            )} 
            {/* celda("center",{width: 120 + "px" },
              <NumberFormat
                thousandSeparator={true} displayType="text"
                value={row.tensorE4A} //
              />
            ) */}               
          </TableRow>
        );
      }
      return auxFilas;
    } else {
      tablaSinResultados();
    }
  }

  const generarTabla =
    termSelect === 1
      ? generarTablaTerm1()
      : termSelect === 2
      ? generarTablaTerm2()
      : null;
  return termSelect === 1 || termSelect === 2 ? (
    <Paper className={classes.root}>
      <TableContainer
        className={classes.alturaTabla}
        style={{ height: alturaTabla }}
      >
        <Table
          className={classes.table} stickyHeader
          size="small" aria-label="resultados"          
        >
          <TableHead>
            <TableRow>
              <TableCell
                key={8} padding={"none"} align="center"
                className={classes.tableCellHeader}
              ></TableCell>
              {termSelect === 1
                ? columnasTerm1.map((row) => (
                    <TableCell
                      key={row.id} padding={"none"}
                      align="center" className={classes.tableCellHeader}
                    >
                      {row.titulo}
                    </TableCell>
                  ))
                : columnasTerm2.map((row) => (
                    <TableCell
                      key={row.id} padding={"none"} align="center"
                      className={classes.tableCellHeader}
                    >
                      {row.titulo}
                    </TableCell>
                  ))}
            </TableRow>
          </TableHead>
          <TableBody>{generarTabla}</TableBody>
        </Table>
      </TableContainer>
      {modEditar}
    </Paper>
  ) : (
    <div></div>
  );
}
