import React from 'react';
import {Button,Dialog,DialogActions,DialogContent,DialogContentText,
        DialogTitle,CircularProgress} from '@material-ui/core';
import Formulario from './EditFormFallaDummy'

import { useHistory } from "react-router-dom";
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';

import Alert from '@material-ui/lab/Alert';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    //margin: theme.spacing(0),
    marginTop: 0,
    paddingTop:0,
    position: 'relative',
  },
 
  buttonGuardar:{color: green[500],},
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function EditarFallaDummy(propiedades) {
  const [open, setOpen] = React.useState(false);
  const [aviso, setAviso] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');
  const [mensaje, setMensaje] = React.useState('');
  const [tipoAdvertencia, setTipoAdvertencia] = React.useState('success');
  const [horaActual, setHoraActual] = React.useState('');
  const [nuevaFalla,guardarNuevaFalla]= React.useState({
    Conductor:'',Terminal:'',Usuario:'',Tipo:'',Ala:'',Lugar:'',Estado:0,
    Fecha:'',Horario:'Thu Jun 17 2021 00:00:00 GMT-0500 (hora de verano central)',Informacion:'',SolucionId:'',Solucion:'',
    Tiempo:'Thu Jun 17 2021 00:00:00 GMT-0500 (hora de verano central)',TipoDescr:'',Ubicacion:'',UbicDescr:''})
    const {Terminal,Tipo,Ala,Lugar,Estado,Horario,Informacion,SolucionId
      //,Usuario,Conductor,Fecha
      ,Solucion,Tiempo,TipoDescr,Ubicacion,UbicDescr} = nuevaFalla;
  let history = useHistory();
  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    guardarNuevaFalla({
      Conductor:'',Terminal:'',Usuario:'',Tipo:'',Ala:'',Lugar:'',Estado:0,
      Fecha:'',Horario:'Thu Jun 17 2021 00:00:00 GMT-0500 (hora de verano central)',Informacion:'',SolucionId:'',Solucion:'',
      Tiempo:'Thu Jun 17 2021 00:00:00 GMT-0500 (hora de verano central)',TipoDescr:'',Ubicacion:'',UbicDescr:''})
    setScroll(scrollType);
  };
  const abrirAviso = () => () => {
    setAviso(true);
    
  };

  const cerrarBorrar = () => {
    guardarNuevaFalla({
      Conductor:'',Terminal:'',Usuario:'',Tipo:'',Ala:'',Lugar:'',Estado:0,
      Fecha:'',Horario:'Thu Jun 17 2021 00:00:00 GMT-0500 (hora de verano central)',Informacion:'',SolucionId:'',Solucion:'',
      Tiempo:'Thu Jun 17 2021 00:00:00 GMT-0500 (hora de verano central)',TipoDescr:'',Ubicacion:'',UbicDescr:''})
    setOpen(false);
    propiedades.setModalAbierto(false)
    setAviso(false);
  };
  const handleClose = () => {
    setOpen(false);
    propiedades.setModalAbierto(false)
  };
  const handleCloseSN = () => {
    setAviso(false);
  };
  
  const classes = useStyles();
  const [espera,setEspera]=React.useState(false);
 
  
  
  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (propiedades.modalAbierto) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
     // console.log("trayendo datos");
      
    }
    //console.log('editar 1');
    let hoy = new Date();     
    let fecha = moment(hoy);      
    setHoraActual(fecha);
    /*guardarNuevaFalla({
      ...nuevaFalla,
      Horario:fecha
    })*/
    const {seleccionadoEdit}=propiedades
      console.log(seleccionadoEdit);
      
      guardarNuevaFalla({
        Conductor:seleccionadoEdit.conducId , Terminal:seleccionadoEdit.termiId,Usuario:seleccionadoEdit.userId,
        Tipo:seleccionadoEdit.tipoId,Ala:seleccionadoEdit.alaId , Lugar:seleccionadoEdit.lugarId,Estado:seleccionadoEdit.Status.toString(),
        Fecha: seleccionadoEdit.fecha ,Horario: seleccionadoEdit.hora ,Informacion: seleccionadoEdit.info ,
        SolucionId:seleccionadoEdit.solucId ,Solucion: seleccionadoEdit.solucion, Tiempo: seleccionadoEdit.tiempo ,
        TipoDescr:seleccionadoEdit.tipoNom,Ubicacion:seleccionadoEdit.ubicId,UbicDescr:seleccionadoEdit.ubicDescr})

  }, [propiedades.modalAbierto]);
  
  return (

    <Dialog
      open={propiedades.modalAbierto}
      onClose={handleClose} scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth={true} maxWidth="md"
    >
      <DialogTitle style={{color:'#F46239'}}  id="titulo-editar-falla" align="center" >
        {propiedades.titulo}
      </DialogTitle>              
      <DialogContent dividers={scroll === 'paper'}>        
        <Formulario nuevaFalla={nuevaFalla} guardarNuevaFalla={guardarNuevaFalla }/>
        
        <DialogContentText
          id="scroll-dialog-description"
          ref={descriptionElementRef}
          tabIndex={-1}
        >
          
        </DialogContentText>
        { aviso&&tipoAdvertencia &&mensaje.length!=0 ? 
            <Alert severity={tipoAdvertencia}  onClose={handleCloseSN}>
              {mensaje}
            </Alert>
        :   <span>
              <br/><br/>
            </span>
        }
      </DialogContent>
    
      <DialogActions>
        <Button onClick={cerrarBorrar} disabled={espera} color="secondary" variant="outlined">
          Cerrar
        </Button>              
      </DialogActions>      
    </Dialog>     
  );
}
