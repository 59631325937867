import React,{createContext,useState} from 'react';

export const PermisosContext = createContext();

const PermisosProvider = (props) => {
    const [permisos,setPermisos]=useState([])
    return (
         <PermisosContext.Provider
         value={{
            permisos,setPermisos
         }}
         >
             {props.children}
        </PermisosContext.Provider> 
    );
}
 
export default PermisosProvider;