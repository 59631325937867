import axios from "axios";
import qs from "qs";
import moment from "moment";
const CancelToken = axios.CancelToken;
const source = CancelToken.source();
/* function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
} */
export  async function registrarConsumo(Consumo,Costo,Medidor,Terminal,FechaFinal) {
    let resultado=false;
   let auxU = process.env.REACT_APP_LINK + `/energia-nuevo_1_1`;
    let token = localStorage.getItem("token20");
    let FechaSinFormato = moment(FechaFinal).format();
    let Fecha = FechaSinFormato.slice(0,-6);
    let dataInfo = qs.stringify({
      Terminal: Terminal,
      Fecha: Fecha,
      Consumo: Consumo,
      Precio: Costo,
      Medidor: Medidor,
      idDispositivo: "EBRDVDVDVFFD",
    });
    let config = {
      url: auxU,
      method: "POST",
      data: dataInfo,
      headers: {
        "access-token": token,
        "Content-Type": "application/x-www-form-urlencoded",
      },
      cancelToken: source.token,
    };
    let aux = {};
    await axios(config)
      .then(async (response) => {
        aux = response.data;
        if (aux.respuesta===1) {
          resultado= true
          //console.log("exitoso"+new Date())
                                                       
        } else {
          console.log("Ha ocurrido un error, inténtelo más tarde");
          resultado = false;
        }
      })
      .catch(function (error) {
          if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
          {
            console.log("Datos Incorrectos");
            resultado=false;
          }
      }); 
      
    //await sleep(400);
    var ahora = new Date();
    var milisegundos = ahora.getHours()+':' +ahora.getMinutes()+':' +ahora.getSeconds()+'.' +ahora.getMilliseconds();
    console.log(milisegundos);
    
    //resultado=true;
     return resultado 
  }
