import React from "react";
import {Line} from 'react-chartjs-2';

export default function GraficaEnergia({arrayPrecios, arrayDias, labelGrafica,arrayMedidores, graficaFiltrada}) {
    const data = {
        labels: arrayDias,
        datasets: [
            {
            label: labelGrafica,
            backgroundColor: "#F46239",
            borderColor: "#F46239",
            data: arrayPrecios
            ,tension: 0.4
            },
        ]
      }; 

      return (
            <Line
            data={data}
            options={{
                plugins: {
                    tooltip:{
                        callbacks: {
                            label: function(context){
                                const index = context.dataIndex;
                                const valor = context.dataset.data[index];
                                const bucle = !graficaFiltrada ? labelGrafica : "";
                                const label = bucle  + " " +arrayMedidores[index] + " " +valor.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
                                return label
                            },
                        }
                    },
                },

                scales: {
                    y: {
                        ticks: {
                            callback: function(value) {
                                return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
                            }
                        }
                    },
                    x:{

                    }
                },
                
              }}
            />
      );
}