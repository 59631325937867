import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import { Box, IconButton, Paper,LinearProgress, 
    Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Tooltip,
} from '@material-ui/core';
import axios from 'axios';
import {celdaPadding} from '../Generales/TablaGeneral';
import {useHistory} from "react-router-dom";
import CreateIcon from '@material-ui/icons/Create';
import EditarUsuario from "./EditUsuario";
import TablaPerfilTipo from './TablaPerfTipo';
import TablaPerfilOperacion from './TablaPerflOpera';
import {ErrorGeneral, llamaApiCargaToken,llamadaApiToken } from "../funciones/ClienteAxios";
import qs from 'qs';
import {findNumber} from "../funciones/FindNumber";
const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: window.innerHeight - 170,
        minHeight: 280,
        minWidth: '100%',
    },
    container2: {
        maxHeight: 550,
    },
    tableRow: {
        "&$selected, &$selected:hover": {
            backgroundColor: "#F46239"
        }
    },
    tableCell: {
        "$selected &": {
            color: "white"
        }
    },
    hover: {},
    selected: {}
});

export default function TablaUsuario({actualizar, datos, usuario}) {
    const classes = useStyles();
    let history = useHistory();
    let nvlSNPermiso = '5'
    const source = axios.CancelToken.source();
    const [selectedID, setSelectedID] = useState(null);
    const [open, setOpen] = useState(false);
    const [espera, setEspera] = useState(false);
    const [ordenSelecc, setOrdenSelecc] = useState(0);
    const [seleccionadoEdit, setSeleccionadoEdit] = useState([]);
    const [modalAbierto, setModalAbierto] = useState(false);
    const [tipoSelect, setTipoSelect] = useState([]);    
    const [listPermUsr, setListPermUsr] = useState([]);    
        
    useEffect(() => {
        setListPermUsr([])

        return () => {
            source.cancel();
        }
    }, []);

    const llamaPermisos = (llave) => {        
        let idusu = localStorage.getItem("UsuId");
        let data = qs.stringify({                
            usuario: idusu,      
            llave:llave,   
            idDispositivo: 'rjvikcmkcmsdkdcdsmc'
        });

        let url = '/usuario-permiso-list';

        function response(auxiliar) 
        {            
            if (auxiliar.length !== 0) 
            {   setListPermUsr(auxiliar)                                                                                           
                //console.log(auxiliar);
            }                                                
        }       
        llamaApiCargaToken( data ,url, response, ErrorGeneral, setEspera, history, source);             
    }

    const llamaPermisos2 = (llave) => {        
        let idusu = localStorage.getItem("UsuId");
        let data = qs.stringify({                
            usuario: idusu,      
            llave:llave,   
            idDispositivo: 'rjvikcmkcmsdkdcdsmc'
        });

        let url = '/usuario-permiso-list';

        function response(auxiliar) 
        {            
            if (auxiliar.length !== 0) 
            {   setListPermUsr(auxiliar)                                                                                           
                //console.log(auxiliar);
            }                                                
        }       
        llamadaApiToken(data, url, response,ErrorGeneral,history,source)                   
    }

    
    const seleccionado = (auxId, auxOrden) => {
        setSelectedID(auxId)        
        setListPermUsr([])  
        setTimeout(() => {              
            llamaPermisos(auxOrden.Id) 
        }, 200);
        
        setTipoSelect([])        
    }

    function editarFall(auxFalla) {
        setSeleccionadoEdit(auxFalla)        
        setModalAbierto(true)
    }

    function verMas(auxId) {

        if (auxId === ordenSelecc) {
            setOpen(!open)
        } else {
            setOpen(true)
        }
        setOrdenSelecc(auxId)
    }

    function tipoEstado(estado, idSelecc) {
        let auxEtiqueta = ''
        let auxColor = ''
        if (estado === 0) {
            auxEtiqueta = 'Registrado'
            auxColor = '#F46239'
        } else {
            if (estado === 1) {
                auxEtiqueta = 'Solucionado'
                auxColor = '#049b4f'
            } else {
                if (estado === -1) {
                    auxEtiqueta = 'Cancelado'
                    auxColor = 'red'
                }
            }
        }
        let estilo = selectedID === idSelecc ?
            {color: 'white', fontWeight: 700, width: 100 + 'px'}
            : {color: auxColor, fontWeight: 700, width: 100 + 'px'}
        return celdaPadding("center", estilo, auxEtiqueta, 5, classes.tableCell)
    }

    function Row(props) {
        const {row} = props;
        return (        
        <TableRow onClick={() => { seleccionado(row.Id, row) }}
            className={classes.tableRow} selected={selectedID === row.Id}
            classes={{hover: classes.hover, selected: classes.selected}}
        >   
            { row.Id!==1? celdaPadding("center", {width: 30 + 'px'}, 
                 findNumber('|37|') ? 
                    <Tooltip title={'Editar'} >
                        <IconButton size="small" onClick={() => editarFall(row)}
                            aria-label="add circle" component="span"                        
                        >
                            {selectedID === row.Id ? 
                                <CreateIcon style={{color: 'white'}}   /> 
                            : <CreateIcon color="primary"/> }
                        </IconButton>
                    </Tooltip>       
                 : null     
            , 1, classes.tableCell)
            :celdaPadding("center", {padding:15+'px'}, "", 4, classes.tableCell)
            }
            {celdaPadding("left", {fontSize:12+'px'}, row.Nombre, 4, classes.tableCell)}
            {celdaPadding("center", {width: 80 + 'px',fontSize:11+'px'}, row.TerminalNom, 3, classes.tableCell)}
            {celdaPadding("left", {width: 90 + 'px',fontSize:12+'px'}, row.TipoNom, 2, classes.tableCell)}
            {celdaPadding("left", {width: 170 + 'px',fontSize:12+'px'}, row.Puesto, 5, classes.tableCell)}            
            
        </TableRow>                   
        );
    }

    function tablaCompleta(auxlista) {
        return (
            <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            
                            <TableCell align="center" padding='none'>{auxlista.length-1}</TableCell>
                            <TableCell align="center" padding='none'>Nombre</TableCell>                            
                            <TableCell align="center" padding='none'>Terminal </TableCell>                            
                            <TableCell align="center" padding='none'>Tipo</TableCell>
                            <TableCell align="center" padding='none'>Puesto</TableCell>
                            
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {auxlista.length !== 0 ? (auxlista.map((row) => {
                            if (row.Id!==1) {
                                return <Row key={row.Id} row={row}/>   
                            }
                             }
                        )) : null}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    const tablaSinDatos = () => {
        return (
            <TableContainer className={classes.container2}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                    <TableRow>
                        <TableCell align="center" padding='none'>Nombre</TableCell>
                        <TableCell align="center" padding='none'>Puesto</TableCell>
                        <TableCell align="center" padding='none'>Terminal </TableCell>      
                    </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow className={classes.tableRow}>
                            <TableCell className={classes.tableCell} align="center"></TableCell>
                            <TableCell className={classes.tableCell} align="left">Sin</TableCell>
                            <TableCell className={classes.tableCell} align="left">Usuarios</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    const modalEditar = modalAbierto ? (usuario?.nivel != undefined && usuario.nivel !== nvlSNPermiso ?
        <EditarUsuario modalAbierto={modalAbierto} setModalAbierto={setModalAbierto} titulo={'Editar Usuario ' + seleccionadoEdit.Nombre}
        actualizar={actualizar}         seleccionadoEdit={seleccionadoEdit} user={usuario} isUpdate={true}/> : null) : null;
                       
    const tabTipo = listPermUsr.length !==0  ? <TablaPerfilTipo datos={listPermUsr}  setTipoSelect={setTipoSelect} tipoSelect={tipoSelect}/> :null
    const contenido = datos.length !== 0 ? tablaCompleta(datos) : tablaSinDatos()
    const tabOpera= tipoSelect.length !==0 && tipoSelect.permisos ? 
                            <TablaPerfilOperacion datos={tipoSelect.permisos}  original={datos}  
                                tipo={1} usrId={selectedID} llamaApi={llamaPermisos2} /> :null
    return (
    <Box width={"100%"}>
        
        <Box display="flex" width={"100%"} row>
        
            <Paper style={{ width:35+'rem'}}>
                {contenido}
            </Paper>           
                {espera ? 
                    <Box style={{width:"14rem", marginTop:.8+'rem',marginLeft:.8+'rem'}} > <LinearProgress  color="primary"/> </Box>
                :<Paper style={{ marginLeft:1+'rem'}}>{tabTipo}</Paper>    }
            
            <Paper style={{ marginLeft:1+'rem'}}>
                {tabOpera}
            </Paper> 
        </Box>
        {modalEditar}
    </Box>
    );
}