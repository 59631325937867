import {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {
    Grid, TextField, LinearProgress
} from "@material-ui/core";
import Modal from "../Generales/Modal2";
import {useHistory} from "react-router-dom";
import axios from "axios";
import qs from "qs";
import Autocomplete, {createFilterOptions} from "@material-ui/lab/Autocomplete";
import {llamaApiCargaToken, ErrorGeneral} from '../funciones/ClienteAxios';
import 'react-toastify/dist/ReactToastify.css';
import {toast} from 'react-toastify';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: {
            margin: theme.spacing(1),
        },
    }, medidor: {
        width: '100px', marginBottom: '1em'
    }, campos: {
        width: '200px', marginBottom: '1em'
    }, camposLectura: {
        width: '150px', marginBottom: '1em'
    }, fecha: {
        width: '200px', marginTop: '3px', marginBottom: '1em'
    }

}));

const CrearRefacciones = ({
                              modalAbierto,
                              setModalAbierto,
                              titulo,
                              listaRefacciones,
                              actualiza,
                              setActualiza
                          }) => {

    const classes = useStyles();
    const filter = createFilterOptions();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const historial = useHistory();

    const [mensaje, setMensaje] = useState("");
    const [tipoAdvertencia, setTipoAdvertencia] = useState("success");
    const [aviso, setAviso] = useState(false);
    const [registro, setRegistro] = useState({
        Alias: '', Descr: '', Fecha: '', Folio: '', Id: '', Status: '', Unidad: ''
    });
    const [validateErrorFolio, setValidateErrorFolio] = useState('');
    const [validateErrorAlias, setValidateErrorAlias] = useState('');
    const [validateErrorDesc, setValidateErrorDesc] = useState('');
    const [cargando, setCargando] = useState(false);
    const [botonDeshabilitado, setBotonDeshabilitado] = useState(false);

    const crearRefaccion = () => {
        let data = qs.stringify({
            Folio: registro.Folio,
            Alias: registro.Alias,
            Descr: registro.Descr,
            idDispositivo: '987gyubjhkkjh'
        });
        let url = "/refaccion-nuevo";

        function respuesta(auxiliar) {
            if (auxiliar.respuesta === 1) {
                setModalAbierto(!modalAbierto)
                setTipoAdvertencia("success")
                let mensaje = "Se agrego correctamente la refacción " + auxiliar.mensaje
                setMensaje(mensaje);
                setAviso(true)
                toast.success(mensaje, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                // listaRefacciones.splice(0, 0, registro)
            }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, historial, source);

        setActualiza(!actualiza);
    }

    const cerrarBorrar = () => {
        setAviso(false);
        setModalAbierto(false)
    };

    const handleDescr = (newValue) => {
        setValidateErrorDesc('')
        if (typeof newValue === 'string' && newValue) {
            setBotonDeshabilitado(true)
            setValidateErrorDesc('Ya existe una refacción con la misma descripción.')

        } else if (newValue && newValue.inputValue) {
            if (newValue.inputValue.trim().length < 4) {
                setBotonDeshabilitado(true)
                setValidateErrorDesc('La descripción es insuficiente...')
            } else if (newValue.inputValue.trim() === registro.Descr) {
                setBotonDeshabilitado(true)
                setValidateErrorDesc('Ya existe una refacción con la misma descripción.')
            }
            setRegistro({
                ...registro, Descr: newValue.inputValue.trim(),
            })
            setBotonDeshabilitado(false)
        } else {
            if (typeof newValue === 'object') {
                if (newValue) {
                    setRegistro({
                        ...registro, Descr: newValue.value,
                    })
                } else {
                    setRegistro({
                        ...registro, Descr: '',
                    })
                }
            }
        }
    }

    const handleFolio = (e) => {
        setRegistro({...registro, Folio: e.target.value})
        setValidateErrorFolio('')
    }

    const handleAlias = (e) => {
        setRegistro({...registro, Alias: e.target.value})
        setValidateErrorAlias('')
    }

    const validarFolio = () => {
        if (registro.Folio !== '') {
            setValidateErrorFolio('')
        } else {
            toast.error('El campo de Folio obligatorio.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setValidateErrorFolio('El campo de Folio obligatorio.')

        }
    }

    const validarAlias = () => {
        setValidateErrorAlias('')
        setRegistro({...registro, Alias: ''})
    }

    const validarDescr = () => {
        if (registro.Descr !== '' && registro.Descr.length > 3) {
            setValidateErrorDesc('')
        } else {
            toast.error('El campo de Descripción obligatorio.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setValidateErrorDesc('El campo de Descripción  obligatorio.')

        }
    }

    const validar = () => {
        if (registro.Descr && registro.Folio !== '') {
            setBotonDeshabilitado(false)
            setCargando(true)
            return crearRefaccion()
        } else {
            validarFolio()
            validarAlias()
            validarDescr()
        }
    }

    return (<Modal hideBackdrop
                   disableEscapeKeyDown
                   espera={cargando}
                   btnDesact={botonDeshabilitado}
                   titulo={titulo}
                   modalAbierto={modalAbierto}
                   setModalAbierto={setModalAbierto}
                   guardarDatos={validar}
                   mensaje={mensaje}
                   tipoAdvertencia={tipoAdvertencia}
                   aviso={aviso} setAviso={setAviso}
                   tamanio={"xs"}
                   cerrarBorrar={cerrarBorrar}
    >
        <form className={classes.root} autoComplete="off">
            <Grid container justifyContent={'space-around'} spacing={2}>
                <Grid item xs={6}>
                    <TextField id="Folio" name="Folio"
                               label="Folio" size="small"
                               onChange={handleFolio}/>
                    {validateErrorFolio !== '' ? <small style={{color: 'red'}}>{validateErrorFolio}</small> : null}
                </Grid>

                <Grid item xs={6}>
                    <TextField id="alias" name={'Alias'}
                               label="Alias" size="small"
                               onChange={handleAlias}/>
                    {validateErrorAlias !== '' ? <small style={{color: 'red'}}>{validateErrorAlias}</small> : null}
                </Grid>

                <Grid item xs={12}>
                    {listaRefacciones.length > 0 ? <Autocomplete
                        id='Descr'
                        onChange={(event, newValue) => handleDescr(newValue)}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                            if (params.inputValue.trim() !== '') {
                                filtered.push({
                                    inputValue: params.inputValue,
                                    label: `Agregar "${params.inputValue}"`,
                                });
                            }
                            return filtered;
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        options={listaRefacciones.map(e => e.Descr)}
                        getOptionLabel={(option) => {
                            if (typeof option === 'string') {
                                return option;
                            }
                            if (option.inputValue) {
                                return option.inputValue;
                            }
                            return option.label;
                        }}
                        loading={listaRefacciones.length != 0}
                        freeSolo
                        size="small"
                        color="primary"
                        renderInput={(params) => (
                            <TextField {...params} label="Escriba una refacción" variant="outlined"/>)}
                    /> : <LinearProgress color="primary"/>}
                    {validateErrorDesc !== '' ? <small style={{color: 'red'}}>{validateErrorDesc}</small> : null}
                </Grid>
            </Grid>
        </form>
    </Modal>);
};

export default CrearRefacciones;