import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Box,Collapse,IconButton,Paper,Table,TableBody,TableCell,LinearProgress,
        TableContainer,TableHead,TableRow,Tooltip} from '@material-ui/core';
        import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import axios from 'axios';
import { useHistory } from "react-router-dom";
import Editar from './EditarDetallePilo'
import EditarDummy from './EditarDetalleCabDummy'
import CreateIcon from '@material-ui/icons/Create';
import EditarDetallePil from './EditarDetallePilona';
import {llamaApiCargaToken,ErrorGeneral } from '../funciones/ClienteAxios';
import qs from 'qs';
import {findNumber} from "../funciones/FindNumber";
const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    //maxHeight: 550,
    maxHeight: window.innerHeight-480,
    minHeight: 190,
    minWidth:'100%',
  },
  container2: {
    maxHeight: 550,
    //maxHeight: window.innerHeight-170,
    //minHeight: 280,
  },
  tableRow: {
    "&$selected, &$selected:hover": {
      backgroundColor: "#F46239"
    }
  },
  tableCell: {
    "$selected &": {
      color: "white"
    }
  },
  hover: {},
  selected: {}
});

const columnas=[      
   /*      
    {
      Id:3,
      titulo:"Estatus",
      alineacion:'center' 
    }, */        
    {
      Id:2,
      titulo:"Lado A",
      alineacion:'center'
    },
    {
      Id:3,
      titulo:"Lado B",
      alineacion:'center'
    },
    { Id:4,
      titulo:"Descripción",
      alineacion:'left'
    },    
  ]

export default function TablaPilonaDetalle({orden, turno,usuarioContxt,cabinaSelec,setArrCabinas,setAvTotal,setAuxCabSelecc,nomPersonal,setAuxPilDet}) {
  const classes = useStyles();
  let history = useHistory();
  let nvlSNPermiso ='5'      
  let idusu= localStorage.getItem('UsuId');  
  const source = axios.CancelToken.source();       
  const [selectedID, setSelectedID] = useState(null);
  const [open, setOpen] = React.useState(false);     
  const [espera,setEspera]=useState(false);
  const [ordenSelecc, setOrdenSelecc] = React.useState(0);
  const [seleccionadoEdit, setSeleccionadoEdit] = React.useState([]);
  const [modalAbierto, setModalAbierto] = React.useState(false);
  const [modalListCab, setModalListCab] = React.useState(false);
  const [datos, guardarDatos] = React.useState([])  
  const[sinDatos,guardarSinDatos]= React.useState(false)
  const [update, setUpdate] = useState(false)

  React.useEffect(()=>{ 
    
     //console.log(cabinaSelec);
    
    llamarDetPilona()
    return ()=> {
      source.cancel();
    }                
   
  },[cabinaSelec]);
 
  React.useEffect(() => {
    llamarDetPilona()
  }, [update]);

  const llamarDetPilona = () => {        
    let data = qs.stringify({
      MttoId:cabinaSelec[0].Id,
      Usuario:idusu ,
      idDispositivo:'89y7gttvjhj98h'
    });
    let url = "/pilona-mtto-det-plan-list";
    function respuesta(auxiliar) {
      if (auxiliar ) 
      { //console.log(auxiliar);
        guardarDatos(auxiliar)
        guardarSinDatos(true)
      }               
    }      
    llamaApiCargaToken(data, url,respuesta,ErrorGeneral,setEspera,history,source);            
  };   

  const seleccionado=(auxId,auxOrden)=>{
    setSelectedID(auxId)      
  }

  function editarList(auxFalla) {    
    setModalListCab(true)
  }

  function tipoEstado(estado,idSelecc,etiq)
  { let auxEtiqueta=''
    let auxColor=''
    if (estado === 1) 
    { auxEtiqueta='Pendiente'
      auxColor='#D83906'
    }
    else
    { if (estado === 3) 
      { auxEtiqueta='Completo'
        auxColor='#107014'
      }
      else
      { if (estado === 2) 
        { auxEtiqueta='Avance'
          auxColor='#E1AA06'
        } 
      }
    }
    let renglon = <div></div>
    let colorCell = selectedID ===idSelecc? 'white':auxColor
  
    renglon = <TableCell style={{ color:colorCell,fontWeight:700,width:100+'px'}} 
                padding={'none'} align="center" >
                {etiq}
              </TableCell>
    return renglon
  }
  function verMas(auxId) {
    if (auxId === ordenSelecc) {
        setOpen(!open)
    } else {
        setOpen(true)
    }
    setOrdenSelecc(auxId)
}

  const celda = (alinear, estilo, contenido) => {
    return (
      <TableCell
        align={alinear} className={classes.tableCell}
        padding={"none"} style={estilo}
      >
        {contenido}
      </TableCell>
    );
  };
function miniTabla2( row) {
  return (
  <Table size="small" aria-label="purchases">
 
    <TableBody>
      {row.LadoAObserv !== '' ?
        <TableRow key={row.Id}>
          {celda("left", {width: '25px'},"" )} 
          {celda("left", {width: '50px', color: '#FF6439'}, <b> Lado A</b>)}
          {celda("left", {}, row.LadoAObserv)}                        
        </TableRow> 
        : null
      }
      {row.LadoBObserv !== '' ? 
      <TableRow key={row.Id}>
        {celda("left", {width: '25px'},"" )} 
        {celda("left", {width: '50px', color: '#FF6439'}, <b> Lado B</b>)}          
        {celda("left", {}, row.LadoBObserv)}          
      </TableRow> 
      : null
      }
    </TableBody>
</Table>
)
}

const IconoCol=({row})=>{
  return( 
  <TableCell align="center" padding={'none'} style={{width: 30 + 'px'}}>
    <Tooltip title={open && ordenSelecc === row.Id ? 'Ver menos' : 'Ver más'}>
      <IconButton color="primary" aria-label="expand row" size="small"
        onClick={() => verMas(row.Id)}>
        {open && ordenSelecc === row.Id ? 
          (selectedID === row.Id ?
            <KeyboardArrowUpIcon style={{color: 'white'}}/> 
          : <KeyboardArrowUpIcon/>) 
          : (selectedID === row.Id ? 
              <KeyboardArrowDownIcon style={{color: 'white'}}/> 
            :<KeyboardArrowDownIcon/>)
        }
      </IconButton>
    </Tooltip>
  </TableCell>               
  )
}
let celdaVacia=<TableCell align="center" padding={'none'}></TableCell> 
function Row(props) {
  const { row } = props;  
  return (
    <React.Fragment>
      <TableRow onClick={ () => { seleccionado(row.Id,row); } }
        className={classes.tableRow} selected={ selectedID === row.Id}
        classes={{ hover: classes.hover, selected: classes.selected }}        
      >  
        {/* row.LadoAObserv!==""|| row.LadoBObserv !==""  ? <IconoCol row={row} />:celdaVacia */}                          
        {tipoEstado(row.statusId,row.Id,row.statusNom)}     
        {celda("center", {width:75+'px'},row.LadoA===1 ? "Ok" :"Pendiente" )}  
        {celda("center", {width:80+'px'},row.LadoB===1 ? "Ok" :"Pendiente" )}      
        {celda("left", {}, row.TpPlanNom )}                                                                                                                  
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5} >                                       
          {miniTabla2( row)}         
        </TableCell>
      </TableRow>    
    </React.Fragment>
    );
  }

  function tablaCompleta(auxlista){
    //console.log("entrando sin preguntar");
    return(
    <TableContainer className={classes.container}>
    <Table stickyHeader aria-label="sticky table">
      <TableHead>
        <TableRow>                          
          <TableCell align="center" padding={'none'}>
            {findNumber('|51|') ?  
              <Tooltip title={'Editar'}>
                <IconButton   aria-label="edit List" size="small" 
                  onClick={() => editarList()} component="span"
                >
                  <CreateIcon color="primary"/>
                </IconButton>    
              </Tooltip> 
            : null}
          </TableCell>                             
          {columnas.map((col)=>{
            return <TableCell  key={col.Id} align={col.alineacion} 
              padding={'none'}
            >
              {col.titulo}
            </TableCell> 
          })}                   
        </TableRow>
      </TableHead>
      <TableBody>
        {auxlista.length!==0 ? (auxlista.map((row) => (
          <Row key={row.Id} row={row} />
        ))):null}
      </TableBody>
    </Table>
    </TableContainer>
   )
  }

  const tablaSinDatos=()=>{
   return(<TableContainer className={classes.container2}>
    <Table stickyHeader aria-label="sticky table">
      <TableHead>
        <TableRow>            
        {columnas.map((col)=>{
       return   <TableCell  key={col.Id} align={col.alineacion} 
            padding={'none'}
          >
            {col.titulo}
          </TableCell> 
        })}                                            
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow  className={classes.tableRow} >          
          <TableCell  className={classes.tableCell} align="center" padding={'none'}>
            Dia
          </TableCell>          
          <TableCell  className={classes.tableCell} align="left" padding={'none'}>
            Sin
          </TableCell>             
          <TableCell  className={classes.tableCell} align="left" padding={'none'}>
            Reportes
          </TableCell>                      
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
  ) 
  }

  const tablaSinResultados=()=>{
    return(
      <TableContainer className={classes.container2}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>            
              {columnas.map((col)=>{
               return <TableCell  key={col.Id} align={col.alineacion} 
                  padding={'none'}
                >
                  {col.titulo}
                </TableCell> 
              })}                     
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className={classes.tableRow} >          
              <TableCell  className={classes.tableCell} align="center">Orden</TableCell>          
              <TableCell  className={classes.tableCell} align="left">Sin</TableCell>             
              <TableCell  className={classes.tableCell} align="left">coincidencias</TableCell>                  
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    ) 
   }

  function filtro()
  {        
    let info=[]      
    if (turno.length!=0) 
    { let auxCadena=turno.trim();       
      info=datos.filter((dat)=>dat.turno.toString().search(auxCadena.toUpperCase() ) !== -1)   
     // console.log(info);
    } 
    else 
    {
      if (orden.length!=0) 
      { let auxCadena=orden.trim();
        info=datos.filter((dat)=>dat.Id.toString().search(auxCadena) !== -1)                   
      }
    }
    //let info=datos.filter((dat)=>dat.Id.toString().search(auxCadena.toUpperCase() ) !== -1)         
    let metodos=null
    if (info.length>0) {
     //console.log("mandandando la lista");
      metodos= tablaCompleta(info)
    } else {
      metodos=tablaSinResultados()
    }
    return metodos ;
  }

  const contenido2= turno!=''||  orden!=''  ? filtro() :tablaCompleta(datos) 
  const contenido = sinDatos && datos.length !== 0 ? contenido2 : tablaSinDatos()
  const tabla = espera ?  (<Box mt={2} mb={2} ml={2} mr={2}><LinearProgress/> </Box>) :( contenido )
  const modal =seleccionadoEdit.length!=0 && modalAbierto ? 
              (  usuarioContxt?.nivel !=undefined && usuarioContxt.nivel!== nvlSNPermiso ?
                (<Editar titulo={'Editar Cabina '+seleccionadoEdit.cabinaId+" "+seleccionadoEdit.planCabDescr} 
                  modalAbierto={modalAbierto} setModalAbierto={setModalAbierto}
                  mttoActividad={seleccionadoEdit} setArrCabinas={setArrCabinas} //setBuscar={setBuscar} buscar={buscar}
                />)
              
              :(<EditarDummy titulo={'Cabina '+seleccionadoEdit.cabinaId+" "+seleccionadoEdit.planCabDescr} 
                  modalAbierto={modalAbierto} setModalAbierto={setModalAbierto} mttoActividad={seleccionadoEdit} 
                />)              
              )        
              :null
  const modListCab = modalListCab ? 
            (usuarioContxt?.nivel !== undefined && usuarioContxt.nivel !== nvlSNPermiso ?
               ( 
                <EditarDetallePil titulo={`Pilona ${cabinaSelec[0].PiloNom} Detalle (${datos.length})`  }
                  modalAbierto={modalListCab} setModalAbierto={setModalListCab} mttoActividad={datos}
                  setAvTotal={setAvTotal} setAuxCabSelecc={setAuxCabSelecc} pilona={cabinaSelec[0]}
                  setEsperaTabla={setEspera} nomPersonal={nomPersonal} setUpdate={setUpdate}
                  update={update} setAuxPilDet={setAuxPilDet}
              />) :null)
              :null
  return (
  <Paper className={classes.root}>
    {tabla}
    {modal}
    {modListCab}
  </Paper>
  );
}