import React,{useState,useEffect} from 'react'
import {List,ListItem ,ListItemText //,ListItemIcon
        ,Divider,Collapse ,Typography  } from '@material-ui/core';
/* import InboxIcon from '@material-ui/icons/MoveToInbox';
import BuildIcon from '@material-ui/icons/Build'; */
import {//DirectionsTransitIcon,
        ExpandLess,ExpandMore  } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import {findNumberCad} from '../funciones/FindNumber'
import {GeneralesContext} from '../context/GeneralesContext'
const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
      
    },
    nested: {
      paddingLeft: theme.spacing(4),
      
    },
    nested2: {
        paddingLeft: theme.spacing(8),
        
      },
  }));

const Listas = ({funcionAbrir}) => {
    let history = useHistory();
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [open4, setOpen4] = useState(false);
    const [open5, setOpen5] = useState(false);
    const [open6, setOpen6] = useState(false);
    const [cadena, setCadena] = useState("");
    let idTipo = localStorage.getItem('Tipo');
    const {generales}=React.useContext(GeneralesContext);

    useEffect(() => {
        let str = localStorage.getItem("UserOperacion");
        if ((str===null ||str===undefined) && generales.userOperacion) 
        {
            setCadena(generales.userOperacion+"|")
          
        }else{
            setCadena(str+"|")
          
        }
      
    }, [generales])    

      const cambiar = (opcion) => {
        switch (opcion) {
            case 1:
                setOpen(!open);    
                break;
            case 2:
                setOpen2(!open2);    
                break;
            case 3:
                setOpen3(!open3);    
                break;
            case 4:
                setOpen4(!open4);    
                break;  
            case 5:
                setOpen5(!open5);    
                break;   
            case 6:
                setOpen6(!open6);    
                break;                
            default:
                break;
        }              
        
    };
    const nuevaVentana =(elLink)=>{    
        //funcionAbrir()
        history.push(elLink)	
    }

    const contacto=(e)=> {
        e.preventDefault()
        window.open("https://daduva.mx");
    }

    const ItemLista = (link, descripcion) => {
        return (
        <ListItem button className={classes.nested}
          onClick={() => nuevaVentana(link)}
        >
          <ListItemText
            secondary={
              <Typography variant="body1" style={{ color: "white" }}>
                {descripcion}
              </Typography>
            }
          />
        </ListItem>
        );
      };
    
      const ItemLista2 = (link, descripcion) => {
        return (
        <ListItem button className={classes.nested2}
            onClick={() => nuevaVentana(link)}
        >
            <ListItemText
            secondary={
                <Typography variant="body1" style={{ color: "white" }}>
                {descripcion}
                </Typography>
            }
            />
        </ListItem>
        );
    };

    return (
    <div>
    <List component='nav'>                
        <ListItem button onClick={()=>cambiar(2)}>                    
            <ListItemText primary="Paros y Fallas" />
            {open2 ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open2} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>                        
                {findNumberCad('|8|',cadena) ||findNumberCad('|11|',cadena) ? ItemLista("/paros-fallas", "Bitácora"): null }                      
                {findNumberCad('|8|',cadena) || findNumberCad('|12|',cadena) ? ItemLista("/direct-drive", "Direct-Drive") : null}                                                                        
                {findNumberCad('|65|',cadena) ?  ItemLista("/carro-tensor", "Carro Tensor")   : null}                                             
            </List>
        </Collapse>
        <Divider/>
        <ListItem button onClick={()=>cambiar(3)}>                    
            <ListItemText primary="Mantenimiento" />
            {open3 ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open3} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>   
                <ListItem button onClick={()=>cambiar(6)}  className={classes.nested}>                    
                    <ListItemText primary="Teleféricos" />
                    {open6 ? <ExpandLess /> : <ExpandMore />}
                </ListItem>      
                <Collapse in={open6} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>                               
                        {findNumberCad('|5|',cadena) ?ItemLista2("/orden-trabajo", "Orden de Trabajo"): null}                                                        
                        {findNumberCad('|46|',cadena) ? ItemLista2("/mtto-cabina", "Mtto Cabinas") : null}                     
                        {findNumberCad('|49|',cadena) ?ItemLista2("/mtto-pilona", "Mtto Pilonas") : null}
                    </List>
                </Collapse>   
                {findNumberCad('|28|',cadena) ?ItemLista("/edificio-mantenimiento", "Edificios"): null}     
            </List>
        </Collapse>
        <Divider/>
        <ListItem button onClick={()=>cambiar(4)}>                    
            <ListItemText primary="Sistema" />
            {open4 ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open4} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>           
                {findNumberCad('|26|',cadena)  ?ItemLista("/catalogo-energia", "Consumo de energia"):null }                                     
                {findNumberCad('|23|',cadena) ?ItemLista("/catalogo-paro-solucion", "Paro/Falla - Solución"):null }  
                {findNumberCad('|21|',cadena) ?ItemLista("/catalogo-refacciones", "Catálogo Refacciones") :null }  
                {/* findNumberCad('|33|',cadena) ?ItemLista("/bitacora-cctv", "CCTV"):null  */}
                {findNumberCad('|69|',cadena) ?ItemLista("/monitoreo-cctv", "CCTV Monitoreo"):null }
                {/* idTipo && parseInt(idTipo) === 1 && findNumber('|36|') ? ItemLista("/elimina-evidencia", "Eliminación Evidencia") :null */}
                { findNumberCad('|36|',cadena) ? ItemLista("/elimina-evidencia", "Eliminación Evidencia") :null}
                {/* idTipo && parseInt(idTipo) === 1 && findNumber('|45|') ? ItemLista("/elimina-evidencia-edificio", "Eliminación Evidencia Edificio") :null */}
                { findNumberCad('|45|',cadena) ? ItemLista("/elimina-evidencia-edificio", "Eliminación Evidencia Edificio") :null}
                {/*ItemLista("/dias-Festivos", "Dias Festivos")*/ }                        
                {/* ItemLista("/horario", "Horario") */ }
                { findNumberCad('|15|',cadena) ?ItemLista("/catalogo-usuarios", "Usuarios"):null  }    
                {/* idTipo && parseInt(idTipo) === 1 && findNumber('|15|') ?ItemLista("/catalogo-usuarios", "Usuarios"):null   */}                                                                        
            </List>
        </Collapse>
        <Divider/>
        <ListItem button onClick={()=>cambiar(5)}>                    
            <ListItemText primary="Reportes" />
            {open5 ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open5} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>                        
                {findNumberCad('|53|',cadena) ? ItemLista("/reporte-bitacora-rango", "Bitácora") :null}
                {findNumberCad('|55|',cadena) ? ItemLista("/reporte-direct-drive", "Direct-Drive") :null }
                {findNumberCad('|57|',cadena) ? ItemLista("/reporte-mtto-edificio", "Mtto Edificios") :null}
                {findNumberCad('|59|',cadena) ? ItemLista("/reporte-orden-actividad", "Ordenes y Actividades") :null }   
                {findNumberCad('|63|',cadena) ? ItemLista("/reporte-cctv", "CCTV") :null }  
                {findNumberCad('|72|',cadena) ? ItemLista("/reporte-monitoreo", "CCTV Monitoreo") :null }                                                       
                {findNumberCad('|61|',cadena) ? ItemLista("/reporte-mtto-excel", "Mtto Filtro") :null }     
            </List>
        </Collapse>
        <Divider/>
        <ListItem button onClick={contacto} >                    
            <ListItemText primary="Contacto" />                    
        </ListItem>
    </List>
    </div>
    )
}

export default Listas
