import {makeStyles} from "@material-ui/core/styles";
import {useState} from "react";
import ModalDesactivar from "./ModalDesactivar";
import {Grid} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    imageSize: {
        width: '14vw', height: '20vh', margin: theme.spacing(1)
    },
    container: {
        overflowY: 'auto', maxHeight: window.innerHeight - 170
    }
}));

const Galeria = ({
                     galeria, idusu, history, source, cargando, setCargando, abrirModal, setAbrirModal
                 }) => {

    const classes = useStyles();
    const [imageId, setImageId] = useState(0);
    const [imageSrc, setImageSrc] = useState('');

    const handleDelete = (event) => {
        setImageId(event.target.alt);
        setImageSrc(event.target.src);
        setAbrirModal(true);
    }

    const lista = () => {
        return galeria.map(element => {
            return <Grid item>
                <img
                    src={element.Link}
                    className={classes.imageSize}
                    alt={element.Id}
                    id={element.Id}
                    name={element.Id}
                    onClick={handleDelete}
                />
            </Grid>
        })
    }
    const modalDesactivar = <ModalDesactivar abrirModal={abrirModal}
                                             setAbrirModal={setAbrirModal}
                                             cargando={cargando}
                                             setCargando={setCargando}
                                             imageId={imageId}
                                             imageSrc={imageSrc}
                                             history={history}
                                             source={source}
                                             idusu={idusu}
                                             galeria={galeria}
    />

    return (<>
        {modalDesactivar}
        <Grid container className={classes.container}>
            {lista()}
        </Grid>
    </>);
};

export default Galeria;