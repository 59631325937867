import {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {
    Grid, TextField, LinearProgress
} from "@material-ui/core";
import Modal from "../Generales/Modal2";
import {useHistory} from "react-router-dom";
import axios from "axios";
import qs from "qs";
import Autocomplete, {createFilterOptions} from "@material-ui/lab/Autocomplete";
import {llamaApiCargaToken, ErrorGeneral} from '../funciones/ClienteAxios';
import 'react-toastify/dist/ReactToastify.css';
import {toast} from 'react-toastify';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: {
            margin: theme.spacing(1),
        },
    }, medidor: {
        width: '100px', marginBottom: '1em'
    }, campos: {
        width: '200px', marginBottom: '1em'
    }, camposLectura: {
        width: '150px', marginBottom: '1em'
    }, fecha: {
        width: '200px', marginTop: '3px', marginBottom: '1em'
    }

}));

const CrearParoFallas = ({
                             modalAbierto,
                             setModalAbierto,
                             titulo,
                             actualiza,
                             setActualiza,
                             catalogo
                         }) => {

    const classes = useStyles();
    const filter = createFilterOptions();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const historial = useHistory();

    const [mensaje, setMensaje] = useState("");
    const [tipoAdvertencia, setTipoAdvertencia] = useState("success");
    const [aviso, setAviso] = useState(false);
    const [registro, setRegistro] = useState({
        Descr: '', Fecha: '', Id: '', Status: ''
    });
    const [validateErrorDesc, setValidateErrorDesc] = useState('');
    const [cargando, setCargando] = useState(false);
    const [botonDeshabilitado, setBotonDeshabilitado] = useState(false);

    const crearParoFalla = () => {
        let data = qs.stringify({
            usuario: localStorage.getItem("UsuId"),
            llave: 0,
            descr: registro.Descr,
            status: 1,
            idDispositivo: '987gyubjhkkjh'
        });
        let url = "/parofalla-catalogo-editar";

        function respuesta(auxiliar) {
            if (auxiliar.respuesta === 1) {
                catalogo.splice(0, 0, registro)
                setModalAbierto(!modalAbierto)
                setTipoAdvertencia("success")
                let mensaje="Se agrego correctamente la solución "+auxiliar.mensaje
                setMensaje(mensaje);
                setAviso(true)
                toast.success(mensaje, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                // catalogo.splice(0, 0, registro)
            }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, historial, source);

        setActualiza(!actualiza);
    }

    const cerrarBorrar = () => {
        setAviso(false);
        setModalAbierto(false)
    };

    const handleDescr = (newValue) => {
        setValidateErrorDesc('')
        if (typeof newValue === 'string' && newValue) {
            setBotonDeshabilitado(true)
            setValidateErrorDesc('Ya existe una solución con la misma descripción.')

        } else if (newValue && newValue.inputValue) {
            if (newValue.inputValue.trim().length < 4) {
                setBotonDeshabilitado(true)
                setValidateErrorDesc('La descripción es insuficiente...')
            } else if (newValue.inputValue.trim() === registro.Descr) {
                setBotonDeshabilitado(true)
                setValidateErrorDesc('Ya existe una solución con la misma descripción.')
            }
            setRegistro({
                ...registro, Descr: newValue.inputValue.trim(),
            })
            setBotonDeshabilitado(false)
        } else {
            if (typeof newValue === 'object') {
                if (newValue) {
                    setRegistro({
                        ...registro, Descr: newValue.value,
                    })
                } else {
                    setRegistro({
                        ...registro, Descr: '',
                    })
                }
            }
        }
    }

    const validarDescr = () => {
        if (registro.Descr !== '' && registro.Descr.length > 3) {
            setValidateErrorDesc('')
        } else {
            toast.error('El campo de Solución obligatorio.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setValidateErrorDesc('El campo de Solución obligatorio.')
        }
    }

    const validar = () => {
        if (registro.Descr !== '') {
            setBotonDeshabilitado(false)
            setCargando(true)
            return crearParoFalla()
        } else {
            validarDescr()
        }
    }

    return (<Modal hideBackdrop
                   disableEscapeKeyDown
                   espera={cargando}
                   btnDesact={botonDeshabilitado}
                   titulo={titulo}
                   modalAbierto={modalAbierto}
                   setModalAbierto={setModalAbierto}
                   guardarDatos={validar}
                   mensaje={mensaje}
                   tipoAdvertencia={tipoAdvertencia}
                   aviso={aviso} setAviso={setAviso}
                   tamanio={"sm"}
                   cerrarBorrar={cerrarBorrar}
    >
        <form className={classes.root} autoComplete="off">
            <Grid container justifyContent={'space-around'} spacing={2}>
                <Grid item xs={12}>
                    {catalogo.length > 0 ? <Autocomplete
                        id='Descr'
                        onChange={(event, newValue) => handleDescr(newValue)}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                            // Suggest the creation of a new value
                            if (params.inputValue.trim() !== '') {
                                filtered.push({
                                    inputValue: params.inputValue,
                                    label: `Agregar "${params.inputValue}"`,
                                });
                            }
                            return filtered;
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        options={catalogo.map(e => e.Descr)}
                        getOptionLabel={(option) => {
                            if (typeof option === 'string') {
                                return option;
                            }
                            if (option.inputValue) {
                                return option.inputValue;
                            }
                            return option.label;
                        }}
                        loading={catalogo.length != 0}
                        freeSolo
                        size="small"
                        color="primary"
                        renderInput={(params) => (
                            <TextField {...params} label="Escriba una solución" variant="outlined"/>)}
                    /> : <LinearProgress color="primary"/>}
                    {validateErrorDesc !== '' ? <small style={{color: 'red'}}>{validateErrorDesc}</small> : null}
                </Grid>
            </Grid>
        </form>
    </Modal>);
};

export default CrearParoFallas;