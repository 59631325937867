import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {InputLabel,FormControl,Select as MaterialSelect  ,Box 
  ,TextareaAutosize ,TextField,RadioGroup,FormControlLabel,Radio
  } from '@material-ui/core';
import axios from 'axios';
import qs from 'qs';
import {toast} from "react-toastify";
import { useHistory } from "react-router-dom";
import Relojes from './Relojes4'
import Modal from './Modal';
import moment from 'moment';
import {llamaApiCargaToken,ErrorGeneral } from '../funciones/ClienteAxios';
const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '20ch',
      }, 
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
      Textnumero: {
        width: 5+"px",
      },
      formControlFull: {
        //margin: theme.spacing(1),
        //minWidth: 120,
        width:5+"rem"
      },
    },
  }));

const EditarCierre = ({modalAbierto,setModalAbierto,titulo,bitacora,bitacoraTotales,setBitacoraTotales}) => {
  let history = useHistory();
  const classes = useStyles();
  const fhoy=new Date()
  const source = axios.CancelToken.source();   
  const [espera,setEspera]= useState(false);
  const [mensaje, setMensaje] = useState('');
  const [aviso, setAviso] = useState(false);
  const [tipoAdvertencia, setTipoAdvertencia] = useState('success');  
  const [horaCierre,guardarHoraCierre]= useState({Horario:'',Cabinas:0,Pinzas:0, Usuarios:0,Exentos:0,Incidencia:''});
  const {Horario,Cabinas,Pinzas,Usuarios,Exentos,Incidencia}=horaCierre
  React.useEffect(()=>{ 
    const {Cabinas,Pinzas,Usuarios,Exentos,Incidencia,HorarioFin} = bitacoraTotales    
    guardarHoraCierre({
    ...horaCierre,
    Horario:HorarioFin!=="00:00:00"? `Thu Jun 17 2021 ${HorarioFin} GMT-0600`:fhoy , 
    Cabinas:Cabinas,Pinzas:Pinzas,Usuarios:Usuarios,Exentos:Exentos,Incidencia:Incidencia
    //Tiempo:'Thu Jun 17 2021 '+tiempoOp+' GMT-0500 (hora de verano central)',    
   })
                 
    return ()=> {
      source.cancel();
    }
    
  },[]);

  const registrarCierre=(auxHFin)=>{
    const idusu= localStorage.getItem('UsuId');                  
    const data = qs.stringify({
        bitacora:bitacora,
        usuario:idusu,
        fechaTermino:auxHFin,
        cabina:Cabinas,
        pinza:Pinzas,
        userNormal:Usuarios,
        userExento:Exentos,
        incidencia:Incidencia,
        idDispositivo:'987t67fyvgh98uh'
      });   
    const url = "/bitacora-editar-cierre";
    function respuesta(aux) {
      if (aux.respuesta==1) 
      {                                                                 
        //console.log(aux);
        let mensaje="Se registro correctamente el Cierre "
        toast.success(mensaje, {
          position: "top-right", autoClose: 5000,
          hideProgressBar: false, closeOnClick: true,
          pauseOnHover: true, draggable: true,
          progress: undefined,
        });
        setModalAbierto(false)
        setTipoAdvertencia("success")      
        setMensaje(mensaje);
        setAviso(true)          
        setBitacoraTotales({
          ...bitacoraTotales,
          Cabinas:Cabinas,Pinzas:Pinzas ,Usuarios:Usuarios,Exentos:Exentos,
          Incidencia:Incidencia,HorarioFin:auxHFin,Vueltas:aux.vuelta,Recorrido:aux.recorrido
          
        })               
      }        
    }    
    llamaApiCargaToken(data, url,respuesta,ErrorGeneral,setEspera,history,source);                 
  }  

  const onChange = e =>
  { let expNopermitida = new RegExp('[#A-Za-z_:$!%-({})/*=?|]');
    let expMenos = new RegExp('-');
    let expMas = new RegExp('[+]');     
    let expCadena = new RegExp('[0-9]');
    if (expCadena.test(e.target.value)&&!expNopermitida.test(e.target.value)&&!expMenos.test(e.target.value)&&
        !expMas.test(e.target.value)||e.target.value===' '||e.target.value==='') 
    {
      guardarHoraCierre({
        ...horaCierre,
        [e.target.name] : e.target.value  
      })
      if (aviso) 
      {
        setMensaje('');
        setAviso(false);
      }
    }
  };

  const onChange2 = e =>
  { let expNopermitida = new RegExp('[#_:$!%-({})/*=?|]');
    let expMenos = new RegExp('-');
    let expMas = new RegExp('[+]');     
    let expCadena = new RegExp('[A-Za-z0-9]');
    if (expCadena.test(e.target.value)&&!expNopermitida.test(e.target.value)&&!expMenos.test(e.target.value)&&
        !expMas.test(e.target.value)||e.target.value===' '||e.target.value==='') 
    {
      guardarHoraCierre({
        ...horaCierre,
        [e.target.name] : e.target.value  
      })
      if (aviso) 
      {
        setMensaje('');
        setAviso(false);
      }
    }
  };
  
  function verifHorario(auxHora)
  { let fec = moment(Horario);
    let auxNum =parseInt(fec.format("HH"));
    console.log(auxNum);
    return(auxNum )                       
  }

  const mostrarAlertas =(auxMensaje)=>{
    setTipoAdvertencia("warning")
    let mensaje=auxMensaje            
    setMensaje(mensaje);
    setAviso(true)   
  }

  function valNulos(auxMensaje,auxCampo) 
  {    let valida=false    
      if ( auxCampo !== '' && auxCampo !== '0' && auxCampo !==0 
          && auxCampo !== null && auxCampo!==" " ) 
      {   valida=true              
      }
      if (!valida) 
      {   mostrarAlertas(auxMensaje)
      }
      return valida
  }

  const guardarDatos = () => 
  {
    if (Horario!= '' &&Horario!= '0' && Horario!= null && verifHorario(Horario)>5&&verifHorario(Horario)<=23) 
    { if (Cabinas != ''&&Cabinas != '0') 
      { if (Pinzas != ''&& Pinzas != '0') 
        {  
          if (Usuarios != ''&& Usuarios != '0') 
          {            
            if (valNulos("Debe llenar el campo Incidencia Externa",Incidencia ) ) {
              let fec = moment(Horario);                        
              //console.log("con incidencia",Incidencia);
              registrarCierre(fec.format("HH:mm"))             
            }                                              
          } 
          else 
          { mostrarAlertas("Debe llenar el campo Usuarios")            
          }
        } 
        else 
        { mostrarAlertas("Debe llenar el campo Pinzas")          
        }
      }   
      else
      { mostrarAlertas("Debe llenar el campo Cabinas")        
      }   
    } 
    else 
    { mostrarAlertas("Debe seleccionar Hora de Termino")      
    }                           
  };
  
  return ( 
  <Modal
    titulo={titulo} modalAbierto={modalAbierto}
    setModalAbierto={setModalAbierto} guardarDatos={guardarDatos}
    mensaje={mensaje} tipoAdvertencia={tipoAdvertencia}
    aviso={aviso} setAviso={setAviso} espera={espera} btnCancelar={false}
  >
    <form className={classes.root} noValidate autoComplete="off" fullWidth>                    
      
      <Relojes horaApertura={horaCierre}  guardarHoraApertura={guardarHoraCierre}/>   
      <TextField id="input-cabina" label="Cabinas" size="small" type="number"
        value={Cabinas} onChange={onChange} name="Cabinas" style={{width:6.5+'rem'}}
      />
      <TextField id="input-pinza" label="Pinzas" size="small" type="number"
        value={Pinzas} onChange={onChange} name="Pinzas" style={{width:6.5+'rem'}}
      />
      <br/>
      <TextField id="input-usuarios" label="Usuarios" size="small" type="number"
        value={Usuarios} onChange={onChange} name="Usuarios" style={{width:6.5+'rem'}}
      />        
      <TextField id="input-usuarios" label="Exentos" size="small" type="number"
          value={Exentos} onChange={onChange} name="Exentos" style={{width:6.5+'rem'}}
      />
      <br/>
      <TextField id="input-usuarios" label="Incidencia Externa" size="small" type="text"
        value={Incidencia} onChange={onChange2} name="Incidencia" style={{width:25+'rem'}}
      />
    </form>
  </Modal>
  );
}
 
export default EditarCierre;