import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {TextField ,LinearProgress,Box ,
  //Select as MaterialSelect ,FormControlLabel,InputLabel,FormControl,Checkbox
  } from '@material-ui/core';
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import Autocomplete //, { createFilterOptions } 
  from '@material-ui/lab/Autocomplete';
import Modal from '../Generales/ModalTam';
//import Rating from '@material-ui/lab/Rating';
import {toast} from "react-toastify";
import {ErrorGeneral, llamaApiCargaToken} from "../funciones/ClienteAxios";
const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
       // width: '20ch',
      }, 
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
      Textnumero: {
        width: 5+"px",
      },
      formControlFull: {
        //margin: theme.spacing(1),
        //minWidth: 120,
        width:5+"rem"
      },
      margin: {
        margin: theme.spacing(1),
      },
    },
  }));

const NuevaRefaccion = ({modalAbierto,setModalAbierto,titulo,auxOrdenId,funcionActual}) => {
  //const filter = createFilterOptions();
  let history = useHistory();
  const classes = useStyles();
  const [espera,setEspera]=useState(false);
  const [mensaje, setMensaje] = useState('');
  const [tipoAdvertencia, setTipoAdvertencia] = useState('success');
  const [aviso, setAviso] = useState(false);  
  const [nvActividad,guardarNvActividad]= useState({Orden:0,Finaliza:0,refaccion:0,
                                                          Descripcion:'',cantidad:0});  
  const {Orden,refaccion,Descripcion,cantidad}=nvActividad  
  const[seleccionado,setSeleccionado]= useState(null)
  const [listRefacc, guardarListRefacc] = useState([])
  //const [value, setValue] = React.useState(0);
  const source = axios.CancelToken.source();    
  React.useEffect(()=>{           
      guardarNvActividad({
        ...nvActividad,
        Orden:auxOrdenId
      })    
              
      
   const llenarListTipo = () => {
    let data = qs.stringify({
        idDispositivo: '987gyubjhkkjh'
    });
    let url = "/orden-refaccion";

    function respuesta(aux) {
      if (aux[0].Id!==-1) 
      {//console.log(aux);
        let auxArre=[];   
        aux.forEach((tipo) => {
          if (tipo.Id!==0) 
          {
            auxArre.push({ value : tipo.Id, label :tipo.Descr })  
          }            
        })                    
        guardarListRefacc(auxArre);                                      
      }    
    }
    llamaApiCargaToken(data, url,respuesta,ErrorGeneral,setEspera,history,source);
  };   
       
  if (listRefacc.length===0) 
  {
    llenarListTipo()
  }  
  return ()=> {
      source.cancel();
  }     
    // eslint-disable-next-line react-hooks/exhaustive-deps	
},[]);

  const registrarNRefaccion=()=>{        
    const llenarNvRef = () => {
      let idusu= localStorage.getItem('UsuId'); 
      //let NomUsu= localStorage.getItem('UsuarioNom');  
      let data = qs.stringify({
        'Orden':Orden,
        'Usuario':idusu,
        'Refaccion':refaccion,
        'Cantidad':cantidad,
        'Descr':Descripcion,
        'idDispositivo':'8978yuhjnfrsdsd'
      });
      let url = "/orden-refaccion-nuevo";
      
      function respuesta(aux) 
      {  if (aux.respuesta===1) 
        { //console.log(aux);
          let mensaje='Se registro correctamente la Nueva Refacción'
          setTipoAdvertencia("success")      
          setMensaje(mensaje);
          setAviso(true)   
          toast.success(mensaje, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });    
          funcionActual()   
          setModalAbierto(false)               
        }    
      };
      llamaApiCargaToken(data, url,respuesta,ErrorGeneral,setEspera,history,source);     
    }

    llenarNvRef();    
  }

  const onChange = e =>
  { let expNopermitida = new RegExp('[#A-Za-z_:$!%-({})/*=?|]');
    let expMenos = new RegExp('-');
    let expMas = new RegExp('[+]');     
    let expCadena = new RegExp('[0-9]');
    if ( ( expCadena.test(e.target.value)&&!expNopermitida.test(e.target.value)&&!expMenos.test(e.target.value)&&
        !expMas.test(e.target.value) ) || e.target.value===' '||e.target.value==='') 
    {
    
      guardarNvActividad({
        ...nvActividad,
        [e.target.name] : e.target.value  
      })
      if (aviso) 
      {
        setMensaje('');
        setAviso(false);
      }
    }
  };
  

  const validaDatos=(valor, mensaje)=>{
    let respuesta=false    
    if (valor!== null && valor!== '' && valor!== '0' && valor!== 0 ) 
    { respuesta=true     
    }
    else
    { setTipoAdvertencia("warning")    
      setMensaje(mensaje);
      setAviso(true)     
    }
    return respuesta
  }
  const guardarDatos = () => 
  {
    if (validaDatos(cantidad,"Escribir Cantidad")) 
    { if ( validaDatos(refaccion,"Seleccionar refacción")) 
      { if ( ((Descripcion.length>=5 && validaDatos(Descripcion, "Seleccionar refacción") ))
          || (Descripcion.length<5 && validaDatos(0, "Seleccionar refacción"))
        ) 
        { 
          registrarNRefaccion()                                         
        }           
      }    
    }                             
  };
  
  /* const guardarParo=(newValue)=>{
    //console.log(newValue);
    if (typeof newValue === 'string' && newValue ) {          
      guardarNvActividad({
        ...nvActividad,
        refaccion :newValue.value  ,
        Descripcion:newValue.label,                
      })
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input          
      guardarNvActividad({
        ...nvActividad,
        refaccion :1 ,
        Descripcion: newValue.inputValue.trim(),
        
      })
    } else {
      if (typeof newValue === 'object' ) {            
        if (newValue) {
          guardarNvActividad({
            ...nvActividad,
            refaccion :newValue.value  ,
            Descripcion: newValue.label,     
          })            
        }else{
          guardarNvActividad({
          ...nvActividad,
          refaccion :''  ,
          Descripcion: '',              
        })   }         
      }
      
    }
  } */
  const guardarParo=(newValue)=>{
    //console.log(newValue);   
    if (typeof newValue === 'object' ) 
    { if (newValue) 
      {
        guardarNvActividad({
          ...nvActividad,
          refaccion :newValue.value  ,
          Descripcion: newValue.label,     
        })      
        setSeleccionado(newValue)      
      }else{
        guardarNvActividad({
          ...nvActividad,
          refaccion :''  ,
          Descripcion: '',              
        })  
        setSeleccionado(null)  
     }         
    }
      
    
  }
  return ( 
    <Modal
      titulo={titulo} modalAbierto={modalAbierto} setEspera={setEspera}
      setModalAbierto={setModalAbierto} guardarDatos={guardarDatos}
      mensaje={mensaje} tipoAdvertencia={tipoAdvertencia}
      aviso={aviso} setAviso={setAviso} espera={espera} tamanio={"md"}
    >
      <form className={classes.root} noValidate autoComplete="off" fullWidth>  
      <Box display="flex" flexDirection="row">
        <Box >      
          <TextField id="input-cantidad" label="Cantidad" size="small" type="number"
            value={cantidad} onChange={onChange} name="cantidad" style={{width:4+'rem'}}
          />
        </Box>                             
      <Box ml={1} pt={1}>
      {/* listRefacc.length!=0 ?  
      <Autocomplete
      value={Descripcion} handleHomeEndKeys      
      id= 'AutoSelect-Solucion' selectOnFocus clearOnBlur      
      onChange={(event, newValue) =>guardarParo(newValue) }
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        // Suggest the creation of a new value
        if (params.inputValue.trim() !== '') {
          filtered.push({
            inputValue: params.inputValue,
            label: `Agregar "${params.inputValue}"`,
          });
        }
        return filtered;
      }}
      
      options={listRefacc}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.label;
      }}
      renderOption={(option) => option.label}
      style={{ width: 550 }}
      loading={listRefacc.length!=0 }
      freeSolo size="small" color="primary"
      renderInput={(params) => (
        <TextField {...params} label="Selecciona una refacción" variant="outlined" />
      )}
    />:<LinearProgress color="primary" /> */}    
    {listRefacc.length!==0 ?  
      <Autocomplete
        value={seleccionado} id= 'AutoSelect-Solucion' 
        onChange={(event, newValue) =>guardarParo(newValue) }
        clearOnBlur selectOnFocus handleHomeEndKeys
        options={listRefacc}      
        //renderOption={(option) => option.label}
        style={{ width: 33+'rem' }}
        loading={listRefacc.length!==0 }
        //noOptionsText={"Sin resultados"}
        loadingText="Sin resultados"
        getOptionLabel= {(option) => option.label}
        freeSolo size="small" color="primary"
        
        renderInput={(params) => (
        <TextField {...params} label="Selecciona una refacción" variant="outlined" />
      )}
    />:<LinearProgress color="primary" />}  
    </Box>
 </Box>
      </form>
  </Modal>
  );
}
 
export default NuevaRefaccion;