import React,{useContext,useState,useEffect} from 'react'
import { useHistory } from "react-router-dom";
import {AuthContext} from '../context/AuthContext'
import {GeneralesContext} from '../context/GeneralesContext'
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import { Box,Card  ,CardContent ,Typography,IconButton,TextField,    
        } from '@material-ui/core'
import TablaBitacora from './TablaBitacora';
//import RepoTab from './RepoTab';
import moment from 'moment';
import Relojes from './Relojes2'
//import NuevaOrden from './ModalNvo'
import axios from 'axios';
import qs from 'qs';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {llamadaApiToken,ErrorGeneral , llamaApiCargaToken } from '../funciones/ClienteAxios';
//import ModalEditar from './ModalEditar2';
import {findNumber} from "../funciones/FindNumber";
import CardMonit from './CardMonit';
import NewEditMonit from './NewEditMonit';
import CreateIcon from '@material-ui/icons/Create'; 

const useStyles = makeStyles((theme)=>({
    root: {
      minWidth: 275,
    },    
    title: {
      fontSize: 28,
    },
    title2: {
        fontSize: 18,
      },
    subTitle2: {
        fontSize: 12,
      },
    pos: {
      marginBottom: 12,
    },
    addButton: {
        marginRight: theme.spacing(2),       
      },
    altura2: {
        minHeight: 70,    
        marginTop:0,
        paddingTop:0,    
    },
    altura3: {
        minHeight: 80,
        marginTop:0,
        paddingTop:0,    
    },
    altura1: {
        height: 90,        
    },
  }));
 
const Monitoreo = () => {
    let history = useHistory();
    const classes = useStyles();
    const nvlSNPermiso ='5'  
    const usu= localStorage.getItem('UsuarioNom');
    const nvl= localStorage.getItem('Tipo');   
    const source = axios.CancelToken.source();
    const idusu= localStorage.getItem('UsuId');    
    const colorTitulo=process.env.REACT_APP_COLOR_SEC
    const {usuario,guardarUsuario}=useContext(AuthContext);
    const {guardarGenerales}= useContext(GeneralesContext);          
    const [orden,setOrden]= useState([])
    const [filtro, setFiltro] =  useState("");
    const [fechasSeleccionadas, setFechasSeleccionadas] = useState({inicio:new Date(),final:new Date()});    
  
    const [buscar, setBuscar] = useState(true);    
    const [modalNvo, setModalNvo] = useState(false);            
    const [modalEdit, setModalEdit] = useState(false);            
    const [listUbic, setListUbic] = useState([])
    const [listTipo, setListTipo] = useState([]) 
    const [listPersonal, setListPersonal] = useState([]) 
    const [datos, setDatos] = useState([])
    const [espera, setEspera] = useState(false)

    useEffect(()=>{         
        
		
	    veriSesion();	
        return ()=> {
            source.cancel();
        } 

    },[]);
  
    const veriSesion =  () => {            
            
        if(usu === null)
        {                                
            guardarUsuario([])						
            guardarGenerales([])     
            localStorage.clear();           
            history.push("/")	
        }
        else
        {   if (usuario.length === 0) 
            {   guardarUsuario({Nombre:usu,nivel:nvl});                                      
            }
            
            guardarGenerales({Titulo:'Monitoreo CCTV'})  
             
            if (listTipo.length === 0) {
                llamarTipo()
            }         
                
            if (listPersonal.length === 0) {
                llamarPersonal()
            }    
            llenaListUbic()     
        }
    }

    const llamarTipo = () => {      
        const data = qs.stringify({
            Usuario:idusu,
            idDispositivo:'89y7gttvjhj98h'
        });
        const url = "/cctv-monitoreo-tipo";
        function respuesta(auxiliar) {
         /* if (auxiliar[0].Id!==-1) 
          {  let auxArre=[];                 
            auxiliar.map((tipo) => {
             auxArre.push({ value : tipo.Id, label :tipo.Descr })                      
            })                              
            setListTipo(auxArre);                                                                   
          } */ 
            if (auxiliar[0].Id !== -1) 
            {                                                                  
              setListTipo(auxiliar)   
            }
            else
            { setListTipo([])        
            }                      
        }
  
        llamadaApiToken(data, url,respuesta,ErrorGeneral,history,source);
    };    
    
    const llenaListUbic=()=>{      
      
        const data = qs.stringify({    
          Usuario:idusu,          
          idDispositivo:'89y7gttvjhj98h'
        });
        const url = "/cctv-ubicacion-list";
        function respuestaListTabla(aux) {
          if (aux[0].Id !== -1) 
          {                                                                  
            setListUbic(aux)   
          }
          else
          { setListUbic([])        
          }           
        }
    
        llamadaApiToken(data, url,respuestaListTabla,ErrorGeneral,history,source);
    }

    const llamarPersonal = () => {      
        const data = qs.stringify({
            Usuario:idusu,
            idDispositivo:'89y7gttvjhj98h'
        });
        const url = "/cctv-monitoreo-personal";
        function respuesta(auxiliar) {
          if (auxiliar[0].Id!==-1) 
          {                
           /* let auxArre=[];                 
             auxiliar.map((tipo) => {          
                auxArre.push({ value : tipo.Id, label :tipo.Descr })                      
            })                               */
            setListPersonal(auxiliar);                                                         
          }                    
        }
  
        llamadaApiToken(data, url,respuesta,ErrorGeneral,history,source);
    };  
       
    const onChange = e =>
    {   let expNopermitida = new RegExp('[#._:$!%-({})/*=?|]');
        let expMenos = new RegExp('-');
        let expMas = new RegExp('[+]');     
        let expCadena = new RegExp('[A-Za-z0-9]');
        if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) &&
            !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) ||e.target.value===' '||e.target.value==='') 
        {
            setFiltro( e.target.value.trim())
        }
        
    };

    const incrementarBuscar = e =>
    {   setBuscar(!buscar)    
    };  

    const Encabezado=({espacio,estiloCampo,descripcion,campo})=>{
        return(
        <Box {...espacio} >
            <Typography className={classes.title2}  variant="h5" color="secondary" 
                component="h2" align="center"  style={estiloCampo}
            >                                        
                {campo}
            </Typography>                                            
            <Typography  className={classes.subTitle2} color="textSecondary"  align="center" >
                {descripcion }
            </Typography>
        </Box>
        )
    }

    const CampoRenglon=({estiloBox,estiloTitulo,titulo,campo})=>{
        return(
        <Box  {...estiloBox} >
            <span style={estiloTitulo}>{titulo} </span>  
            <Typography variant='body'> {campo}  </Typography>                        
        </Box>
        )
    }

    const costadoDerecho =()=>{
        const fecha = moment.utc(orden.FIni);          
        const modificada= fecha.local(true).format("DD/MM/YYYY HH:mm");        
        const fecha2 = moment.utc(orden.FFin);          
        const modificada2= fecha2.local(true).format("DD/MM/YYYY HH:mm");       
        const sinCambio =modificada!==modificada2?true:false
        return(      
        <Box pl={1} my={1} >
        <Card className={classes.altura3}  >
            <CardContent >
                <Box display="flex" flexDirection="row" >
                    <Encabezado espacio={{ }} estiloCampo={ {fontWeight: '900',fontSize:2+'rem' }} 
                        descripcion={""} campo={orden.Folio} />    
                    { findNumber('|70|') ?
                        <Box  >
                            <IconButton color="primary"  aria-label="add circle" size={"small"}
                                component="span" onClick={()=>setModalNvo(true)} style={{marginTop:.2+'rem'}}
                            >
                                <CreateIcon/>
                            </IconButton>                                                                                                                              
                        </Box> 
                    :null}                     
                    <Box display="flex" flexDirection="row" >
                        <Box mx={1} >
                            <CampoRenglon estiloBox={{display:"flex"}} titulo="Inicio " campo={modificada} 
                                estiloTitulo={{color:colorTitulo,fontWeight:700,paddingRight:0.2+'rem'}}
                            />      
                            {sinCambio?<CampoRenglon estiloBox={{display:"flex"}} titulo="Fin  " campo={` ${modificada2}`} 
                                estiloTitulo={{color:colorTitulo,fontWeight:700,paddingRight:0.2+'rem' }}
                            />:null   }                                                      
                        </Box>
                        <Box   >
                        {orden.Titulo&& orden.Titulo.length!==0 ?
                            <CampoRenglon estiloBox={{display:"flex"}} titulo="Nombre de Evento " campo={orden.Titulo} 
                                estiloTitulo={{color:colorTitulo,fontWeight:700,paddingRight:0.2+'rem' }}
                            />                                                                                      
                        :null}               
                        {orden.Descr && orden.Descr.length!==0 ?
                            <CampoRenglon estiloBox={{display:"flex"}} titulo="Descripción " campo={orden.Descr} 
                                estiloTitulo={{color:colorTitulo,fontWeight:700,paddingRight:0.2+'rem' }}
                            />                                                                                      
                        :null}       
                        </Box>
                    </Box>                                                                                                                                                                                                                                                                                            
                </Box>                                                                                             
            </CardContent>                     
        </Card>
        </Box>                                                 
        )
    }
    
    const abrirModNvo=()=>{
        setOrden([])
        setModalNvo(true)
    }
      
    const llenaListTabla=()=>{
     
        const fInicio=moment(fechasSeleccionadas.inicio).format("YYYY-MM-DD")
        const fFinal=moment(fechasSeleccionadas.final).format("YYYY-MM-DD")
        setOrden([])     
        let data = qs.stringify({    
          Usuario:idusu,    
          Fini:fInicio+'T00:00:00' ,
          Ffin:fFinal+'T23:59:59',              
          idDispositivo:'89y7gttvjhj98h'
        });
        let url = "/cctv-monitoreo-list";
        function respuestaListTabla(aux) {
          if (aux[0].Id !== -1) 
          {                                                                  
            setDatos(aux)   
          }
          else
          { setDatos([])        
          }           
        }
  
        llamaApiCargaToken(data, url,respuestaListTabla,ErrorGeneral,setEspera,history,source);
    }

    const contenido = orden.length!==0 ?(costadoDerecho(orden)):(null)                                         
 
    const modNvoEdit= modalNvo ? <NewEditMonit modalAbierto={modalNvo} setModalAbierto={setModalNvo} auxMonitoreo={orden} 
                                    llenaListTabla={llenaListTabla} listPersonal={listPersonal} />:null
  return (
    <Box display="flex" flexDirection="column" >
    <Box   >
        <Card className={classes.altura2}  >
        <CardContent style={{padding:0,marginTop:.6+'rem',marginBottom:.5+'rem'}} >
            <Box display="flex" flexDirection="row" >
                <Box ml={1} >                                            
                    { findNumber('|70|') ?
                        <IconButton size={"small"} color="primary"  
                            aria-label="add circle" component="span" 
                            onClick={abrirModNvo} style={{marginTop:.6+'rem'}}
                        >
                            <AddCircleOutlineIcon/>
                        </IconButton>        
                        :   null                                
                    }
                </Box>                                                                  
                <Box  >
                    <IconButton color="primary"  aria-label="add circle" size={"small"}
                        component="span" onClick={incrementarBuscar} style={{marginTop:.6+'rem'}}
                    >
                        <SearchIcon/>
                    </IconButton>                                                                                                                              
                </Box>
               <Box >                                                                           
                    <Box   component="span" >                                                                                                                                                                               
                        <Relojes fechasSeleccionadas={fechasSeleccionadas} 
                            setFechasSeleccionadas={setFechasSeleccionadas} />
                      {/*    <TextField id="outlined-basic" label="Folio" variant="outlined" 
                            size="small" style={({width:8+"rem",marginTop: .3+'rem',marginLeft:.4+'rem'})} 
                            name="filtro" value={filtro} onChange={onChange}                                                   
                        />   */}
                    </Box>                                                                                               
                </Box>                                                         
            </Box>                                                                                                        
        </CardContent>                                
        </Card>
    </Box>  
    <Box  display="flex" flexDirection="row">                
        <Box width="40%"  >                                                        
            <Box  mt={1} >
                <TablaBitacora filtro={filtro} buscar={buscar}
                    setOrden={setOrden}  
                    datos={datos}  llenaListTabla={llenaListTabla}
                    espera={espera} orden={orden}
                />                       
            </Box>
        </Box>                    
        <Box width="60%"   >                        
            {contenido}
            {orden.length!==0? 
                <CardMonit  orden={orden} listTipo={listTipo} 
                    listUbic={listUbic}
                />
            :null}
        </Box> 
    </Box>              
    {modNvoEdit}  
</Box>      
  )
}

export default Monitoreo