import React from "react";
import { Line } from "react-chartjs-2";

export default function GraficaEnergia({dataGrafica}) {
    
  const data = {
    labels:dataGrafica.labels,
    datasets: [
      {
        label: dataGrafica.estacion==="Ecatepec 01"?"Estacion 1":"Estacion 7",
        backgroundColor: "#F46239",
        borderColor: "#F46239",
        data: dataGrafica.data,
        tension: 0.4,
      },
    ],
  };

  return (
    <Line
    width={700} height={600}
      data={data}
       options={{
        animation: false,
       /* plugins: {
          tooltip: {
            callbacks: {
              label: function (context) {
                const index = context.dataIndex;
                const valor = context.dataset.data[index];
                const bucle = !graficaFiltrada ? labelGrafica : "";
                const label =
                  bucle +
                  " " +
                  arrayMedidores[index] +
                  " " +
                  valor.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
                return label;
              },
            }, 
          },
        },*/

        scales: {
          y: {
            ticks: {
              callback: function (value) {
                return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
              },
            },
          },
          x: {},
        },
      }}
    />
  );
}
