import React from 'react';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import esLocale from "date-fns/locale/es";
import {  
  TimePicker,  
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

const Relojes = ({horaApertura,guardarHoraApertura}) => {
    //const [selectedDate, handleDateChange] = React.useState(new Date());
    function seleccionaHorario(hora) {
      guardarHoraApertura({
            ...horaApertura,
            Horario :hora  ,                        
          })
    }
    function seleccionTiempo(hora) {
        guardarHoraApertura({
            ...horaApertura,
            Tiempo :hora  ,                        
          })
    }
    const {Horario,Tiempo}=horaApertura
    return (
      <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
      <TimePicker
        ampm={false}
        openTo="hours"
       // views={["hours", "minutes", "seconds"]}
        //format="HH:mm:ss"
        label="Operación"
        value={Tiempo}
        onChange={seleccionTiempo}
        size='small'
        style={{width:4+'rem'}}
      />   
      <TimePicker
        //clearable
        ampm={false}
        size='small'
        label="Hora Inicio"
        value={Horario}
        style={{width:5+'rem'}}
        onChange={seleccionaHorario}
      />    
      
      </MuiPickersUtilsProvider>
    );
}

export default Relojes
