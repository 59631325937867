import React, {useState} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {Box, LinearProgress, MobileStepper, Paper, Typography, Button} from '@material-ui/core';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import {autoPlay} from 'react-swipeable-views-utils';
import axios from 'axios';
import qs from 'qs';
import {useHistory} from "react-router-dom";
import Viewer from 'react-viewer';
import {llamaApiCargaToken, ErrorGeneral} from '../funciones/ClienteAxios';
import ModalEvidencia from './ModalEvidencia'
import {findNumber} from "../funciones/FindNumber";
const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '100%', flexGrow: 1,
    }, header: {
        display: 'flex',
        alignItems: 'center',
        height: 50,
        paddingLeft: theme.spacing(4),
        backgroundColor: theme.palette.background.default,
    }, container: {
        height: '400px',
        width: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        backgroundPosition: ' 50% 50%',
        backgroundSize: 'contain',
        imageRendering: 'pixelated'
    }
}));

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function TablaEvidencia({orden}) {
    const classes = useStyles();
    const theme = useTheme();
    let history = useHistory();
    //const maxSteps = tutorialSteps.length;
    const [activeStep, setActiveStep] = React.useState(0);
    const [maxSteps, setMaxSteps] = React.useState(0);
    const [espera, setEspera] = useState(false);
    const [datos, guardarDatos] = React.useState([])
    const [listIMG, guardarListIMG] = React.useState([])    
    const [visible, setVisible] = React.useState(false);
    const [modalEvid, setModalEvid] = React.useState(false);
    const [index, setIndex] = useState(0);
    React.useEffect(() => {
        const source = axios.CancelToken.source();
        const llenaListEvidencia = () => {
            guardarDatos([])
            setMaxSteps(0)
            setActiveStep(0)
            guardarListIMG([])
            const data = qs.stringify({
                orden: orden.Id, idDispositivo: '89y7gttvjhj98h'
            });
            const url = "/orden-evidencia-list";

            function respuestaListAct(aux) {
                if (aux[0].Id !== -1) {
                    console.log(aux);
                    
                    setMaxSteps(aux.length)
                    guardarDatos(aux)
                    setActiveStep(0)
                    let arregloIMG = []
                    aux.forEach((step, index) => (arregloIMG.push(step.Link)))

                    guardarListIMG(arregloIMG)
                } else {
                    guardarDatos([])
                }
            }

            llamaApiCargaToken(data, url, respuestaListAct, ErrorGeneral, setEspera, history, source);
        };

        llenaListEvidencia();

        return () => {
            source.cancel();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps	
    }, [orden]);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    const btnEvidencia = <Button color="secondary" style={{marginRight: 1 + 'rem'}} onClick={() => {
        setModalEvid(true)
    }}>
        Cargar Evidencias</Button>

    const tablaCompleta = (auxDatos) => {

        return (<div>
            <Paper square elevation={0} className={classes.header}>
                {findNumber('|4|') ? btnEvidencia : null}
                <Typography>{auxDatos.length !== 0 ? auxDatos[activeStep].UsrNom : null}</Typography>
                <Button color="secondary" onClick={() => {
                    setVisible(true);
                }}>pantalla completa</Button>
                <Viewer
                    visible={visible} onClose={() => {
                    setVisible(false);
                }}
                    images={auxDatos.map((imagen, index) => {
                        return ({src: imagen.Link, alt: ''})
                    })}
                   
                    zIndex={2000}
                />
            </Paper>

            <AutoPlaySwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={activeStep} onChangeIndex={handleStepChange}
                enableMouseEvents autoplay={!visible} interval={5000}
            >
                {auxDatos.length !== 0 ? auxDatos.map((step, index) => (
                    <div key={step.label} className={classes.container}
                         style={{backgroundImage: 'url(' + step.Link + ')'}}>

                    </div>)) : null}
            </AutoPlaySwipeableViews>

            <MobileStepper
                steps={maxSteps} position="static"
                variant="text" activeStep={activeStep}
                nextButton={<Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                    Siguiente
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft/> : <KeyboardArrowRight/>}
                </Button>}
                backButton={<Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                    {theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
                    Atras
                </Button>}
            />
        </div>)
    }

    const tablaSinDatos = () => {
        return (<Paper square elevation={0} className={classes.header}>
            {findNumber('|4|') ? btnEvidencia : null}
            <Typography>Sin fotografias</Typography>
        </Paper>)
    }
    const contenido = datos.length !== 0 ? tablaCompleta(datos) : tablaSinDatos()
    const cargando = !espera ? contenido : <Box my={2}><LinearProgress/></Box>
    const modEvidencia = modalEvid ? <ModalEvidencia modalAbierto={modalEvid} orden={orden} setMaxSteps={setMaxSteps}
                                                     datos={datos} maxSteps={maxSteps} arregloIMG={listIMG}
                                                     setDatos={guardarDatos}
                                                     setListIMG={guardarListIMG}
                                                     setModalAbierto={setModalEvid}
                                                     titulo={"Evidencias Orden " + orden.Id}/> : null
    return (<div className={classes.root}>
        {cargando}
        {modEvidencia}
    </div>);
}

export default TablaEvidencia;
