import Modal from "../Generales/ModalTam";
import axios from "axios";
import qs from "qs";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { toast } from "react-toastify";
import { useEffect, useRef, useState } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Box,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select as MaterialSelect,
  TextField,
} from "@material-ui/core";
import {
  ErrorGeneral,
  llamaApiCargaToken,
  llamadaApiToken,
} from "../funciones/ClienteAxios";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(0),
    },
  },
}));

export default function NuevaOrden({
  setModalAbierto,
  titulo,
  modalAbierto,
  funcionActuliza,
}) {
  const [open, setOpen] = useState(false);
  const [aviso, setAviso] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [tipoAdvertencia, setTipoAdvertencia] = useState("success");
  const [btnDesact, setBtnDesact] = useState(false);
  const [nuevaFalla, guardarNuevaFalla] = useState({
    Usuario: "",
    Periodo: "",
    Lugar: "",
    Terminal: "",
    FchCausa: "",
    Turno: "N",
    Descripcion: "",
    Solucion: "",
    Causa: "",
    Finaliza: false,
    Usrfinaliza: "",
    Ubicacion: "",
    UbicDescr: "",
    SeccId: "",
    TipoId: "",
    Multiple:false,
  });

  const [listPeriodo, setListPeriodo] = useState([]);
  const [listLugar, setListLugar] = useState([]);
  const [listLugarTerminal, setListLugarTerminal] = useState([]);
  const [listTipo, setListParoTipo] = useState([]);
  const [ubicacionSeleccion, guardarUbicacionSeleccion] = useState([]);
  const [auxUbicacionSelecc, guardarAuxUbicacionSelecc] = useState([]);
  const [listUbicacion, guardarListUbicacion] = useState([]);
  const [auxUbicacionTipo, guardarAuxUbicacionTipo] = useState([]);
  const [listSeccion, guardarListSeccion] = useState([]);

  let numUbiOtro = 234;
  let idusu = localStorage.getItem("UsuId");
  const source = axios.CancelToken.source();

  let history = useHistory();
  let NomUsu = localStorage.getItem("UsuarioNom");

  useEffect(() => {
    guardarNuevaFalla({...nuevaFalla,
      Usuario: "",
      Periodo: "",
      Lugar: "",
      Terminal: "",
      FchCausa: "",
      Turno: "N",
      Descripcion: "",
      Solucion: "",
      Causa: "",
      Finaliza: false,
      Usrfinaliza: "",
      Ubicacion: "",
      UbicDescr: "",
      SeccId: "",
      TipoId: "",
      EspecialId:0,
      Multiple:false,
    });
    setMensaje("");
    setAviso(false);
  }, []);
  const classes = useStyles();
  const [listEspecialidad,setListEspecialidad] = useState([])
  const [espera, setEspera] = useState(false);
  const {
    Lugar,
    Terminal,
    Turno,
    Descripcion,
    Solucion,
    Finaliza,
    Ubicacion,
    UbicDescr,
    SeccId,
    TipoId,
    Periodo,
    EspecialId,
    Multiple
  } = nuevaFalla;

  function llamada( auxUbic) {
    const guardaNuevaFalla = () => {
      setBtnDesact(true);
      setEspera(true);
      let data = qs.stringify({
        Usuario: idusu,
        Periodo: 1,//Periodo,
        Lugar: Lugar,
        Terminal: Terminal,
        Turno: Turno,
        Finaliza: 0,
        Descripcion: Descripcion,
        UbicId: Ubicacion,
        UbicDescr: Ubicacion == 0 ? auxUbic : UbicDescr,
        SeccId: SeccId,
        TipoId: TipoId,
        EspecialId:EspecialId,
        Solucion: "",
        Causa: " ",
        Fchcausa: " ",
        Usrfinaliza: "",
        Multiple: Multiple ? 1 : 0,  
        idDispositivo: "89HIUCDNSKCDS",
      });
      let url = "/mtto-nuevo";

      function response(auxiliar) {
        if (auxiliar.respuesta == 1) {
          let mensaje = "Se registro correctamente el Mtto";
          setTipoAdvertencia("success");
          setMensaje(mensaje);
          setAviso(false);
          toast.success(mensaje, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          funcionActuliza();
          setOpen(false);
          setBtnDesact(false);
          setModalAbierto(false);
          setEspera(false);
        }
      }
      llamaApiCargaToken(
        data,
        url,
        response,
        ErrorGeneral,
        setEspera,
        history,
        source
      );
    };
    guardaNuevaFalla();
  }

  useEffect(() => {
    const llamaListPeriodo = () => {
      let data = qs.stringify({
        tipo: "MTTO",
        idDispositivo: "987gyubjhkkjh",
      });
      let url = "/mtto-periodo";

      function respuesta(aux) {
        if (aux[0].length !== 0) {
          setListPeriodo(aux);
        }
      }

      llamadaApiToken(data, url, respuesta, ErrorGeneral, history, source);
    };

    if (listPeriodo.length === 0) {
      llamaListPeriodo();
    }

    const llenarListLugar = () => {
      let data = qs.stringify({
        tipo:'MTTO',
        idDispositivo: "89y7gttvjhj98h",
      });
      let url = "/lugar-list-1_1";

      function respuesta(aux) {
        if (aux[0].Id !== -1) {
          let auxArre = [];
          aux.map((tipo) => {
            if (tipo.Id != 0) {
              auxArre.push({ value: tipo.Id, label: tipo.Tipo });
            }
          });
          setListLugar(auxArre);
        }
      }

      llamadaApiToken(data, url, respuesta, ErrorGeneral, history, source);
    };

    if (listLugar.length === 0) {
      llenarListLugar();
    }

    const llenarListTerminal = () => {
      let data = qs.stringify({
        usuario: idusu,
        idDispositivo: "89y7gttvjhj98h",
      });
      let url = "/lugar-terminal-list";

      function respuesta(aux) {
        if (aux[0].Id !== -1) {
          let auxArre = [];
          aux.map((tipo) => {
            if (tipo.Id != 0) {
              auxArre.push({ value: tipo.Id, label: tipo.Descr });
            }
          });
          setListLugarTerminal(auxArre);
        }
      }

      llamadaApiToken(data, url, respuesta, ErrorGeneral, history, source);
    };

    if (listLugarTerminal.length === 0) {
      llenarListTerminal();
    }

    const llenarListParo = () => {
      let data = qs.stringify({
        idDispositivo: "89y7gttvjhj98h",
      });
      let url = "/mtto-actividad-tipo";

      function respuesta(aux) {
        if (aux[0].Id !== -1) {
          let auxArre = [];
          aux.map((tipo) => {
            auxArre.push({ value: tipo.Id, label: tipo.Tipo });
          });
          setListParoTipo(auxArre);
        }
      }

      llamadaApiToken(data, url, respuesta, ErrorGeneral, history, source);
    };

    if (listTipo.length === 0) {
      llenarListParo();
    }
    const llenarListEspecial = () => {
        let data = qs.stringify({          
          idDispositivo: "89y7gttvjhj98h",
        });
        let url = "/mtto-especialidad-list";
  
        function respuesta(aux) {
          if (aux[0].Id !== -1) {
            let auxArre = [];
            aux.map((tipo) => {
              if (tipo.Id != 0) {
                auxArre.push({ value: tipo.Id, label: tipo.Tipo });
              }
            });
            setListEspecialidad(auxArre);
          }
        }
  
        llamadaApiToken(data, url, respuesta, ErrorGeneral, history, source);
    };

    function llenarUbicaciones() {
      const auxUbi = JSON.parse(localStorage.getItem("LisUbicMtto"));

      let auxArre = [];
      let auxArre2 = [];

      auxArre2.push("TODO");
      auxUbi.forEach((ubica) => {
        if (
          ubica.LugarId.toString() === Lugar &&
          ubica.Terminal.search(Terminal) !== -1
        ) {
          auxArre.push({ value: ubica.Id, label: ubica.Tipo });
          auxArre2.push(ubica.Tipo);
        }
      });
      if (Lugar === 5 || Lugar === "5") {
        guardarNuevaFalla({
          ...nuevaFalla,
          Ubicacion: numUbiOtro,
          Multiple:false,
          UbicDescr: UbicDescr.length !== 0 ? UbicDescr : "",
        });
      } else {
        guardarNuevaFalla({
          ...nuevaFalla,
          Ubicacion: 0,
          UbicDescr: "",
          Multiple:false,
        });
      }
      guardarUbicacionSeleccion([]);
      guardarAuxUbicacionSelecc([]);
      guardarListUbicacion(auxArre);
      guardarAuxUbicacionTipo(auxArre2);
    }

    if (Lugar !== "" && Terminal !== "") {
      llenarUbicaciones();
    }

    async function llenarListParoAla() {
      let data = qs.stringify({
        idDispositivo: "8978yuhjnfrsdsd",
      });
      let url = "/mtto-seccion-lista";

      function respuesta(aux) {
        if (aux[0].Id !== -1) {
          let auxArre = [];
          aux.forEach((tipo) => {
            if (tipo.Id !== 0) {
              auxArre.push({ value: tipo.Id, label: tipo.Tipo });
            }
          });
          guardarListSeccion(auxArre);
        }
      }

      llamadaApiToken(data, url, respuesta, ErrorGeneral, history, source);
    }

    if (listSeccion.length === 0) {
      llenarListParoAla();
    }

    if (listEspecialidad.length===0) 
    { llenarListEspecial() 
    }   

    return () => {
      source.cancel();
    };
  }, [Terminal, Lugar]);

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const mostrarAlertas = (auxMensaje) => {
    setTipoAdvertencia("warning");
    let mensaje = auxMensaje;
    setMensaje(mensaje);
    setAviso(true);
  };

  function valNulos(auxMensaje, auxCampo) {
    let valida = false;
    if (
      auxCampo !== "" &&
      auxCampo !== "0" &&
      auxCampo !== 0 &&
      auxCampo !== null &&
      auxCampo !== " "
    ) {
      valida = true;
    }
    if (!valida) {
      mostrarAlertas(auxMensaje);
    }
    return valida;
  }

  const guardarDatos = () => {
    if (
      valNulos("Seleccionar Terminal", Terminal) &&
      valNulos("Seleccionar Tipo", TipoId) &&
     // valNulos("Seleccionar Periodo", Periodo) &&
      valNulos("Seleccionar Lugar", Lugar) &&
      valNulos("Seleccionar Ubicación", UbicDescr) &&
      //valNulos("", Finaliza) &&
      valNulos("Seleccionar Sección.",SeccId)&&
      valNulos("Seleccionar Especialidad.",EspecialId)&&
      valNulos("Ingrese una Descripción", Descripcion)
    ) 
    {      
      let auxFiltro=UbicDescr
      if (Lugar === 5 || Lugar === "5") {
        let ultima=UbicDescr.lastIndexOf(",")
        if (ultima!==-1&& (ultima===UbicDescr.length-1 || ultima===UbicDescr.length-2)) 
        {
          auxFiltro=UbicDescr.substring(0, ultima)     
        }
      }
      else{
        auxFiltro=UbicDescr.substring(0, UbicDescr.length - 2)
      }
      auxFiltro=auxFiltro.trim()
    
      while (auxFiltro.indexOf("  ")!== -1) 
      {                
        auxFiltro = auxFiltro.replace("  ", " ")                                                         
      }
      //console.log(auxFiltro);
      llamada( auxFiltro);
    }
  };

  const onChange = (e) => {
    guardarNuevaFalla({
      ...nuevaFalla,
      [e.target.name]: e.target.value,
    });
  };

  const handleDescripcion = (e) => {
    e.preventDefault();
    guardarNuevaFalla({ ...nuevaFalla, Descripcion: e.target.value });
  };

  const guardaTurno = (e) => {
    guardarNuevaFalla({
      ...nuevaFalla,
      Turno: e.target.value,
    });
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const filterArray = (arr1, arr2) => {
    const filtered = arr1.filter((el) => {
      return arr2.indexOf(el) === -1;
    });
    return filtered;
  };

  const seleccionarUbicacion = (e) => {
    let resultados = [];
    let auxArreglo = [];

    if (e.target.value.length > 1) {
      let ubicNueva = filterArray(auxUbicacionSelecc, e.target.value);

      if (ubicNueva.length !== 0) {
        if (ubicNueva == "TODO") {
          auxArreglo = [];
        } else {
          resultados = listUbicacion.filter(
            (registro) => registro.label == ubicNueva
          );
          if (ubicacionSeleccion.length > 1) {
            let arrFiltrado = ubicacionSeleccion.filter((registro) => {
              return registro.value !== resultados[0].value;
            });
            auxArreglo = arrFiltrado;
          } else {
            auxArreglo.push({
              value: resultados[0].value,
              label: resultados[0].label,
            });
          }
        }
      } else {
        if (e.target.value[e.target.value.length - 1] === "TODO") {
          auxArreglo = listUbicacion;
        } else {
          resultados = listUbicacion.filter(
            (registro) =>
              registro.label === e.target.value[e.target.value.length - 1]
          );
          auxArreglo = ubicacionSeleccion;
          auxArreglo.push({
            value: resultados[0].value,
            label: resultados[0].label,
          });
        }
      }
    } else {
      if (e.target.value[0] === "TODO") {
        auxArreglo = listUbicacion;
      } else {
        resultados = listUbicacion.filter(
          (registro) => registro.label === e.target.value[0]
        );
        if (resultados.length !== 0) {
          auxArreglo.push({
            value: resultados[0].value,
            label: resultados[0].label,
          });
        } else {
          auxArreglo = [];
        }
      }
    }

    guardarUbicacionSeleccion(auxArreglo);

    if (auxArreglo.length !== 0) {
      if (auxArreglo[0].length != 0) {
        let nomUbiID = "";
        auxArreglo.forEach((ubicacion) => {
          nomUbiID = nomUbiID + ubicacion.value + ",";
        });
        let nomUbi = "";
        if (e.target.value[e.target.value.length - 1] === "TODO") {
          auxArreglo = listUbicacion;
          auxArreglo.forEach((ubicacion) => {
            nomUbi = `${nomUbi}${ubicacion.label}, `;
          });
        } else {
          e.target.value.forEach((ubicacion) => {
            if (ubicacion !== "TODO") {
              nomUbi = `${nomUbi} ${ubicacion}, `;
            }
          });
        }
        guardarNuevaFalla({
          ...nuevaFalla,
          Ubicacion: 0,
          UbicDescr: nomUbi,
          Multiple:auxArreglo.length===1?false:Multiple,
        });
      } else {
        guardarNuevaFalla({
          ...nuevaFalla,
          Ubicacion: "",
          UbicDescr: "",
          Multiple:false,
        });
      }
    } else {
      guardarNuevaFalla({
        ...nuevaFalla,
        Ubicacion: "",
        UbicDescr: "",
        Multiple:false,
      });
    }

    if (e.target.value[e.target.value.length - 1] === "TODO") {
      //console.log(e.target.value[e.target.value.length-1]);
      if (auxArreglo.length != 0) {
        guardarAuxUbicacionSelecc(auxUbicacionTipo);
      } else {
        guardarAuxUbicacionSelecc([]);
      }
    } else {
      let ubicNueva = filterArray(auxUbicacionSelecc, e.target.value);
      if (ubicNueva[0] == "TODO") {
        guardarAuxUbicacionSelecc([]);
      } else {
        guardarAuxUbicacionSelecc(e.target.value);
      }
    }
  };

  const guardaUbicacionInput = (e) => {
    guardarNuevaFalla({
      ...nuevaFalla,
      Ubicacion: numUbiOtro,
      UbicDescr: e.target.value,
      Multiple:false,   
    });
  };
 
  const onChangeCheck = (event) => {
    guardarNuevaFalla({
      ...nuevaFalla,
        [event.target.name]: event.target.checked 
      });
  };

  return (
    <Modal
      titulo={titulo} modalAbierto={modalAbierto}
      setModalAbierto={setModalAbierto} guardarDatos={guardarDatos}
      mensaje={mensaje} tipoAdvertencia={tipoAdvertencia}
      aviso={aviso} setAviso={setAviso}
      espera={espera} setEspera={setEspera}
      tamanio={"md"} btnDesact={btnDesact}
    >
      <form className={classes.root} noValidate autoComplete="off" >
        <Box>
            <Box>
                <FormControl component="fieldset">
                <FormLabel component="legend">Turno</FormLabel>
                <RadioGroup
                    row aria-label="position"  name="Turno"
                    value={Turno} onChange={guardaTurno}
                >
                    <FormControlLabel value="N" label="T.Nocturno"
                    control={<Radio color="primary" />}                  
                    />
                    <FormControlLabel value="D" label="T.Diurno"
                    control={<Radio color="primary" />}                  
                    />
                </RadioGroup>
                </FormControl>
                {listLugarTerminal.length !== 0 ? (
                <FormControl size="small">
                    <InputLabel htmlFor="age-native-simple">Terminal</InputLabel>
                    <MaterialSelect
                    native value={Terminal} onChange={onChange}
                    inputProps={{ name: "Terminal", id: "Terminal", }}
                    >
                    <option aria-label="None" value="" />
                    {listLugarTerminal.map((row) => (
                        <option value={row.value}>{row.label}</option>
                    ))}
                    </MaterialSelect>
                </FormControl>
                ) : null}
            </Box>          
            <Box display="flex" row mb={2}>          
                <Box mr={2} >
                    {listTipo.length !== 0 ? (
                    <FormControl size="small">
                        <InputLabel htmlFor="Periodo">Tipo</InputLabel>
                        <MaterialSelect
                        native value={TipoId} onChange={onChange}
                        inputProps={{ name: "TipoId", id: "TipoId", }}
                        >
                        <option aria-label="None" value="" />
                        {listTipo.map((row) => (
                            <option value={row.value}>{row.label}</option>
                        ))}
                        </MaterialSelect>
                    </FormControl>
                    ) : null}
                </Box>
                <Box mr={2} >
                    {listLugar.length !== 0 ? (
                    <FormControl size="small">
                        <InputLabel htmlFor="age-native-simple">Lugar</InputLabel>
                        <MaterialSelect
                        native value={Lugar} onChange={onChange}
                        inputProps={{ name: "Lugar", id: "Lugar", }}
                        >
                        <option aria-label="None" value="" />
                        {listLugar.map((row) => (
                            <option value={row.value}>{row.label}</option>
                        ))}
                        </MaterialSelect>
                    </FormControl>
                    ) : null}
                </Box>
             
                <Box mr={2} >
                    {listUbicacion.length !== 0 ? (
                    Lugar !== 5 || Lugar !== "5" ? (
                        <FormControl
                        className={classes.formControl2}
                        style={{ width: 8 + "rem" }} size="small"
                        >
                        <InputLabel id="input-label-ubic">Ubicación</InputLabel>
                        <MaterialSelect
                            labelId="mutiple-checkbox-label-ubic"
                            id="mutiple-checkbox-ubic"
                            multiple value={auxUbicacionSelecc}
                            onChange={seleccionarUbicacion}
                            input={<Input />} MenuProps={MenuProps}
                            renderValue={(selected) => selected.join(", ")}                    
                        >
                            {auxUbicacionTipo.map((ubic) => {
                            return (
                                <MenuItem key={ubic} value={ubic}>
                                <Checkbox
                                    checked={auxUbicacionSelecc.indexOf(ubic) > -1}
                                />
                                <ListItemText primary={ubic} />
                                </MenuItem>
                            );
                            })}
                        </MaterialSelect>
                        </FormControl>
                    ) : (
                        <TextField
                        id="outlinedUbicacion" label="Ubicación"
                        size="small" value={UbicDescr}
                        onChange={guardaUbicacionInput}
                        name="UbicDescr" style={{ width: 10 + "rem" }}
                        />
                    )
                    ) : null}
                </Box>
                <Box mr={2}>
                {listSeccion.length !== 0 ? (
                <FormControl
                    className={classes.formControl} size="small"
                    style={{ width: 4.8 + "rem" }}
                >
                    <InputLabel htmlFor="Seccion-Input">Sección</InputLabel>
                    <MaterialSelect native value={SeccId} onChange={onChange}
                        inputProps={{ name: "SeccId", id: "SeccId", }}
                    >
                    <option aria-label="None" value="" />
                    {listSeccion.map((row) => (
                        <option value={row.value}>{row.label}</option>
                    ))}
                    </MaterialSelect>
                </FormControl>
                ) : null}
            </Box>
                {/* <Box mr={2}>
                    {listPeriodo.length !== 0 ? (
                    <FormControl size="small">
                        <InputLabel htmlFor="age-native-simple">Periodo</InputLabel>
                        <MaterialSelect
                        native value={Periodo} onChange={onChange}
                        inputProps={{ name: "Periodo", id: "Periodo" }}
                        >
                        <option aria-label="None" value="" />
                        {listPeriodo.map((row, index) => (
                            <option key={index} value={row.Id}>
                            {row.Tipo}
                            </option>
                        ))}
                        </MaterialSelect>
                    </FormControl>
                    ) : null}
                </Box>      */}
              {listEspecialidad.length!=0 ?
                <Box>
                  <FormControl style={{width:10+'rem'}} size="small" >
                    <InputLabel htmlFor="EspecialId">Especialidad</InputLabel>
                    <MaterialSelect
                      native value={EspecialId} onChange={onChange}
                      inputProps={{ name: 'EspecialId', id: 'EspecialId', }}
                    >
                      <option aria-label="None" value="" />                     
                      {listEspecialidad.map((row) => (
                          <option value={row.value}>{row.label}</option>
                      ))}   
                    </MaterialSelect>
                </FormControl>     
                </Box>
              :null}        
            </Box>    
            {auxUbicacionSelecc.length>1?
                <Box  >
              <FormControlLabel style={{marginLeft:.1+'rem'}}
                  control={<Checkbox checked={Multiple} onChange={onChangeCheck} name="Multiple" />}
                  label="Generar actividades por cada Ubicación"
              />     </Box>
              :null}       
            <Box >
              <TextField style={{ width: "100%" }}
                id="Descripcion" label="Descripción" maxRows={4}
                multiline minRows={4} value={Descripcion} name="Descripcion" 
                onChange={handleDescripcion} variant="outlined"
              />
            </Box>
        </Box>
      </form>
    </Modal>
  );
}
