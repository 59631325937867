import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {InputLabel,FormControl,Select as MaterialSelect  ,Box 
  ,LinearProgress ,TextField,RadioGroup,FormControlLabel,Radio,FormLabel,//,Typography
  Typography
  } from '@material-ui/core';
import axios from 'axios';
import qs from 'qs';
import PilonaContainerBlock from './PilonaContainerBlock';
import { useHistory } from "react-router-dom";
import Modal from '../Generales/Modal';
//import moment from 'moment';
import {toast} from "react-toastify";
import {llamaApiCargaToken,ErrorGeneral,llamadaApiToken } from '../funciones/ClienteAxios';
const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '20ch',
      }, 
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
      Textnumero: {
        width: 5+"px",
      },
      formControlFull: {
        //margin: theme.spacing(1),
        //minWidth: 120,
        width:5+"rem"
      },
      margin: {
        margin: theme.spacing(1),
      },
    },
  }));

const EditarMtto = ({modalAbierto,setModalAbierto,titulo,lecturaSelect,auxListCabs,setOrdenSelect,datos, setDatos}) => {
  let history = useHistory();
  const classes = useStyles();
  let idusu= localStorage.getItem('UsuId'); 
  const source = axios.CancelToken.source();      
  const [espera,setEspera]=React.useState(false);
  const [mensaje, setMensaje] = React.useState('');
  const [tipoAdvertencia, setTipoAdvertencia] = React.useState('success');
  const [arregloFiltrado, setArregloFiltrado] = useState([])
  const [aviso, setAviso] = React.useState(false);
  const [cabinaSelec, setCabinaSelec] = React.useState([]);  
  const[nvoMtto,setNvoMtto]=React.useState({Terminal:0,Tipo:0,Auxiliar:"",Tecnico:"",Estado:'0' , Descripcion:"",
                                            Especial:"",MttoID:0})

  const{Terminal ,Tipo ,Descripcion ,Estado,MttoID,Especial}=nvoMtto                                                                
  const [listLugarTerminal, setListLugarTerminal] = React.useState([])  
  const [listTipo, setListTipo] = React.useState([])
  const[listStatus,setListStatus]= React.useState([])
  const[listCabs,setListCabs]= React.useState([])
  const[arrCabSelect,setArrCabSelect]= React.useState([])

  React.useEffect(()=>{ 

    setNvoMtto({...nvoMtto,Descripcion:lecturaSelect.descr ,
      MttoID:lecturaSelect.Id ,Estado:lecturaSelect.stId.toString(),
      Terminal:lecturaSelect.termId,Tipo:lecturaSelect.tpId,
      Especial:lecturaSelect.tpEspecial
    })
    console.log(lecturaSelect);    
    let auxTerminal= localStorage.getItem('UsuTerminal');  
    const llenarListStatus = () => {      
      let data = qs.stringify({      
        idDispositivo:'89y7gttvjhj98h'
      });
      let url = "/pilona-mtto-status";
      function respuestaListStat(auxiliar) {
        if (auxiliar[0].Id !== -1) 
        {                                            
          setListStatus(auxiliar)
        }      
      }
  
      llamaApiCargaToken(data, url,respuestaListStat,ErrorGeneral,setEspera,history,source);
    };
    const llenarListTerminal = () => {      
      let data = qs.stringify({
        usuario:idusu,
        idDispositivo:'89y7gttvjhj98h'
      });
      let url = "/lugar-terminal-list";
      function respuestaListTermin(aux) {
        if (aux[0].Id !== -1) 
        { let auxArre=[];   
          //console.log(aux);
          aux.map((tipo) => {
            if (tipo.Id!=0) 
            { auxArre.push({ value : tipo.Id, label :tipo.Descr })  
            }            
          })                    
          setListLugarTerminal(auxArre);  
        }
      }
      
      llamadaApiToken(data, url, respuestaListTermin,ErrorGeneral,history,source)
    };    

    const llenarListCabs = () => {      
      let data = qs.stringify({
        usuario:idusu,
        idDispositivo:'89y7gttvjhj98h'
      });
      let url = "/pilona-list";
      function respuesta(aux) {
        if (aux[0].Id !== -1) 
        { setListCabs(aux);     
          //console.log(aux)                    
          var au1x = aux.filter(function (val) {
            return val.TermId === parseInt(auxTerminal);
          });        
          au1x.sort(function (a, b) { return a.Id - b.Id; });              
          setArregloFiltrado(au1x) 
        }
      }
      
      llamadaApiToken(data, url, respuesta,ErrorGeneral,history,source)
    };    
    
    const llenarListTipo = () => {      
      let data = qs.stringify({        
        idDispositivo:'89y7gttvjhj98h'
      });
      let url = "/pilona-mtto-tipo";
      function respuesta(aux) {
        if (aux[0].Id !== -1) 
        {  let auxArre=[];  
          //console.log(aux); 
          aux.map((tipo) => {
            if (tipo.Id!==0) 
            {
              auxArre.push({ value : tipo.Id, label :tipo.Tipo })  
            }            
          })                    
          setListTipo(auxArre); 
        }
      }
      
      llamadaApiToken(data, url, respuesta,ErrorGeneral,history,source)
    };    
   
    if (listTipo.length === 0) 
    {
      llenarListTipo()
    }
    if (listLugarTerminal.length === 0) 
    {
      llenarListTerminal();  
    }
    if (listCabs.length === 0) 
    {
      llenarListCabs();  
    }
    if (listStatus.length === 0) 
    {
      llenarListStatus();    
    }  

   /*  if (Terminal===0) {    
      setNvoMtto({
        ...nvoMtto, Terminal : auxTerminal  })  
    }     */  

    return ()=> {
      source.cancel();
    }     
      
  },[]);

  const registrarNVLectura=( )=>{    
    const llenarEditarCabs = () => {      
      let data = qs.stringify({
        MttoId:MttoID,
        Status:parseInt(Estado),
        Usuario:idusu,                
        Descr:Descripcion,//'Mantenimiento programado',                
        idDispositivo:'987gyubjhkkjh'
      });
      let url = "/pilona-mtto-edit";
      function respuestaEditCab(auxiliar) {
        if (auxiliar.respuesta==1) 
        { // console.log(auxiliar);
          let mensaje="Se edito correctamente el Mantenimiento "+MttoID
          setTipoAdvertencia("success")      
          setMensaje(mensaje);
          setAviso(true)     
          let auxStd=listStatus.filter((std)=>std.Id===parseInt(Estado))
          let arrEdit={...lecturaSelect,stId:parseInt(Estado),
            descr:Descripcion,stDescr:auxStd[0].Tipo}
          //  console.log(datos );
          setOrdenSelect(arrEdit)   
          let auxlistArray=datos 
          let pos =auxlistArray.findIndex((mtto)=>mtto.Id===arrEdit.Id)
          auxlistArray.splice(pos, 1,arrEdit )        
          toast.success(mensaje, {
            toastId: `edit-pilona${MttoID}`,
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }); 
          setModalAbierto(false)
          //console.log(auxlistArray);
          //actualizar()//llamada de funcionamiento   
        }       
      }  
      llamaApiCargaToken(data, url,respuestaEditCab,ErrorGeneral,setEspera,history,source);
    }
    const llenarValida = () => {      
      let data = qs.stringify({
        Mtto:MttoID,        
        idDispositivo:'987gyubjhkkjh'
      });
      let url = "/pilona-mtto-verifica-total-avance";
      function respuesta(auxiliar) {
        if (auxiliar.length!==0) 
        {  //console.log(auxiliar);
          if (auxiliar.total===auxiliar.avance) {
            llenarEditarCabs()
          }
          else{
            let mensaje="Mantenimientos pendientes "+( auxiliar.total - auxiliar.avance)
            console.log(mensaje);
            setTipoAdvertencia("error")      
            setMensaje(mensaje);
            setAviso(true)
          }  
        }       
      }  
      llamaApiCargaToken(data, url,respuesta,ErrorGeneral,setEspera,history,source);
    }
    //console.log(Estado,parseInt(Estado));
    if (parseInt(Estado)===3) 
    {   llenarValida() 
    }
    else
    {  llenarEditarCabs()
    }
    
  }

  const onChange2 = e =>
  { let expNopermitida = new RegExp('[_:$!%({})/*=?|]');
    let expMenos = new RegExp('-');
    let expMas = new RegExp('[+]');     
    let expCadena = new RegExp('[A-Za-z0-9#-]');
    if ( ( expCadena.test(e.target.value)&&!expNopermitida.test(e.target.value)&&!expMenos.test(e.target.value)&&
        !expMas.test(e.target.value) )||e.target.value===' '||e.target.value==='') 
    { setNvoMtto({
        ...nvoMtto, [e.target.name] : e.target.value })
      if (aviso) 
      { setMensaje('');
        setAviso(false);
      }
    }
  };  

  const onChange = e =>
  { let expNopermitida = new RegExp('[#_:$!%-({})/*=?|]');
    let expMenos = new RegExp('-');
    let expMas = new RegExp('[+]');     
    let expCadena = new RegExp('[A-Za-z0-9]');
    if ( ( expCadena.test(e.target.value)&&!expNopermitida.test(e.target.value)&&!expMenos.test(e.target.value)&&
        !expMas.test(e.target.value) )||e.target.value===' '||e.target.value==='') 
    { setNvoMtto({
        ...nvoMtto, [e.target.name] : e.target.value })
      if (aviso) 
      { setMensaje('');
        setAviso(false);
      }
    }
  };  
  
  const guardTerminal = e =>
  {
    if (parseInt(e.target.value) > 0) 
    { setArrCabSelect([])  
      var aux = listCabs.filter(function (val) 
      {
        return val.TermId === parseInt(e.target.value);
      });
      aux.sort(function (a, b) { return a.Id - b.Id; });    
                    
      setArregloFiltrado(aux)
    }
    else
    { setArrCabSelect([]) 
      setArregloFiltrado([])
    }
    setNvoMtto({
      ...nvoMtto, [e.target.name] : e.target.value  })
    if (aviso) 
    { setMensaje('');
      setAviso(false);
    }
  };

  const mostrarAlertas =(auxMensaje)=>{
    setTipoAdvertencia("warning")
    let mensaje=auxMensaje            
    setMensaje(mensaje);
    setAviso(true)   
  }  

  function valNulos(auxMensaje,auxCampo) 
  { let valida=false    
    if (auxCampo !== '' && auxCampo !== '0'&& auxCampo !==0 && auxCampo !== null && auxCampo!==" " ) 
    {      
      valida=true              
    }
    if (!valida) 
    { 
      mostrarAlertas(auxMensaje)
    }
    return valida
  }
  function valCabinas() 
  { let valida=false    
    if (arrCabSelect.length>0 ) 
    {      
      valida=true              
    }
    if (!valida) 
    { 
      mostrarAlertas("Seleccionar Cabinas")
    }
    return valida
  }
  
  const guardarDatos = () => 
  {
    if ( valNulos("Seleccionar Estatus",Estado) ) 
    {
      if ( (Descripcion.length>=5 && valNulos("Llenar campo de Descripción (mayor a 5 caracteres)",Descripcion) )
        || (Descripcion.length<5 && valNulos("Llenar campo de Descripción (mayor a 5 caracteres)",0))
      ) 
      {            
        registrarNVLectura()                                                                                                                                                     
      }                 
    }       
  };

  const guardaEstado = e =>
  {  
    setNvoMtto({
      ...nvoMtto, Estado : e.target.value  })  
    if (aviso) 
    { setMensaje('');
      setAviso(false);
    }      
  };

  const onChangeSelect = e =>
  {  
    setNvoMtto({
      ...nvoMtto,   [e.target.name] : e.target.value })

    if (aviso) 
    { setMensaje('');
      setAviso(false);
    }   
  };

  return ( 
    <Modal
      titulo={titulo} modalAbierto={modalAbierto}
      setModalAbierto={setModalAbierto} guardarDatos={guardarDatos}
      mensaje={mensaje} tipoAdvertencia={tipoAdvertencia}
      aviso={aviso} setAviso={setAviso} espera={espera}
      setEspera={setEspera} tamanio={"md"}
    >
    <form  noValidate autoComplete="off" fullWidth>       
      <Box  display="flex" flexDirection="row">                
        <Box style={{width:33+'rem'}} className={classes.root}>  
          {listStatus.length!=0? 
            <FormControl component="fieldset" style={{width:40+'rem'}}>              
              <FormLabel component="legend" style={{color:'#FF6439',fontWeight:500}}>
                Estatus
              </FormLabel>      
              <RadioGroup row aria-label="position" defaultValue="top"   
                name="Estado" value={Estado} onChange={guardaEstado}
              >            
                {listStatus.map((nvStatus)=>{
                  if (nvStatus.Id!== -1&&nvStatus.Id!== 0) 
                  { return(  
                    <span> 
                      <FormControlLabel value={nvStatus.Id.toString()} style={{color:nvStatus.Color}} 
                        control={<Radio color="primary" />} label={nvStatus.Tipo} />    
                      <br/>
                    </span>)
                  }
                })}                        
              </RadioGroup>                     
            </FormControl>     
          :null}      
          <br/>                   
          {listLugarTerminal.length!=0 ?  
            <FormControl className={classes.formControl} size="small" style={{width:8+'rem'}}>
              <InputLabel htmlFor="age-native-simple">Terminal</InputLabel>
              <MaterialSelect
                native value={Terminal} onChange={guardTerminal}
                inputProps={{ name: 'Terminal', id: 'Terminal'}}
                disabled
              >
                <option aria-label="None" value="" />      
                {listLugarTerminal.map((row) => (
                    <option value={row.value}>{row.label}</option>
                ))}   
              </MaterialSelect>
            </FormControl>     
          :null}  
          {listTipo.length!=0 ?  
            <FormControl className={classes.formControl} size="small" style={{width:10+'rem'}}>
              <InputLabel htmlFor="age-native-simple">Periodo</InputLabel>
              <MaterialSelect
                native value={Tipo} onChange={onChangeSelect}
                inputProps={{ name: 'Tipo', id: 'Tipo'}}
                disabled
              >
                <option aria-label="None" value="" />      
                {listTipo.map((row) => (
                    <option value={row.value}>{row.label}</option>
                ))}   
              </MaterialSelect>
            </FormControl>     
          :null}          
            
          {parseInt(Tipo)=== 7 ?      
            <TextField id="input-EspecialDesc" style={{width:31+'rem'}}           
              label="Especial"  size="small" value={Especial} disabled
              onChange={onChange2} name="Especial"     maxLength={500}                                           
            /> 
          :null}
          <Box display="flex" flexDirection="column"  style={{width:18+'rem'}}>  
            <Typography component="p" variant="body2" color='primary'>Descripción</Typography>                           
            <textarea id="Descr-TextArea" name="Descripcion" rows="3"
              cols="100"placeholder="Descripción" style={{width: 31+"rem",borderRadius:3+'px'}} 
              value={Descripcion} onChange={onChange} 
            />
          </Box>                                                                                 
        </Box>
        <Box style={{width:20+'em'}} display={"flex"} flexDirection="column">  
        <FormLabel component="legend" style={{color:'#FF6439',fontWeight:500}}>
          Pilonas
        </FormLabel>      
        {Terminal!==0&& auxListCabs.length>0  ?
          <Box style={{marginTop:'1rem'}} >
            <PilonaContainerBlock cabinasTotales={auxListCabs.length} setCabinaSelec={setCabinaSelec} 
              cabSelecc={cabinaSelec} arrCabinas={auxListCabs} arrCabSelect={arrCabSelect}
              setArrCabSelect={setArrCabSelect} //auxListCabs={auxListCabs}
            />
          </Box>
          : <Box pl={1} pt={5}><LinearProgress/></Box>
        }
        </Box>
      </Box>
    </form>
  </Modal>
  );
}
 
export default EditarMtto;