import {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField} from "@material-ui/core";
import ModalGeneral from "./Modal";
import {useHistory} from "react-router-dom";
import axios from "axios";
import qs from "qs";
import moment from "moment";
import 'react-toastify/dist/ReactToastify.css';
import {toast} from 'react-toastify';
import {ErrorGeneral, llamaApiCargaToken} from "../funciones/ClienteAxios";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: {
            margin: theme.spacing(1),
        },
    }, medidor: {
        width: '100px', marginBottom: '1em'
    }, campos: {
        width: '200px', marginBottom: '1em'
    }, camposLectura: {
        width: '150px', marginBottom: '1em'
    }, fecha: {
        width: '200px', marginTop: '3px', marginBottom: '1em'
    }

}));

const EditarCarroTensor = ({modalAbierto, setModalAbierto, titulo, energia, setListaCTensor, listaCTensor}) => {
        const classes = useStyles();
        const [mensaje, setMensaje] = useState("");
        const [tipoAdvertencia, setTipoAdvertencia] = useState("success");
        const [aviso, setAviso] = useState(false);
        const [editActivo, setEditActivo] = useState("activo");
        const [registro, setRegistro] = useState({
            bitacora: energia.Id,
            usuario: energia.UsrId,
            LecturaE1: energia.tensorE1,
            LecturaE4A: energia.tensorE4A,
            LecturaE7: energia.tensorE7,
            LecturaE4B: energia.tensorE4B,
            Fecha: moment().format(energia.fBitacora),
            FechaAlta: moment().format(energia.fAlta),
            Activo: 0,
            Terminal: energia.termId
        });

        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        const [cargando, setCargando] = useState(false);
        const [botonDeshabilitado, setBotonDeshabilitado] = useState(false);
        const {
            bitacora, usuario, LecturaE1, LecturaE4A, LecturaE7, LecturaE4B, Fecha, FechaAlta, Activo, Terminal
        } = registro;
        const expPermitidaDecimal = /^(\d+\.?\d*|\.\d+)$/;
        const exPermitidaNumeros = /^\d+(\.\d{1,2})?$/;
        const historial = useHistory();

        let FechaSinFormato = moment(registro.Fecha).format();

        //console.log('REG',registro)
        //console.log('EREGBE',energia)

        const editarCarro = () => {
            setCargando(true)
            setBotonDeshabilitado(true);       
            let idusu= localStorage.getItem('UsuId');                     
            let data = qs.stringify({
                bitacora: bitacora,
                usuario: idusu,
                Fecha: FechaSinFormato.slice(0, -6),
                LecturaE1: LecturaE1,
                LecturaE4A: 0,
                LecturaE4B: 0,
                LecturaE7: LecturaE7,
                idDispositivo: "EBRDVDVDVFFD",
                Status: "activo",
            });

            let url = "/bitacora-ctensor-editar";

            function respuesta(auxiliar) {
                let mensaje =""
                if (auxiliar.respuesta === 1) {
                    mensaje = "Se editó correctamente la lectura ";
                    setTipoAdvertencia("success");
                    setMensaje(mensaje);
                    setAviso(true)
                    setModalAbierto(false)
                    toast.success( mensaje + moment(FechaSinFormato).format("DD/MM/YYYY"), {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    let posicion = listaCTensor.findIndex((element) => element.Id === registro.bitacora)
                    let arrMod={...energia, UsrId:idusu, tensorE1: LecturaE1,                        
                            tensorE7: LecturaE7,                                                                        
                        }
                    listaCTensor.splice(posicion, 1, arrMod)
                    let nvoArr=listaCTensor
                    //console.log(listaCTensor);
                    setListaCTensor([])
                    setTimeout(() => {
                        setListaCTensor(nvoArr)
                      }, 100);  
                    
                } 
                else 
                {
                    mensaje = "Ha ocurrido un error, inténtelo más tarde ";
                    setTipoAdvertencia("warning");
                    setMensaje(mensaje);
                    setAviso(true);
                       toast.error(mensaje + moment(FechaSinFormato).format("DD/MM/YYYY"), {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            }

            llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, historial, source);
        }

        const validar = () => {
            if ((registro.LecturaE1 && registro.Activo) !== "") 
            {           
                editarCarro()
            } 
            else 
            {
                toast.error('Debe llenar todos los campos.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setTipoAdvertencia("error");
                setMensaje("Seleccione una Terminal Válida.");
                return setAviso(true);
            }
        }

        const onChangeEstacion1 = (e) => {
            let expNopermitida = new RegExp('[#A-Za-z]');
            if ((expPermitidaDecimal.test(e.target.value) && !expNopermitida.test(e.target.value) )
                || e.target.value === ' ' || e.target.value === '') {

                setRegistro({
                    ...registro, LecturaE1: e.target.value,
                });
            }
        }

        const onChangeEstacion7 = (e) => {
            let expNopermitida = new RegExp('[#A-Za-z]');
            if ((expPermitidaDecimal.test(e.target.value) && !expNopermitida.test(e.target.value) )
            || e.target.value === ' ' || e.target.value === '') {

                setRegistro({
                    ...registro, LecturaE7: e.target.value,
                });
            }
        }

        const onChangeEstacion4A = (e) => {
            let expNopermitida = new RegExp('[#A-Za-z]');
            if ((expPermitidaDecimal.test(e.target.value) && !expNopermitida.test(e.target.value) )
                || e.target.value === ' ' || e.target.value === '') {

                setRegistro({
                    ...registro, LecturaE4A: e.target.value,
                });
            }
        }

        const onChangeEstacion4B = (e) => {
            let expNopermitida = new RegExp('[#A-Za-z]');
            if ( ( expPermitidaDecimal.test(e.target.value) && !expNopermitida.test(e.target.value) )
                || e.target.value === ' ' || e.target.value === '') {

                setRegistro({
                    ...registro, LecturaE4B: e.target.value,
                });
            }
        }

        const onChangeActivo = (event) => {
            event.preventDefault();
            setEditActivo(event.target.value);
            switch (event.target.value) {
                case "activo":
                    setRegistro({
                        ...registro, Activo: 1,
                    });
                    break;
                case "inactivo":
                    setRegistro({
                        ...registro, Activo: 0,
                    });
                    break;
            }
        };

        function verificarEstacion(estacion, nombre) {
            let validado = false;
            if (estacion && estacion != " ") {
                if (expPermitidaDecimal.test(estacion)) {
                    if ((estacion >= 0.25 && estacion <= 8.00)) {
                        validado = true;
                    } else {
                        validado = false;
                        setMensaje(`La Estación ${nombre} debe ser mayor o igual a 0.25 y menor o igual a 8.00`);
                        setAviso(true);
                        setTipoAdvertencia("error");
                    }
                } else {
                    validado = false;
                    setMensaje(`La Estación ${nombre} debe tener máximo 2 decimales`);
                    setAviso(true);
                    setTipoAdvertencia("error");
                }
            } else {
                validado = false;
                setMensaje(`Ingrese Estación ${nombre}`);
                setAviso(true);
                setTipoAdvertencia("error");
            }

            return validado
        }

        return (
        <ModalGeneral
            titulo={titulo} modalAbierto={modalAbierto}
            setModalAbierto={setModalAbierto} guardarDatos={validar}
            mensaje={mensaje} tipoAdvertencia={tipoAdvertencia}
            aviso={aviso} setAviso={setAviso}
            tamanio={"md"} botonDeshabilitado={botonDeshabilitado}
            cargando={cargando}
        >
            <form className={classes.root} autoComplete="off">

                {Terminal === 1 ? <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <FormControl className={classes.campos}>
                            <TextField id="LecturaE1"
                                label="Estación 1" variant="outlined"
                                size="small" value={LecturaE1} autoFocus={true}
                                onChange={onChangeEstacion1} name="LecturaE1"
                            />
                        </FormControl>
                    </Grid>
                    {/* <Grid item xs={1}>
                        <FormControl className={classes.campos}>
                            <TextField id="LecturaE4A"
                                       label="Estación 4A" variant="outlined"
                                       size="small" value={LecturaE4A}
                                       onChange={onChangeEstacion4A} name="LecturaE4A"
                            />
                        </FormControl>
                    </Grid> */}
                </Grid> 
                : 
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <FormControl className={classes.campos}>
                            <TextField id="LecturaE7"
                                label="Estación 7" variant="outlined"
                                size="small" value={LecturaE7} autoFocus={true}
                                onChange={onChangeEstacion7} name="LecturaE7"
                            />
                        </FormControl>
                    </Grid>
                    {/* <Grid item xs={1}>
                        <FormControl className={classes.campos}>
                            <TextField id="LecturaE4B"
                                       label="Estación 4B" variant="outlined"
                                       size="small" value={LecturaE4B}
                                       onChange={onChangeEstacion4B} name="LecturaE4B"
                            />
                        </FormControl>
                    </Grid> */}
                </Grid>}
                {/* <Grid container spacing={2}>
                    <Grid item>
                        <FormControl className={classes.campos}>
                            <FormLabel>Activo</FormLabel>
                            <RadioGroup aria-label="activo"
                                        defaultValue={editActivo}
                                        onChange={onChangeActivo}
                                        name="Activo"
                            >
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <FormControlLabel value="activo" control={<Radio/>} label="Activo"/>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <FormControlLabel value="inactivo" control={<Radio/>} label="Inactivo"/>
                                    </Grid>
                                </Grid>
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid> */}
            </form>
        </ModalGeneral>);
    }
;

export default EditarCarroTensor;