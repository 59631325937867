import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Box , Button, } from '@material-ui/core';
import axios from 'axios';
import Progress from "../Generales/Progress";
import { useHistory } from "react-router-dom";
import Modal from '../Generales/ModalTam';
import {toast} from 'react-toastify';
import {llamaApiFormData,ErrorGeneral } from '../funciones/ClienteAxios';
import { v4 as uuidv4 } from 'uuid';
const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '20ch',
      },         
      margin: {
        margin: theme.spacing(1),
      },
    },
  }));

const EditEvidencia = ({modalAbierto,setModalAbierto,titulo,orden,setMaxSteps,maxSteps,datos,arregloIMG,setDatos,setListIMG}) => {
    let history = useHistory();
    const classes = useStyles();
    const source = axios.CancelToken.source();      
    let idusu= localStorage.getItem('UsuId');
    let NomUsu= localStorage.getItem('UsuarioNom');    
    const [espera,setEspera]=useState(false);
    const [mensaje, setMensaje] = useState('');
    const [tipoAdvertencia, setTipoAdvertencia] = useState('success');  
    const [aviso, setAviso] = useState(false);      
    const [arraySubidos,setArraySubidos] = useState([])
    const [arrayPreview,setArrayPreview] = useState([])        
    const [progresoEnvio, setProgresoEnvio] = useState(0);
    React.useEffect(()=>{             

        return ()=> {
        source.cancel();
        }     
        
    },[]);

    const registrarlistEvid=()=>{
        const llenarListEvid = () => {   
            let auxSize =0   
            let bodyFormData = new FormData();
            bodyFormData.append('idDispositivo','wed4513s1c2s1cds1cd')
            bodyFormData.append('Llave',orden.Id)
            bodyFormData.append('Usuario',idusu)
            //bodyFormData.append('Status',0)
            //bodyFormData.append('descr',"")
            arraySubidos.forEach((img)=>{
                auxSize=auxSize+img.size
                bodyFormData.append('multi-files',img)
            })        
            let url = "/cctv-bitacora-det-envio";
            function respuesta(auxiliar) {
                if (auxiliar.success===true) 
                {   //console.log(auxiliar);
                    setProgresoEnvio(0)
                    let mensaje="Se subieron correctamente las imagenes"
                    setTipoAdvertencia("success")      
                    setMensaje(mensaje);
                    setAviso(true)     
                    toast.success(`${mensaje}`,{
                        pauseOnFocusLoss: false,
                        theme: "colored",
                        toastId: `exito-env`
                    })  
                    let auxArr=[]      
                    let auxArr2=[]      
                    let arrLink=[]      
                    auxiliar.archivos.forEach((archivo,index)=>{
                        let auxLink=archivo.dirDest+archivo.archivo
                        arrLink.push(auxLink)
                        auxArr={Ext: archivo.ext, Fecha: "", Id: archivo.keyId,
                        Dir: archivo.dirDest,Archivo: archivo.archivo,Size: archivo.tamanio, Tipo: "IMG",
                            UsrId: idusu, UsrNom: NomUsu}
                        auxArr2[index]=auxArr    
                        if (datos.length!==0) 
                        {   datos.splice(index, 0,auxArr )
                        }
                      // console.log(datos);                                         
                    })                                                            
                   
                    
                    if (datos.length===0) 
                    {   setDatos(auxArr2)    
                        setListIMG(arrLink)
                    }                    
                    else
                    {   arregloIMG.splice(0,0,arrLink)
                    }
                    setMaxSteps(maxSteps +auxiliar.archivos.length)
                    setModalAbierto(false)                        
                }       
            }    
            llamaApiFormData(bodyFormData, url,respuesta,ErrorGeneral,setEspera,history,source,auxSize,setProgresoEnvio);
        }            
        llenarListEvid();    
    }  

    function onChangeArchivos2(event){
        setArrayPreview([])
        setArraySubidos([])
        const regex = /([a-zA-Z0-9\s_\.\-\(\):])+(.jpg|.jpeg|png)$/
        let arrArch = [] ,arrArch2 = []
        let arrPrev=[]
        let valido = true;
        let limite=event.target.files.length <= 3 ? event.target.files.length :3 
        for (let i =0;i <limite ;i++){
            let img = event.target.files[i]
            let auxCode = uuidv4();
            if(img && img.name.toLowerCase().match(regex))
            {   let auxNombre = auxCode + img.name.slice(img.name.lastIndexOf("."), img.name.length);
               arrArch.push(img)
               let myNewFile = new File([img], auxNombre, { type: img.type });   
               arrArch2.push(myNewFile)
               arrPrev.push(URL.createObjectURL(img))
            }
            else
            {   let nom ="";                
                if(img.name.length>=25)
                {   nom =  img.name.substring(0,24)+"..."+img.name.substring(img.name.lastIndexOf("."),img.name.length);
                }
                else
                {   nom = img.name;
                }
                let mensaje=`Formato de archivo incorrecto en ${nom} seleccione una imagen tipo jpg,jpeg o png.`
                toast.warning(mensaje,{
                    pauseOnFocusLoss: false,
                    theme: "colored",
                    toastId: `incorrecto-sel`
                  }); 
                  valido=false;
                  setArraySubidos([])
                  break;
            }
        }
        if(valido)
        {   setArraySubidos(arrArch2)
            setArrayPreview(arrPrev)
        }
        //console.log("original",arrArch);
        //console.log("modificado",arrArch2);
    }    
  
    const guardarDatos = () => 
    {   if (arraySubidos.length!==0) 
        {   //console.log("guardarDatos");    
            registrarlistEvid()
        }   
        else{
            let mensaje=`Debe seleccionar una imagen para subir`
            toast.warning(mensaje,{
                pauseOnFocusLoss: false,
                theme: "colored",
                toastId: `incorrecto-sel`
            }); 
        }    
    };

  return ( 
    <Modal
      titulo={titulo} modalAbierto={modalAbierto}
      setModalAbierto={setModalAbierto} guardarDatos={guardarDatos}
      mensaje={mensaje} tipoAdvertencia={tipoAdvertencia}
      aviso={aviso} setAviso={setAviso} espera={espera}
      setEspera={setEspera} tamanio={"md"}
    >
    <form  noValidate autoComplete="off" fullWidth>       
      <Box  display="flex" flexDirection="row">                
        <Box style={{width:33+'rem'}} className={classes.root}>         
            <Box sx={{ display: 'flex', flexDirection: 'row', marginTop:'.3em',marginRight:'.7em'}}>
                <input type="file" name="file" onChange={onChangeArchivos2} hidden id={`cargarArchivo`}
                    accept={"image/jpg,image/jpeg,image/png"} multiple />
                <label htmlFor={`cargarArchivo`}>
                    <Button color="primary" variant="contained" size="small" component="span" 
                        style={{fontSize:12}}
                    >
                        Seleccionar imagen
                    </Button>
                </label>
            </Box> 
            {arrayPreview.length!==0 ?
                arrayPreview.map((imagen,index)=>{
                return(
                <img className={classes.formImg} hidden={!imagen}  src={imagen } 
                    alt="Imagen Revista" id={index} />
                )})
                :null
            }
            {progresoEnvio>0 ? (
                <Progress progress={progresoEnvio}/>)
            :null}
        </Box>     
      </Box>
    </form>
  </Modal>
  );
}
 
export default EditEvidencia