import React from 'react';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import esLocale from "date-fns/locale/es";
import {  
  TimePicker,  
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

const Relojes = ({horaApertura,guardarHoraApertura}) => {
  //const [selectedDate, handleDateChange] = React.useState(new Date());
  function seleccionaHorario(hora) {
    guardarHoraApertura({
      ...horaApertura,
      Horario :hora  ,                        
    })
  }
  
  const {Horario}=horaApertura
  return(
    <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>      
      <TimePicker
        clearable
        ampm={false}
        size='small'
        label="Hora Termino"
        value={Horario}
        style={{width:6.5+'rem'}}
        onChange={seleccionaHorario}
      />    
    
    </MuiPickersUtilsProvider>
  );
}

export default Relojes
