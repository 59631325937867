import React,{useState,useContext} from 'react'
import { useHistory } from "react-router-dom";
import {AuthContext} from '../context/AuthContext'
import {GeneralesContext} from '../context/GeneralesContext'
//import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import { Box,LinearProgress,//Card  ,CardContent ,IconButton,Tooltip,
    Breadcrumbs,Typography,Button } from '@material-ui/core'
//import moment from 'moment'; 
import axios from 'axios';
import qs from 'qs';
//import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {llamadaApiToken,ErrorGeneral } from '../funciones/ClienteAxios';
import TablaUsuario from './TablaUsuario';
import TablaPerfil from './TablaPerfil';
import NuevoUsuario from "./EditUsuario";
import {findNumber} from "../funciones/FindNumber";
const useStyles = makeStyles((theme)=>({
    root: {
      minWidth: 275,
    },    
    title: {
      fontSize: 28,
    },
    title2: {
        fontSize: 18,
      },
    subTitle2: {
        fontSize: 12,
      },
    pos: {
      marginBottom: 12,
    },
    addButton: {
        marginRight: theme.spacing(2),       
      },
    altura2: {
        minHeight: 70,    
        marginTop:0,
        paddingTop:0,    
    },
    altura3: {
        minHeight: 100,
        marginTop:0,
        paddingTop:0,    
    },
    altura1: {
        height: 90,        
    },
    textoSelect: {
        '&:hover': {
          color:'#9ccdfa',
        },
        fontSize:1.25+'rem'
      },
  }));

const Usuarios = () => {
    let nvlSNPermiso ='5'  
    let history = useHistory();
    const classes = useStyles();    
    const {usuario,guardarUsuario}= useContext(AuthContext);
    const {guardarGenerales}= useContext(GeneralesContext);                   
    const [buscar, setBuscar] = useState(true);    
    const [modalNvo, setModalNvo] = useState(false);                    
    const [listUsuarios, setListUsuarios] = useState([])    
    const [usuSelect, setUsuSelect] = useState([])    
    const[opcion,setOpcion]=useState(1)
    const [espera,setEspera]=useState(false)     
    
    React.useEffect(()=>{ 
        const source = axios.CancelToken.source();
        let idusu= localStorage.getItem('UsuId');
        const llenarListUsu = () => {      
            let data = qs.stringify({
              usuario:idusu,
              idDispositivo:'89y7gttvjhj98h'
            });
            let url = "/usuario-catalogo";
            function respuesta(aux) {
              if (aux[0].Id !== -1) 
              {                          
                setListUsuarios(aux);                             
              }
            }            
            llamadaApiToken(data, url, respuesta,ErrorGeneral,setEspera,history,source)
        }; 
   
		const veriSesion =  () => {            
            const usu= localStorage.getItem('UsuarioNom');
            const nvl= localStorage.getItem('Tipo');   
            if(usu === null)
            {                                
                guardarUsuario([])						
                guardarGenerales([])     
                localStorage.clear();           
                history.push("/")	
            }
            else
            {   if (usuario.length === 0) 
                {   guardarUsuario({Nombre:usu,nivel:nvl});                                      
                }
                
                guardarGenerales({Titulo:'Catalogo Usuarios'})  
                                     
                llenarListUsu()        
            }
        }
	    veriSesion();	

        return ()=> {
            source.cancel();
        } 

    },[buscar]);
                                                
    const actualizar=()=>{
        setBuscar(!buscar)
    }
    const selectOpcion =(e,auxValor)=>{
        e.preventDefault();
        //console.log(auxValor);
        setOpcion(auxValor)
    }

    const menuOpcion=(  
        <Breadcrumbs separator="-"   aria-label="breadcrumb"   >        
            <Typography variant="h5" className={classes.textoSelect} 
                color={opcion === 1 ? "Primary":'textPrimary'} 
                onClick={e=>selectOpcion(e,1)}
            >
                Usuario
            </Typography>
          {/*   <Typography variant="h5" className={classes.textoSelect} 
                color={opcion === 2 ? "Primary":'textPrimary'} 
                onClick={e=>selectOpcion(e,2)}
            >
                Perfil
            </Typography>       */}
        </Breadcrumbs> 
    )
    function agregarUsuario() {
        setModalNvo(true)
    }

    const principal=()=>{        
        return(
        <Box display="flex" flexDirection="column" >                                        
            <Box display="flex" pt={1} pb={1} justifyContent='center' >
                {menuOpcion}
            </Box>
            { !espera   ?     
                (opcion===1 ?
                    <Box>
                        {findNumber('|16|') ?
                        <Box>
                            <Button onClick={agregarUsuario} variant="outlined" 
                                size="small" color='primary' style={{marginBottom:.5+'rem'}}>
                                Nuevo Usuario
                            </Button>
                        </Box> :null}
                        <TablaUsuario  datos={listUsuarios} actualizar={actualizar}
                            usuario={usuario} />
                    </Box>
                    :null)
            : <Box> <LinearProgress /> </Box> }
            {opcion===2 ?
                <TablaPerfil  usuario={usuario} setUsuSelect={setUsuSelect} 
                    usuSelect={usuSelect} 
                />
            :null}                                 
        </Box>      
        )
    }
    const modalNuevo = modalNvo ? 
        (usuario?.nivel != undefined && usuario.nivel !== nvlSNPermiso ?
            <NuevoUsuario modalAbierto={modalNvo} setModalAbierto={setModalNvo} actualizar={actualizar}
                titulo={'Agregar Nuevo Usuario '}  user={usuario} isUpdate={false}/> 
            : null) 
        : null;

    return (
    <div>
        {principal()}
        {modalNuevo}
    </div>
    )
}

export default Usuarios
