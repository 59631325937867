import React from 'react'
import {Doughnut} from 'react-chartjs-2';
/*import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme)=>({
    root: {
      minWidth: 275,
    },    
    title: {
      fontSize: 28,
    },
   
  }));*/  
const GraficaParo = ({enservicio,paro}) => {
  const options = {
    animation: false
  }
    //const classes = useStyles();    
    const data = {
        labels: [
          "En servicio",
          "Paro/Falla"
        ],
        datasets: [{
          data: [enservicio, paro],
          backgroundColor: [
          "#5DE163",
          "#FF0D0D"
          
          ],
          hoverBackgroundColor: [
            "#5DE163",
            "#FF0D0D"
          ]
        }]
      }; 
     
      

    return (
      <React.Fragment>
        <Doughnut
            data={data}
            width={80}
            height={80} 
            options={options} 
        />
      </React.Fragment>
    )
}

export default GraficaParo
