import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {InputLabel,FormControl,Select as MaterialSelect  ,Box 
  ,FormControlLabel,LinearProgress,Checkbox,TextField} from '@material-ui/core';
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import Modal from './Modal';
import Rating from '@material-ui/lab/Rating';
const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '20ch',
      }, 
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
      Textnumero: {
        width: 5+"px",
      },
      formControlFull: {
        //margin: theme.spacing(1),
        //minWidth: 120,
        width:5+"rem"
      },
      margin: {
        margin: theme.spacing(1),
      },
    },
  }));

const NuevaRefaccion = ({modalAbierto,setModalAbierto,titulo,auxRefaccion}) => {
  const filter = createFilterOptions();
  let history = useHistory();
  const classes = useStyles();
  const [espera,setEspera]=React.useState(false);
  const [mensaje, setMensaje] = React.useState('');
  const [tipoAdvertencia, setTipoAdvertencia] = React.useState('success');
  const [aviso, setAviso] = React.useState(false);  
  const [nvActividad,guardarNvActividad]= React.useState({Orden:0,Finaliza:0,refaccion:0,
                                                          Descripcion:'',cantidad:0,estatus:0
                                                        ,mttoId:0,});  
  const {Orden,refaccion,Descripcion,cantidad,estatus,mttoId}=nvActividad  
  const[seleccionado,setSeleccionado]= React.useState(null)
  const [listRefacc, guardarListRefacc] = React.useState([])
  //const [value, setValue] = React.useState(0);
  React.useEffect(()=>{           
    guardarNvActividad({
      ...nvActividad,
      Orden:auxRefaccion.Id,
      cantidad:auxRefaccion.cantidad,
      estatus:auxRefaccion.estatus,
      mttoId:auxRefaccion.mttoId,
      refaccion:auxRefaccion.refId,
      Descripcion:auxRefaccion.refNom,
    })    
    console.log(auxRefaccion);
              
    const source = axios.CancelToken.source();       
   
   
  async function llenarListTipoDrive()  {       
    setEspera(true)   
    let auxU=process.env.REACT_APP_LINK +`/mtto-refaccion`;             
    let token =localStorage.getItem('token20') ;             
    let dataInfo = qs.stringify({                                    
        'idDispositivo':'9uccjcsdcsdcsd'
        });
    let config = {
        url:auxU ,
        method: 'POST',
        headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
        data: dataInfo,
        cancelToken: source.token
    };
    let aux ={};           
    await axios(config)
    .then(function (response) {      
      aux=  response.data;                           
      if (aux[0].Id!=-1) 
      {//console.log(aux);
        let auxArre=[];   
        aux.map((tipo) => {
          if (tipo.Id!=0) 
          {
            auxArre.push({ value : tipo.Id, label :tipo.Descr })  
          }            
        })   
        let auxRefa = auxArre.filter(edoAfecta => edoAfecta.value == auxRefaccion.refId)                         
        //console.log(auxRefa);
        if (auxRefa.length>0) {
          setSeleccionado(auxRefa[0])
        }
        guardarListRefacc(auxArre);                                      
      }                                            
    })
    .catch(function (error) {                  
      console.log(`Error: ${error}`);      
      if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
      {
        console.log('datos incorrectos' );  
      }else
      {
        if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
            ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
        {
          console.log('loguearse de nuevo');
          localStorage.clear();                      
          history.push("/");                              
        } 
      }
    });  
    setEspera(false)                       
  };     
  if (listRefacc.length==0) 
  {
    llenarListTipoDrive()
  }  
  return ()=> {
      source.cancel();
  }     
    
},[]);

  const registrarNRefaccion=()=>{
    const source = axios.CancelToken.source();     
    setEspera(true)     
    async function llenarNvRef()  {                   
      let auxU=process.env.REACT_APP_LINK +`/mtto-refaccion-nuevo`;                   
      let token =localStorage.getItem('token20') ;         
      let idusu= localStorage.getItem('UsuId'); 
      let NomUsu= localStorage.getItem('UsuarioNom');  
      let dataInfo = qs.stringify({                                              
          'Mtto':Orden,
          'Usuario':idusu,
          'Refaccion':refaccion,
          'Cantidad':cantidad,
          'Descr':Descripcion,//'28004792-0.38A DISPOSITIVO DE SOBRETENSION CTM 24V 24V',
          'idDispositivo':'8978yuhjnfrsdsd'
          });
      let config = {
          url:auxU ,
          method: 'POST',
          headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
          data: dataInfo,
          cancelToken: source.token
      };
      let aux ={};           
      await axios(config)
      .then(function (response) {                 
        aux=  response.data;       
       // console.log(aux);                               
        if (aux.respuesta==1) 
        { console.log(aux);
          let mensaje='Se registro correctamente la Nueva Refacción'
          setTipoAdvertencia("success")      
          setMensaje(mensaje);
          setAviso(true)                    
        }                                            
      })
      .catch(function (error) {                  
        console.log(`Error: ${error}`);
        
        if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
        {
          console.log('datos incorrectos' );  
        }else
        {
          if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
              ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
          {
            console.log('loguearse de nuevo');
            localStorage.clear();                      
            history.push("/");                              
          } 
        }
      });
      setEspera(false)       
      
    };
    llenarNvRef();    
    return ()=> {
        source.cancel();
    }

  }
  const onChange = e =>
  { let expNopermitida = new RegExp('[#A-Za-z_:$!%-({})/*=?|]');
    let expMenos = new RegExp('-');
    let expMas = new RegExp('[+]');     
    let expCadena = new RegExp('[0-9]');
    if (expCadena.test(e.target.value)&&!expNopermitida.test(e.target.value)&&!expMenos.test(e.target.value)&&
        !expMas.test(e.target.value)||e.target.value===' '||e.target.value==='') 
    {
    
      guardarNvActividad({
        ...nvActividad,
        [e.target.name] : e.target.value  
      })
      if (aviso) 
      {
        setMensaje('');
        setAviso(false);
      }
    }
  };
  
  const guardTerminal = e =>
  {
    guardarNvActividad({
      ...nvActividad,
      [e.target.name] : e.target.value  
    })
  };
  const validaDatos=(valor, mensaje)=>{
    let respuesta=false    
    if (valor!== null && valor!== '' && valor!== '0' && valor!== 0 ) 
    { respuesta=true     
    }
    else
    { setTipoAdvertencia("warning")    
      setMensaje(mensaje);
      setAviso(true)     
    }
    return respuesta
  }
  const guardarDatos = () => 
  {
    if (validaDatos(cantidad,"Escribir Cantidad")) 
    { if ( validaDatos(refaccion,"Seleccionar refacción")) 
      { if ( ((Descripcion.length>=5 && validaDatos(Descripcion, "Seleccionar refacción") ))
          || (Descripcion.length<5 && validaDatos(0, "Seleccionar refacción"))
        ) 
        { 
          registrarNRefaccion()                                         
        }           
      }    
    }                             
  };
  
  const cambioActualiza = (event) => {
    guardarNvActividad({
      ...nvActividad,
        [event.target.name]: event.target.checked 
      });
  };
  /* const guardarParo=(newValue)=>{
    //console.log(newValue);
    if (typeof newValue === 'string' && newValue ) {          
      guardarNvActividad({
        ...nvActividad,
        refaccion :newValue.value  ,
        Descripcion:newValue.label,                
      })
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input          
      guardarNvActividad({
        ...nvActividad,
        refaccion :1 ,
        Descripcion: newValue.inputValue.trim(),
        
      })
    } else {
      if (typeof newValue === 'object' ) {            
        if (newValue) {
          guardarNvActividad({
            ...nvActividad,
            refaccion :newValue.value  ,
            Descripcion: newValue.label,     
          })            
        }else{
          guardarNvActividad({
          ...nvActividad,
          refaccion :''  ,
          Descripcion: '',              
        })   }         
      }
      
    }
  } */
  const guardarParo=(newValue)=>{  
    if (typeof newValue === 'object' ) 
    { if (newValue) 
      { guardarNvActividad({
          ...nvActividad,
          refaccion :newValue.value  ,
          Descripcion: newValue.label,     
        })      
        setSeleccionado(newValue)      
      }
      else
      { guardarNvActividad({
          ...nvActividad,
          refaccion :''  ,
          Descripcion: '',              
        }) 
        setSeleccionado(null)  
      }         
    }          
  }

  return ( 
    <Modal
      titulo={titulo} modalAbierto={modalAbierto} setEspera={setEspera}
      setModalAbierto={setModalAbierto} guardarDatos={guardarDatos}
      mensaje={mensaje} tipoAdvertencia={tipoAdvertencia}
      aviso={aviso} setAviso={setAviso} espera={espera} tamanio={"md"}
    >
      <form className={classes.root} noValidate autoComplete="off" fullWidth>  
      <Box display="flex" flexDirection="row">
        <Box>      
      <TextField id="input-cantidad" label="Cantidad" size="small" type="number"
        value={cantidad} onChange={onChange} name="cantidad" style={{width:4+'rem'}}
      /></Box>                             
      <Box ml={2} pt={1}>
      {/* listRefacc.length!=0 ?  
      <Autocomplete
      value={Descripcion} handleHomeEndKeys      
      id= 'AutoSelect-Solucion' selectOnFocus clearOnBlur      
      onChange={(event, newValue) =>guardarParo(newValue) }
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        // Suggest the creation of a new value
        if (params.inputValue.trim() !== '') {
          filtered.push({
            inputValue: params.inputValue,
            label: `Agregar "${params.inputValue}"`,
          });
        }
        return filtered;
      }}
      
      options={listRefacc}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.label;
      }}
      renderOption={(option) => option.label}
      style={{ width: 550 }}
      loading={listRefacc.length!=0 }
      freeSolo size="small" color="primary"
      renderInput={(params) => (
        <TextField {...params} label="Selecciona una refacción" variant="outlined" />
      )}
    />:<LinearProgress color="primary" /> */}    
    {listRefacc.length!=0 ?  
      <Autocomplete
        value={seleccionado} id= 'AutoSelect-Solucion' 
        onChange={(event, newValue) =>guardarParo(newValue) }
        clearOnBlur selectOnFocus handleHomeEndKeys
        options={listRefacc}      
        //renderOption={(option) => option.label}
        style={{ width: 550 }}
        loading={listRefacc.length!=0 }
        //noOptionsText={"Sin resultados"}
        loadingText="Sin resultados"
        getOptionLabel= {(option) => option.label}
        freeSolo size="small" color="primary"
        
        renderInput={(params) => (
        <TextField {...params} label="Selecciona una refacción" variant="outlined" />
      )}
    />:<LinearProgress color="primary" />}  
    </Box>
 </Box>
      </form>
  </Modal>
  );
}
 
export default NuevaRefaccion;