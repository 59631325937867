import {useState, useEffect} from 'react';
import axios from 'axios';
import qs from 'qs';
import {useHistory} from "react-router-dom";
import {Typography,Box} from '@material-ui/core';
import ModalDummy from '../Generales/ModalDummyTam';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CardDetalleCabina from './CardDetalleCabina'
import {llamadaApiToken,llamaApiCargaToken,ErrorGeneral } from '../funciones/ClienteAxios';
const EditarDetalleCab = ({modalAbierto, setModalAbierto, titulo, mttoActividad, setArrCabinas,setAvTotal,setAuxCabSelecc,setEsperaTabla,nomPersonal,MttoId,setOrdenSelect}) => {

    let history = useHistory();    
    const [espera, setEspera] = useState(false);
    const [mensaje, setMensaje] = useState('');
    const [tipoAdvertencia, setTipoAdvertencia] = useState('success');
    const [aviso, setAviso] = useState(false);
    const [listCambios, setListCambios] = useState(false);
    const source = axios.CancelToken.source();
    let idusu= localStorage.getItem('UsuId');    

    const llamadaMtto=(mttoId)=>{    
        const llenaDetalleCab = () => {                
          let data = qs.stringify({
            Mtto:  mttoId,
            idDispositivo:'89y7gttvjhj98h'
          });
          let url = "/cabina-mtto-det-idmtto_1_1";
          function respuesta(auxiliar) {
            if (auxiliar[0].Id!=-1) 
            {   setArrCabinas(auxiliar)
                 console.log(auxiliar);
                setModalAbierto(false)    
             // setOrdenSelect(mttoId)  
              //setAuxListCabs(auxiliar)
            }             
          }
          llamaApiCargaToken(data, url,respuesta,ErrorGeneral,setEsperaTabla,history,source);
          //llamadaApiToken(data, url,respuestaDetaCab,ErrorGeneral,history,source);
        };     
        const llamaAvance = () => {     
            setAvTotal({avance:"",original:[]}) 
          let data = qs.stringify({
              MttoId:mttoId ,
              Cabina:0,
              Status:3,
              idDispositivo:'89y7gttvjhj98h'
          });
          let url = "/cabina-mtto-avance";
          function respuestaAvan(auxiliar) {
            if (auxiliar ) 
            { //console.log(auxiliar);
              setAvTotal({avance:`${auxiliar.avance}/${auxiliar.total}`,original:auxiliar})
            }               
          }      
          llamadaApiToken(data, url,respuestaAvan,ErrorGeneral,source,history);
        };
        llenaDetalleCab()    
        llamaAvance()          
      }
      
    const editActividad = (actividad,actStd,setAvailable,setCarga,setActividad) => {                
        const llamaEditAct = () => {                  
            let data = qs.stringify({
                MttoIdCabina: actividad.MttoIdCabina,
               // Cabina: actividad.Cabina,
                Status: actStd,
                Usuario: idusu,
                Verifica: actividad.Verifica,
                Cantidad: actividad.Cantidad,     
                Observ:actividad.Observ.trim(),
                idDispositivo:'89y7gttvjhj98h'
            });
            let url = "/cabina-mtto-det-edit_1_2";
            setEspera(true)
            function respuesta(aux) {             
                if (aux.respuesta == 1) { 
                    //console.log(aux);                                        
                    let mensaje = `Se edito correctamente el plan "${actividad.planDescr}" `
                    toast.success(mensaje,{
                        pauseOnFocusLoss: false,                    
                        toastId: `nvo-edicion${actividad.MttoIdCabina}`
                    })
                    setListCambios(true)
                    setTipoAdvertencia("success")
                    setMensaje(mensaje);
                    setAvailable(false)
                    setEspera(false)
                    //setAviso(true)
                    setActividad({...actividad,stId:3})
                }             
            }
            llamaApiCargaToken(data, url,respuesta,ErrorGeneral,setCarga,history,source);
          }; 

        llamaEditAct();
    }

    const mostrarAlertas = (auxMensaje) => {
        setTipoAdvertencia("warning")
        let mensaje = auxMensaje
        setMensaje(mensaje);
        setAviso(true)
    }

   /*  function valNumero(auxNumero, auxCampo) {
        let valida = false
        if (typeof parseInt(auxNumero) === 'number') 
        {   if (parseInt(auxNumero) == 0) 
            {   valida = true
            } 
            else 
            {   if (parseInt(auxNumero) > 0) 
                {   valida = true
                }
            }
        }
        if (!valida) 
        {   mostrarAlertas("Llenar campo " + auxCampo)
        }
        return valida
    } */

    function valNulos(auxMensaje, auxCampo) {
        let valida = false
        if (auxCampo !== '' && auxCampo !== '0' && auxCampo !== 0 && auxCampo !== null && auxCampo !== " ") {
            valida = true
        }
        if (!valida) {
            mostrarAlertas(auxMensaje)
        }
        return valida
    }


    const guardarDatos = (actividad,actStd,setAvailable,setCarga,setActividad) => {
        if ( valNulos("Seleccionar Estatus", true)) 
        {   editActividad(actividad,actStd,setAvailable,setCarga,setActividad)
        }
    };


    const cerrarModal = (aux) => {
        /* if (auxListCabs.length > 0) 
        {   setArrCabinas(auxListCabs)
        } */
        setEspera(true)
        setAuxCabSelecc(mttoActividad[0].CabId)
        if (listCambios) 
        {   llamadaMtto(MttoId)            
        }
        else
        {   setModalAbierto(aux)    
        }
        
    }

    const list2=()=>{    
      //  console.log(mttoActividad,MttoId);    
        return ( 
         mttoActividad.map((element, index) => {         
         return <CardDetalleCabina element={element}
                index={index} mttoActividad={mttoActividad} guardarDatos={guardarDatos}
                aviso={aviso} setAviso={setAviso} setMensaje={setMensaje}
            />           
        }))                
    }

    const auxSubtitulo=(titulo,campo)=>{
       return( <Box display={"flex"} alignItems="center" justifyContent="center" ml={1} >
       <Typography color="textPrimary" 
           style={{ fontWeight: 600, fontSize: 16, marginBottom: 0, paddingBottom: 0 ,marginRight:.5+'rem' }}
       >
           {titulo}
       </Typography>
       <Typography color="textPrimary" 
           style={{ fontSize: 12, marginBottom: 0, paddingBottom: 0 }}
       >
           {campo}
       </Typography>
       </Box>) 
    }
    
    const subtitulo=()=>{
       return(
       <Box display={"flex"}  row justifyContent="center" > 
        {nomPersonal.tecnico.length!==0 ?auxSubtitulo("Técnico",nomPersonal.tecnico):null}           
        {nomPersonal.auxiliar.length!==0 ? auxSubtitulo("Auxiliar",nomPersonal.auxiliar):null}        
             
        </Box>)
    }

    return (
    <ModalDummy
        titulo={titulo} modalAbierto={modalAbierto}
        setModalAbierto={cerrarModal} 
        mensaje={mensaje} tipoAdvertencia={tipoAdvertencia}
        aviso={aviso} setAviso={setAviso} espera={espera}
        tamanio={'lg'} subtitulo={subtitulo} maxTam={true}
    >
        {list2()}
    </ModalDummy>)
}
export default EditarDetalleCab;