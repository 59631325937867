import {useContext,useEffect} from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { GeneralesContext } from "../context/GeneralesContext";

const BitacoraCCTV = () => {
  let history = useHistory();
  const { usuario, guardarUsuario } = useContext(AuthContext);
  const { guardarGenerales } = useContext(GeneralesContext);

  useEffect(() => {
    const veriSesion = () => {
      const usu = localStorage.getItem("UsuarioNom");
      const nvl = localStorage.getItem("Tipo");
      if ( usu !== null|| usuario.length!==0 ) 
      {
        if (usuario.length === 0) 
        {
          guardarUsuario({ Nombre: usu, nivel: nvl });
        }

        guardarGenerales({ Titulo: "" });        
      } 
      else 
      {
        guardarUsuario([]);
        guardarGenerales([]);
        //localStorage.clear();
        history.push("/");
      }
    };
    veriSesion();

  }, []);

  return <div></div>;
};

export default BitacoraCCTV;
