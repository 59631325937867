import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Box,Collapse,IconButton,Paper,Table,TableBody,TableCell,
      TableContainer,TableHead,TableRow,Tooltip} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import CreateIcon from '@material-ui/icons/Create';
import Editar from './EditParoFalla';
import EditarDummy from './EditarFallaDummy'
import {llamadaApiToken,ErrorGeneral } from '../funciones/ClienteAxios';
import AgregarOT from './AgregarOT';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import {findNumber} from "../funciones/FindNumber";
const useStyles = makeStyles({
  root: {
    width: '100%',    
   // height: '100%',
  },
  container: {
    //maxHeight: '100%',
    maxHeight: window.innerHeight-280,    
    minHeight: 280,
    //height: '100%',
  },
  container2: {
    //maxHeight: '100%',
    //maxHeight: window.innerHeight-280,
    //minHeight: 280,
    //height: '100%',
  },
});


const rowFiltro = [
  {
    id: 4, titulo: "Tiempo", alinear: "center",
  },  
  {
    id: 5, titulo: "Estatus", alinear: "center",
  },
  {
    id: 6, titulo: "Lugar", alinear: "center",
  },
  {
    id: 5, titulo: "Ubicación", alinear: "center",
  },
  {
    id: 6, titulo: "Sección", alinear: "center",
  },  
];

const sinRow = [
  {
    id: 1, titulo: "Dia", alinear: "center",
  },  
  {
    id: 2, titulo: "", alinear: "center",
  },
  {
    id: 3, titulo: "Sin", alinear: "center",
  },
  {
    id: 4, titulo: "", alinear: "center",
  },
  {
    id: 5, titulo: "Fallas", alinear: "center",
  },  
  {
    id: 6, titulo: "/", alinear: "center",
  },  
  {
    id: 7, titulo: "Paros", alinear: "center",
  },  
];

export default function TablaParo({buscar,folioSelecc,finicio,fFinal,Bucle,usuarioContxt,nvlSNPermiso,recargaTabla}) {
  const classes = useStyles();
  let history = useHistory();  
  let numUbiOtro = 234,  colorFuente = "#F46239"
  const [open, setOpen] = React.useState(false);
  const [seleccionado, setSeleccionado] = React.useState(0);
  const [seleccionadoEdit, setSeleccionadoEdit] = React.useState([]);
  const [modalAbierto, setModalAbierto] = React.useState(false);
  const [modalAbiertoOT, setModalAbiertoOT] = useState(false);
  const[diaDif,setDiaDif]=React.useState(false);  
  const[sinDatos,guardarSinDatos]= React.useState(true)
  const [datos, guardarDatos] = React.useState([])
  
  React.useEffect(()=>{ 
    const source = axios.CancelToken.source();    
    let idusu= localStorage.getItem('UsuId');

    const llenarListParos = () => {      
      let data = qs.stringify({           
        usuario: idusu,
        terminal:Bucle,                          
        fechaIni:finicio+'00:00:00',
        fechaFin:fFinal+'23:59:59',                  
        idDispositivo:'89y7gttvjhj98h'
      });
      let url = "/parofalla-list-terminal";
      function respuesta(aux) 
      {  if (aux[0].Id !== -1) 
        { guardarSinDatos(true)                 
          guardarDatos(aux)
        }
        else
        { guardarSinDatos(false)
        } 
      }
      
      llamadaApiToken(data, url, respuesta,ErrorGeneral,history,source)
    };
      console.log("entrando");
    llenarListParos();
    if (finicio!==fFinal) 
    {       
      setDiaDif(true);        
    }else
    {
      setDiaDif(false);        
    }
    console.log( window.location.host);
    return ()=> {
      source.cancel();
    }                

  },[]);

function verMas(auxId) {
  //setOpen(!open)
// console.log(auxId);
  setSeleccionado(auxId)
  if (auxId ===seleccionado) 
  {
    setOpen(!open)
  }
  else
  {
    setOpen(true)
  }
}
function editarFall(auxFalla) {
  setSeleccionadoEdit(auxFalla)
  setModalAbierto(true)
}
function abrirNvaOT(auxFalla) {
  setSeleccionadoEdit(auxFalla)
  setModalAbiertoOT(true)
}

function tipoEstado(estado)
  { let auxEtiqueta=''
    let auxColor=''
    if (estado==0) 
    { auxEtiqueta='Registrado'
      auxColor='#F46239'
    }
    else
    { if (estado==1) 
      { auxEtiqueta='Solucionado'
        auxColor='#049b4f'
      }
      else
      { if (estado == -1) 
        { auxEtiqueta='Cancelado'
          auxColor='red'
        } 
      }
    }
    let renglon=<TableCell align="center" style={{ color:auxColor,fontWeight:700,width:82+'px'}}  padding={'none'} >
                  {auxEtiqueta}
                </TableCell>
    return renglon
  }

function miniTabla(auxId,titulo,registro) {
  return(
    <Table size="small" aria-label="purchases">
      <TableHead>
        <TableRow>                                    
          <TableCell align="left" style={{ color:colorFuente}} padding={'none'}>
            {titulo}
          </TableCell>                
        </TableRow>                  
      </TableHead>
      <TableBody>
        <TableRow key={auxId}>                        
          <TableCell  className={classes.tableCell} align="left"
            padding={'none'} //style={{ width:120+'px'}}
          >
            {registro}
          </TableCell>                              
        </TableRow>
      </TableBody>
    </Table>
  )
} 
const celda = (alinear, estilo, contenido,key) => {
  return (
    <TableCell
      align={alinear} className={classes.tableCell}
      padding={"none"} style={estilo} key={key}
    >
      {contenido}
    </TableCell>
  );
};

function Row(props) {
  const { row } = props;  

  const ordenFecha=(fecha)=>{
    let ordenada=""
    ordenada=fecha.substring(8,10)+'/'+fecha.substring(5,7)+'/'+fecha.substring(0,4)
    return ordenada;
  }
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell align="center" padding={'none'} style={{ width:1+'rem'}}>
          <IconButton color="primary"  aria-label="expand row" size="small" onClick={() => verMas(row.Id)}>
            { open && seleccionado=== row.Id? <KeyboardArrowUpIcon /> :  <KeyboardArrowDownIcon />}            
          </IconButton>
        </TableCell>
        <TableCell align="center" padding={'none'} style={{ width:1+'rem'}} >
          {findNumber('|38|') ?
          <IconButton color="primary"  aria-label="add circle" size="small" onClick={() => editarFall(row)} component="span">
              <CreateIcon/>
          </IconButton>   :""}                                                                                                                                                                       
        </TableCell> 
        <TableCell align="center" padding={'none'} style={{width: 1 + 'rem'}}>
          {row.orden === 0 ?
            <Tooltip title="Generar OT">
              <IconButton color="primary" aria-label="edit circle" size="small"
                  onClick={() => abrirNvaOT(row)} component="span">
                  <AssignmentTurnedInIcon/>
              </IconButton>
            </Tooltip> :
            <Tooltip title="OT Asignada">              
              <AssignmentTurnedInIcon  style={{color: 'green'}}  />              
            </Tooltip>
          }
      </TableCell>                                       
        <TableCell component="th" scope="row" align="center" style={{ fontWeight:700,width:100+'px'}} padding={'none'}  >
          {row.Id}
        </TableCell>        
        <TableCell align="center" padding={'none'} style={{ width:120+'px'}} >
          { diaDif? ordenFecha(row.fecha)+' '+row.hora:row.hora }
        </TableCell>
        <TableCell align="center" padding={'none'} style={{ width:80+'px'}}  >{row.tiempo}</TableCell>
          {tipoEstado(row.Status)}
        <TableCell align="center" padding={'none'} style={{ width:75+'px'}}  >{row.lugarNom}</TableCell>
        <TableCell align="center" padding={'none'} style={{ width:50+'px'}}  >
          {row.ubicId ==numUbiOtro ?row.ubicDescr:row.ubicNom}
        </TableCell>
        <TableCell align="center" padding={'none'} style={{ width:50+'px'}}>
          {row.alaNom}
        </TableCell>      
        <TableCell align="center" padding={'none'} style={{width: 75 + 'px'}}>
          {row?.orden !==0 && row.orden !=="0"?row.orden:"" }
        </TableCell>  
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={11}>
          <Collapse in={ open && seleccionado=== row.Id} timeout="auto" unmountOnExit>
          <Box margin={1}>                              
            {row.descr.length!== 0 ? miniTabla(row.Id,"Descripción",row.descr) :null}
            {row.info.length!== 0 ? miniTabla(row.Id,"Info. Adicional",row.info) :null}
            {row.solucion.length!== 0 ? miniTabla(row.Id,"Solución",row.solucion) :null}                                                                          
          </Box>            
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
function tablaCompleta(auxlista){
  //console.log("entrando sin preguntar");
  return(
    <TableContainer className={classes.container} id="tablaCompleta-paro">
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow  >
            <TableCell></TableCell>    
            {celda( "center", { color:colorFuente }, (auxlista.length!==0? auxlista.length:null ),1 )}
            {celda("center", {color: colorFuente}, "", 8)}
            {celda( "center", { color:colorFuente }, "#Paro/Falla",2 )}            
            {celda( "center", { color:colorFuente }, (diaDif ? 'Fecha':'Horario'),3 )}                        
            <TableCell style={{ color:colorFuente}} align="center" padding={'none'} >Tiempo</TableCell>
            <TableCell style={{ color:colorFuente}} align="center" padding={'none'} >Estatus</TableCell>
            <TableCell style={{ color:colorFuente}} align="center" padding={'none'} >Lugar</TableCell>
            <TableCell style={{ color:colorFuente}} align="center" padding={'none'} >Ubicación</TableCell>
            <TableCell style={{ color:colorFuente}} align="center" padding={'none'} >Sección</TableCell>           
            <TableCell style={{color: colorFuente}} align="center" padding={'none'}>Orden</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {auxlista.length!=0? (auxlista.map((row) => (
          <Row key={row.Id} row={row} />
        ))):null}           
        </TableBody>
      </Table>
    </TableContainer>
 )
}
const tablaSinDatos=()=>{
 return(<TableContainer className={classes.container2}>
  <Table stickyHeader aria-label="sticky table">
    <TableHead>
      <TableRow>                    
        <TableCell style={{ color:colorFuente}}  >#Paro/Falla </TableCell>            
        <TableCell style={{ color:colorFuente}} align="center">Hora</TableCell>
        <TableCell style={{ color:colorFuente}} align="center">Tiempo</TableCell>        
        <TableCell style={{ color:colorFuente}} align="center">Lugar</TableCell>
        <TableCell style={{ color:colorFuente}} align="center">Ubicación</TableCell>
        <TableCell style={{ color:colorFuente}} align="center">Sección</TableCell>
        <TableCell style={{color: colorFuente}} align="center"  >Orden</TableCell>
        <TableCell style={{ color:colorFuente}} align="center">Descripción</TableCell>                              
      </TableRow>
    </TableHead>
    <TableBody>
      <TableRow className={classes.tableRow} >          
      {sinRow.map((row)=>{
        return(
          celda( row.alinear,{}, row.titulo,row.id )
        )
      })}   
      </TableRow>
    </TableBody>
  </Table>
</TableContainer>
) 
}
const tablaSinResultados=()=>{
  return(<TableContainer className={classes.container2}>
   <Table stickyHeader aria-label="sticky table">
     <TableHead>
        <TableRow>            
        <TableCell style={{ color:colorFuente}}>#Paro/Falla </TableCell>            
          <TableCell style={{ color:colorFuente}} align="center">Hora</TableCell>
          <TableCell style={{ color:colorFuente}} align="center">Tiempo</TableCell>
          <TableCell style={{ color:colorFuente}} align="center">Estatus</TableCell>
          <TableCell style={{ color:colorFuente}} align="center">Lugar</TableCell>
          <TableCell style={{ color:colorFuente}} align="center">Ubicación</TableCell>
          <TableCell style={{ color:colorFuente}} align="center">Sección</TableCell>
          <TableCell style={{color: colorFuente}} align="center"  >Orden</TableCell>
          <TableCell style={{ color:colorFuente}} align="center">Descripción</TableCell>                         
        </TableRow>
     </TableHead>
     <TableBody>
      <TableRow className={classes.tableRow} >          
      <TableCell  className={classes.tableCell} align="left"></TableCell>          
         <TableCell  className={classes.tableCell} align="center">Folio</TableCell>
         <TableCell  className={classes.tableCell} align="left"></TableCell>
         <TableCell  className={classes.tableCell} align="left">Sin</TableCell>   
         <TableCell  className={classes.tableCell} align="left"></TableCell>   
         <TableCell  className={classes.tableCell} align="left">coincidencias</TableCell>   
         <TableCell  className={classes.tableCell} align="left"></TableCell>          
         <TableCell  className={classes.tableCell} align="left"></TableCell>          
       </TableRow>
     </TableBody>
   </Table>
 </TableContainer>
 ) 
 }

 function filtro(){        
  let auxCadena=folioSelecc.trim();           
  let info=datos.filter((dat)=>dat.Id.toString().search(auxCadena.toUpperCase() ) !== -1)         
  let metodos=null
  if (info.length>0) {
    //console.log("mandandando la lista");
    metodos= tablaCompleta(info)
  } else {
    metodos=tablaSinResultados()
  }
  return metodos ;
}

const contenido2=folioSelecc.length !== 0 && folioSelecc !== '' ? filtro() : tablaCompleta(datos) 
const contenido = sinDatos && datos.length !== 0 ?contenido2:tablaSinDatos()
const modal =seleccionadoEdit.length!=0 && modalAbierto !='' ? 
              (usuarioContxt?.nivel !=undefined && usuarioContxt.nivel!== nvlSNPermiso ? 
                <Editar titulo={'Editar Paro/Falla '+ seleccionadoEdit.Id} modalAbierto={modalAbierto} 
                  setModalAbierto={setModalAbierto} paroSelect={seleccionadoEdit} recargaTabla={recargaTabla}
                />:
                <EditarDummy titulo={'Paro/Falla '+ seleccionadoEdit.Id} modalAbierto={modalAbierto} 
                  setModalAbierto={setModalAbierto} seleccionadoEdit={seleccionadoEdit}
                />              
              )
              :null
  const modalOT = seleccionadoEdit.length !== 0 && modalAbiertoOT !== false ?
              (usuarioContxt?.nivel !== undefined && usuarioContxt.nivel !== nvlSNPermiso ?
                      <AgregarOT  modalAbierto={modalAbiertoOT} setModalAbierto={setModalAbiertoOT} titulo={'Nueva OT'}
                                  paroSelect={seleccionadoEdit} recargaTabla={recargaTabla}
                      /> :
                      <EditarDummy titulo={'Paro/Falla ' + seleccionadoEdit.Id} modalAbierto={modalAbierto}
                                   setModalAbierto={setModalAbiertoOT} seleccionadoEdit={seleccionadoEdit}
                      />
              )
              : null              
  return (
    <Paper className={classes.root}>
      {contenido}
     {modal}
     {modalOT}
    </Paper>
  );
}
