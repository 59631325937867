import React, { useState } from 'react';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import esLocale from "date-fns/locale/es";
import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

const RelojDDrive = ({nvLecturaDriv,guardarNvLecturaDriv,HoraD}) => {
//  const [selectedDate, handleDateChange] = useState(new Date());
  const[horaActiva,setHoraActiva]= useState(false);
  const {DrivHorario}=nvLecturaDriv
  React.useEffect(() => {
    if (HoraD && HoraD!== null && HoraD!== undefined) {
      setHoraActiva(true)  
    }    
      
  }, [])
  
  function seleccionaHorario(hora) {
    guardarNvLecturaDriv({
      ...nvLecturaDriv,
      DrivHorario :hora  ,                        
    })
  }
  
  
  return(
    <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>      
      <TimePicker
        clearable
        disabled={horaActiva}
        ampm={false}
        size='small'
        label="Horario"
        value={DrivHorario}
        style={{width:6.5+'rem'}}
        onChange={seleccionaHorario}
      />    
    
    </MuiPickersUtilsProvider>
  );
}

export default RelojDDrive
