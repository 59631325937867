import React,{useState,useEffect} from 'react';
import { makeStyles,Grid, Box, Typography,Card} from '@material-ui/core'
    const useStyles = makeStyles((theme) => ({
        img:{
            width:'35px',
            height:'35px',
            marginTop: 2+'px',
        },
        imgSeleccionada:{
           // 
            width:'35px',
            height:'35px',
          //  position: "absolute",
        },
        contenedorimg:{
            width:'40px',
            height:'40px',  
            position: "relative",
            textAlign: "center",
            //backgroundColor:'white'
        },
        contenedorimgSeleccionado:{
            width:'40px',
            height:'40px',  
            position: "relative",
            alignItems:"center",
            textAlign: "center",
            backgroundColor:'#1C8AEB'
        },
        textoCentrado:{
            position: "absolute",
            color:'white',
            top: "48%",
            left: "50%",
            transform: "translate(-50%, -50%)"
        },
        fila:{
            padding:1
        },
        contenedor:{
            overflowX:'auto',
            overflowY:'hidden'
            /*borderRadius: '6px',
            border: '2px solid #FF6439',
            overflowX:'scroll'*/
          }
      }));

export default function PilonaContainer({setCabinaSelec,cabSelecc,arrCabinas,avTotal}){
    const classes = useStyles();
    let altuTamMax=825
    let anchoTamMax=1535
    const [filas,setFilas] =useState(0)
    const [restantes, setRestantes] = useState(0)
    const [numCabinas, setNumCabinas] =useState(0);    
    const [indice,setIndice] = useState(-1);
    const [cabinaObjLista, setCabinaObjLista] = useState([])
    const [alturaContenedor, setAlturaContenedor] = useState (window.innerHeight<altuTamMax ? window.innerHeight-460: window.innerHeight - 600)        
    const [anchoContenedor, setAnchoContenedor] = useState(window.innerWidth<anchoTamMax ? window.innerWidth-600 : window.innerWidth-600)
    
    useEffect(()=>{
        let auxCabinas = Math.trunc(((alturaContenedor)/55))
         //console.log(window.innerHeight,alturaContenedor)
         //console.log(auxCabinas)
        setNumCabinas(auxCabinas)
       // let auxTotalCab =0
        let auxArreCab=[]
        //let contFinalizado=0
        arrCabinas.forEach((cab)=>{
            let auxStd = estatus(cab.Avance,cab.Total)
            if (auxArreCab.length===0) {
                
                auxArreCab.push({Id:cab.Id , status:auxStd , nomb:cab.PiloNom })
            }
            let aux=auxArreCab.filter((cabina)=>cabina.Id === cab.Id)
            if (aux.length===0) 
            {
                auxArreCab.push({Id:cab.Id , status:auxStd , nomb:cab.PiloNom})
            }
        })
        auxArreCab.sort(function (a, b) { return a.Id - b.Id; }); 
        //let nvoArreCab=[]
        //console.log(auxArreCab);
        
       /*  auxArreCab.forEach((cab)=>{            
            let statusCab=0;                        
            let aux=avTotal.original.filter((elemnto)=>elemnto.Id===cab.Id)                                     
            let auxStatus=aux[0]            
            if (auxStatus.avance < auxStatus.total && auxStatus.avance !== 0 ) 
            {   statusCab=2
            } 
            else 
            {   if (auxStatus.avance === auxStatus.total ) 
                {   statusCab=3
                }
                else
                {   statusCab=1
                }
            }  
            nvoArreCab.push({ Id: cab.Id ,status: statusCab })
        })
        */
        const auxfilas = Math.trunc((auxArreCab.length/auxCabinas))
        setFilas(auxfilas)
        if(auxArreCab.length%auxCabinas!=0){
            const resta = auxArreCab.length - auxfilas * auxCabinas
            setRestantes(resta)
        } 
 
        setCabinaObjLista(auxArreCab)   
        //setCabinaObjLista(nvoArreCab)   
        setCabinaSelec([])        

        window.addEventListener('resize', function() {
            if(window.innerHeight<altuTamMax){
                setAlturaContenedor(window.innerHeight-495)
            }else{
                setAlturaContenedor(window.innerHeight-630)
            }

            if(window.innerWidth<anchoTamMax){
                setAnchoContenedor(window.innerWidth-680)
            }else{
                setAnchoContenedor(window.innerWidth-600)
            }
        });
        
        if (cabSelecc===0) 
        {
            setIndice(auxArreCab[0].Id)
            let arrSelecc=arrCabinas.filter((cab)=>cab.Id === auxArreCab[0].Id)
            setCabinaSelec(arrSelecc)    
        }
        else{
            setIndice(cabSelecc)
            let arrSelecc=arrCabinas.filter((cab)=>cab.Id === cabSelecc)
            setCabinaSelec(arrSelecc)    
        }
        
    },[arrCabinas])

    const estatus=(auxAvance,auxTotal)=>{
        let respuesta=0 , bandera=false
        if (!bandera && auxAvance===0) 
        {
            respuesta=1 
            bandera=true
        }
        if (!bandera && auxAvance > 0 && auxAvance < auxTotal ) 
        {
            respuesta=2 
            bandera=true
        }
        if (!bandera && auxAvance === auxTotal ) 
        {
            respuesta=3             
        }
        return respuesta
    }

    function seleccionarCabina(seleccionado){
       // setCabinaSeleccionada(seleccionado)   
       let arrSelecc=arrCabinas.filter((cab)=>cab.Id === seleccionado.Id)     
        //console.log(arrSelecc);
        if(indice!== seleccionado.Id)
        {   setIndice(seleccionado.Id)
        }
        if (arrSelecc.length>0) {
            setCabinaSelec(arrSelecc)
        }
        
      // console.log(seleccionado)
    }

    function generarCabinas(){
        let arr=[];
        let id=0;
        for(let i = 0; i<filas;i++){
            let arrCab= []
            for(let cabinas=0;cabinas<numCabinas;cabinas++){
                let cabina=cabinaObjLista[id];
                let srcImg="";                
                switch(cabina.status){
                    case 1:
                        srcImg="/iconos/Pilona pendiente.svg"
                        break;
                    case 2:
                        srcImg="/iconos/Pilona avance.svg"
                        break;
                    case 3:
                        srcImg="/iconos/Pilona completo.svg"
                        break;
                    default:
                        srcImg="/iconos/Pilona vacio.svg"
                        break;
                }
                arrCab.push(<div className={ cabina.Id === indice ? classes.contenedorimgSeleccionado :classes.contenedorimg} 
                                onClick={()=>{seleccionarCabina(cabina)}}
                            >
                                <img className={classes.img} src={srcImg}/>
                                <div className={classes.textoCentrado}>
                                    <strong>{cabinaObjLista[id].nomb}</strong>
                                </div>
                             </div>)    
                id++;
            }
            let grid = <Box flexDirection={"column"} display="flex" className={classes.fila}>
                {arrCab}
                 </Box>
            arr.push(grid);
        }

        if(restantes>0){
            let arrCab= []
            for(let cabinas=0;cabinas<restantes;cabinas++){
                if(id<cabinaObjLista.length){
                    let cabina=cabinaObjLista[id];
                    let srcImg="";                
                    switch(cabina.status){
                        case 1:
                            srcImg="/iconos/Pilona pendiente.svg"
                            break;
                        case 2:
                            srcImg="/iconos/Pilona avance.svg"
                            break;
                        case 3:
                            srcImg="/iconos/Pilona completo.svg"
                            break;
                        default:
                            srcImg="/iconos/Pilona vacio.svg"
                            break;
                    }
                    arrCab.push(<div className={ cabina.Id === indice ? classes.contenedorimgSeleccionado :classes.contenedorimg} 
                                    onClick={()=>{seleccionarCabina(cabina)}}
                                >
                                    <img className={classes.img} src={srcImg}/>
                                    <div className={classes.textoCentrado}>
                                        <strong>{cabinaObjLista[id].nomb}</strong>
                                    </div>
                                </div>)    
                    id++;
                }
            }
            if(arrCab.length>0){
                let grid = <Box flexDirection={"column"} display="flex" className={classes.fila}>
                    {arrCab}
                </Box>
                arr.push(grid)
            }
        }
        return arr;
    }

    const cabMostrar = cabinaObjLista.length>0 ?  generarCabinas() : null;  
    return(
        <Box flexDirection={"row"} display="flex" className={classes.contenedor} id="contenedor-pilonas"
            style={{maxHeight:alturaContenedor, maxWidth:anchoContenedor,paddingRight:.5+'rem'}}
        >
            {cabMostrar}
        </Box>
    )
}