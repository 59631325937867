import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FormControlLabel, Box, MenuItem, FormControl, Select, 
         InputLabel, Radio, RadioGroup, FormLabel ,Grid} from "@material-ui/core";
import ModalDummy from "./ModalDummy";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    root: {
      margin: {
        margin: theme.spacing(1),
      },
    },
    campos:{
      width: '200px',
      marginBottom: '1em'
    },
    fecha:{
      width: '200px',
      marginTop:'3px',
      marginBottom: '1em'
    }
  
  }));

const FormEditarDummy = ({ modalAbierto, setModalAbierto, titulo, energia,terminales}) => {
    const classes = useStyles();
    const [mensaje, setMensaje] = useState("");
    const [tipoAdvertencia, setTipoAdvertencia] = useState("success");
    const [aviso, setAviso] = useState(false);
    const [registro, setRegistro] = useState({
                                                  Id: energia.Id,
                                                  Consumo: energia.Consumo,
                                                  Costo: energia.PrecioPromedio,
                                                  Terminal: energia.TerminalId,
                                                  Fecha: moment().format(energia.Fecha),
                                                  Medidor: energia.NumMedidor,
                                                  Activo: "activo"
                                                  });

    const [cargando, setCargando] = useState(false);
    const { Consumo, Costo, Terminal, Fecha, Medidor, Activo } = registro;



    return (
        <ModalDummy
        titulo={titulo}
        modalAbierto={modalAbierto}
        setModalAbierto={setModalAbierto}      
        mensaje={mensaje}
        tipoAdvertencia={tipoAdvertencia}
        aviso={aviso}
        setAviso={setAviso}
        espera={cargando}
        setEspera={setCargando}
        >
            <form className={classes.root} autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl className={classes.campos}>
                    <InputLabel id="Terminal">Terminal</InputLabel>
                    <Select
                      label="Terminal" id="Terminal" name="Terminal" value={Terminal} disabled
                    >
                      {terminales.map((terminal, index) => (
                        <MenuItem value={terminal.Id} key={terminal.Id}>
                          <em>{terminal.Descr}</em>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={1}>
                  <FormControl className={classes.fecha}>
                    <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
                      <DatePicker format="dd/MM/yyyy"
                        label="Fecha" size="small" name ="Fecha"
                        value={Fecha} disabled
                        maxDate="2099-12-31"
                      />
                    </MuiPickersUtilsProvider>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item>
                  <FormControl className={classes.campos}>
                    <FormLabel>Activo</FormLabel>
                    <RadioGroup aria-label="activo"
                      defaultValue={Activo} name="Activo"
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <FormControlLabel value="activo" control={<Radio />} label="Activo" disabled/>
                        </Grid>
                        <Grid item xs={1}>
                          <FormControlLabel value="inactivo" control={<Radio />} label="Inactivo" disabled />
                        </Grid>
                      </Grid>
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
              <Box display="flex" flexDirection="row" style={{width:28+'rem', marginBottom:.5+'rem'}}  >     
                <Box display="flex" flexDirection="row"  style={{width:8+'rem',marginRight:1+"rem"}}>
                  <span style={{width:3+'rem',color:'#F46239',fontWeight:600,marginRight:.8+'rem'}}>
                    {'Medidor'}
                  </span>           
                  <input type="text" id="Medidor" value={Medidor} 
                    disabled name="Medidor" style={{width:4+'rem',height:1.5+'rem',fontWeight:700}}/>
                </Box>
                <Box display="flex" flexDirection="row"  style={{width:10+'rem',marginRight:1.2+"rem"}}>
                  <span style={{width:4.2+'rem',color:'#F46239',fontWeight:600,marginRight:.5+'rem'}}>
                      Consumo
                  </span>           
                  <input type="text" id="Consumo" value={Consumo} 
                    disabled name="Consumo" style={{width:6+'rem',height:1.5+'rem',fontWeight:700}}/>
                </Box>
                <Box display="flex" flexDirection="row"  style={{width:10+'rem'}}>
                  <span style={{width:2.5+'rem',color:'#F46239',fontWeight:600,marginRight:.5+'rem'}}>
                      Costo
                  </span>           
                  <input type="text" id="Costo" value={Costo} 
                    disabled name="Costo" style={{width:5+'rem',height:1.5+'rem',fontWeight:700}}/>
                </Box>
              </Box>
            </form>
        </ModalDummy>
      );
};
    
export default FormEditarDummy;