import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Box,Card} from '@material-ui/core'
const GraficaDirect = ({listaDirectDV,Bucle}) => {
    const [arrGrafica1, guardarArrGrafica1] = React.useState([]) 
    const [nomHorario, guardaNomHorario] = React.useState([]) 
    const DATA_COUNT = 12;
    const labels = [];
    for (let i = 0; i < DATA_COUNT; ++i) {
    labels.push(i.toString());
    }
    //const datapoints = [0, 20, 20, 60, 60, 120, NaN, 180, 120, 125, 105, 110, 170];
    const colores=["#5DE163","#FFC400","#24392D","#018786","#03DAC5",
                    "#E91E63","#F4511E","#00B0FF","#0AC15D","#F67956","#0358A3"]
    const camposListas=[
        'Temperatura Ambiente', 'Valor Referencia de Velocidad del Sistema',
        'Temperatura de Rodamiento del Motor ','Temperatura Inverter LeitDrive 1',
        'Temperatura Inverter LeitDrive 2','Temperatura Inverter LeitDrive 3',
        'Temperatura Inverter LeitDrive 4',
        'Dif. Presión P1-P3','Dif. Presión P2-P3','Temperatura Máxima Instantánea Devanados Motor',
        'Temperatura Mínima Instantánea Devanados Motor']
    React.useEffect(()=>{                 
       // console.log(listaDirectDV);
        let auxArreglo= [
            [0], [0], [0], [0],
            [0], [0], [0], [0],
            [0], [0], [0], [0],[0]
        ];        
        let auxNomHora=[]
        let contador=0;
        listaDirectDV.map((lectura) =>
        {            
            if (Bucle==lectura.TermId) {                
                auxArreglo[0][contador] =lectura.ValTemAmb
                auxArreglo[1][contador] =lectura.ValVelSistem
                auxArreglo[2][contador] =lectura.ValTempRoda
                auxArreglo[3][contador] =lectura.ValTempInv1
                auxArreglo[4][contador] =lectura.ValTempInv2
                auxArreglo[5][contador] =lectura.ValTempInv3
                auxArreglo[6][contador] =lectura.ValTempInv4
                auxArreglo[7][contador] =lectura.ValDifPreP1P3
                auxArreglo[8][contador] =lectura.ValDifPreP2P3
                auxArreglo[9][contador] =lectura.ValTempMaxDev
                auxArreglo[10][contador] =lectura.ValTempMinDev
                auxNomHora.push(lectura.Horario)
                contador++                
            }            
        })                       
        //console.log(auxArreglo);
        guardaNomHorario(auxNomHora);
        guardarArrGrafica1(auxArreglo);
    },[listaDirectDV,Bucle]);
    const generaData=()=>
    {   let arreData=[]
        for (let index = 0; index < colores.length; index++) 
        {
            arreData.push(
            {   type: 'line',
                label:camposListas[index],
                data: arrGrafica1[index],
                backgroundColor: colores[index],
                borderColor: colores[index],
                fill: false                             
                ,tension: 0.4
            })
        }
        console.log(arreData);
        return(arreData)
    }
    const data = {
        labels:nomHorario,
        /* datasets: [
            {type: 'line',
            label: camposListas[0],
            data: arrGrafica1[0],
            backgroundColor: colores[0],
            fill: false,
            cubicInterpolationMode: 'monotone',
            tension: 0.4
            }, 
            {type: 'line',
            label: camposListas[1],
            data: arrGrafica1[1],
            backgroundColor: colores[1],
            fill: false,
            tension: 0.4
            }
        ] */
        datasets:generaData()
        };
    const config = {
    type: 'line',
    data: data,
    options: {
        responsive: true,
        plugins: {
        title: {
            display: true,
            text: 'Chart.js Line Chart - Cubic interpolation mode'
        },
        },
        interaction: {
        intersect: false,
        },
        scales: {
        x: {
            display: true,
            title: {
            display: true
            }
        },
        y: {
            display: true,
            title: {
            display: true,
            text: 'Value'
            },
            suggestedMin: -10,
            suggestedMax: 200
        }
        }
    },
    };
    const options={

        responsive: true,
        plugins: {
          title: {
            display: false,
            text: 'Lecturas '
          },
        },
        interaction: {
          intersect: false,
        },
        scales: {
          x: {
            display: true,
            title: {
              display: true
            }
          },
          y: {
            display: true,
            title: {
              display: false,
              text: 'Value'
            },
            //suggestedMin: -10,
           // suggestedMax: 200
          }
        },
        legend: {
            display: false,
          },
          tooltips: {
            enabled: true,
          },
      
    }

    return (
        <Box // style={{width:37+'rem',height:30+'rem'}}
        pt={1}
        >    
        <Card style={{paddingBottom:.1+'rem' }}>
        <span style={{paddingLeft:.2+'rem' ,color:'red' }}>Clic en el color de la leyenda para activar o desactivar el dato en la Grafica</span>
        </Card>
        
            <Bar data={data} 
                options={options}
                width={400}
                height={380}
            />
        </Box>  
    );
}
 
export default GraficaDirect;