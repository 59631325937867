import {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {
    FormControlLabel, FormControl, Radio, RadioGroup, FormLabel, Grid, TextField, LinearProgress
} from "@material-ui/core";
import Modal from "../Generales/Modal2";
import {useHistory} from "react-router-dom";
import axios from "axios";
import qs from "qs";
import Autocomplete, {createFilterOptions} from "@material-ui/lab/Autocomplete";
import {llamaApiCargaToken, ErrorGeneral} from '../funciones/ClienteAxios';
import 'react-toastify/dist/ReactToastify.css';
import {toast} from 'react-toastify';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: {
            margin: theme.spacing(1),
        },
    }, medidor: {
        width: '100px', marginBottom: '1em'
    }, campos: {
        width: '200px', //marginBottom: '1em'
    }, camposLectura: {
        width: '150px', marginBottom: '1em'
    }, fecha: {
        width: '200px', marginTop: '3px', marginBottom: '1em'
    }

}));

const EditarParoFalla = ({
                             modalAbierto,
                             setModalAbierto,
                             titulo,
                             paroFalla,
                             catalogo,
                             actualiza,
                             setActualiza
                         }) => {
    
        const filter = createFilterOptions();
        const classes = useStyles();
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        const historial = useHistory();

        const [mensaje, setMensaje] = useState("");
        const [tipoAdvertencia, setTipoAdvertencia] = useState("success");
        const [aviso, setAviso] = useState(false);
        const [registro, setRegistro] = useState({
            Id: paroFalla.Id,
            Fecha: paroFalla.Fecha,
            Descr: paroFalla.Descr,
            Status: paroFalla.Status
        });
        const [validateError, setValidateError] = useState(false);
        const [cargando, setCargando] = useState(false);
        const [botonDeshabilitado, setBotonDeshabilitado] = useState(true);

        const {Id, Fecha, Descr, Status} = paroFalla;

        const editarParoFalla = () => {
            setCargando(true)
            setBotonDeshabilitado(true);
            let data = qs.stringify({
                    usuario: localStorage.getItem("UsuId"),
                    llave: registro.Id,
                    descr: registro.Descr,
                    status: registro.Status,
                    idDispositivo: '987gyubjhkkjh'
                })
            ;
            let url = "/parofalla-catalogo-editar";

            function respuesta(auxiliar) {
                if (auxiliar.respuesta === 1) {
                    // setModalAbierto(!modalAbierto)
                    // setTipoAdvertencia("success")
                    // setMensaje("Se edito correctamente el registro " + registro.Id);
                    setAviso(true)
                    toast.success("Se edito correctamente el registro " + registro.Id, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                    let posicion = catalogo.findIndex((element) => element.Id === registro.Id)
                    catalogo.splice(posicion, 1, registro)
                    setModalAbierto(false)
                }
            }

            llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, historial, source);

            // setActualiza(!actualiza)
        }

        const cerrarBorrar = () => {
            setAviso(false);
            setModalAbierto(false)
        };

        const validar = () => {
            if ((registro.Status && registro.Descr) !== "") {
                return editarParoFalla()
            } else {
                toast.error('Es obligatorio llenar todos los campos.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                return setValidateError('Es obligatorio llenar todos los campos.')
            }
        }

        const onChangeActivo = (event) => {
            event.preventDefault();
            setBotonDeshabilitado(false)
            console.log(event.target.value)
            switch (event.target.value) {
                case "1":
                    return setRegistro({
                        ...registro,
                        ...{Status: 1},
                    });
                case "2":
                    return setRegistro({
                        ...registro,
                        ...{Status: 2},
                    });
            }
        };

        const handleDescr = (newValue) => {
            setValidateError('')
            if (typeof newValue === 'string' && newValue) {
                return setValidateError('Ya existe una solución con la misma descripción.');
            } else if (newValue && newValue.inputValue) {
                if (newValue.inputValue.trim().length < 4) {
                    return setValidateError('La descripción es insuficiente.')
                } else if (newValue.inputValue.trim() === registro.Descr) {
                    return setValidateError('Ya existe una solución con la misma descripción.');
                }
                setBotonDeshabilitado(false)
                setRegistro({
                    ...registro, Descr: newValue.inputValue.trim(),
                })
            } else {
                if (typeof newValue === 'object') {
                    if (newValue) {
                        setRegistro({
                            ...registro, Descr: newValue.value,
                        })
                    } else {
                        setRegistro({
                            ...registro, Descr: '',
                        })
                    }
                }
            }
        }

        return (
        <Modal hideBackdrop disableEscapeKeyDown
            espera={cargando} btnDesact={botonDeshabilitado}
            titulo={titulo} modalAbierto={modalAbierto}
            setModalAbierto={setModalAbierto} guardarDatos={validar}
            mensaje={mensaje} tipoAdvertencia={tipoAdvertencia}
            aviso={aviso} setAviso={setAviso} tamanio={"sm"}
            cerrarBorrar={cerrarBorrar}
        >
            <form className={classes.root} autoComplete="off">
                <Grid container justifyContent={'space-around'} spacing={2}>
                    <Grid item xs={6}>
                        <Grid container spacing={2}>
                            <Grid item>
                                <FormControl className={classes.campos} size={'small'} component="fieldset">
                                    <FormLabel component="legend" size={'small'} >Estatus</FormLabel>
                                    <RadioGroup row aria-label="estatus" name="Estatus" size={'small'}
                                            defaultValue={Status.toString()} onChange={onChangeActivo}>
                                        <FormControlLabel value="1" style={{color: '#049b4f'}} size={'small'}
                                            control={<Radio color="primary" size={'small'} />} label="Activo"/>
                                        <FormControlLabel value="2" style={{color: '#F46239'}} size={'small'}
                                            control={<Radio color="primary" size={'small'} />} label="Inactivo"/>
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>

                    </Grid>
                    <Grid item xs={12}>
                        {catalogo.length > 0 ? <Autocomplete
                            defaultValue={Descr}
                            id='Descr'
                            onChange={(event, newValue) => handleDescr(newValue)}
                            filterOptions={(options, params) => {
                                const filtered = filter(options, params);
                                if (params.inputValue.trim() !== '') {
                                    filtered.push({
                                        inputValue: params.inputValue, label: `Agregar "${params.inputValue}"`,
                                    });
                                }
                                return filtered;
                            }}
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            options={catalogo.map(e => e.Descr)}
                            getOptionLabel={(option) => {
                                if (typeof option === 'string') {
                                    return option;
                                }
                                if (option.inputValue) {
                                    return option.inputValue;
                                }
                                return option.label;
                            }}
                            loading={catalogo.length != 0}
                            freeSolo
                            size="small"
                            color="primary"
                            renderInput={(params) => (
                                <TextField {...params} label="Escriba una solución" variant="outlined"/>)}
                        /> : <LinearProgress color="primary"/>}
                        {validateError ? <small style={{color: 'red'}}>{validateError}</small> : null}
                    </Grid>
                </Grid>
            </form>
        </Modal>);
    }
;

export default EditarParoFalla;