import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from "date-fns/locale/es";
import {
    DatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import {Box} from '@material-ui/core'

const Relojes = ({fechasSeleccionadas, setFechasSeleccionadas}) => {
    const {inicio, final} = fechasSeleccionadas
    const guardarInicio = auxfecha => {
        setFechasSeleccionadas({
            ...fechasSeleccionadas,
            inicio: auxfecha
        })
    };
    const guardarFinal = auxfecha => {
        setFechasSeleccionadas({
            ...fechasSeleccionadas,
            final: auxfecha
        })
    };

    return (
        <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
            <Box component="span" pb={1}>
                <Box ml={1} component="span" width={1 / 4}>
                    <DatePicker
                        disableFuture
                        format="dd/MM/yyyy"
                        views={["year", "month", "date"]}
                        label="Fecha inicial"
                        size="small"
                        minDate="2017-03-14"
                        maxDate={new Date()}
                        value={inicio}
                        onChange={guardarInicio}
                        style={{width: 6.5 + 'rem'}}
                    />
                </Box>
                <Box ml={1} component="span" width="25%">
                    <DatePicker
                        disableFuture
                        id="final"
                        format="dd/MM/yyyy"
                        label="Fecha Final"
                        views={["year", "month", "date"]}
                        minDate={inicio}
                        maxDate={new Date()}
                        value={final}
                        name="final"
                        size="small"
                        style={{width: 6.5 + 'rem'}}
                        onChange={guardarFinal}
                    />
                </Box>
            </Box>
        </MuiPickersUtilsProvider>
    );
}

export default Relojes
